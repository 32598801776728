import React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { Divider, ListItemText, Stack, Typography } from "@mui/material";
import { AppIcon } from "../../../../components/Elements";
import {
  type RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../store";
import {
  type NewSite,
  areaClicked,
  makeSelectAreasBySiteId,
  selectAllSites,
  selectCurrentSiteId,
  siteClicked,
} from "../../../locations/locationsSlice";
import { makeSelectInjuryReportsBySiteId } from "../../../injuries/store/injuryReportsSlice";
import { REPORT_TYPES } from "../../../injuries/constants";
import { ReportTypeIconNames } from "../../../injuries/constants/mappedProps";

export const testBasesMappedNames: any = {
  Grodem: "Grødem skole",
  "Kongsvinger ungdomsskole": "Kongsvinger ungdomsskole",
  Marikollen: "Marikollen skole",
};

type BaseLocationListItemProps = {
  site: NewSite;
  onClick: () => void;
};

const BaseLocationListItem = (props: BaseLocationListItemProps) => {
  const { site } = props;
  const dispatch = useAppDispatch();

  const selectAreasBySiteId = React.useMemo(makeSelectAreasBySiteId, [
    site.site_id,
  ]);
  const selectInjuryReportsBySiteId = React.useMemo(
    makeSelectInjuryReportsBySiteId,
    [site.site_id],
  );

  const areas = useAppSelector((state: RootState) =>
    selectAreasBySiteId(state, site.site_id),
  );
  const injuryReports = useAppSelector((state: RootState) =>
    selectInjuryReportsBySiteId(state, site.site_id),
  );

  const currentSiteId = useAppSelector(selectCurrentSiteId);

  const handleClick = () => {
    dispatch(siteClicked(site.site_id));
  };

  const accidentsCount = injuryReports.filter(
    (report: any) => report.type === REPORT_TYPES.ACCIDENT,
  ).length;
  const illnessCount = injuryReports.filter(
    (report: any) => report.type === REPORT_TYPES.ILLNESS,
  ).length;
  const minorInjuryCount = injuryReports.filter(
    (report: any) => report.type === REPORT_TYPES.MINOR_INJURY,
  ).length;

  return (
    <ListItemButton
      selected={site.site_id === currentSiteId}
      onClick={handleClick}
      sx={{}}
    >
      <ListItemText
        primary={
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant="h5" fontWeight={500}>
              {site?.name}
            </Typography>
            {areas.length > 0 && (
              <Typography
                variant="body2"
                sx={{ mb: "-1px" }}
                color="text.primary"
              >{`(${areas.length} areas)`}</Typography>
            )}
          </Stack>
        }
        secondary={
          <Stack direction="column" gap={1} mt={0.5} sx={{ minWidth: "0px" }}>
            <Typography
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                pr: 0.5,
              }}
              variant="body2"
              color="text.secondary"
            >
              {site.address}
            </Typography>
            <Stack direction="row" alignItems="center" gap={1.25}>
              <Stack direction="row" gap={1} flexGrow={1}>
                <Typography
                  variant="body2"
                  color={
                    injuryReports.length > 0 ? "text.primary" : "textVariant"
                  }
                >
                  {`${injuryReports.length} injuries reported`}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={0.75}>
                <AppIcon
                  iconName={ReportTypeIconNames[REPORT_TYPES.ACCIDENT]}
                  color={accidentsCount > 0 ? "textVariant" : "text.disabled"}
                  opticalSize={18}
                />
                <Typography
                  variant="body2"
                  color={accidentsCount > 0 ? "text.primary" : "textVariant"}
                >
                  {accidentsCount}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={0.75}>
                <AppIcon
                  iconName={ReportTypeIconNames[REPORT_TYPES.ILLNESS]}
                  opticalSize={18}
                  color={illnessCount > 0 ? "textVariant" : "text.disabled"}
                />
                <Typography
                  variant="body2"
                  color={illnessCount > 0 ? "text.primary" : "textVariant"}
                >
                  {illnessCount}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={0.75}>
                <AppIcon
                  iconName={ReportTypeIconNames[REPORT_TYPES.MINOR_INJURY]}
                  opticalSize={18}
                  color={minorInjuryCount > 0 ? "textVariant" : "text.disabled"}
                />
                <Typography
                  variant="body2"
                  color={minorInjuryCount > 0 ? "text.primary" : "textVariant"}
                >
                  {minorInjuryCount}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        }
      />
    </ListItemButton>
  );
};

export const BaseLocationList = () => {
  const sites = useAppSelector(selectAllSites);

  const dispatch = useAppDispatch();

  const handleClick = (siteId: string) => {
    dispatch(areaClicked(siteId));
  };

  return (
    <List
      disablePadding
      sx={{
        px: 0,
        py: 1,
      }}
    >
      {sites.map((site, i: number) => (
        <React.Fragment key={site.site_id}>
          <BaseLocationListItem
            site={site}
            onClick={() => handleClick(site.site_id)}
          />
          {i < sites.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};
