import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { AppDialogTitle } from "../../../components/Display/AppDialogTitle";
import {
  useForm,
  type SubmitHandler,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { type RootState, useAppDispatch, useAppSelector } from "../../../store";
import { selectEmployees } from "../../employees/employeesSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { snackAlertOpened } from "../../global/controlsSlice";
import { makeSelectInjuryReportById } from "../../injuries/store/injuryReportsSlice";
import type { IAction } from "../actionTypes";
import {
  AppMessage,
  useChatScroll,
} from "../../../components/Messages/AppMessage";
import { SendMessage } from "../../../components/Messages/SendMessage";
import {
  type CreateMessagePayload,
  createMessageSchema,
} from "../../injuries/components/ReportDetails/CasePanels/Comments";
import {
  type CreateInjuryActionCommentReq,
  createInjuryActionComment,
} from "../../injuries/store/injuryReportThunks";
import { useTranslation } from "react-i18next";

interface ActionCommentsDialogProps {
  open?: boolean;
  action: IAction;
  onClose: () => void;
}

export default function ActionCommentsDialog(props: ActionCommentsDialogProps) {
  const { action, onClose, open = false } = props;
  const { t } = useTranslation();

  const selectInjuryReportById = React.useMemo(makeSelectInjuryReportById, []);
  const injuryReport = useAppSelector((state: RootState) =>
    selectInjuryReportById(state, action.report_id),
  );

  const comments = action?.comments || [];
  const chatRef = useChatScroll(comments);

  const employees = useAppSelector(selectEmployees);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();

  const { handleSubmit, control, reset, setValue, watch } =
    useForm<CreateMessagePayload>({
      resolver: yupResolver(createMessageSchema),
      defaultValues: {
        attachments: [],
      },
    });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "attachments",
    },
  );

  const attachments = useWatch({ name: "attachments", control: control });

  const handleSave = async (req: CreateInjuryActionCommentReq) => {
    setLoading(true);

    const resultAction = await dispatch(createInjuryActionComment(req));
    if (createInjuryActionComment.fulfilled.match(resultAction)) {
      dispatch(
        snackAlertOpened({
          message: t("wif.injury.snackMessages.messageSaved.success"),
          severity: "success",
        }),
      );
      setLoading(false);
      reset();
    } else {
      if (resultAction.payload) {
        dispatch(
          snackAlertOpened({
            message: t("wif.injury.snackMessages.genericError"),
            severity: "error",
          }),
        );
        setLoading(false);
      } else {
        dispatch(
          snackAlertOpened({
            message: t("wif.injury.snackMessages.errorWithMessage", {
              message: resultAction.error,
            }),
            severity: "error",
          }),
        );
        setLoading(false);
      }
    }
  };

  const getBase64 = (file: any) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        res({
          file: reader.result,
          file_name: file.name,
          file_type: file.type,
          file_size: file.size,
        });
      reader.onerror = (error) => rej(error);
    });
  };

  const getAttachmentFiles = async (attachments: any[]) => {
    const promises = attachments
      .filter((attachment) => attachment?.file)
      .map((attachment) => getBase64(attachment.file));
    const attachment_files = await Promise.all(promises);

    return attachment_files;
  };
  const onSubmit: SubmitHandler<CreateMessagePayload> = (data) => {
    setLoading(true);
    getAttachmentFiles(data.attachments).then((attachment_files: any[]) => {
      const req: CreateInjuryActionCommentReq = {
        reportId: injuryReport.id,
        actionId: action.action_id,
        payload: {
          message: data.message,
          attachments: attachment_files,
        },
      };
      handleSave(req);
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <AppDialogTitle id="create-injury-action-dialog-title" onClose={onClose}>
        {t("wif.injury.actionCommentsDialog.title", {
          actionName: action?.text,
        })}
      </AppDialogTitle>
      <DialogContent
        dividers
        sx={{
          width: "600px",
          overflow: "hidden",
          pt: `0px!important`,
        }}
      >
        {action?.comments && action?.comments?.length > 0 ? (
          <Box
            ref={chatRef}
            sx={{
              flexGrow: 1,
              width: "560px",
              zIndex: 0,
              borderRadius: 1,
              maxHeight: {
                xs: "200px",
                md: "300px",
                lg: "400px",
              },
              overflowY: "auto",
              gap: 2.5,
              pt: 2.5,
              pb: 2.5,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {action?.comments &&
              action.comments.map((comment: any) => (
                <AppMessage
                  key={comment.comment_id}
                  timestamp={comment?.created_at}
                  sender={employees[comment.user_id]?.name || comment?.user_id}
                  message={comment?.message}
                  attachmentKeys={comment?.attachment_keys}
                />
              ))}
          </Box>
        ) : (
          <Box sx={{ py: 2.5 }}>
            <Typography variant="h5">
              {t("wif.injury.actionCommentsDialog.noComments")}
            </Typography>
            <Typography>
              {t("wif.injury.actionCommentsDialog.noCommentsText")}
            </Typography>
          </Box>
        )}

        <SendMessage
          formId="add-injury-comment"
          onSubmit={handleSubmit(onSubmit)}
          watch={watch}
          control={control}
          append={append}
          remove={remove}
          fields={fields}
          attachments={attachments}
          loading={loading}
        />
      </DialogContent>
    </Dialog>
  );
}
