import * as yup from "yup";
import { FormTextField } from "../../../../components/FormInputs";
import { FormRow } from "../../../../components/Forms";
import { useTranslation } from "react-i18next";
import { getTranslationKeyForSectionFieldLabel } from "../../helpers/dataMapping";
import { STUDENT_SECTION_NAMES } from "../../constants/student";

export const baseInjuredPartySchema = yup.object().shape({
  nationalIdentityNumber: yup
    .string()
    .required("minmax 11")
    .min(11, "min 11")
    .max(11, "max 11")
    .matches(/^\d+$/), // Should be 11 characters long
  citizenship: yup
    .string()
    .required("between 4 86")
    .min(4, "min 4")
    .max(86, "max 86"), // Should be between 4 and 86 characters long'
  address: yup
    .string()
    .required("between 4 42")
    .min(4, "min 4")
    .max(42, "max 42"),
  municipality: yup
    .string()
    .required("between 4 43")
    .min(4, "min 4")
    .max(43, "max 43"),
});

export type BaseInjuredPartySection = yup.InferType<
  typeof baseInjuredPartySchema
>;

export const BaseInjuredPartyControls = (props: any) => {
  const {
    methods: { control },
  } = props;
  const { t } = useTranslation();

  return (
    <>
      <FormTextField
        control={control}
        name="nationalIdentityNumber"
        label={t(
          getTranslationKeyForSectionFieldLabel(
            STUDENT_SECTION_NAMES.STUDENT,
            "nationalIdentityNumber",
          ),
        )}
        sx={{ maxWidth: "320px" }}
      />
      <FormTextField
        control={control}
        name="citizenship"
        label={t(
          getTranslationKeyForSectionFieldLabel(
            STUDENT_SECTION_NAMES.STUDENT,
            "citizenship",
          ),
        )}
        sx={{ maxWidth: "560px" }}
      />
      <FormRow>
        <FormTextField
          control={control}
          name="address"
          label={t(
            getTranslationKeyForSectionFieldLabel(
              STUDENT_SECTION_NAMES.STUDENT,
              "address",
            ),
          )}
          sx={{ flex: 1 }}
        />
        <FormTextField
          control={control}
          name="municipality"
          label={t(
            getTranslationKeyForSectionFieldLabel(
              STUDENT_SECTION_NAMES.STUDENT,
              "municipality",
            ),
          )}
          sx={{ flex: 1 }}
        />
      </FormRow>
    </>
  );
};
