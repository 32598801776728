import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  createSelector,
} from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import type { FetchActionsQuery, IAction } from "./actionTypes";
import {
  createInjuryReportAction,
  updateInjuryReportAction,
} from "../injuries/store/injuryReportsSlice";

import {
  completeInjuryAction,
  createInjuryActionComment,
  reopenInjuryAction,
} from "../injuries/store/injuryReportThunks";
import { httpPost } from "../../utils/httpService";
import config from "../../config/config";
import type { FetchedState } from "../departments/departmentsSlice";

const actionsAdapter = createEntityAdapter<IAction>({
  selectId: (action) => action.action_id,
});

export const fetchActions = createAsyncThunk(
  "actions/fetchActions",
  async (query: FetchActionsQuery, thunkAPI) => {
    const response = await httpPost(
      `${config.baseUrl}/actions/getActionsFromTable/${query.startIndex}/${query.count}`,
      query.body,
    );
    return response.data;
  },
);

const initialState = actionsAdapter.getInitialState({
  fetched: "idle",
} as FetchedState);

export const actionsSlice = createSlice({
  name: "action",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchActions.pending, (state, action) => {
      state.fetched = "pending";
    });
    builder.addCase(fetchActions.fulfilled, (state, action) => {
      actionsAdapter.setAll(state, action.payload);
      state.fetched = "succeeded";
    });
    builder.addCase(createInjuryReportAction.fulfilled, (state, action) => {
      actionsAdapter.addOne(state, action.payload.actionRes);
    });
    builder.addCase(updateInjuryReportAction.fulfilled, (state, action) => {
      actionsAdapter.updateOne(state, action.payload.actionRes);
    });
    builder.addCase(createInjuryActionComment.fulfilled, (state, action) => {
      actionsAdapter.updateOne(state, action.payload.actionRes);
    });
    builder.addCase(completeInjuryAction.fulfilled, (state, action) => {
      actionsAdapter.updateOne(state, action.payload.actionRes);
    });
    builder.addCase(reopenInjuryAction.fulfilled, (state, action) => {
      actionsAdapter.updateOne(state, action.payload.actionRes);
    });
  },
});

export default actionsSlice.reducer;

export const {
  selectIds: selectActionIds,
  selectEntities: selectActions,
  selectAll: selectAllActions,
  selectTotal: selectTotalActions,
  selectById: selectActionById,
} = actionsAdapter.getSelectors<RootState>((state) => state.actions);

export const selectActionsFetched = (state: RootState) => state.actions.fetched;

const getId = (_: any, id: any) => id;

export const makeSelectActionById = () => {
  return createSelector([selectActions, getId], (actions, id) => actions[id]);
};
