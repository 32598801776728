import React from "react";
import type { Control, FieldPath } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import type {
  FieldValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form/dist/types";
import FormCoordinateMapWithOutlinePart from "./Parts/FormCoordinateMapWithOutlinePart";
import type { NewCoordinates } from "../../features/locations/locationsSlice";

type FormCoordinateMapWithOutlineProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  control?: Control<TFieldValues, any>;
  outlineFieldName: TName;
  baseCoordinates: NewCoordinates;
  watch?: UseFormWatch<TFieldValues>;
  setValue: UseFormSetValue<TFieldValues>;
  name: TName;
};

export function FormCoordinateMapWithOutline<TFieldValues extends FieldValues>(
  props: FormCoordinateMapWithOutlineProps<TFieldValues>,
) {
  const {
    control = useFormContext<TFieldValues>().control,
    setValue,
    watch = useFormContext<TFieldValues>().watch,
    outlineFieldName,
    name,
    baseCoordinates,
  } = props;

  const outline = watch(outlineFieldName);

  const handleChangeOutline = (newOutline: any) => {
    setValue(outlineFieldName, newOutline);
  };

  React.useEffect(() => {
    console.log(
      `FormCoordinateMapWithOutline:outline: ${JSON.stringify(outline)}`,
    );
  }, [outline]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, ...field }, fieldState: { error } }) => (
        <FormCoordinateMapWithOutlinePart
          coordinates={value}
          onChangeCoordinates={(newCoordinates: any) => {
            field.onChange(newCoordinates);
          }}
          error={!!error}
          errorMessage={error?.message}
          outline={{
            value: outline,
            onChange: handleChangeOutline,
          }}
          baseCoordinates={baseCoordinates}
        />
      )}
    />
  );
}
