import { Button, Paper, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import { AppIcon } from "../Elements";
import { FormTextField } from "../FormInputs";
import { LoadingButtonContainer } from "../Elements/LoadingButtonContainer";
import { BOX_SHADOWS } from "../../config/theme/boxShadows";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { TKB_INJURY } from "../../features/injuries/constants";

export const SendEmailMessageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1.5),
  width: "100%",
  paddingBottom: theme.spacing(1.5),

  overflow: "visible",
}));

export const emailMessageSchema = yup.object().shape({
  message: yup.string().required().min(4, "min 4").max(1000, "max 1000"),
});

export type EmailMessagePayload = yup.InferType<typeof emailMessageSchema>;

export const SendMessagePaper = (props) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        borderRadius: 1,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        boxShadow: BOX_SHADOWS.elevation0,
        pb: 1.5,
        width: "100%",
      }}
    >
      <Stack direction="column" gap={0} width="100%">
        {props.children}
      </Stack>
    </Paper>
  );
};

export const SendMessageInputsContainer = (props) => {
  const { borderTop = false } = props;
  return (
    <Stack
      direction="column"
      gap={1.5}
      sx={{
        flex: 1,
        px: 1.5,
        pt: 1.5,

        ...(borderTop && {
          borderTop: 1,
          borderColor: "divider",
        }),
      }}
    >
      {props.children}
    </Stack>
  );
};

type EmailMessageProps = {
  formId: string;
  onSubmit: (data: any) => void;
  onReset: () => void;
  watch: any;
  control: any;
  loading: boolean;
  cancelButtonLabel?: string;
  cancelButtonIconName?: string;
  sendButtonLabel?: string;
  sendButtonIconName?: string;
  reset: any;
};

export const EmailMessage = (props: EmailMessageProps) => {
  const {
    formId,
    onSubmit,
    onReset,
    control,
    loading = false,
    cancelButtonLabel = "cancel",
    cancelButtonIconName,
    sendButtonLabel = "post",
    sendButtonIconName,
    reset,
  } = props;
  const { t } = useTranslation();

  const handleReset = () => {
    reset({ message: null });
    onReset();
  };
  return (
    <SendEmailMessageContainer>
      <form id={formId} onSubmit={onSubmit} onReset={handleReset}>
        <SendMessagePaper>
          <SendMessageInputsContainer>
            <FormTextField
              variant="standard"
              name="message"
              label=""
              maxRows={6}
              multiline
              control={control}
              fullWidth
              placeholder={t("wif.injury.messageInput.textPlaceholder")}
            />
            <Stack
              direction="row"
              justifyContent="end"
              alignItems="end"
              gap={2}
              width="100%"
            >
              <Box
                sx={{
                  justifyContent: "end",
                }}
              >
                <LoadingButtonContainer
                  loading={loading}
                  sx={{
                    mx: "2",
                    px: 2,
                  }}
                >
                  <Button
                    sx={{
                      mx: 2,
                    }}
                    type="reset"
                    variant="contained"
                    disabled={loading}
                    endIcon={
                      cancelButtonIconName && (
                        <AppIcon iconName={cancelButtonIconName} />
                      )
                    }
                  >
                    {t(`${TKB_INJURY}.cancel`)}
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    endIcon={
                      sendButtonIconName && (
                        <AppIcon iconName={sendButtonIconName} />
                      )
                    }
                  >
                    {t(`${TKB_INJURY}.messageInput.send`)}
                  </Button>
                </LoadingButtonContainer>
              </Box>
            </Stack>
          </SendMessageInputsContainer>
        </SendMessagePaper>
      </form>
    </SendEmailMessageContainer>
  );
};
