import {
  Divider,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import {
  type RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../store";
import {
  type NewArea,
  type NewSite,
  areaClicked,
  makeSelectAreasBySiteId,
  makeSelectAreasCountBySiteId,
  selectAllSites,
  selectCurrentAreaId,
  selectCurrentSiteId,
  selectSites,
  siteClicked,
  siteDeselected,
} from "../../locationsSlice";
import { SIKRI_COLORS } from "../../../../config";
import { AppIcon } from "../../../../components/Elements";
import { useTheme, alpha } from "@mui/material";
import AreasTabMap from "./AreasTabMap";
import { BOX_SHADOWS } from "../../../../config/theme/boxShadows";

type AreaMapListItemProps = {
  area: NewArea;
};

const AreaMapListItem = (props: AreaMapListItemProps) => {
  const { area } = props;
  const dispatch = useAppDispatch();

  const currentAreaId = useAppSelector(selectCurrentAreaId);

  const handleClick = () => {
    dispatch(areaClicked(area.area_id));
  };

  return (
    <ListItemButton
      selected={area.area_id === currentAreaId}
      onClick={handleClick}
    >
      <ListItemIcon>
        <AppIcon
          iconName={area.outline_coordinates ? "polyline" : "pin_drop"}
        />
      </ListItemIcon>
      <ListItemText
        primary={<Typography variant="body2">{area.name}</Typography>}
      />
    </ListItemButton>
  );
};

type AreaMapListProps = {
  siteId: string;
};

const AreaMapList = (props: AreaMapListProps) => {
  const { siteId } = props;

  const selectAreasBySiteId = React.useMemo(makeSelectAreasBySiteId, [siteId]);
  const areas = useAppSelector((state: RootState) =>
    selectAreasBySiteId(state, siteId),
  );

  const theme = useTheme();

  return (
    <List
      disablePadding
      sx={{
        px: 0,
        py: 0,
        pb: 1.5,
        overflowY: "auto",
        "& .Mui-selected": {
          ...(theme.palette.mode === "light"
            ? {
                bgcolor: `${SIKRI_COLORS.galaxyGreenSelected}!important`,
              }
            : {
                bgcolor: `${alpha(SIKRI_COLORS.galaxyGreen, 0.24)}!important`,
              }),
        },
      }}
    >
      {areas.map((area: NewArea, i: number) => (
        <React.Fragment key={area.area_id}>
          <AreaMapListItem area={area} />
        </React.Fragment>
      ))}
    </List>
  );
};

type SitesMapListItemProps = {
  site: NewSite;
};

const SitesMapListItem = (props: SitesMapListItemProps) => {
  const { site } = props;
  const dispatch = useAppDispatch();

  const currentSiteId = useAppSelector(selectCurrentSiteId);

  const selectAreasCountBySiteId = React.useMemo(makeSelectAreasCountBySiteId, [
    site,
  ]);

  const areasCount = useAppSelector((state: RootState) =>
    selectAreasCountBySiteId(state, site.site_id),
  );

  const handleClick = () => {
    dispatch(siteClicked(site.site_id));
  };

  return (
    <ListItemButton
      selected={site.site_id === currentSiteId}
      onClick={handleClick}
      sx={{}}
    >
      <ListItemText
        primary={
          <Typography variant="h6" fontWeight={500}>
            {site?.name}
          </Typography>
        }
        secondary={
          <Typography
            variant="body2"
            color="text.secondary"
          >{`${areasCount} areas`}</Typography>
        }
      />
      <AppIcon iconName="chevron_right" opticalSize={24} color="textVariant" />
    </ListItemButton>
  );
};

const SitesMapList = () => {
  const sites = useAppSelector(selectAllSites);
  const theme = useTheme();

  return (
    <List
      disablePadding
      sx={{
        px: 0,
        py: 0,
        "& .Mui-selected": {
          ...(theme.palette.mode === "light"
            ? {
                bgcolor: `${SIKRI_COLORS.galaxyGreenSelected}!important`,
              }
            : {
                bgcolor: `${alpha(SIKRI_COLORS.galaxyGreen, 0.24)}!important`,
              }),
        },
      }}
    >
      {sites.map((site: NewSite, i: number) => (
        <React.Fragment key={site.site_id}>
          <SitesMapListItem site={site} />
          {i < sites.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};

const SitesMapLocationsControl = () => {
  const dispatch = useAppDispatch();

  const currentSiteId = useAppSelector(selectCurrentSiteId);

  const sites = useAppSelector(selectSites);

  const handleClickBack = () => {
    dispatch(siteDeselected());
  };

  return (
    <Paper
      elevation={1}
      sx={{
        position: "absolute",
        left: 12,
        top: 12,
        maxHeight: "calc(100% - 32px)",
        border: 1,
        borderColor: "backgroundVariant",
        width: "360px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "100%",
          p: 1.5,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {currentSiteId && (
          <Stack direction="row" alignItems="center" gap={1}>
            <IconButton edge="start" size="small" onClick={handleClickBack}>
              <AppIcon iconName="arrow_back" />
            </IconButton>
            <Typography variant="h5" fontWeight={500}>
              {sites[currentSiteId]?.name}
            </Typography>
          </Stack>
        )}

        <TextField
          size="small"
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AppIcon iconName="search" />
              </InputAdornment>
            ),
            sx: {
              pl: 1.75,
              borderRadius: 1.5,
              borderColor: (theme) => `${theme.palette.primary.main}!important`,
              "& input": {
                lineHeight: 20,
                ml: 0,
                pl: 0.25,
                "&::placeholder": {
                  color: "textVariant",
                  opacity: 0.9,
                },
              },
              boxShadow: BOX_SHADOWS.elevation0,
              height: 36,
              mb: -0.5,
            },
          }}
          placeholder="Search..."
        />
      </Box>
      {currentSiteId ? (
        <AreaMapList siteId={currentSiteId} />
      ) : (
        <SitesMapList />
      )}
    </Paper>
  );
};

export const AreasTabMapView = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          flex: 1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AreasTabMap />
      </Box>
      <SitesMapLocationsControl />
    </Box>
  );
};
