export const SiteTypeIconNames = {
  generic: "home_work",
  school: "school",
  workplace: "domain",
  office: "domain",
  warehouse: "warehouse",
};

export const SiteTypeOptions = Object.keys(SiteTypeIconNames);

export const getSiteTypeIconName = (type: string) => {
  return SiteTypeIconNames[type] || "generic";
};
