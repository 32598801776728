import * as yup from "yup";
import type { WithFormMethods } from "../../../../../components/Forms";
import {
  ACCIDENT_TYPE_OPTIONS,
  DAMAGED_BODY_PART_OPTIONS,
  DAMAGE_NATURE_OPTIONS,
} from "..";
import { FormAutocompleteWithOrder } from "../../../../../components/FormInputs/FormAutocomplete";
import { useTranslation } from "react-i18next";
import { TKB_INJURY } from "../../../constants";
import { GENERIC_VALIDATION_MSG } from "../../../constants/mappedInputLabels";
import { getTranslationKeyForSectionFieldLabel } from "../../../helpers/dataMapping";
import { EMPLOYEE_SECTION_NAMES } from "../../../constants/employee";

export const B_TYPE_OPTIONS: string[] = [
  "B1",
  "B2",
  "B3",
  "B4",
  "B5",
  "B6",
  "B7",
  "B8",
  "B9",
  "B10",
  "B11",
  "B12",
  "B13",
  "B14",
  "B15",
  "B16",
  "B17",
  "B18",
  "B19",
];

export const B_TYPE_NAMES: any = {
  B1: "Lack of marking, notification, signage",
  B10: "Incorrect charging",
  B11: "Wrong location",
  B12: "Incorrect lifting",
  B13: "Incorrect execution of the task",
  B14: "Insufficient training",
  B15: "Attacked/threatened by another person",
  B16: "Unreasonable 'play', joke or bet",
  B17: "Influence of alcohol/other drugs",
  B18: "Corporate sports etc",
  B19: "Other",
  B2: "Inadequate safety procedures",
  B3: "Insufficient protection",
  B4: "Protective measures not/partially implemented",
  B5: "Protective equipment disabled",
  B6: "Protective equipment removed",
  B7: "Unauthorized use of equipment",
  B8: "Defective equipment",
  B9: "Incorrect use of machines and equipment",
};

export const E_TYPE_OPTIONS: string[] = [
  "E1",
  "E2",
  "E3",
  "E4",
  "E5",
  "E6",
  "E7",
  "E8",
  "E9",
];

export const E_TYPE_NAMES: any = {
  E1: "Contact with electrical voltage, temperature, dangerous substances",
  E2: "Drowned, buried, enveloped",
  E3: "Horizontal or vertical contact with a stationary object (the injured person is in motion)",
  E4: "Hit by a moving object, collision with",
  E5: "Contact with sharp, pointed, uneven, rough external factors",
  E6: "Caught, pinched, etc.",
  E7: "Physical or mental strain",
  E8: "Bites, kicks, etc. (animal or human)",
  E9: "Other contacts - modes of damage that are not included in the classification of contact - mode of damage",
};

export const F_TYPE_OPTIONS: string[] = [
  "F1",
  "F2",
  "F3",
  "F4",
  "F5",
  "F6",
  "F7",
  "F8",
  "F9",
  "F10",
  "F11",
  "F12",
  "F13",
];

export const F_TYPE_NAMES: any = {
  F1: "Space for industrial activity",
  F10: "Below the ground surface, excl. construction areas",
  F11: "On/over water, excl. construction areas",
  F12: "In high-pressure environments, excl. construction areas",
  F13: "Other workplace that is not included in the classification of workplace",
  F2: "Construction area, construction site, open pit quarry, open pit mine",
  F3: "Space for agriculture, animal breeding, fish farming, forestry",
  F4: "Space for service activities, office, entertainment, miscellaneous",
  F5: "Health facilities",
  F6: "Public place",
  F7: "At home",
  F8: "Sports area",
  F9: "In the air, above ground level, excl. construction areas",
};

export const G_TYPE_OPTIONS: string[] = [
  "G1",
  "G2",
  "G3",
  "G4",
  "G5",
  "G6",
  "G7",
  "G8",
  "G9",
];

export const G_TYPE_NAMES: any = {
  G1: "Deviation due to electrical problems, explosion, fire",
  G2: "Deviation as a result of overflow, overturning, leakage, outflow, evaporation, radiation",
  G3: "Bursting, bursting, splitting, sliding, falling, external factor collapses",
  G4: "Loss of control (in whole or in part) over machine, means of transport, equipment for moving material, hand-held tool, object, animal",
  G5: "Slipping – tripping and falling – person falling",
  G6: "Body movement without physical strain (usually leading to an external injury)",
  G7: "Body movement under or with physical strain (usually leading to an internal injury)",
  G8: "Shock, fear, violence, aggression, threat, presence",
  G9: "Other deviations that are not included in the classification of deviations",
};

export const H_TYPE_OPTIONS: string[] = ["H0", "H1", "H2", "H9"];

export const H_TYPE_NAMES: any = {
  H0: "The number of days lost (days of absence) is unknown",
  H1: "3 days or less (days of absence)",
  H2: "More than 3 days (absence days)",
  H9: "Fatal accident",
};

export const employeeTypesSchema = yup.object().shape({
  accidentType: yup.array().required(GENERIC_VALIDATION_MSG),
  background: yup.array().required(GENERIC_VALIDATION_MSG),
  damageNature: yup.array().required(GENERIC_VALIDATION_MSG),
  damagedBodyPart: yup.array().required(GENERIC_VALIDATION_MSG),
  damageMode: yup.array().required(GENERIC_VALIDATION_MSG),
  typeOfWorkplace: yup.array().required(GENERIC_VALIDATION_MSG),
  deviation: yup.array().required(GENERIC_VALIDATION_MSG),
  assumedAbsence: yup.array().required(GENERIC_VALIDATION_MSG),
});

export type EmployeeTypesSection = yup.InferType<typeof employeeTypesSchema>;

export const EmployeeTypesControls = ({
  methods,
  methods: { control },
}: WithFormMethods<EmployeeTypesSection>) => {
  const { t } = useTranslation();

  const translationPart = `${TKB_INJURY}.employee.typeTab`;
  return (
    <>
      <FormAutocompleteWithOrder
        control={control}
        name="accidentType"
        options={ACCIDENT_TYPE_OPTIONS}
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES,
            "accidentType",
          ),
        )}
        getLabelFromOption={(option) =>
          `${option} ${t(`${translationPart}.typeOfAccident.${option}`)}`
        }
      />
      <FormAutocompleteWithOrder
        control={control}
        name="background"
        options={B_TYPE_OPTIONS}
        getLabelFromOption={(option) =>
          `${option} ${t(`${translationPart}.background.${option}`)}`
        }
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES,
            "background",
          ),
        )}
      />
      <FormAutocompleteWithOrder
        control={control}
        name="damageNature"
        options={DAMAGE_NATURE_OPTIONS}
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES,
            "damageNature",
          ),
        )}
        getLabelFromOption={(option) =>
          `${option} ${t(`${translationPart}.natureOfDamage.${option}`)}`
        }
      />
      <FormAutocompleteWithOrder
        control={control}
        name="damagedBodyPart"
        options={DAMAGED_BODY_PART_OPTIONS}
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES,
            "damagedBodyPart",
          ),
        )}
        getLabelFromOption={(option) =>
          `${option} ${t(`${translationPart}.damagedBodyPart.${option}`)}`
        }
      />
      <FormAutocompleteWithOrder
        control={control}
        name="damageMode"
        options={E_TYPE_OPTIONS}
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES,
            "damageMode",
          ),
        )}
        getLabelFromOption={(option) =>
          `${option} ${t(`${translationPart}.damageMode.${option}`)}`
        }
      />
      <FormAutocompleteWithOrder
        control={control}
        name="typeOfWorkplace"
        options={F_TYPE_OPTIONS}
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES,
            "typeOfWorkplace",
          ),
        )}
        getLabelFromOption={(option) =>
          `${option} ${t(`${translationPart}.typeOfWorkplace.${option}`)}`
        }
      />
      <FormAutocompleteWithOrder
        control={control}
        name="deviation"
        options={G_TYPE_OPTIONS}
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES,
            "deviation",
          ),
        )}
        getLabelFromOption={(option) =>
          `${option} ${t(`${translationPart}.deviation.${option}`)}`
        }
      />
      <FormAutocompleteWithOrder
        control={control}
        name="assumedAbsence"
        options={H_TYPE_OPTIONS}
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES,
            "assumedAbsence",
          ),
        )}
        getLabelFromOption={(option) =>
          `${option} ${t(`${translationPart}.assumedAbsence.${option}`)}`
        }
      />
    </>
  );
};
