import * as React from "react";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  type RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../store";
import {
  makeSelectInjuryReportsByModule,
  selectAllInjuryUnits,
} from "../../store/injuryReportsSlice";
import { useTranslation } from "react-i18next";
import {
  CASE_STATUS,
  REPORT_CATEGORY,
  REPORT_TYPES,
  TKB_INJURY,
} from "../../constants";
import {
  DepartmentChild,
  SamDepartment,
  selectAllDepartments,
} from "../../../departments/departmentsSlice";
import { selectAllEmployees } from "../../../employees/employeesSlice";
import { getSectionWithDepartmentAndUnitName } from "../../helpers";
import _ from "lodash";
import { getActiveUser } from "../../../../utils/user";
import { IconButton, Paper, Stack, useTheme } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import { AppIcon } from "../../../../components/Elements";
import Chip from "@mui/material/Chip";
import Drawer from "@mui/material/Drawer";
import {
  makeSelectInjuryControlsByModuleView,
  selectedFiltersSet,
  appliedFilterRemoved,
  appliedFiltersReset,
  appliedFiltersSet,
  selectedFiltersReset,
  selectedFilterRemoved,
} from "../../store/injuryControlsSlice";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { t } from "i18next";

export const getFilterBetweenDateTimestampValues = (daysBack: number) => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  const tomorrow = new Date().setDate(date.getDate() + 1);
  return [new Date().setDate(date.getDate() - daysBack), tomorrow];
};

export const getTertiaryDatesForYear = (
  year: number,
  tertiary: 1 | 2 | 3,
): [number, number] => {
  switch (tertiary) {
    case 1:
      return [new Date(year, 0, 1).getTime(), new Date(year, 3, 30).getTime()];
    case 2:
      return [new Date(year, 4, 1).getTime(), new Date(year, 7, 31).getTime()];
    case 3:
      return [new Date(year, 8, 1).getTime(), new Date(year, 11, 31).getTime()];
    default:
      return [new Date(year, 0, 1).getTime(), new Date(year, 11, 31).getTime()];
  }
};

type StatisticsFiltersDialogProps = {
  module?: string;
};

export const StatisticsFiltersDialog = (
  props: StatisticsFiltersDialogProps,
) => {
  const { module = "all" } = props;
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Button
        variant="outlined"
        startIcon={<AppIcon iconName="tune" />}
        onClick={() => setDrawerOpen(true)}
      >
        {t("wif.injury.filters", "Filters")}
      </Button>
      <Drawer
        anchor={"right"}
        variant="temporary"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            top: "56px",
            height: "calc(100% - 56px)",
          },
        }}
      >
        <Paper
          sx={{
            height: "100%",
            width: "360px",
            px: 2.5,
            pt: 2.25,
            pb: 2.25,
            overflowY: "auto",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <StatisticFilters
            module={module}
            handleClose={() => setDrawerOpen(false)}
          />
        </Paper>
      </Drawer>
    </React.Fragment>
  );
};

type StatisticFiltersProps = {
  module?: string;
  handleClose?: any;
};
const StatisticFilters = (props: StatisticFiltersProps) => {
  const { module = "all", handleClose } = props;

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const selectInjuryControlsByModuleView = React.useMemo(
    makeSelectInjuryControlsByModuleView,
    [module],
  );
  const selectInjuryReportsByModule = React.useMemo(
    makeSelectInjuryReportsByModule,
    [module],
  );
  const injuryControls = useAppSelector((state: RootState) =>
    selectInjuryControlsByModuleView(state, `${module}_handler`),
  );
  const injuryReports = useAppSelector((state: RootState) =>
    selectInjuryReportsByModule(state, module),
  );
  const departments = useAppSelector(selectAllDepartments);
  const employees = useAppSelector(selectAllEmployees);
  const units = useAppSelector(selectAllInjuryUnits);
  const activeUser = getActiveUser();

  const reportYears = useAppSelector(
    (state: RootState) => state.injuryReports.reportYears,
  );

  const tertiaryOptions = reportYears
    .map((year) => {
      return [
        {
          value: getTertiaryDatesForYear(year, 1),
          label: `${t("wif.injury.firstTertiary")} (${year})`,
          key: `1T`,
          year,
        },
        {
          value: getTertiaryDatesForYear(year, 2),
          label: `${t("wif.injury.secondTertiary")} (${year})`,
          key: "2T",
          year,
        },
        {
          value: getTertiaryDatesForYear(year, 3),
          label: `${t("wif.injury.thirdTertiary")} (${year})`,
          key: "3T",
          year,
        },
      ];
    })
    .flat();

  const [filterValues, setFilterValues] = React.useState({});
  const selectedDepartmentsChildren = React.useMemo(() => {
    const departmentFilters: { label: string; value: string }[] =
      filterValues["departmentId"] || [];

    return departmentFilters.flatMap((departmentFilter) => {
      const department = departments.find(
        (dep) => dep.id === departmentFilter.value,
      );

      return department.children;
    });
  }, [filterValues]);

  React.useEffect(() => {
    let initFilterValues: any = {};
    for (const activeFilter of injuryControls.selectedFilters) {
      initFilterValues = {
        ...initFilterValues,
        [activeFilter.field]: activeFilter.value,
      };
    }
    setFilterValues(initFilterValues);
  }, [module]);

  const setFieldFilterValue = (
    field: string,
    newValue: StatisticsFilterOption | StatisticsFilterOption[] | null,
  ) => {
    setFilterValues((prevFilterValues) => {
      const newFilterValues = { ...prevFilterValues };
      newFilterValues[field] = newValue;
      return newFilterValues;
    });
  };

  const filterControls = React.useMemo(() => {
    const controlItems: any[] = [
      {
        field: "status",
        label: t(`${TKB_INJURY}.status`),
        paths: ["status"],
        operator: "is",
        options: [
          { value: CASE_STATUS.ONGOING, label: t(`${TKB_INJURY}.ongoing`) },
          { value: CASE_STATUS.CLOSED, label: t(`${TKB_INJURY}.closed`) },
          { value: CASE_STATUS.REJECTED, label: t(`${TKB_INJURY}.rejected`) },
        ],
      },
      {
        field: "type",
        label: t(`${TKB_INJURY}.type`),
        paths: ["type"],
        operator: "is",
        options: [
          {
            value: REPORT_TYPES.ACCIDENT,
            label: t(`${TKB_INJURY}.reportType.accident`),
          },
          {
            value: REPORT_TYPES.ILLNESS,
            label: t(`${TKB_INJURY}.reportType.illness`),
          },
          {
            value: REPORT_TYPES.MINOR_INJURY,
            label: t(`${TKB_INJURY}.reportType.minorInjury`),
          },
        ],
      },
    ];

    if (module === "all") {
      controlItems.unshift({
        field: "category",
        label: t(`${TKB_INJURY}.category`),
        paths: ["category"],
        operator: "is",
        options: [
          {
            value: REPORT_CATEGORY.STUDENT,
            label: t(`${TKB_INJURY}.reportCategory.student`),
          },
          {
            value: REPORT_CATEGORY.EMPLOYEE,
            label: t(`${TKB_INJURY}.reportCategory.employee`),
          },
        ],
      });
    }

    controlItems.push({
      field: "reportedTimestamp",
      label: t("wif.injury.reportedDate"),
      paths: ["reportedTimestamp"],
      operator: "between",
      options: [
        {
          value: getFilterBetweenDateTimestampValues(7),
          label: t("wif.injury.last7Days"),
          key: "7D",
        },
        {
          value: getFilterBetweenDateTimestampValues(30),
          label: t("wif.injury.lastMonth"),
          key: "1M",
        },
        {
          value: getFilterBetweenDateTimestampValues(183),
          label: t("wif.injury.last6Months"),
          key: "6M",
        },
        {
          value: getFilterBetweenDateTimestampValues(365),
          label: t("wif.injury.lastYear"),
          key: "1Y",
        },
        ...tertiaryOptions,
      ],
    });
    const injuryDepartmentPaths: string[] = [];
    const injuryUnitPaths: string[] = [];
    let injuryDepartmentIds: string[] = [];
    let injuryUnitIds: string[] = [];

    for (const report of injuryReports) {
      const sec = getSectionWithDepartmentAndUnitName(report);
      if (sec && report?.sections[sec]?.departmentId) {
        const path = `sections.${sec}.departmentId`;
        if (injuryDepartmentPaths.indexOf(path) === -1) {
          injuryDepartmentPaths.push(path);
        }
        injuryDepartmentIds.push(report?.sections[sec]?.departmentId);
      }
      if (sec && report?.sections[sec]?.unitId) {
        const path = `sections.${sec}.unitId`;
        if (injuryUnitPaths.indexOf(path) === -1) {
          injuryUnitPaths.push(path);
        }
        injuryUnitIds.push(report?.sections[sec]?.unitId);
      }
    }

    let injuryAssignees = injuryReports.map((report) => report?.assignee_id);
    let injuryReporters = injuryReports.map((report) => report?.reporter_id);
    injuryDepartmentIds = _.uniq(injuryDepartmentIds);
    injuryUnitIds = _.uniq(injuryUnitIds);
    injuryAssignees = _.uniq(injuryAssignees);
    injuryReporters = _.uniq(injuryReporters);
    const filteredDepartments = departments.filter(
      (department) => injuryDepartmentIds.indexOf(department.id) !== -1,
    );
    const filteredUnitIds = units.filter(
      (unit) => injuryUnitIds.indexOf(unit.unit_id) !== -1,
    );
    const filteredAssignees = employees.filter(
      (user) => injuryAssignees.indexOf(user.id) !== -1,
    );
    const filteredReporters = employees.filter(
      (user) => injuryReporters.indexOf(user.id) !== -1,
    );
    if (filteredReporters.length > 0) {
      controlItems.push({
        field: "reporter_id",
        label: t("wif.injury.reportedBy"),
        paths: ["reporter_id"],
        operator: "isAnyOf",
        options: filteredReporters.map((employee) => ({
          value: employee.id,
          label: employee?.name || employee.id,
        })),
      });
    }
    if (filteredDepartments.length > 0) {
      controlItems.push({
        field: "departmentId",
        label: t(`${TKB_INJURY}.department`),
        paths: injuryDepartmentPaths,
        operator: "isAnyOf",
        options: filteredDepartments.map((department) => ({
          value: department.id,
          label: department?.name || department.id,
        })),
      });
    }

    if (selectedDepartmentsChildren.length > 0) {
      controlItems.push({
        field: "subDepartmentId",
        label: t(`${TKB_INJURY}.subDepartment`),
        paths: injuryDepartmentPaths,
        operator: "isAnyOf",
        options: selectedDepartmentsChildren.map((child) => ({
          value: child.id,
          label: child.name,
        })),
      });
    }

    if (filteredUnitIds.length > 0) {
      controlItems.push({
        field: "unitId",
        label: t(`${TKB_INJURY}.unit`),
        paths: injuryUnitPaths,
        operator: "isAnyOf",
        options: filteredUnitIds.map((unit) => ({
          value: unit.unit_id,
          label: unit.unit_name,
        })),
      });
    }
    if (filteredAssignees.length > 0) {
      controlItems.push({
        field: "handler",
        label: t(`${TKB_INJURY}.assignedTo`),
        paths: ["assignee_id"],
        operator: "isAnyOf",
        options: filteredAssignees.map((employee) => ({
          value: employee.id,
          label:
            activeUser.id === employee.id
              ? "Me"
              : employee?.name || employee.id,
        })),
      });
    }

    return controlItems;
  }, [
    injuryReports,
    employees,
    departments,
    units,
    selectedDepartmentsChildren,
  ]);

  const applyFilters = () => {
    const selectedFilters: any = [];

    for (const [field, value] of Object.entries(filterValues)) {
      const controlItem = filterControls.find(
        (controlItem) => controlItem.field === field,
      );
      if (
        controlItem &&
        value &&
        value !== null &&
        !(Array.isArray(value) && value.length < 1)
      ) {
        selectedFilters.push({
          field: field,
          label: controlItem?.label,
          operator: controlItem.operator,
          paths: controlItem.paths,
          value: value,
        });
      }
    }

    const appliedFilters = filterValues["subDepartmentId"]
      ? selectedFilters.filter((filter) => filter.field !== "departmentId")
      : selectedFilters;

    dispatch(
      appliedFiltersSet({
        moduleView: `${module}_handler`,
        newAppliedFilters: appliedFilters,
      }),
    );
    dispatch(
      selectedFiltersSet({
        moduleView: `${module}_handler`,
        newSelectedFilters: selectedFilters,
      }),
    );

    handleClose();
  };

  const resetFilters = () => {
    setFilterValues({});
    dispatch(appliedFiltersReset({ moduleView: `${module}_handler` }));
  };

  const filterValuesVals = Object.values(filterValues).filter(
    (value) =>
      value && value !== null && !(Array.isArray(value) && value.length < 1),
  );

  return (
    <>
      <Stack direction="row" alignItems="center" gap={1} mb={1.5}>
        <Typography variant="h4" sx={{ flex: 1 }}>
          {t("wif.injury.filters", "Filters")}
        </Typography>
        <Button
          variant="text"
          color="primary"
          startIcon={<AppIcon iconName="refresh" />}
          onClick={resetFilters}
          disabled={injuryControls.appliedFilters.length === 0}
        >
          {t("wif.injury.resetAll", "Reset all")}
        </Button>
        <IconButton
          edge="end"
          size="small"
          sx={{
            mr: -0.75,
          }}
          onClick={handleClose}
        >
          <AppIcon iconName="close" opticalSize={22} />
        </IconButton>
      </Stack>
      <Stack direction="column" gap={2}>
        <Stack direction="column" gap={2}>
          {filterControls.map((filterControl) => (
            <FilterSection
              key={filterControl.field}
              label={filterControl.label}
              field={filterControl.field}
              options={filterControl.options}
              value={filterValues[filterControl.field]}
              setFilterValue={(newValue: StatisticsFilterOption | null) =>
                setFieldFilterValue(filterControl.field, newValue)
              }
            />
          ))}
        </Stack>
        <Stack
          direction="row"
          gap={1}
          justifyContent="space-between"
          alignItems="center"
          mt={1}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={applyFilters}
            disabled={filterValuesVals.length === 0}
          >
            {t("wif.injury.applyFilters", "Apply filters")}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

type FilterChipProps = {
  option: StatisticsFilterOption;
  checked?: boolean;
  setFilterValue?: any;
};

const FilterChip = (props: FilterChipProps) => {
  const { option, checked, setFilterValue } = props;

  const handleClick = () => {
    if (checked) {
      setFilterValue(null);
    } else {
      setFilterValue(option);
    }
  };
  return (
    <Chip
      key={option.value}
      label={option?.label || option?.value}
      variant={checked ? "filled" : "outlined"}
      color={checked ? "secondary" : "default"}
      onClick={handleClick}
      size="medium"
      icon={
        checked ? (
          <AppIcon iconName="check" opticalSize={22} color="primary.main" />
        ) : null
      }
      sx={{
        fontSize: "body2.fontSize",
        ...(checked && {
          pl: "4px",
          "& .MuiChip-label": {
            pl: "4px!important",
          },
        }),
      }}
    />
  );
};

type FilterSectionProps = {
  label: string;
  options: StatisticsFilterOption[];
  value?: StatisticsFilterOption | StatisticsFilterOption[];
  setFilterValue?: (
    newValue: StatisticsFilterOption | StatisticsFilterOption[] | null,
  ) => void;
  field?: string;
};

const FilterSection = (props: FilterSectionProps) => {
  const { label, options, value, setFilterValue, field } = props;
  const [expanded, setExpanded] = React.useState(true);

  const handleClickExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <Stack
      direction="column"
      sx={{
        overflow: "visible",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          height: "32px",
          overflow: "visible",
          width: "100%",
        }}
      >
        <Typography variant="h5" flex={1} color="text.secondary">
          {label}
        </Typography>
        <IconButton
          onClick={handleClickExpand}
          size="small"
          edge="end"
          sx={{
            p: 0.25,
          }}
        >
          <AppIcon
            iconName={expanded ? "expand_less" : "expand_more"}
            color={"textVariant"}
            opticalSize={22}
          />
        </IconButton>
      </Stack>

      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
        sx={{
          py: 1,
        }}
      >
        {(options.length >= 10 && field !== "reportedTimestamp") ||
        field === "departmentId" ? (
          <StatisticsFilterInput
            label={label}
            options={options}
            value={value && Array.isArray(value) && value}
            setFilterValue={setFilterValue}
          />
        ) : (
          <Stack direction="row" gap={1} flexWrap="wrap">
            {options.map((option) => (
              <FilterChip
                key={option.value}
                option={option}
                checked={
                  !Array.isArray(value) &&
                  (value?.label === option?.label ||
                    value?.value === option?.value)
                }
                setFilterValue={setFilterValue}
              />
            ))}
          </Stack>
        )}
      </Collapse>
    </Stack>
  );
};

interface StatisticsFilterOption {
  value: any;
  label?: string;
}

type StatisticsFilterInputProps = {
  label: string;
  options: StatisticsFilterOption[];
  value?: StatisticsFilterOption[];
  setFilterValue?: (newValue: StatisticsFilterOption[] | null) => void;
};

const StatisticsFilterInput = (props: StatisticsFilterInputProps) => {
  const { label, options, value, setFilterValue } = props;

  return (
    <Autocomplete
      multiple
      value={value || []}
      onChange={(event: any, newValue: StatisticsFilterOption[] | null) => {
        setFilterValue(newValue);
      }}
      limitTags={2}
      options={options}
      getOptionLabel={(option) => option?.label || option.value}
      renderInput={(params) => <TextField {...params} placeholder={label} />}
    />
  );
};

type StatisticsAppliedFilterChipsProps = {
  module: string;
};

export const StatisticsAppliedFilterChips = (
  props: StatisticsAppliedFilterChipsProps,
) => {
  const { module = "all" } = props;
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { t } = useTranslation();

  const selectInjuryControlsByViewAndModule = React.useMemo(
    makeSelectInjuryControlsByModuleView,
    [module],
  );
  const injuryControls = useAppSelector((state: RootState) =>
    selectInjuryControlsByViewAndModule(state, `${module}_handler`),
  );

  const appliedStatisticFilters = injuryControls.appliedFilters;

  const handleRemoveFilter = (field: string) => {
    dispatch(appliedFilterRemoved({ moduleView: `${module}_handler`, field }));
    dispatch(selectedFilterRemoved({ moduleView: `${module}_handler`, field }));
  };

  const handleResetFilters = () => {
    dispatch(appliedFiltersReset({ moduleView: `${module}_handler` }));
    dispatch(selectedFiltersReset({ moduleView: `${module}_handler` }));
  };

  if (appliedStatisticFilters.length < 1) {
    return <></>;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      flexWrap="wrap"
      sx={{
        px: {
          xs: 2.5,
          md: 3,
        },
      }}
    >
      <Typography variant="body2" sx={{ mr: 0.25 }}>
        {t("wif.injury.appliedFilters", "Applied filters")}:{" "}
      </Typography>
      {appliedStatisticFilters.map((appliedFilter) => (
        <Chip
          key={appliedFilter.field}
          variant="filled"
          color="secondary"
          sx={{
            fontSize: "body2.fontSize",
            pl: "3px",
            pr: 0.75,
            "& .MuiChip-label": {
              pr: 2,
            },
          }}
          onDelete={() => handleRemoveFilter(appliedFilter.field)}
          deleteIcon={
            <CloseOutlinedIcon
              fontSize="small"
              sx={{
                color: `${theme.palette.primary.main}!important`,
                opacity: 0.8,
                fontSize: "18px!important",
                marginLeft: "-4px!important",
              }}
            />
          }
          label={
            <React.Fragment>
              <Typography
                sx={{ display: "inline", fontWeight: 400 }}
                component="span"
                variant="body2"
              >
                {`${appliedFilter?.label || appliedFilter.field}: `}
              </Typography>
              {`${
                Array.isArray(appliedFilter.value)
                  ? appliedFilter.value
                      .map(
                        (filterValue) =>
                          filterValue?.label || filterValue.value,
                      )
                      .join(", ")
                  : appliedFilter.value?.label || appliedFilter.value.value
              }`}
            </React.Fragment>
          }
        />
      ))}
      {appliedStatisticFilters.length > 0 && (
        <Button
          variant="text"
          color="primary"
          sx={{ height: "32px", ml: 0.5 }}
          onClick={handleResetFilters}
        >
          {t("wif.injury.clearAll", "Clear all")}
        </Button>
      )}
    </Stack>
  );
};
