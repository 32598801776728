import * as React from "react";
import {
  DialogContent,
  ListItemButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { AppUserAvatar } from "../Elements/AppAvatar";
import {
  formatDateTimeFromTimestamp,
  getTranslateFriendlyUpdatedStr,
} from "../../utils/format";
import type { WithChildren } from "../../types";
import AppDialog from "../AppDialog/AppDialog";
import { AppDialogTitle } from "../Display/AppDialogTitle";
import { useTranslation } from "react-i18next";
import { APP_COLORS } from "../../config";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

type AppLogItemProps = WithChildren<{
  username: string;
  message: string;
  time: any;
  disablePx?: boolean;
  paper?: boolean;
  resourceLink?: {
    to: string;
    text: string;
  };
  hideDetails?: boolean;
}>;

export const AppLogItem = (props: AppLogItemProps) => {
  const {
    username,
    message,
    time,
    children,
    disablePx,
    paper = true,
    resourceLink,
    hideDetails = false,
  } = props;
  const { t } = useTranslation();

  const [open, setOpen] = React.useState<boolean>(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ListItemButton
      disabled={!hideDetails}
      selected={open}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: 1.25,
        pb: hideDetails ? 1.25 : 0,
        pt: 1.25,
        px: disablePx ? 0 : 2.5,
        opacity: "1!important",
      }}
      onClick={() => setOpen(true)}
    >
      <AppUserAvatar username={username} size="medium" />
      <Stack
        direction="column"
        gap={1}
        flexGrow={1}
        sx={{
          minWidth: 0,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          {message}
          {resourceLink && (
            <Link
              component={RouterLink}
              variant="h6"
              to={resourceLink.to}
              underline={"none"}
              sx={{
                display: "inline",
                "&:hover": {
                  textDecoration: "underline",
                  color: "text.primary",
                },
                fontWeight: 600,
                minWidth: 0,
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "#c7d2fe" /* indigo 300 */,
              }}
            >
              {` ${resourceLink.text}`}
            </Link>
          )}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary", mt: -0.75 }}>
          {t.apply(
            null,
            getTranslateFriendlyUpdatedStr(new Date(time).getTime()),
          )}
        </Typography>
        {hideDetails ? (
          <AppDialog
            open={open}
            onClose={handleClose}
            disableDefaultCancel
            sx={{
              bgcolor: "background.default",
            }}
          >
            <AppDialogTitle onClose={handleClose}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1.25,
                  flex: 1,
                }}
              >
                <AppUserAvatar username={username} size="medium" />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    flexGrow: 1,
                  }}
                >
                  <Typography variant="h6" fontWeight={500}>
                    {message}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary", mt: -0.75 }}
                  >
                    {formatDateTimeFromTimestamp(time)}
                  </Typography>
                </Box>
              </Box>
            </AppDialogTitle>
            <DialogContent>
              <Paper
                variant="outlined"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  p: 2,
                  py: 1.75,
                }}
              >
                {children}
              </Paper>
            </DialogContent>
          </AppDialog>
        ) : (
          <>
            {paper ? (
              <Paper
                variant="outlined"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 1,
                  minWidth: 0,
                  width: "100%",
                  overflow: "hidden",
                  gap: 2,
                  p: 2,
                  py: 1.5,
                  bgcolor: (theme) =>
                    theme.palette.mode === "light"
                      ? APP_COLORS.light2
                      : APP_COLORS.dark1,
                }}
              >
                {children}
              </Paper>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 1,
                  minWidth: 0,
                  width: "100%",
                  overflow: "hidden",
                  gap: 2,
                  py: 0.5,
                }}
              >
                {children}
              </Box>
            )}
          </>
        )}
      </Stack>
    </ListItemButton>
  );
};
