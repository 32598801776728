import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {
  type FieldValues,
  type SubmitHandler,
  useForm,
  type UseFormProps,
  type UseFormReturn,
} from "react-hook-form";
import type * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

type AppFormProps<TFieldValues extends FieldValues, TSchema> = {
  className?: string;
  onSubmit?: SubmitHandler<TFieldValues>;
  children?: (methods: UseFormReturn<TFieldValues>) => React.ReactNode;
  options?: UseFormProps<TFieldValues>;
  data?: any;
  id?: string;
  schema?: TSchema;
};

export const AppForm = <
  TFieldValues extends Record<string, unknown> = Record<string, unknown>,
  TSchema = yup.ObjectSchema<any>,
>(
  props: AppFormProps<TFieldValues, TSchema>,
) => {
  const {
    className = "",
    onSubmit,
    children,
    options,
    id,
    schema,
    data,
  } = props;
  const methods = useForm<TFieldValues>({
    ...options,
    resolver: schema && yupResolver(schema),
    defaultValues: React.useMemo(() => {
      if (data) {
        return data;
      }
      if (options?.defaultValues) {
        return options.defaultValues;
      }
    }, [data]),
  });

  const {
    formState: { errors },
  } = methods;
  const scrollRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.scrollIntoView();
    }
  }, [errors]);

  return (
    <form
      onSubmit={
        onSubmit !== undefined ? methods.handleSubmit(onSubmit) : undefined
      }
      id={id}
      style={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: -64,
          left: 0,
        }}
        ref={scrollRef}
      />
      {Object.keys(errors).length > 0 && (
        <Alert
          severity="error"
          sx={{
            mb: 3,
          }}
        >
          <AlertTitle>Submission failed</AlertTitle>
          {JSON.stringify(errors, null, 2)}
          {Object.keys(errors).map((key, index) => (
            <ErrorMessage
              errors={errors}
              name={key as any}
              render={({ message }) => (
                <Typography variant="body2">
                  <strong>{`${key} `}</strong>
                  {message}
                </Typography>
              )}
            />
          ))}
        </Alert>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2.5,
        }}
      >
        {children?.(methods)}
      </Box>
    </form>
  );
};
