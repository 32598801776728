import React from "react";
import * as yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { AppDialogTitle } from "../../../components/Display/AppDialogTitle";
import { useForm, type SubmitHandler } from "react-hook-form";
import { FormSelect, FormTextField } from "../../../components/FormInputs";
import { Stack } from "@mui/material";
import { type RootState, useAppDispatch, useAppSelector } from "../../../store";
import { selectAllEmployees } from "../../employees/employeesSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { snackAlertOpened } from "../../global/controlsSlice";
import {
  makeSelectInjuryReportById,
  updateInjuryReportAction,
} from "../../injuries/store/injuryReportsSlice";
import { LoadingButtonContainer } from "../../../components/Elements/LoadingButtonContainer";
import type { IAction } from "../actionTypes";
import { useTranslation } from "react-i18next";
import { reopenInjuryAction } from "../../injuries/store/injuryReportThunks";

export const reopenActionDetailsSchema = yup.object().shape({
  comment: yup.string().notRequired().min(4, "min 4").max(180, "max 180"),
  responsible: yup.string().required(),
});

export type IReopenActionDetails = yup.InferType<
  typeof reopenActionDetailsSchema
>;

interface ReopenActionDialogProps {
  action?: IAction;
  onClose: () => void;
  open?: boolean;
}

export default function ReopenActionDialog(props: ReopenActionDialogProps) {
  const { onClose, action, open = false } = props;
  const { t } = useTranslation();

  const selectInjuryReportById = React.useMemo(makeSelectInjuryReportById, [
    action.report_id,
  ]);
  const injuryReport = useAppSelector((state: RootState) =>
    selectInjuryReportById(state, action.report_id),
  );

  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState(false);

  const { handleSubmit, control, reset } = useForm<IReopenActionDetails>({
    resolver: yupResolver(reopenActionDetailsSchema),
    defaultValues: React.useMemo(() => {
      return {
        responsible: action?.assignee?.length > 0 && action?.assignee?.[0],
      };
    }, [action]),
  });

  const handleReopen = () => {
    const payload = Object.assign(
      {},
      {
        ...action,
        status: "pending",
      },
    );
    const req = {
      reportId: injuryReport.id,
      action_id: action?.action_id,
      payload: payload,
    };
    setLoading(true);
    console.log(`completeInjuryReportAction: ${JSON.stringify(req)}`);
    dispatch(updateInjuryReportAction(req))
      .unwrap()
      .then((originalPromiseResult) => {
        setLoading(false);
        dispatch(
          snackAlertOpened({
            severity: "success",
            message: t("wif.injury.snackMessages.actionReopened.success"),
          }),
        );
        onClose && onClose();
      })
      .catch((rejectedValueOrSerializedError) => {
        setLoading(false);
        dispatch(
          snackAlertOpened({
            severity: "error",
            message: t("wif.injury.snackMessages.genericError"),
          }),
        );
      });
  };

  const onSubmit: SubmitHandler<IReopenActionDetails> = (data) => {
    const payload: any = {
      report_id: injuryReport.id,
      action_id: action?.action_id,
      reopenDetails: data,
    };
    const req = {
      payload: payload,
    };
    setLoading(true);
    dispatch(reopenInjuryAction(req))
      .unwrap()
      .then((originalPromiseResult) => {
        setLoading(false);
        dispatch(
          snackAlertOpened({
            severity: "success",
            message: t("wif.injury.snackMessages.actionReopened.success"),
          }),
        );
        onClose && onClose();
      })
      .catch((rejectedValueOrSerializedError) => {
        setLoading(false);
        dispatch(
          snackAlertOpened({
            severity: "error",
            message: t("wif.injury.snackMessages.genericError"),
          }),
        );
      });
  };

  const users = useAppSelector(selectAllEmployees);

  const repeatedNames = React.useMemo(() => {
    const allNames: string[] = [],
      repeated: string[] = [];

    users.forEach((department) => {
      if (allNames.includes(department.name)) {
        repeated.push(department.name);
      } else {
        allNames.push(department.name);
      }
    });

    return repeated;
  }, [users]);

  return (
    <Dialog open={open} onClose={onClose}>
      <AppDialogTitle id="create-injury-action-dialog-title" onClose={onClose}>
        {t("wif.injury.reopenActionDialog.title", { actionName: action?.text })}
      </AppDialogTitle>
      <DialogContent
        dividers
        sx={{
          width: 600,
        }}
      >
        <form id="reopen-injury-action" onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" gap={2.5} width="100%">
            <FormSelect
              control={control}
              name="responsible"
              label={t("wif.injury.reopenActionDialog.responsible.title")}
              options={users.map((user, i) => ({
                value: user.id,
                label:
                  repeatedNames.indexOf(user.name) === -1
                    ? `${user.name} (${user?.email})`
                    : user.name,
              }))}
            />
            <FormTextField
              name="comment"
              control={control}
              multiline
              minRows={2}
              maxRows={6}
              label={t("wif.injury.reopenActionDialog.comment.title")}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="text" onClick={onClose} sx={{ mr: 1 }}>
          {t("wif.injury.cancel")}
        </Button>
        <LoadingButtonContainer loading={loading}>
          <Button
            type="submit"
            form="reopen-injury-action"
            variant="contained"
            disabled={loading}
          >
            {t("wif.injury.reopen")}
          </Button>
        </LoadingButtonContainer>
      </DialogActions>
    </Dialog>
  );
}
