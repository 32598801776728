import axios, { type AxiosError } from "axios";
import { Auth } from "aws-amplify"; // Import Amplify Auth module
import CookieStorage from "./CookieStorage";
import { CONSTANTS } from "../common/constants";

const instance = axios.create({});

instance.interceptors.request.use(
  async (config) => {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken().getJwtToken();
      const selectedOrg: any = CookieStorage.getItem(
        CONSTANTS.COOKIE_KEYS.SELECTED_ORG,
      );

      if (idToken) {
        config.headers.Authorization = idToken;
      }
      if (selectedOrg) {
        const parsedOrg = JSON.parse(selectedOrg);
        parsedOrg.org_name = undefined;
        parsedOrg.name = undefined;
        config.headers["x-selected-org"] = JSON.stringify(parsedOrg);
      }
    } catch (error) {
      console.error("Error fetching token:", error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      window.location.href = "/#/auth/login";
    }
    return Promise.reject(error);
  },
);

export default instance;
