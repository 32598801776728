import * as React from "react";
import * as yup from "yup";
import { FormTextField } from "../../../../components/FormInputs";
import type { WithFormMethods } from "../../../../components/Forms";
import { AppForm } from "../../../../components/Forms/AppForm";
import { useCurrentSite } from "../../locationsHooks";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { useLoading } from "../../../../common/useLoading";
import { updateSiteDetails } from "../../locationsThunks";
import { FormSingleAutocomplete } from "../../../../components/FormInputs/FormSingleAutocomplete";
import { SiteTypeIconNames, SiteTypeOptions } from "../SiteIcon";
import { getActiveUser } from "../../../../utils/user";
import {
  makeSelectDepartmentIdsLeadByUser,
  selectDepartmentEntities,
} from "../../../departments/departmentsSlice";

export const editSitePropertiesFormSchema = yup.object({
  name: yup.string().required(),
  type: yup.string().required("Type is required"),
  department_id: yup.string().required("Type is required"),
  description: yup.string().optional().notRequired(),
});

export type IUpdateSiteDetailsPayload = yup.InferType<
  typeof editSitePropertiesFormSchema
>;

export const EditSitePropertiesControls = (
  props: WithFormMethods<IUpdateSiteDetailsPayload>,
) => {
  const { methods } = props;
  const { control, watch, setValue } = methods;

  const user = getActiveUser();
  const selectDepartmentIds = React.useMemo(
    makeSelectDepartmentIdsLeadByUser,
    [],
  );

  const departmentIds = useAppSelector((state) =>
    selectDepartmentIds(state, user?.id),
  );
  const departments = useAppSelector(selectDepartmentEntities);

  const departmentMapped = React.useMemo(() => {
    const labels = {};
    const depth = {};
    for (const id of departmentIds) {
      labels[id] = departments[id].name;
      depth[id] = departments[id].depth;
    }
    return {
      labels,
      depth,
    };
  }, [departmentIds]);

  return (
    <>
      <FormTextField
        control={control}
        name="name"
        label="Site name"
        placeholder="Site name"
      />
      <FormSingleAutocomplete
        name="type"
        label="Site type"
        control={control}
        options={SiteTypeOptions}
        optionIconNames={SiteTypeIconNames}
      />
      <FormSingleAutocomplete
        name="department_id"
        label="Department"
        control={control}
        options={departmentIds
          .slice()
          .sort((a, b) =>
            departments[a].path.localeCompare(departments[b].path),
          )}
        optionLabels={departmentMapped?.labels}
        optionDepths={departmentMapped?.depth}
      />
      <FormTextField
        control={control}
        name="description"
        label="Description"
        placeholder="Description"
        rows={4}
      />
    </>
  );
};

type EditSitePropertiesFormProps = {
  stopEditing?: () => void;
};

export const EditSitePropertiesForm = (props: EditSitePropertiesFormProps) => {
  const dispatch = useAppDispatch();
  const site = useCurrentSite();

  const [{ isLoading, loadingMessage }, { startLoading, stopLoading }] =
    useLoading(false);

  const handleSubmit = (data) => {
    const payload = Object.assign({}, data);
    startLoading();
    dispatch(updateSiteDetails({ site_id: site.site_id, payload })).then(
      (resAction) => {
        stopLoading();
      },
    );
  };

  return (
    <AppForm<IUpdateSiteDetailsPayload, typeof editSitePropertiesFormSchema>
      schema={editSitePropertiesFormSchema}
      onSubmit={handleSubmit}
      id="update-site-details-form"
      data={{
        name: site.name,
        description: site.description,
        type: site.type,
      }}
    >
      {(methods) => <EditSitePropertiesControls methods={methods} />}
    </AppForm>
  );
};
