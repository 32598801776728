import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  FormDatePicker,
  FormSelectWithSearch,
  FormTextField,
} from "../../../../components/FormInputs";
import type { WithFormMethods } from "../../../../components/Forms";
import { useAppSelector } from "../../../../store";
import {
  selectAllDepartments,
  selectDepartmentEntities,
} from "../../../departments/departmentsSlice";
import { GENERIC_VALIDATION_MSG } from "../../constants/mappedInputLabels";
import {
  getTranslationKeyForSectionFieldLabel,
  getTranslationKeyForSectionFieldPlaceholder,
} from "../../helpers/dataMapping";
import { EMPLOYEE_SECTION_NAMES } from "../../constants/employee";
import _ from "lodash";
import { FormSingleAutocomplete } from "../../../../components/FormInputs/FormSingleAutocomplete";
import {
  selectAllEmployees,
  selectEmployeesFetched,
} from "../../../employees/employeesSlice";

export const baseMinorInjurySchema = yup.object().shape({
  title: yup.string().required(GENERIC_VALIDATION_MSG),
  date: yup.string().required(GENERIC_VALIDATION_MSG),
  nameOfInjuredParty: yup.string().required(GENERIC_VALIDATION_MSG),
  ssn: yup
    .string()
    .required("minmax 11")
    .min(11, "min 11")
    .max(11, "max 11")
    .matches(/^\d+$/),
  departmentId: yup.string().required(GENERIC_VALIDATION_MSG),
  departmentName: yup.string().optional(),
  unitId: yup.string().optional(),
  description: yup.string().required(GENERIC_VALIDATION_MSG),
});

export type BaseMinorInjurySection = yup.InferType<
  typeof baseMinorInjurySchema
>;

export const BaseMinorInjurySection = (
  props: WithFormMethods<BaseMinorInjurySection>,
) => {
  const { t } = useTranslation();
  const { methods } = props;
  const { control, watch, setValue } = methods;

  const departments = useAppSelector(selectAllDepartments);
  const departmentEntities = useAppSelector(selectDepartmentEntities);
  const employees = useAppSelector(selectAllEmployees);
  const injuryEmployeesFetched: string = useAppSelector(selectEmployeesFetched);

  const departmentNames: any = _.mapValues(
    departmentEntities,
    (dep) => dep.name,
  );

  return (
    <>
      <FormTextField
        control={control}
        name="title"
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.MINOR_INJURY,
            "title",
          ),
        )}
        placeholder={t(
          getTranslationKeyForSectionFieldPlaceholder(
            EMPLOYEE_SECTION_NAMES.MINOR_INJURY,
            "title",
          ),
        )}
      />
      <FormDatePicker
        control={control}
        name="date"
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.MINOR_INJURY,
            "date",
          ),
        )}
      />
      <FormSelectWithSearch
        control={control}
        name="nameOfInjuredParty"
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.MINOR_INJURY,
            "nameOfInjuredParty",
          ),
        )}
        options={employees.map((employee) => ({
          value: employee.id,
          label: employee?.name,
        }))}
        minorInjury={true}
        loading={injuryEmployeesFetched !== "succeeded"}
        employees={employees}
      />
      <FormTextField
        control={control}
        name="ssn"
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.MINOR_INJURY,
            "ssn",
          ),
        )}
        placeholder={t(
          getTranslationKeyForSectionFieldPlaceholder(
            EMPLOYEE_SECTION_NAMES.MINOR_INJURY,
            "ssn",
          ),
        )}
      />
      <FormSingleAutocomplete
        control={control}
        name="departmentId"
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.EMPLOYER,
            "department",
          ),
        )}
        options={departments.map((department, i) => department.id)}
        optionLabels={departmentNames}
      />
    </>
  );
};
