import React from "react";
import {
  makeSelectLocationSiteById,
  siteDeselected,
} from "../../locationsSlice";
import {
  type RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../store";
import {
  DetailViewTabs,
  DetailsCardHeader,
  StyledDetailsCard,
  useAppTabs,
} from "../../../../components/DetailsCard/DetailsCard";
import { CurrentSiteContext } from "../../locationsHooks";
import { SiteDetailsInfoPanel } from "./SiteDetailsInfoPanel";
import { getSiteTypeIconName } from "../SiteIcon";
import { useTranslation } from "react-i18next";
import { SiteDetailsHistoryPanel } from "./SiteDetailsHistoryPanel";

type SiteDetailsProps = {
  siteId: string;
};

export const SiteDetails = (props: SiteDetailsProps) => {
  const { siteId } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const selectSiteById = React.useMemo(makeSelectLocationSiteById, [siteId]);
  const site = useAppSelector((state: RootState) =>
    selectSiteById(state, siteId),
  );

  const [tab, handleChangeTab] = useAppTabs("details");

  const handleCloseSite = () => {
    dispatch(siteDeselected());
  };

  const getTabLabel = React.useCallback(
    (tabName: string) => {
      return t(`wif.injury.common.${tabName}`);
    },
    [t],
  );

  return (
    <CurrentSiteContext.Provider value={site}>
      <StyledDetailsCard>
        <DetailsCardHeader
          title={site?.name}
          onClose={handleCloseSite}
          iconName={getSiteTypeIconName(site?.type)}
        />
        <DetailViewTabs
          tabs={["details", "activity"]}
          activeTab={tab}
          onChangeActiveTab={handleChangeTab}
          getTabLabel={getTabLabel}
        />
        {tab === "details" && <SiteDetailsInfoPanel />}
        {tab === "activity" && <SiteDetailsHistoryPanel />}
      </StyledDetailsCard>
    </CurrentSiteContext.Provider>
  );
};
