import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { AppDialogTitle } from "../../../components/Display/AppDialogTitle";
import { List, ListItem, ListItemText } from "@mui/material";
import type { IAction } from "../actionTypes";
import { TKB_INJURY } from "../../injuries/constants";
import { useTranslation } from "react-i18next";

interface ActionCompletionDialogProps {
  action?: IAction;
  onClose: () => void;
  open?: boolean;
}

export default function ActionCompletionDialog(
  props: ActionCompletionDialogProps,
) {
  const { action, onClose, open = false } = props;

  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <AppDialogTitle id="create-injury-action-dialog-title" onClose={onClose}>
        {`${action?.text ? action.text : action?.action_id}: ${t(
          `${TKB_INJURY}.completionEvaluation`,
        )}`}
      </AppDialogTitle>

      <DialogContent
        dividers
        sx={{
          width: 600,
        }}
      >
        <List
          disablePadding
          sx={{
            p: 0,
            mb: 1.5,
          }}
        >
          {action?.completionAndEvaluation?.evaluation && (
            <ListItem disablePadding>
              <ListItemText
                primary={t(`${TKB_INJURY}.evaluation`)}
                secondary={action?.completionAndEvaluation?.evaluation}
              />
            </ListItem>
          )}
          {action?.completionAndEvaluation?.workDone && (
            <ListItem disablePadding>
              <ListItemText
                primary={t(`${TKB_INJURY}.workDone`)}
                secondary={action?.completionAndEvaluation?.workDone}
              />
            </ListItem>
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
}
