import * as EMPLOYEE_SECTION_NAMES from "./sectionNames";
export * as EMPLOYEE_SECTION_NAMES from "./sectionNames";

export const EmployeeSectionDetails = {
  [EMPLOYEE_SECTION_NAMES.EMPLOYEE]: {
    title: "Employee",
    description: "Information about the injured employee",
  },
  [EMPLOYEE_SECTION_NAMES.EMPLOYMENT]: {
    title: "Employment",
    description: "About the employment of the injured party",
  },
  [EMPLOYEE_SECTION_NAMES.EMPLOYER]: {
    title: "Employer",
    description: "Information about the employer and location",
  },
  [EMPLOYEE_SECTION_NAMES.ACCIDENT]: {
    title: "Accident",
    description: "Information about the nature and time of the accident",
  },
  [EMPLOYEE_SECTION_NAMES.ILLNESS]: {
    title: "Illness",
    description: "Information about the illness",
  },
  [EMPLOYEE_SECTION_NAMES.MINOR_INJURY]: {
    title: "Minor injury",
    description: "Employee Minor Injury description",
  },
  [EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION]: {
    title: "Further information",
    description:
      "Detailed description of the sequence of events, triggers, and underlying causes/circumstances that led to the injury",
  },
  [EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES]: {
    title: "Types",
    description:
      "Detailed description of the sequence of events, triggers, and underlying causes/circumstances that led to the injury",
  },
};
