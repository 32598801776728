import { Text, View } from "@react-pdf/renderer";
import { DocColors } from "./InjuryDocument";

type DocListContainerHeaderProps = {
  color?: string;
  title: string;
};

export const DocListHeader = (props: DocListContainerHeaderProps) => {
  const { color = DocColors.text.primary, title } = props;

  return (
    <View
      style={{
        backgroundColor: DocColors.background.level3,
        width: "100%",
        borderRadius: 6,
        paddingHorizontal: 12,
        paddingVertical: 10,
        marginTop: 2,
        marginBottom: 4,
        color: DocColors.primary,
      }}
    >
      <Text
        style={{
          fontSize: 12,
          fontWeight: 600,
          color: color,
        }}
      >
        {title}
      </Text>
    </View>
  );
};

type DocListContainerProps = {
  bgcolor?: string;
  variant?: "outlined" | "filled";
  borderRadius?: number;
  children: any;
};

export const DocListContainer = (props: DocListContainerProps) => {
  const { children } = props;

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginBottom: 12,
      }}
    >
      {children}
    </View>
  );
};

type InjuryDocumentFormItemProps = {
  field: string;
  value: string;
  bottomDivider?: boolean;
  disableHorizontalPadding?: boolean;
  disableVerticalPadding?: boolean;
};

export const DocListDescriptionItem = (props: InjuryDocumentFormItemProps) => {
  const {
    field,
    value,
    bottomDivider = true,
    disableHorizontalPadding = false,
    disableVerticalPadding = false,
  } = props;
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        paddingTop: disableVerticalPadding ? 0 : 8,
        paddingBottom: disableVerticalPadding ? 0 : 7,
        paddingHorizontal: disableHorizontalPadding ? 0 : 12,
        width: "100%",
        ...(bottomDivider
          ? {
              borderBottom: `1px solid ${DocColors.background.level4}`,
            }
          : {
              paddingBottom: 12,
            }),
      }}
    >
      <Text
        style={{
          fontSize: 10,
          color: DocColors.text.primary,
          fontWeight: 500,
        }}
      >
        {field}
      </Text>
      <Text
        style={{
          fontSize: 10,
          color: DocColors.text.secondary,
          fontWeight: 400,
        }}
      >
        {value}
      </Text>
    </View>
  );
};
