export function stringToHslColor(str, s, l) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;
  return `hsl(${h}, ${s}%, ${l}%)`;
}

const getHashOfString = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  hash = Math.abs(hash);
  return hash;
};

const normalizeHash = (hash: number, min: number, max: number) => {
  return Math.floor((hash % (max - min)) + min);
};

const hRange = [0, 360];
const sRange = [55, 65];
const lRange = [35, 45];

const darkHRange = [0, 360];
const darkSRange = [40, 60];
const darkLRange = [45, 65];

const generateHSL = (name: string, dark?: boolean): any => {
  const hash = getHashOfString(name);
  if (dark) {
    const h = normalizeHash(hash, darkHRange[0], darkHRange[1]);
    const s = normalizeHash(hash, darkSRange[0], darkSRange[1]);
    const l = normalizeHash(hash, darkLRange[0], darkLRange[1]);
    return [h, s, l];
  }
  const h = normalizeHash(hash, hRange[0], hRange[1]);
  const s = normalizeHash(hash, sRange[0], sRange[1]);
  const l = normalizeHash(hash, lRange[0], lRange[1]);
  return [h, s, l];
};

export const generateAvatarColor = (str: string, dark?: boolean): any => {
  const hsl = generateHSL(str, dark);
  return `hsl(${hsl[0]}, ${hsl[1]}%, ${hsl[2]}%)`;
};
