import * as React from "react";
import * as yup from "yup";
import type { WithFormMethods } from "../../../../components/Forms";
import { AppForm } from "../../../../components/Forms/AppForm";
import { useCurrentArea } from "../../locationsHooks";
import { useAppSelector } from "../../../../store";
import { useLoading } from "../../../../common/useLoading";
import { selectSites } from "../../locationsSlice";
import { FormCoordinateMapWithOutline } from "../../../../components/FormInputs/FormCoordinateMapWithOutline";

export const updateAreaLocationPayloadSchema = yup.object({
  site_id: yup.string().required(),
  coordinates: yup
    .object()
    .shape({
      lat: yup.number().required("Latitude is required"),
      lng: yup.number().required("Longitude is required"),
    })
    .required("Coordinates are required"),
  outline_coordinates: yup.array().optional().notRequired(),
});

export type IUpdateAreaLocationPayload = yup.InferType<
  typeof updateAreaLocationPayloadSchema
>;

export const UpdateAreaLocationControls = (
  props: WithFormMethods<IUpdateAreaLocationPayload>,
) => {
  const { methods } = props;
  const { control, watch, setValue } = methods;

  const siteId = watch("site_id");
  const sites = useAppSelector(selectSites);

  const baseCoordinates = React.useMemo(() => {
    if (siteId) {
      const site = sites[siteId];
      if (site) {
        return site?.coordinates || undefined;
      }
    }
    return undefined;
  }, [siteId]);

  return (
    <>
      {baseCoordinates && (
        <FormCoordinateMapWithOutline
          control={control}
          name="coordinates"
          outlineFieldName="outline_coordinates"
          baseCoordinates={baseCoordinates}
          setValue={setValue}
          watch={watch}
        />
      )}
    </>
  );
};

type UpdateAreaLocationFormProps = {
  stopEditing?: () => void;
};

export const UpdateAreaLocationForm = (props: UpdateAreaLocationFormProps) => {
  const area = useCurrentArea();

  const [{ isLoading, loadingMessage }, { startLoading, stopLoading }] =
    useLoading(false);

  const handleSubmit = (data) => {
    const payload = Object.assign({}, data);

    if (payload.coordinates) {
      payload.coordinates = {
        lat: +payload.coordinates.lat.toFixed(5),
        lng: +payload.coordinates.lng.toFixed(5),
      };
    }
    startLoading();
  };

  return (
    <AppForm<IUpdateAreaLocationPayload, typeof updateAreaLocationPayloadSchema>
      schema={updateAreaLocationPayloadSchema}
      onSubmit={handleSubmit}
      id="update-area-location-form"
      data={{
        site_id: area.site_id,
        coordinates: area.coordinates,
        outline_coordinates: area?.outline_coordinates,
      }}
    >
      {(methods) => <UpdateAreaLocationControls methods={methods} />}
    </AppForm>
  );
};
