import type * as yup from "yup";
import { baseFurtherInformationSchema } from "../components/FormSections";
import {
  employeeAccidentSchema,
  employerSchema,
  injuredEmployeeSchema,
} from "../components/FormSections/employee";
import { employmentSchema } from "../components/FormSections/employee/EmploymentSection";
import {
  injuredStudentSchema,
  schoolSchema,
  studentAccidentSchema,
} from "../components/FormSections/student";
import { EMPLOYEE_SECTION_NAMES } from "./employee";
import { STUDENT_SECTION_NAMES } from "./student";
import { employeeMinorInjurySchema } from "../components/FormSections/employee/EmployeeMinorInjurySection";
import { studentMinorInjurySchema } from "../components/FormSections/student/StudentMinorInjurySection";

// !TODO Add missing schemas
export const SCHEMA_BY_SECTION_NAME: {
  [section: string]: yup.ObjectSchema<any>;
} = {
  [EMPLOYEE_SECTION_NAMES.EMPLOYEE]: injuredEmployeeSchema,
  [EMPLOYEE_SECTION_NAMES.EMPLOYMENT]: employmentSchema, // !TODO [EMPLOYEE_SECTION_NAMES.EMPLOYMENT
  [EMPLOYEE_SECTION_NAMES.EMPLOYER]: employerSchema,
  [EMPLOYEE_SECTION_NAMES.ACCIDENT]: employeeAccidentSchema,
  [EMPLOYEE_SECTION_NAMES.MINOR_INJURY]: employeeMinorInjurySchema,
  [EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION]: baseFurtherInformationSchema,
  [STUDENT_SECTION_NAMES.STUDENT]: injuredStudentSchema,
  [STUDENT_SECTION_NAMES.SCHOOL]: schoolSchema,
  [STUDENT_SECTION_NAMES.ACCIDENT]: studentAccidentSchema,
  [STUDENT_SECTION_NAMES.MINOR_INJURY]: studentMinorInjurySchema,
  [STUDENT_SECTION_NAMES.FURTHER_INFORMATION]: baseFurtherInformationSchema,
};
