export const CONSTANTS = {
  COOKIE_KEYS: {
    TOKEN: "token",
    SELECTED_ORG: "selectedOrg",
    SUBDOMAIN: "subDomain",
    CLIENT_ID: "clientId",
    EXPIRES_IN: "expiresIn",
    ORG_LANGUAGE: "orgLanguage",
  },
};
