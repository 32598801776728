import { Box, Typography } from "@mui/material";
import type { Identifier, XYCoord } from "dnd-core";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { AppIcon } from "../Elements";
import IconButton from "@mui/material/IconButton";
export const ItemTypes = {
  CARD: "card",
};

export interface CardProps {
  id: any;
  text: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  onRemove: (index: number) => void;
  sx?: any;
}

interface DragItem {
  index: number;
  id: string;
}

export const SortableCard = ({
  id,
  text,
  index,
  moveCard,
  onRemove,
  sx,
}: CardProps) => {
  const dragRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: () => {
        return { id, index };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, index } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, index);
        }
      },
    }),
    [id, index, moveCard],
  );

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const handleRemove = () => {
    onRemove(index);
  };

  drag(dragRef);
  drop(preview(previewRef));
  return (
    <Box
      sx={{
        px: 1.25,
        pr: 0.5,
        py: 1,
        bgcolor: "background.default",
        mb: 1,
        display: "flex",
        flexDirection: "row",
        gap: 1,
        alignItems: "center",
        borderRadius: 1,
        ...sx,
      }}
      ref={previewRef}
      data-handler-id={handlerId}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": {
            cursor: "grab",
          },
        }}
        ref={dragRef}
      >
        <AppIcon iconName="drag_indicator" color="text.secondary" />
      </Box>

      <Typography
        variant="body2"
        flex={1}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {text}
      </Typography>
      <IconButton onClick={handleRemove} size="small">
        <AppIcon iconName="close" color="text.disabled" />
      </IconButton>
    </Box>
  );
};
