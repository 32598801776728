import React from "react";
import type { WithChildren } from "../../types";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link, { type LinkProps } from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { AppIcon } from "../Elements/AppIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getActiveUser } from "../../utils/user";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";

type ContentLayoutProps = WithChildren<{
  fadeInHeaderComponent?: any;
  breadcrumbs?: {
    to: string;
    name?: string;
  }[];
  title?: string;
}>;

export function NewContentLayout(props: ContentLayoutProps) {
  const { fadeInHeaderComponent, breadcrumbs, title, children } = props;

  const [scrollTarget, setScrollTarget] = React.useState<any>(undefined);
  const scrollTrigger = useScrollTrigger({ target: scrollTarget });
  const containerRef = React.useRef(null);

  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.up("lg"), { noSsr: true });

  const maxBreadcrumbItems = React.useMemo(() => {
    if (lgScreen) {
      return 4;
    }
    return 2;
  }, [lgScreen]);

  const activeUser = getActiveUser();

  const backToSamsvar = () => {
    if (activeUser?.userRole === "superadmin") {
      window.location.hash = "#/dashboardlist";
      window.location.reload();
    } else {
      window.location.hash = "#/mainDashboard";
      window.location.reload();
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          pl: 2,
          pr: 1.5,
          flexGrow: 1,
          flexDirection: "column",
          gap: 1,
          minHeight: 0,
        }}
      >
        <Paper
          elevation={1}
          sx={{
            borderColor: "divider",
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%",
            mb: 1.5,
            mt: 0.5,
            overflowY: "hidden",
          }}
        >
          <div
            ref={(node) => {
              if (node) {
                setScrollTarget(node);
              }
            }}
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              width: "100%",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                px: 3,
                pt: 2.25,
                pb: "2px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {breadcrumbs && breadcrumbs?.length > 0 ? (
                <Breadcrumbs
                  maxItems={maxBreadcrumbItems}
                  separator={
                    <AppIcon
                      iconName="chevron_right"
                      opticalSize={22}
                      weight={500}
                      sx={{ mx: -0.5 }}
                      color="textVariant"
                    />
                  }
                  sx={{
                    color: "textVariant",
                    fontSize: "body2.fontSize",
                    flex: 1,
                    overflow: "hidden",

                    "& .MuiBreadcrumbs-li": {
                      height: "24px!important",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minHeight: 0,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      "&:last-of-type": {
                        flex: 1,
                      },
                    },
                    "& .MuiLink-root": {
                      ...(theme.palette.mode === "light"
                        ? {
                            color: "primary",
                          }
                        : {
                            color: "textVariant",
                          }),
                    },
                  }}
                >
                  {breadcrumbs.map((breadcrumb, index) => {
                    const to = `${breadcrumb.to}`;
                    if (index === 0) {
                      return (
                        <Link
                          underline="hover"
                          key={to || index}
                          sx={{ typography: "h5", fontWeight: 500 }}
                          onClick={index === 0 && backToSamsvar}
                        >
                          {breadcrumb?.name ? breadcrumb.name : breadcrumb.to}
                        </Link>
                      );
                    }

                    return (
                      <LinkRouter
                        underline="hover"
                        to={to}
                        key={to}
                        sx={{ typography: "h5", fontWeight: 500 }}
                      >
                        {breadcrumb?.name ? breadcrumb.name : breadcrumb.to}
                      </LinkRouter>
                    );
                  })}
                  <Typography
                    color="text.secondary"
                    variant="h5"
                    fontWeight={600}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    {title}
                  </Typography>
                </Breadcrumbs>
              ) : (
                <Typography
                  variant={"h5"}
                  color="text.primary"
                  sx={{ fontWeight: 600, color: "text.primary" }}
                >
                  {title}
                </Typography>
              )}
            </Box>
            {scrollTrigger && (
              <Fade in={scrollTrigger}>{fadeInHeaderComponent}</Fade>
            )}
            {children}
          </div>
        </Paper>
      </Box>
    </React.Fragment>
  );
}

interface LinkRouterProps extends LinkProps {
  to?: string;
  replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
  return <Link {...props} component={RouterLink as any} />;
}

type OldContentLayoutProps = WithChildren<{
  title?: string;
  breadcrumbs?: {
    to: string;
    name?: string;
  }[];
  altHeader?: any;
  controlPanel?: any;
  maxWidth?: any;
  elevation?: number;
  primaryContainer?: boolean;
}>;

export const ContentLayout = (props: OldContentLayoutProps) => {
  const {
    title = "",
    breadcrumbs,
    controlPanel,
    children,
    maxWidth = "xl",
    elevation = 0,
    altHeader,
    primaryContainer = false,
  } = props;

  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.up("lg"), { noSsr: true });

  const maxBreadcrumbItems = React.useMemo(() => {
    if (lgScreen) {
      return 4;
    }
    return 2;
  }, [lgScreen]);

  const activeUser = getActiveUser();

  const backToSamsvar = () => {
    if (activeUser?.userRole === "superadmin") {
      window.location.hash = "#/dashboardlist";
      window.location.reload();
    } else {
      window.location.hash = "#/mainDashboard";
      window.location.reload();
    }
  };

  const ref = React.useRef(null);
  const [displayAltHeader, setDisplayAltHeader] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = (event: any) => {
      const scrollTop = event?.target?.scrollTop;
      if (scrollTop) {
        if (scrollTop >= 150) {
          setDisplayAltHeader(true);
        } else {
          setDisplayAltHeader(false);
        }
      }
    };

    ref?.current?.addEventListener("scroll", handleScroll);
    return () => ref?.current?.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        pl: 2,
        pr: 1.5,
        flexGrow: 1,
        flexDirection: "column",
        gap: 1,
        minHeight: 0,
        overflow: "hidden",
      }}
    >
      {controlPanel && controlPanel}
      <Paper
        elevation={1}
        ref={ref}
        sx={{
          borderColor: "divider",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "100%",
          mb: 1.5,
          mt: 0.5,
          position: "relative",
          overflow: "hidden",
          overflowY: "auto",
          borderRadius: 1,
          ...(primaryContainer && {
            bgcolor: "primary.main",
          }),
        }}
      >
        {altHeader && (
          <Slide
            in={displayAltHeader}
            mountOnEnter
            unmountOnExit
            direction="down"
          >
            <Paper
              sx={{
                ...(!displayAltHeader && {
                  display: "none",
                }),
                position: "sticky",
                top: 0,
                py: 2.25,
                left: 0,
                zIndex: 11,
                width: "100%",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              {altHeader}
            </Paper>
          </Slide>
        )}
        <Box
          sx={{
            px: 3,
            pt: 2.25,
            pb: "2px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            zIndex: 7,
          }}
        >
          {breadcrumbs && breadcrumbs?.length > 0 ? (
            <Breadcrumbs
              maxItems={maxBreadcrumbItems}
              separator={
                <AppIcon
                  iconName="chevron_right"
                  opticalSize={22}
                  weight={500}
                  sx={{ mx: -0.5 }}
                  color="textVariant"
                />
              }
              sx={{
                color: "textVariant",
                fontSize: "body2.fontSize",
                flex: 1,
                overflow: "hidden",

                "& .MuiBreadcrumbs-li": {
                  height: "24px!important",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: 0,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  "&:last-of-type": {
                    flex: 1,
                  },
                },
                "& .MuiLink-root": {
                  ...(theme.palette.mode === "light"
                    ? {
                        color: "primary",
                      }
                    : {
                        color: "textVariant",
                      }),
                },
              }}
            >
              {breadcrumbs.map((breadcrumb, index) => {
                const to = `${breadcrumb.to}`;
                if (index === 0) {
                  return (
                    <Link
                      underline="hover"
                      key={to || index}
                      sx={{ typography: "h5", fontWeight: 500 }}
                      onClick={index === 0 && backToSamsvar}
                    >
                      {breadcrumb?.name ? breadcrumb.name : breadcrumb.to}
                    </Link>
                  );
                }

                return (
                  <LinkRouter
                    underline="hover"
                    to={to}
                    key={to}
                    sx={{ typography: "h5", fontWeight: 500 }}
                  >
                    {breadcrumb?.name ? breadcrumb.name : breadcrumb.to}
                  </LinkRouter>
                );
              })}
              <Typography
                color="text.secondary"
                variant="h5"
                fontWeight={600}
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "100%",
                }}
              >
                {title}
              </Typography>
            </Breadcrumbs>
          ) : (
            <Typography
              variant={"h5"}
              color="text.primary"
              sx={{ fontWeight: 600, color: "text.primary" }}
            >
              {title}
            </Typography>
          )}
        </Box>
        {children}
      </Paper>
    </Box>
  );
};
