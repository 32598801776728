import React, { useState, useEffect } from "react";
import App from "./App";
import getLDProvider from "./ldProvider";
import { Amplify, Auth } from "aws-amplify";
import awsmobile from "./aws-exports";
import CookieStorage from "./utils/CookieStorage";

// Configure Amplify and Auth
Amplify.configure(awsmobile);
Auth.configure({
  storage: CookieStorage,
});

const Root = (props) => {
  const [LDProvider, setLDProvider] = useState(null);

  useEffect(() => {
    const initializeLDProvider = async () => {
      const LDProviderComponent = await getLDProvider();
      setLDProvider(() => LDProviderComponent);
    };
    initializeLDProvider();
  }, []);

  if (!LDProvider) {
    return null;
  }

  return (
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>
  );
};

export default Root;
