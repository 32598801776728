import { Button, Stack, Typography } from "@mui/material";
import * as React from "react";
import type { WithChildren } from "../../types";
import { AppIcon } from "../Elements";
import { type AppMenuItem, AppMoreMenu } from "../AppMoreMenu/AppMoreMenu";
import { useTranslation } from "react-i18next";
import { TKB_INJURY } from "../../features/injuries/constants";

export const useAppSectionEdit = (
  initialActive?: boolean,
): [boolean, () => void] => {
  const [active, setActive] = React.useState<boolean>(initialActive ?? false);

  const toggleEdit = () => {
    setActive(!active);
  };

  return [active, toggleEdit];
};

type InfoSectionProps = WithChildren<{
  title: string;
  moreMenuItems?: AppMenuItem[];
  edit?: {
    active: boolean;
    onClick: () => void;
    hidden?: boolean;
  };
  titleSx?: any;
}>;

export const AppInfoSection = ({
  title,
  moreMenuItems,
  edit,
  children,
  titleSx,
}: InfoSectionProps) => {
  const { t } = useTranslation();
  return (
    <Stack direction="column" gap={!(moreMenuItems && edit) ? 1 : 1.25}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
        sx={{
          height: "28px",
          ...titleSx,
        }}
      >
        <Typography variant="h5" color={"text.primary"}>
          {title}
        </Typography>
        <Stack direction="row" alignItems="center">
          {edit && !edit?.hidden && (
            <Button
              variant="text"
              color="primary"
              size="small"
              sx={{ height: "28px", mr: "-4px", mt: "1px" }}
              startIcon={
                <AppIcon
                  iconName={edit.active ? "edit_off" : "edit"}
                  opticalSize={16}
                />
              }
              onClick={edit.onClick}
            >
              {t(`${TKB_INJURY}.edit`)}
            </Button>
          )}
          {moreMenuItems && (
            <AppMoreMenu
              menuItems={moreMenuItems}
              sx={{ ...(edit && !edit?.hidden && { ml: "4px" }), mt: "1px" }}
            />
          )}
        </Stack>
      </Stack>
      {children}
    </Stack>
  );
};
