import { IBM_DEFAULT_CATEGORICAL_LIGHT } from "../../config/colors";
import _ from "lodash";
import { getFormattedMonthLabel } from "../../utils/format";
import { getActiveUser } from "../../utils/user";
import { t } from "i18next";

export const getChartColorByIndex = (i: number) => {
  if (i < IBM_DEFAULT_CATEGORICAL_LIGHT.length) {
    return IBM_DEFAULT_CATEGORICAL_LIGHT[i];
  }
  return IBM_DEFAULT_CATEGORICAL_LIGHT[0];
};

const getDefaultCommonChartProperties = (
  theme: any,
  title?: string,
  subtitle?: string,
) => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
          color: theme.palette.divider,
          borderColor: theme.palette.divider,
        },
        ticks: {
          color: theme.palette.textVariant,
          font: {
            family: theme.typography.body2.fontFamily,
            size: 13,
            lineHeight: theme.typography.body2.lineHeight,
          },
          precision: 0,
        },
        stacked: false,
      },
      y: {
        grid: {
          color: theme.palette.divider,
          borderColor: theme.palette.divider,
          borderDash: [3, 3],
        },
        ticks: {
          callback: function (value) {
            const lbl = this.getLabelForValue(value as number);
            if (typeof lbl === "string" && lbl.length > 20) {
              return `${lbl.substring(0, 20)}...`;
            }
            return lbl;
          },
          color: theme.palette.textVariant,
          font: {
            family: theme.typography.body2.fontFamily,
            size: 13,
            lineHeight: theme.typography.body2.lineHeight,
          },
          precision: 0,
        },
        stacked: false,
      },
    },
    plugins: {
      legend: {
        position: "bottom" as const,
        align: "start",
        display: true,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          color: theme.palette.text.primary,
          font: {
            size: 14,
            family: "Inter var",
            opacity: 1,
          },
        },
      },
      title: {
        padding: {
          bottom: -10,
        },
        display: true,
        align: "start",
        color: theme.palette.text.primary,
        font: {
          family: "Inter var",
          size: theme.typography.h4.fontSize,
          weight: 600,
          lineHeight: theme.typography.h4.lineHeight,
        },
        text: title ? title : "Title",
      },
      subtitle: {
        display: true,
        text: subtitle,
        align: "start",
        color: theme.palette.text.secondary,
        font: {
          family: "Inter var",
          size: theme.typography.body2.fontSize,
          lineHeight: theme.typography.body2.fontSize,
        },
        padding: {
          top: 14,
          bottom: 20,
        },
      },
    },
  };
};

export const getLineExtendedProperties = () => {
  return {
    elements: {
      point: {
        borderWidth: 3,
      },
    },
    plugins: {
      tooltip: {
        intersect: false,
        callbacks: {
          title: (_) => null,
        },
      },
      legend: {
        labels: {
          boxWidth: 10,
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            const {
              labels: {
                usePointStyle,
                pointStyle,
                textAlign,
                color,
                useBorderRadius,
                borderRadius,
              },
            } = chart.legend.options;
            return chart._getSortedDatasetMetas().map((meta) => {
              const style = meta.controller.getStyle(
                usePointStyle ? 0 : undefined,
              );
              return {
                text: `${datasets[meta.index].label}    `,
                fillStyle: style.backgroundColor,
                fontColor: color,
                hidden: !meta.visible,
                lineCap: style.borderCapStyle,
                lineDash: style.borderDash,
                lineDashOffset: style.borderDashOffset,
                lineJoin: style.borderJoinStyle,
                lineWidth: 3,
                strokeStyle: style.borderColor,
                pointStyle: pointStyle || style.pointStyle,
                rotation: style.rotation,
                textAlign: textAlign || style.textAlign,
                borderRadius:
                  useBorderRadius && (borderRadius || style.borderRadius),
                datasetIndex: meta.index,
              };
            });
          },
          usePointStyle: true,
          pointStyle: "line",
        },
      },
    },
  };
};

const getBarExtendedProperties = (disableTooltip?: boolean) => {
  return {
    plugins: {
      tooltip: {
        intersect: false,
        callbacks: {
          title: (context) => (disableTooltip ? null : context[0].label),
        },
      },
      legend: {
        labels: {
          usePointStyle: false,
          boxWidth: 10,
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            const {
              labels: {
                usePointStyle,
                pointStyle,
                textAlign,
                color,
                useBorderRadius,
                borderRadius,
              },
            } = chart.legend.options;
            return chart._getSortedDatasetMetas().map((meta) => {
              const style = meta.controller.getStyle(
                usePointStyle ? 0 : undefined,
              );
              return {
                text: `${datasets[meta.index].label}    `,
                fillStyle: style.backgroundColor,
                fontColor: color,
                hidden: !meta.visible,
                lineCap: style.borderCapStyle,
                lineDash: style.borderDash,
                lineDashOffset: style.borderDashOffset,
                lineJoin: style.borderJoinStyle,
                strokeStyle: style.borderColor,
                pointStyle: pointStyle || style.pointStyle,
                rotation: style.rotation,
                textAlign: textAlign || style.textAlign,
                borderRadius:
                  useBorderRadius && (borderRadius || style.borderRadius),
                datasetIndex: meta.index,
              };
            });
          },
        },
      },
    },
  };
};

const getListBarExtendedProperties = (theme: any) => {
  return {
    indexAxis: "y",
    scales: {
      x: {
        grid: {
          display: true,
        },
        border: {
          display: true,
        },
        beginAtZero: true,
        grace: "5%",
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          callback: function (value) {
            // truncate the labels only in this axis
            const lbl = this.getLabelForValue(value as number);
            if (typeof lbl === "string" && lbl.length > 28) {
              return `${lbl.substring(0, 28)}...`;
            }
            return lbl;
          },
          autoSkip: false,
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "y",
      },
      legend: {
        display: false,
      },
      datalabels: {
        color: theme.palette.background.paper,
        display: true,
        font: {
          size: 14,
          family: "Inter var",
          weight: theme.palette.mode === "dark" ? 600 : 400,
          opacity: 1,
        },
      },
      y: {
        ticks: {
          stepSize: 1,
          autoSkip: false,
        },
      },
    },
  };
};

const getMultiListBarExtendedProperties = (theme: any) => {
  return {
    plugins: {
      tooltip: {
        mode: "y",
      },
      legend: {
        display: true,
        position: "bottom" as const,
        align: "start",
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          color: theme.palette.text.primary,
          font: {
            size: 14,
            family: "Inter var",
            opacity: 1,
          },
        },
        title: {
          display: false,
        },
      },
    },
  };
};

interface params {
  theme: any;
  title?: string;
  subtitle?: string;
  disableTooltip?: boolean;
}

export const getDefaultLineChartOptions = ({
  theme,
  title,
  subtitle,
}: params) => {
  const common = getDefaultCommonChartProperties(theme, title, subtitle);
  const line = getLineExtendedProperties();

  return _.merge({}, common, line);
};

export const getDefaultBarChartOptions = ({
  theme,
  title,
  subtitle,
  disableTooltip,
}: params) => {
  const common = getDefaultCommonChartProperties(theme, title, subtitle);
  const bar = getBarExtendedProperties(disableTooltip);

  return _.merge({}, common, bar);
};

export const getDefaultListBarChartOptions = ({
  theme,
  title,
  subtitle,
}: params) => {
  const common = getDefaultCommonChartProperties(theme, title, subtitle);
  const listBar = getListBarExtendedProperties(theme);
  const bar = getBarExtendedProperties();
  return _.merge({}, common, listBar, bar);
};

export const getDefaultMultipleListBarChartOptions = ({
  theme,
  title,
  subtitle,
}: params) => {
  const common = getDefaultCommonChartProperties(theme, title, subtitle);
  const listBar = getListBarExtendedProperties(theme);
  const bar = getBarExtendedProperties();
  const multiListBar = getMultiListBarExtendedProperties(theme);
  return _.merge({}, common, listBar, bar, multiListBar);
};

export const shortMonthLabelTicksCallback = (val, i) => {
  console.log("value", val);
  console.log("index", i);
  const activeUser = getActiveUser();
  const locale = activeUser?.primary_locale || "nb";
  return getFormattedMonthLabel(val, locale, "short");
};

export const longMonthLabelTicksCallback = (val, i) => {
  const activeUser = getActiveUser();
  const locale = activeUser?.primary_locale || "nb";
  return getFormattedMonthLabel(val, locale, "long");
};

export const getDefaultChartPropsWithTitle = ({
  theme,
  title,
  subtitle,
}: params) => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
          color: theme.palette.divider,
          borderColor: theme.palette.divider,
        },
        ticks: {
          color: theme.palette.text.secondary,
          font: {
            family: theme.typography.body2.fontFamily,
            size: 13,
            lineHeight: theme.typography.body2.lineHeight,
          },
          precision: 0,
          callback: function (val, index) {
            return t
              ? t(`wif.injury.stats.months.${this.getLabelForValue(val)}`)
              : val;
          },
        },
      },
      y: {
        grid: {
          color: theme.palette.divider,
          borderColor: theme.palette.divider,
          borderDash: [3, 3],
        },
        ticks: {
          callback: function (value) {
            // truncate the labels only in this axis
            const lbl = this.getLabelForValue(value as number);
            if (typeof lbl === "string" && lbl.length > 20) {
              return `${lbl.substring(0, 20)}...`;
            }
            return lbl;
          },
          color: theme.palette.textVariant,
          font: {
            family: theme.typography.body2.fontFamily,
            size: 13,
            lineHeight: theme.typography.body2.lineHeight,
          },
          precision: 0,
        },
      },
    },
    elements: {
      point: {
        borderWidth: 3,
      },
    },
    plugins: {
      datalabels: {
        color: theme.palette.background.paper,
        display: true,
        font: {
          size: 14,
          family: "Inter var",
          weight: 500,
          opacity: 1,
        },
      },
      tooltip: {
        intersect: false,
        callbacks: {
          title: (context) => {
            const value = (context[0].label as string) || "";

            return t ? t(`wif.injury.stats.months.${value}`) : value;
          },
        },
      },
      legend: {
        position: "bottom" as const,
        align: "start",
        labels: {
          boxWidth: 5,
          boxHeight: 10,
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            const {
              labels: {
                usePointStyle,
                pointStyle,
                textAlign,
                color,
                useBorderRadius,
                borderRadius,
              },
            } = chart.legend.options;
            return chart._getSortedDatasetMetas().map((meta) => {
              const style = meta.controller.getStyle(
                usePointStyle ? 0 : undefined,
              );
              return {
                text: ` ${datasets[meta.index].label}    `,
                fillStyle: style.backgroundColor,
                fontColor: color,
                hidden: !meta.visible,
                lineCap: style.borderCapStyle,
                lineDash: style.borderDash,
                lineDashOffset: style.borderDashOffset,
                lineJoin: style.borderJoinStyle,
                lineWidth: 3,
                strokeStyle: style.borderColor,
                pointStyle: pointStyle || style.pointStyle,
                rotation: style.rotation,
                textAlign: textAlign || style.textAlign,
                borderRadius:
                  useBorderRadius && (borderRadius || style.borderRadius),
                datasetIndex: meta.index,
              };
            });
          },
          usePointStyle: true,
          pointStyle: "line",
          color: theme.palette.text.primary,
          font: {
            size: 14,
            family: "Inter var",
            opacity: 1,
          },
        },
        title: {
          display: true,
          padding: {
            top: 8,
          },
        },
      },
      title: {
        display: false,
        align: "start",
        color: theme.palette.text.primary,
        font: {
          family: "Inter var",
          size: theme.typography.h4.fontSize,
          weight: 600,
          lineHeight: theme.typography.h4.lineHeight,
        },
        text: title ? title : "Title",
      },
      subtitle: {
        display: false,
        text: subtitle || "Last year",
        align: "start",
        color: theme.palette.text.secondary,
        font: {
          family: "Inter var",
          size: theme.typography.body2.fontSize,
          lineHeight: theme.typography.body2.fontSize,
        },
        padding: {
          top: 14,
        },
      },
    },
  } as any;
};

export const getXLabelTicksSettings = () => {
  return {
    scales: {
      x: {
        ticks: {
          callback: function (val, index) {
            return t
              ? t(`wif.injury.stats.months.${this.getLabelForValue(val)}`)
              : val;
          },
        },
      },
    },
  };
};
