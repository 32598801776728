import { useRef } from "react";
import MapGL, { Layer, Source } from "react-map-gl";
import React from "react";
import centerOfMass from "@turf/center-of-mass";

import { Box } from "@mui/system";
import { Paper } from "@mui/material";
import { useTheme } from "@mui/material";
import { MAPBOX_TOKEN, paletteMapStyle } from "../../../sites/components/Map";
import { APP_COLORS } from "../../../../config";

const mapStyles: any = {
  width: "100%",
  height: "100%",
  position: "relative",
  minHeight: "320px",
  minWidth: "480px",
  flex: 1,
};
type PinMovedMapProps = {
  oldCoordinates: any;
  newCoordinates: any;
};

export default function PinMovedMap(props: PinMovedMapProps) {
  const { oldCoordinates, newCoordinates } = props;

  const theme = useTheme();
  const mapRef = useRef<any>(null);

  const pinFeatures: GeoJSON.Feature<GeoJSON.Point>[] = [
    {
      type: "Feature",
      properties: {
        iconImage: "pin-0",
        name: "Old",
      },
      geometry: {
        coordinates: [oldCoordinates.lng, oldCoordinates.lat],
        type: "Point",
      },
      id: "old-point",
    },
    {
      type: "Feature",
      properties: {
        iconImage: "pin-1",
        name: "New",
      },
      geometry: {
        coordinates: [newCoordinates.lng, newCoordinates.lat],
        type: "Point",
      },
      id: "new-point",
    },
  ];

  const pinFeatureCollection: GeoJSON.FeatureCollection<GeoJSON.Point> = {
    type: "FeatureCollection",
    features: [...pinFeatures],
  };

  const lineFeatures: GeoJSON.Feature<GeoJSON.LineString>[] = [
    {
      type: "Feature",
      properties: {},
      geometry: {
        coordinates: [
          [oldCoordinates.lng, oldCoordinates.lat],
          [newCoordinates.lng, newCoordinates.lat],
        ],
        type: "LineString",
      },
      id: "moved-line",
    },
  ];

  const lineFeatureCollection: GeoJSON.FeatureCollection<GeoJSON.LineString> = {
    type: "FeatureCollection",
    features: [...lineFeatures],
  };

  const centerCoordinates = React.useMemo(() => {
    const center = centerOfMass(pinFeatureCollection);
    return center.geometry.coordinates;
  }, []);

  const outlineForPalette = React.useMemo(() => {
    if (theme.palette.mode === "light") {
      return APP_COLORS.darkTextPrimary;
    }
    return APP_COLORS.textPrimary;
  }, [theme.palette.mode]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          position: "relative",
          flex: 1,
          borderRadius: 1,
          overflow: "hidden",
          border: 0,
        }}
      >
        <MapGL
          initialViewState={{
            latitude: centerCoordinates[1],
            longitude: centerCoordinates[0],
            zoom: 14,
          }}
          style={mapStyles}
          maxPitch={85}
          mapStyle={paletteMapStyle[theme.palette.mode as any]}
          mapboxAccessToken={MAPBOX_TOKEN}
          ref={mapRef}
        >
          <Source id="pins" type="geojson" data={pinFeatureCollection}>
            <Layer
              id="pins-layer"
              type="symbol"
              layout={{
                "icon-size": 1,
                "icon-allow-overlap": true,
                "text-allow-overlap": true,
                "text-field": ["get", "name"],
                "text-font": [
                  "case",
                  ["==", ["get", "iconImage"], "pin-1"],
                  ["literal", ["Inter Tight SemiBold"]],
                  ["literal", ["Inter Tight Medium"]],
                ],
                "text-offset": [0, 2.2],
                "text-max-width": 15,
                "text-size": [
                  "case",
                  ["==", ["get", "iconImage"], "pin-4"],
                  15,
                  13,
                ],
                "icon-image": ["get", "iconImage"],
              }}
              paint={{
                "text-color": [
                  "case",
                  ["==", ["get", "iconImage"], "pin-4"],
                  theme.palette.primary.main,
                  ["==", ["get", "iconImage"], "pin-3"],
                  theme.palette.error.main,
                  ["==", ["get", "iconImage"], "pin-2"],
                  theme.palette.warning.main,
                  ["==", ["get", "iconImage"], "pin-1"],
                  theme.palette.success.main,
                  theme.palette.text.secondary,
                ],
                "text-halo-color": outlineForPalette,
                "text-halo-width": 1,
              }}
            />
          </Source>
          <Source
            id="moved-line"
            type="geojson"
            data={lineFeatureCollection}
            lineMetrics
          >
            <Layer
              id="moved-line-layer"
              beforeId="pins-layer"
              type="line"
              paint={{
                "line-color": APP_COLORS.success,
                "line-width": 3,
                "line-dasharray": [2, 1],
                "line-gradient": [
                  "interpolate",
                  ["linear"],
                  ["line-progress"],
                  0,
                  "#a1a1aa",
                  1,
                  "#169e86",
                ],
              }}
            />
          </Source>
        </MapGL>
      </Paper>
    </Box>
  );
}
