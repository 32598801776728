import "./map.css";
import React from "react";
import MapGL, { Layer, Popup, Source } from "react-map-gl";
import { APP_COLORS } from "../../../config";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectInjuryPropertiesForSiteFeature } from "../../injuries/store/injuryReportsSlice";
import {
  selectAreaFeatures,
  selectAreaBoxFeatures,
  selectSites,
  selectCurrentSiteId,
  selectSiteIds,
  selectSiteFeatures,
  selectSitesFetched,
  areaClicked,
} from "../../locations/locationsSlice";
import { PopupCard, type PopupCardProps } from "./HoverPopup";
import { clusterCountLayer, clusterLayer, mainLayer } from "./layers";
import _ from "lodash";
import { alpha } from "@mui/material/styles";
import {
  retrieveSiteCurrent,
  retrieveSiteStatus,
} from "../../locations/helpers/mapExpressions";
export const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZW1rZiIsImEiOiJja29ib2k5cjgzMWd6MnBscDlvb3d0eWViIn0.nFACTNsfD10_6ooqj_SH4A";
import { useTheme } from "@mui/material";

export const paletteMapStyle: any = {
  light: "mapbox://styles/emkf/clf8kdbr8001901mwpja2vrdi",
  dark: "mapbox://styles/emkf/clp1mltd3008l01qt3efi333c",
};

type HoverInfo = {
  longitude: number;
  latitude: number;
  card: PopupCardProps;
};

export default function AppMap() {
  const mapRef = React.useRef<any>(null);
  const [hoverInfo, setHoverInfo] = React.useState<HoverInfo | undefined>();
  const [cursor, setCursor] = React.useState("auto");

  const theme = useTheme();

  const dispatch = useAppDispatch();

  const currentSiteId = useAppSelector(selectCurrentSiteId);

  const siteIds = useAppSelector(selectSiteIds);
  const siteFeatures = useAppSelector(selectSiteFeatures);
  const areaFeatures = useAppSelector(selectAreaFeatures);

  const sites = useAppSelector(selectSites);

  const injuryReportSiteProperties = useAppSelector(
    selectInjuryPropertiesForSiteFeature,
  );
  const areaBoxFeatures = useAppSelector(selectAreaBoxFeatures);

  const areaFeaturesWithStatusColors = React.useMemo(() => {
    return areaFeatures.map((feature) => {
      const area_id = feature.properties?.area_id;
      const addedProperties = injuryReportSiteProperties[area_id] || {};
      return {
        ...feature,
        properties: {
          ...feature.properties,
          ...addedProperties,
        },
      } as GeoJSON.Feature<GeoJSON.Point>;
    });
  }, [areaFeatures, injuryReportSiteProperties]);

  const areaBoxFeaturesWithStatusColors = React.useMemo(() => {
    return areaBoxFeatures.map((feature) => {
      const area_id = feature.properties?.area_id;
      const addedProperties = injuryReportSiteProperties[area_id] || {};
      return {
        ...feature,
        properties: {
          ...feature.properties,
          ...addedProperties,
        },
      } as GeoJSON.Feature<GeoJSON.Polygon>;
    });
  }, [areaBoxFeatures, injuryReportSiteProperties]);

  const siteFeatureCollection = React.useMemo(
    () =>
      ({
        type: "FeatureCollection",
        features: [...siteFeatures],
      }) as GeoJSON.FeatureCollection<GeoJSON.Point>,
    [siteFeatures],
  );

  const areaFeatureCollection = React.useMemo(
    () =>
      ({
        type: "FeatureCollection",
        features: [...areaFeaturesWithStatusColors],
      }) as GeoJSON.FeatureCollection<GeoJSON.Point>,
    [areaFeaturesWithStatusColors],
  );

  const areaBoxFeatureCollection = React.useMemo(
    () =>
      ({
        type: "FeatureCollection",
        features: [...areaBoxFeaturesWithStatusColors],
      }) as GeoJSON.FeatureCollection<GeoJSON.Polygon>,
    [areaBoxFeaturesWithStatusColors],
  );

  const healthStatus1Count = ["==", retrieveSiteStatus, 1];
  const healthStatus2Count = ["==", retrieveSiteStatus, 2];
  const healthStatus3Count = ["==", retrieveSiteStatus, 3];

  const delayMapFeatureHoverEffect = React.useCallback(
    _.debounce((newHoverInfo: any) => {
      setHoverInfo(newHoverInfo);
    }, 200),
    [],
  );

  const delayMapFeatureHoverEffectLonger = React.useCallback(
    _.debounce((newHoverInfo: any) => {
      setHoverInfo(newHoverInfo);
    }, 500),
    [],
  );

  const delayResize = React.useCallback(
    _.debounce(() => {
      mapRef.current?.resize();
    }, 100),
    [],
  );

  const delayFlyTo = React.useCallback(
    _.debounce((args: any) => {
      mapRef.current?.flyTo(args);
    }, 200),
    [],
  );

  const onHover = React.useCallback((event: any) => {
    const feature: any = event.features?.[0];
    if (feature) {
      const newHoverInfo: HoverInfo = {
        latitude: feature.geometry.coordinates[1],
        longitude: feature.geometry.coordinates[0],
        card: {
          label: feature.properties.name,
          reportsCount: JSON.parse(feature.properties.reportsCount) || 0,
          reportsCountStatus:
            JSON.parse(feature.properties.reportsCountStatus) || 0,
        },
      };
      setCursor("pointer");
      delayMapFeatureHoverEffect(newHoverInfo);
    } else {
      setCursor("auto");
      delayMapFeatureHoverEffect(undefined);
    }
  }, []);

  const handleClick = (event: any) => {
    const feature: any = event.features?.[0];
    if (feature) {
      dispatch(areaClicked(feature.properties.area_id));
    }
  };

  React.useEffect(() => {
    if (currentSiteId) {
      const currentBaseLocation = sites[currentSiteId];

      delayFlyTo({
        center: [
          currentBaseLocation.coordinates.lng,
          currentBaseLocation.coordinates.lat,
        ],
        duration: 2000,
        zoom: 16,
      });
    }
  }, [currentSiteId]);

  const textForPalette = React.useMemo(() => {
    if (theme.palette.mode !== "light") {
      return [
        "case",
        ["==", retrieveSiteCurrent, 1],
        theme.palette.primary.main,
        ["==", retrieveSiteStatus, 3],
        theme.palette.error.main,
        ["==", retrieveSiteStatus, 2],
        theme.palette.warning.main,
        ["==", retrieveSiteStatus, 1],
        theme.palette.success.main,
        theme.palette.text.secondary,
      ] as any;
    }
    return [
      "case",
      ["==", retrieveSiteCurrent, 1],
      theme.palette.primary.main,
      ["==", retrieveSiteStatus, 3],
      theme.palette.error.main,
      ["==", retrieveSiteStatus, 2],
      theme.palette.warning.main,
      ["==", retrieveSiteStatus, 1],
      theme.palette.success.main,
      theme.palette.text.secondary,
    ] as any;
  }, [theme.palette.mode]);

  const outlineForPalette = React.useMemo(() => {
    if (theme.palette.mode === "light") {
      return APP_COLORS.darkTextPrimary;
    }
    return APP_COLORS.textPrimary;
  }, [theme.palette.mode]);

  const initViewState = React.useMemo(() => {
    if (currentSiteId) {
      if (sites[currentSiteId]) {
        return {
          latitude: sites[currentSiteId].coordinates.lat,
          longitude: sites[currentSiteId].coordinates.lng,
          zoom: 16,
        };
      }
    }
    if (siteIds.length > 0) {
      return {
        latitude: sites[siteIds[0]].coordinates.lat,
        longitude: sites[siteIds[0]].coordinates.lng,
        zoom: 16,
      };
    }
    return {
      latitude: 60.472,
      longitude: 8.4689,
      zoom: 5,
    };
  }, []);

  const sitesFetched = useAppSelector(selectSitesFetched);

  React.useEffect(() => {
    if (sitesFetched === "succeeded") {
      if (siteIds.length > 0) {
        const site = sites[siteIds[0]];
        if (site) {
          mapRef.current?.flyTo({
            center: [site.coordinates.lng, site.coordinates.lat],
            zoom: 16,
            duration: 2000,
          });
        }
      }
    }
  }, [sitesFetched]);

  return (
    <MapGL
      ref={mapRef}
      initialViewState={initViewState}
      mapboxAccessToken={MAPBOX_TOKEN}
      mapStyle={paletteMapStyle[theme.palette.mode as any]}
      interactiveLayerIds={["main-areas"]}
      onMouseMove={onHover}
      cursor={cursor}
      onClick={handleClick}
    >
      <Source id="sites-source" type="geojson" data={siteFeatureCollection}>
        <Layer
          id="main-sites"
          type="symbol"
          maxzoom={14}
          layout={{
            "icon-size": ["case", ["==", retrieveSiteCurrent, 1], 1.3, 1],
            "icon-allow-overlap": true,
            "text-allow-overlap": true,
            "icon-image": ["get", "iconImage"],
            "text-field": ["get", "name"],
            "text-size": ["case", ["==", retrieveSiteCurrent, 1], 15, 13],
            "text-offset": [
              "case",
              ["==", retrieveSiteCurrent, 1],
              ["literal", [0, 2.2]],
              ["literal", [0, 2]],
            ],
            "text-font": [
              "case",
              ["==", retrieveSiteCurrent, 1],
              ["literal", ["Inter Tight Bold"]],
              ["literal", ["Inter Tight Medium"]],
            ],
          }}
          paint={{
            "text-color": [
              "case",
              ["==", retrieveSiteCurrent, 1],
              theme.palette.primary.main,
              theme.palette.warning.main,
            ],
            "text-halo-color":
              theme.palette.mode === "light"
                ? APP_COLORS.darkTextPrimary
                : APP_COLORS.textPrimary,
            "text-halo-width": 1,
          }}
        />
      </Source>
      <Source
        id="areas-source"
        type="geojson"
        data={areaFeatureCollection}
        cluster={true}
        clusterProperties={{
          healthStatus1Count: ["+", ["case", healthStatus1Count, 1, 0]],
          healthStatus2Count: ["+", ["case", healthStatus2Count, 1, 0]],
          healthStatus3Count: ["+", ["case", healthStatus3Count, 1, 0]],
        }}
      >
        <Layer
          id="main-areas"
          type="symbol"
          minzoom={14}
          layout={{
            ...mainLayer.layout,
            "icon-image": [
              "case",
              ["==", ["get", "showIcon"], 0],
              "",
              ["==", ["get", "current"], 1],
              "pin-4",
              ["concat", "pin-", retrieveSiteStatus],
            ],
            "text-offset": [
              "case",
              ["==", ["get", "showIcon"], 0],
              ["literal", [0, 0]],
              ["==", retrieveSiteCurrent, 1],
              ["literal", [0, 1.9]],
              ["literal", [0, 1.7]],
            ],
            "text-font": [
              "case",
              ["==", retrieveSiteCurrent, 1],
              ["literal", ["Inter Tight Bold"]],
              ["literal", ["Inter Tight Medium"]],
            ],
          }}
          paint={{
            "text-color": textForPalette,
            "text-halo-color": outlineForPalette,
            "text-halo-width": 1,
          }}
        />
        <Layer {...clusterLayer} />
        <Layer {...clusterCountLayer} />
      </Source>
      <Source id="area-box" type="geojson" data={areaBoxFeatureCollection}>
        <Layer
          id="area-box-fill"
          beforeId="main-areas"
          type="fill"
          paint={{
            "fill-color": [
              "case",
              ["==", retrieveSiteCurrent, 1],
              alpha(theme.palette.primary.main, 0.16),
              ["==", retrieveSiteStatus, 3],
              alpha(theme.palette.error.main, 0.12), // '#ead9dd', // error over light3 16%
              ["==", retrieveSiteStatus, 2],
              alpha(theme.palette.warning.main, 0.12),
              ["==", retrieveSiteStatus, 1],
              alpha(theme.palette.success.main, 0.12),
              alpha(theme.palette.secondary.main, 0.8),
            ],
          }}
        />
        <Layer
          id="area-box-outline"
          beforeId="area-box-fill"
          type="line"
          minzoom={14}
          paint={{
            "line-color": [
              "case",
              ["==", retrieveSiteCurrent, 1],
              theme.palette.primary.main,
              ["==", retrieveSiteStatus, 3],
              theme.palette.error.main,
              ["==", retrieveSiteStatus, 2],
              theme.palette.warning.main,
              ["==", retrieveSiteStatus, 1],
              theme.palette.success.main,
              theme.palette.text.secondary,
            ],
            "line-width": ["case", ["==", ["get", "current"], 1], 4, 3],
          }}
        />
      </Source>
      {hoverInfo && (
        <Popup
          latitude={hoverInfo.latitude}
          longitude={hoverInfo.longitude}
          closeButton={false}
          offset={[0, -10] as [number, number]}
        >
          {<PopupCard {...hoverInfo.card} />}
        </Popup>
      )}
    </MapGL>
  );
}
