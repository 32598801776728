import * as React from "react";
import type { AreaLog } from "../../locationsTypes";
import { useAppSelector } from "../../../../store";
import { selectEmployees } from "../../../employees/employeesSlice";
import { AppLogItem } from "../../../../components/AppLogItem/AppLogItem";
import { getActiveUser } from "../../../../utils/user";
import { useTranslation } from "react-i18next";
import PinMovedMap from "../PinMovedMap/PinMovedMap";
import { LogItemUpdatePart, LogUpdates } from "./SiteLogItem";

export const AREA_LOG_TYPES = {
  CREATE: "create",
  UPDATE_DETAILS: "update_details",
  UPDATE_LOCATION: "update_location",
  DELETE: "delete",
};

const areaUpdateLogTypes = [
  AREA_LOG_TYPES.UPDATE_DETAILS,
  AREA_LOG_TYPES.UPDATE_LOCATION,
];

const MappedSiteLogMessages = {
  [AREA_LOG_TYPES.CREATE]: "created an area",
  [AREA_LOG_TYPES.UPDATE_DETAILS]: "updated area details",
  [AREA_LOG_TYPES.UPDATE_LOCATION]: "updated area coordinates",
  [AREA_LOG_TYPES.DELETE]: "deleted area",
};

type AreaLogItemProps = {
  log: AreaLog;
};

export const AreaLogItem = (props: AreaLogItemProps) => {
  const { log } = props;
  const { t } = useTranslation();

  const activeUser = getActiveUser();

  const users = useAppSelector(selectEmployees);

  const username = React.useMemo(() => {
    const user = users[log?.user_id];
    if (user) {
      return user?.name || log?.user_id;
    }
    return log?.user_id || "";
  }, [log, users]);

  const message = React.useMemo(() => {
    const who = log?.user_id === activeUser?.id ? "You" : username;
    const what = MappedSiteLogMessages[log.type] || "did something";
    return `${who} ${what}`;
  }, [log, username]);

  const formatFieldValues = React.useMemo(() => {
    if (log.type === AREA_LOG_TYPES.UPDATE_LOCATION && log.updates) {
      return {
        coordinates: (value) =>
          `${value?.lat.toFixed(5)}, ${value?.lng.toFixed(5)}`,
      };
    }
    return undefined;
  }, [log]);

  const coordinatesUpdate = React.useMemo(() => {
    if (log.type === AREA_LOG_TYPES.UPDATE_LOCATION) {
      return log.updates?.find(
        (update) =>
          update.field_name === "coordinates" &&
          update?.new_value &&
          update?.old_value,
      );
    }
    return undefined;
  }, [log]);

  return (
    <AppLogItem
      username={username}
      message={message}
      time={log?.time}
      hideDetails
    >
      {areaUpdateLogTypes.includes(log.type) && (
        <LogUpdates
          updates={log.updates}
          formatFieldValues={formatFieldValues}
          getFieldLabel={(field_name) =>
            t(`wif.injury.models.site.fields.${field_name}.title`)
          }
        />
      )}
      {log.type === AREA_LOG_TYPES.CREATE && (
        <>
          {log?.payload &&
            Object.keys(log.payload)
              .filter((key) => key === "name" || key === "site_id")
              .map((key) => (
                <LogItemUpdatePart
                  key={key}
                  field_name={key}
                  new_value={log?.payload[key]}
                />
              ))}
        </>
      )}
      {coordinatesUpdate && (
        <PinMovedMap
          oldCoordinates={coordinatesUpdate.old_value}
          newCoordinates={coordinatesUpdate.new_value}
        />
      )}
    </AppLogItem>
  );
};
