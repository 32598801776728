import type { Control, FieldPath } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import type { FieldValues, UseFormSetValue } from "react-hook-form/dist/types";
import FormCoordinateMapPart from "./Parts/FormCoordinateMapPart";

type FormCoordinateMapProps<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  control?: Control<TFieldValues, any>;
  setValue?: UseFormSetValue<TFieldValues>;
  addressFieldName?: TName;
  name: TName;
};

export function FormCoordinateMap<TFieldValues extends FieldValues>(
  props: FormCoordinateMapProps<TFieldValues>,
) {
  const {
    control = useFormContext<TFieldValues>().control,
    setValue,
    addressFieldName,
    name,
  } = props;

  const handleChangeAddress = (newAddress: any) => {
    if (addressFieldName && setValue) {
      setValue(addressFieldName, newAddress);
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, ...field }, fieldState: { error } }) => (
        <FormCoordinateMapPart
          coordinates={value}
          onChangeCoordinates={(newCoordinates: any) => {
            field.onChange(newCoordinates);
          }}
          error={!!error}
          errorMessage={error?.message}
          onChangeAddress={addressFieldName ? handleChangeAddress : undefined}
        />
      )}
    />
  );
}
