import Stack from "@mui/material/Stack";
import type { WithChildren } from "../../types";

type FormRowProps = WithChildren<{
  sx?: any;
}>;

export function FormRow(props: FormRowProps) {
  const { children, sx } = props;

  return (
    <Stack direction="row" spacing={2} sx={{ width: "100%", ...sx }}>
      {children}
    </Stack>
  );
}
