import { styled } from "@mui/material/styles";
import Button, { type ButtonProps } from "@mui/material/Button";

export const AppIconButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "size",
})<ButtonProps>(({ size, color, theme }) => ({
  minWidth: "0px",
  width: "36px",
  height: "36px",
  ...(size === "small" && {
    width: "30px",
    height: "30px",
    minWidth: "0px!important",
  }),
  ...(size === "medium" && {
    width: "36px",
    height: "36px",
  }),
  ...(size === "large" && {
    width: "40px",
    height: "40px",
  }),
  ...(color === "plain" && {
    color: `${theme.palette.text.secondary}!important`,
  }),
}));
