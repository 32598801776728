import { REPORT_CATEGORY, REPORT_TYPES } from "../constants";
import {
  EMPLOYEE_SECTION_NAMES,
  EMPLOYEE_SECTION_NAMES as empSections,
} from "../constants/employee";
import { STUDENT_SECTION_NAMES as studSections } from "../constants/student";
import { STUDENT_SECTION_NAMES } from "../constants/student";
import type { InjuryReportCase } from "../types";

export const getSectionWithDepartmentAndUnitName = (
  injuryReport: InjuryReportCase,
) => {
  let sectionWithUnitAndDepartmentName = undefined;
  if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
    if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
      sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.MINOR_INJURY;
    } else {
      sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.SCHOOL;
    }
  } else if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
    if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
      sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.MINOR_INJURY;
    } else {
      sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.EMPLOYER;
    }
  }
  return sectionWithUnitAndDepartmentName;
};

export const getInjuryReportDepartmentId = (injuryReport: InjuryReportCase) => {
  const containingSection = getSectionWithDepartmentAndUnitName(injuryReport);
  if (injuryReport?.sections[containingSection]?.departmentId) {
    return injuryReport.sections[containingSection].departmentId as string;
  }
  return undefined;
};

export const getInjuryReportUnitId = (injuryReport: InjuryReportCase) => {
  const containingSection = getSectionWithDepartmentAndUnitName(injuryReport);
  if (injuryReport?.sections[containingSection]?.unitId) {
    return injuryReport.sections[containingSection].unitId as string;
  }
  return undefined;
};

export const getInjuryReportSectionNames = (injuryReport: InjuryReportCase) => {
  const category = injuryReport.category;
  const type = injuryReport.type;

  if (category === "employee") {
    if (type === "minorInjury") {
      return [empSections.MINOR_INJURY];
    }
    if (type === "accident") {
      return [
        empSections.EMPLOYEE,
        empSections.EMPLOYER,
        empSections.ACCIDENT,
        empSections.EMPLOYEE_ACCIDENT_TYPES,
        empSections.FURTHER_INFORMATION,
      ];
    }
    if (type === "illness") {
      return [
        empSections.EMPLOYEE,
        empSections.EMPLOYER,
        empSections.ILLNESS,
        empSections.FURTHER_INFORMATION,
      ];
    }
  } else if (category === "student") {
    if (type === "minorInjury") {
      return [studSections.MINOR_INJURY];
    }
    if (type === "accident") {
      return [
        studSections.STUDENT,
        studSections.SCHOOL,
        studSections.ACCIDENT,
        studSections.FURTHER_INFORMATION,
      ];
    }
    if (type === "illness") {
      return [
        studSections.STUDENT,
        studSections.SCHOOL,
        studSections.ILLNESS,
        studSections.FURTHER_INFORMATION,
      ];
    }
  }
  return [];
};

export const getTranslatedInjuryReportName = (
  report: InjuryReportCase,
  t: any,
) => {
  const translatedReportType = t(`wif.injury.reportType.${report.type}`);
  return `${translatedReportType}: ${report.name}`;
};
