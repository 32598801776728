import type { InjuredStudentSection } from "../../components/FormSections/student/InjuredStudentSection";
import { TKB_INJURY } from "../common";
import * as STUDENT_SECTION_NAMES from "./sectionNames";
export * as STUDENT_SECTION_NAMES from "./sectionNames";

export type StudentAccidentSections = {
  [STUDENT_SECTION_NAMES.STUDENT]: InjuredStudentSection;
};

export const StudentSectionDetails = {
  [STUDENT_SECTION_NAMES.STUDENT]: {
    title: `${TKB_INJURY}.pupilStudent`,
    description: "Information about the injured student",
  },
  [STUDENT_SECTION_NAMES.SCHOOL]: {
    title: `${TKB_INJURY}.schoolPlace`,
    description: "Information about the school where the event took place",
  },
  [STUDENT_SECTION_NAMES.ACCIDENT]: {
    title: `${TKB_INJURY}.reportType.accident`,
    description: `${TKB_INJURY}.accidentDescription`,
  },
  [STUDENT_SECTION_NAMES.ILLNESS]: {
    title: `${TKB_INJURY}.reportType.illness`,
    description: `${TKB_INJURY}.illnessDescription`,
  },
  [STUDENT_SECTION_NAMES.MINOR_INJURY]: {
    title: `${TKB_INJURY}.reportType.minorInjury`,
    description: "Information about the minor injury",
  },
  [STUDENT_SECTION_NAMES.FURTHER_INFORMATION]: {
    title: `${TKB_INJURY}.furtherInfo`,
    description: `${TKB_INJURY}.furtherInfoDescription`,
  },
};
