import { useState } from "react";
import * as yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import { type SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AppDialogTitle } from "../../../../components/Display/AppDialogTitle";
import { IconButton, Stack, Typography } from "@mui/material";
import { APP_COLORS, SIKRI_COLORS } from "../../../../config";
import { AppIcon } from "../../../../components/Elements";
import {
  EmailMessage,
  type EmailMessagePayload,
  emailMessageSchema,
} from "../../../../components/Messages/EmailMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "../../../../store";
import { sendInjuryReportMessageEmails } from "../../store/injuryReportsSlice";
import { snackAlertOpened } from "../../../global/controlsSlice";
import InjuryUserSelectDialog from "./InjuryUserSelectDialog";
import { TKB_INJURY } from "../../constants";

export const closingCaseSchema = yup.object().shape({});

interface InjuryEmailDialogProps {
  open?: boolean;
  injuryReportId?: string;
  onClose?: () => void;
}

export default function InjuryEmailDialog(props: InjuryEmailDialogProps) {
  const { onClose, injuryReportId, open = false } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [checkBoxValues, setCheckBoxValues] = useState([]);
  const dispatch = useAppDispatch();
  const [openDialog, setOpenDialog] = useState<undefined | string>();
  const [dialogKey, setDialogKey] = useState(0);
  const { handleSubmit, control, reset, watch } = useForm<EmailMessagePayload>({
    resolver: yupResolver(emailMessageSchema),
    defaultValues: {},
  });
  const ngStorageCurrent = JSON.parse(
    localStorage.getItem("ngStorage-current"),
  );
  const [error, setError] = useState("");

  const onSubmit: SubmitHandler<EmailMessagePayload> = async (data) => {
    const userList = [];
    if (checkBoxValues?.length) {
      for (const user of checkBoxValues) {
        userList.push(user.id);
      }

      const req: any = {
        payload: {
          reportId: injuryReportId,
          message: data.message,
          usersReceived: userList,
          accountId: ngStorageCurrent?.user.org_id,
          sender: {
            id: ngStorageCurrent?.user.id,
            name: ngStorageCurrent?.user.name,
            email: ngStorageCurrent?.user.email,
          },
        },
      };

      setLoading(true);
      const resultAction = await dispatch(sendInjuryReportMessageEmails(req));
      if (sendInjuryReportMessageEmails.fulfilled.match(resultAction)) {
        dispatch(
          snackAlertOpened({
            message: t(`${TKB_INJURY}.snackMessages.emailsent.success`),
            severity: "success",
          }),
        );
        setLoading(false);
        reset();
        setCheckBoxValues([]);
        handleClose();
      } else {
        if (resultAction.payload) {
          dispatch(
            snackAlertOpened({
              message: t(`${TKB_INJURY}.snackMessages.genericError`),
              severity: "error",
            }),
          );
          setLoading(false);
        } else {
          dispatch(
            snackAlertOpened({
              message: t(`${TKB_INJURY}.snackMessages.errorWithMessage`, {
                message: resultAction.error,
              }),
              severity: "error",
            }),
          );
          setLoading(false);
        }
        setCheckBoxValues([]);
        reset();
        handleClose();
      }
    } else {
      setError(t(`${TKB_INJURY}.messageValidation.selectAtleastOneUser`));
    }
  };

  const handleOpenHandlingDialog = () => {
    setOpenDialog("users");
  };
  const handleClose = () => {
    reset({ message: null });
    setDialogKey(dialogKey + 1);
    setCheckBoxValues([]);
    onClose?.();
  };

  const onCheckboxChange = (checkboxValue) => {
    setError("");
    setCheckBoxValues(checkboxValue);
  };

  return (
    <Stack direction="column" gap={2}>
      <Dialog open={open} onClose={handleClose}>
        <AppDialogTitle id="close-report-dialog-title" onClose={handleClose}>
          {t(`${TKB_INJURY}.sendMessage`)}
        </AppDialogTitle>

        <DialogContent
          dividers
          sx={{
            overflowY: "none",
          }}
        >
          <Typography variant="h4" mb={1.5}>
            {t(`${TKB_INJURY}.to`)} :
          </Typography>
          <Box
            sx={{
              marginY: 1.5,
            }}
          >
            <IconButton
              onClick={handleOpenHandlingDialog}
              sx={{
                backgroundColor: SIKRI_COLORS.indigo,
                color: APP_COLORS.light1,
                borderRadius: 0,
                fontSize: 17,
              }}
            >
              {t(`${TKB_INJURY}.selectUsers`)}
              <AppIcon iconName="person_add" />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="body2" mb={1.5}>
              {checkBoxValues.map((item) => `${item.username}  `)}
            </Typography>
            {error && (
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            )}
          </Box>

          <Typography variant="h4" mb={1.5}>
            {t(`${TKB_INJURY}.message`)} :
          </Typography>
          <Box
            sx={{
              width: "500px",
              height: "200px",
              overflowY: "auto",
            }}
          >
            <EmailMessage
              formId="add-injury-email"
              onSubmit={handleSubmit(onSubmit)}
              onReset={handleClose}
              watch={watch}
              control={control}
              loading={loading}
              cancelButtonLabel="cancel"
              sendButtonLabel="send"
              reset={reset}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "space-between",
          }}
        />
      </Dialog>
      <InjuryUserSelectDialog
        key={dialogKey}
        open={openDialog === "users"}
        onClose={() => setOpenDialog(undefined)}
        onCheckboxChange={onCheckboxChange}
      />
    </Stack>
  );
}
