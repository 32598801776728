// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/Inter-roman.var.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-feature-settings: "cv02","cv03","cv04","cv11"!important;
}

@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: block;
  font-style: normal;
  src: local('Inter-roman'), url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2');
}

body {
  height: 100vh;
  width: 100%;
}

input[type='text'], input[type='password'] {
  border-bottom: none;
}

input[type=checkbox], input[type=radio] {
  margin: 0px;
}

label {
  margin-bottom: 0px;
}

#Embed {
  display: none;
}

body > iframe {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,4DAA4D;AAC9D;;AAEA;EACE,wBAAwB;EACxB,oBAAoB;EACpB,mBAAmB;EACnB,kBAAkB;EAClB,kFAAgF;AAClF;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":["* {\n  font-feature-settings: \"cv02\",\"cv03\",\"cv04\",\"cv11\"!important;\n}\n\n@font-face {\n  font-family: 'Inter var';\n  font-weight: 100 900;\n  font-display: block;\n  font-style: normal;\n  src: local('Inter-roman'), url('./assets/Inter-roman.var.woff2') format('woff2');\n}\n\nbody {\n  height: 100vh;\n  width: 100%;\n}\n\ninput[type='text'], input[type='password'] {\n  border-bottom: none;\n}\n\ninput[type=checkbox], input[type=radio] {\n  margin: 0px;\n}\n\nlabel {\n  margin-bottom: 0px;\n}\n\n#Embed {\n  display: none;\n}\n\nbody > iframe {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
