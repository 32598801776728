import {
  Divider,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  type NewSite,
  selectAllSites,
  selectCurrentSiteId,
  siteClicked,
} from "../locationsSlice";
import { SIKRI_COLORS } from "../../../config";
import SitesOverviewMap from "./SitesOverviewMap";
import { AppIcon } from "../../../components/Elements";
import { useTheme, alpha } from "@mui/material";
import { Stack } from "@mui/system";
import { SiteTypeIconNames } from "./SiteIcon";

type SitesMapListItemProps = {
  site: NewSite;
};

const SitesMapListItem = (props: SitesMapListItemProps) => {
  const { site } = props;
  const dispatch = useAppDispatch();

  const currentSiteId = useAppSelector(selectCurrentSiteId);

  const handleClick = () => {
    dispatch(siteClicked(site.site_id));
  };

  return (
    <ListItemButton
      selected={site.site_id === currentSiteId}
      onClick={handleClick}
      sx={{}}
    >
      <ListItemIcon>
        <AppIcon iconName={SiteTypeIconNames[site.type]} />
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="h6" fontWeight={500}>
            {site?.name}
          </Typography>
        }
        secondary={
          <Stack direction="column">
            <Typography
              variant="body2"
              color="text.secondary"
            >{`School • ${site.address}`}</Typography>
          </Stack>
        }
      />
    </ListItemButton>
  );
};

const SitesMapList = () => {
  const sites = useAppSelector(selectAllSites);
  const theme = useTheme();

  return (
    <List
      disablePadding
      sx={{
        px: 0,
        py: 0,
        "& .Mui-selected": {
          ...(theme.palette.mode === "light"
            ? {
                bgcolor: `${SIKRI_COLORS.galaxyGreenSelected}!important`,
              }
            : {
                bgcolor: `${alpha(SIKRI_COLORS.galaxyGreen, 0.24)}!important`,
              }),
        },
      }}
    >
      {sites.map((site: NewSite, i: number) => (
        <React.Fragment key={site.site_id}>
          <SitesMapListItem site={site} />
          {i < sites.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};

const SitesMapLocationsControl = () => {
  return (
    <Paper
      elevation={1}
      sx={{
        position: "absolute",
        left: 12,
        top: 12,
        border: 1,
        borderColor: "backgroundVariant",
        width: "360px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
          p: 1.5,
        }}
      >
        <TextField
          size="small"
          fullWidth
          variant="outlined"
          sx={{
            borderRadius: 2,
            height: 36,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AppIcon iconName="search" />
              </InputAdornment>
            ),
          }}
          placeholder="Search..."
        />
      </Box>
      <SitesMapList />
    </Paper>
  );
};

export const SitesTabMapView = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          flex: 1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SitesOverviewMap />
      </Box>
      <SitesMapLocationsControl />
    </Box>
  );
};
