import { Button, Stack, ToggleButtonGroup } from "@mui/material";
import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ContentLayout } from "../../../components/Layout";
import { Header } from "../../../components/Layout/Header";
import { TabsContainer } from "../../../components/Layout/AppTabs";
import { TabPanel } from "../../../components/Elements/TabPanel";
import { AreasDataGrid } from "../../sites/components/AreasDataGrid/AreasDataGrid";
import CreateAreaDialog from "../../sites/components/CreateAreaDialog/CreateAreaDialog";
import {
  initLocationTestData,
  selectAllSites,
  selectAreasFetched,
  selectCurrentAreaId,
  selectCurrentSiteId,
  selectSitesFetched,
} from "../locationsSlice";
import ContentWrapper from "../../../components/Layout/ContentWrapper";
import { SitesDataGrid } from "../../sites/components/SitesDataGrid.tsx/SitesDataGrid";
import CreateSiteDialog from "../../sites/components/CreateSiteDialog/CreateSiteDialog";
import { AppIcon } from "../../../components/Elements";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ToggleButton from "@mui/material/ToggleButton";
import { SitesTabMapView } from "../components/SitesTabMapView";
import { AreasTabMapView } from "../components/AreasTab/AreasTabMapView";
import { RightContainer } from "../../../components/Layout/RightContainer";
import { SiteDetails } from "../components/SiteDetails/SiteDetails";
import { fetchAllAreas, fetchAllSites } from "../locationsThunks";
import { useTranslation } from "react-i18next";
import { AreaDetails } from "../components/AreaDetails/AreaDetails";

export const Locations = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [tab, setTab] = React.useState(0);
  const [view, setView] = React.useState<string>("list");

  const currentSiteId = useAppSelector(selectCurrentSiteId);
  const currentAreaId = useAppSelector(selectCurrentAreaId);

  const sitesFetched = useAppSelector(selectSitesFetched);
  const areasFetched = useAppSelector(selectAreasFetched);

  const handleChangeView = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | null,
  ) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const [addLocationMenuAnchorEl, setAddLocationMenuAnchorEl] =
    React.useState(null);
  const addLocationMenuOpen = Boolean(addLocationMenuAnchorEl);

  const handleAddLocation = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAddLocationMenuAnchorEl(event.currentTarget);
  };

  const handleCloseAddLocationMenu = () => {
    setAddLocationMenuAnchorEl(null);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const [openedDialog, setOpenedDialog] = React.useState<string | undefined>();

  const sites = useAppSelector(selectAllSites);

  React.useEffect(() => {
    if (sites.length < 1) {
      dispatch(initLocationTestData());
    }
  }, [sites]);

  React.useEffect(() => {
    if (sitesFetched === "idle") {
      dispatch(fetchAllSites());
    }
  }, [sitesFetched]);

  React.useEffect(() => {
    if (areasFetched === "idle") {
      dispatch(fetchAllAreas());
    }
  }, [areasFetched]);

  return (
    <ContentWrapper>
      <ContentLayout
        breadcrumbs={[{ to: "/", name: "Home" }]}
        title="Locations"
      >
        <Stack
          direction="column"
          spacing={1}
          sx={{
            pt: 1.5,
          }}
        >
          <Header
            actions={
              <Stack direction="row" gap={1.5}>
                <ToggleButtonGroup
                  value={view}
                  exclusive
                  onChange={handleChangeView}
                  aria-label="View mode"
                >
                  <ToggleButton value="list" aria-label="List">
                    <AppIcon iconName="view_list" opticalSize={20} />
                  </ToggleButton>
                  <ToggleButton value="map" aria-label="Map">
                    <AppIcon iconName="map" opticalSize={20} />
                  </ToggleButton>
                </ToggleButtonGroup>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleAddLocation}
                  startIcon={<AppIcon iconName="add" opticalSize={24} />}
                >
                  {t("wif.injury.common.new")}
                </Button>
              </Stack>
            }
          >
            {t("wif.injury.common.locations")}
          </Header>
          <TabsContainer px={3}>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              <Tab label={"Base sites"} />
              <Tab label={t("wif.injury.common.areas")} />
            </Tabs>
          </TabsContainer>
        </Stack>
        <TabPanel value={tab} index={0}>
          {view === "list" && <SitesDataGrid />}
          {view === "map" && <SitesTabMapView />}
        </TabPanel>
        <TabPanel value={tab} index={1}>
          {view === "list" && <AreasDataGrid />}
          {view === "map" && <AreasTabMapView />}
        </TabPanel>
        <CreateSiteDialog
          open={openedDialog === "site"}
          handleClose={() => setOpenedDialog(undefined)}
        />
        <CreateAreaDialog
          open={openedDialog === "area"}
          handleClose={() => setOpenedDialog(undefined)}
        />
        <Menu
          id="basic-menu"
          anchorEl={addLocationMenuAnchorEl}
          open={addLocationMenuOpen}
          onClose={handleCloseAddLocationMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            mt: "-2px",
            mr: "-2px",
          }}
        >
          <MenuItem onClick={() => setOpenedDialog("site")}>
            <ListItemIcon sx={{ minWidth: "0px!important" }}>
              <AppIcon iconName="add_location_alt" color="text.secondary" />
            </ListItemIcon>
            <ListItemText>{t("wif.injury.newSite")}</ListItemText>
          </MenuItem>
          {sites.length > 0 && (
            <MenuItem onClick={() => setOpenedDialog("area")}>
              <ListItemIcon sx={{ minWidth: "0px!important" }}>
                <AppIcon iconName="new_window" color="text.secondary" />
              </ListItemIcon>
              <ListItemText>{t("wif.injury.newArea")}</ListItemText>
            </MenuItem>
          )}
        </Menu>
      </ContentLayout>
      {currentSiteId && tab === 0 && (
        <RightContainer>
          <SiteDetails siteId={currentSiteId} />
        </RightContainer>
      )}
      {currentAreaId && tab === 1 && (
        <RightContainer>
          <AreaDetails areaId={currentAreaId} />
        </RightContainer>
      )}
    </ContentWrapper>
  );
};
