import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import { IconButton, Tab } from "@mui/material";
import { AppIcon } from "../Elements";

export const StyledDetailsViewPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 0,
  height: "100%",
}));

export const StyledDetailsCardHeader = styled(CardHeader)(({ theme }) => ({
  "& .MuiCardHeader-title": {
    ...theme.typography.h4,
  },
  "& .MuiCardHeader-avatar": {
    minWidth: 0,
    marginRight: theme.spacing(1.5),
  },
  "& .MuiCardHeader-subheader": {
    ...theme.typography.body2,
    color: "text.secondary",
    mt: 0.25,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "1",
    overflow: "hidden",
    textOverflow: "-",
  },
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  paddingBottom: theme.spacing(0.75),
  paddingTop: theme.spacing(2.25),
  borderBottomWidth: 0,
}));

export const DetailsCardHeader = (props: any) => {
  const { title, subheader, onClose, iconName } = props;

  return (
    <StyledDetailsCardHeader
      title={title}
      subheader={subheader}
      action={
        <IconButton onClick={onClose}>
          <AppIcon iconName="close" opticalSize={22} />
        </IconButton>
      }
      avatar={
        iconName ? <AppIcon iconName={iconName} opticalSize={24} /> : undefined
      }
    />
  );
};

interface StyledDetailsCardProps {
  dash?: boolean;
}

export const StyledDetailChipsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: theme.spacing(1.5),
  paddingLeft: theme.spacing(10),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(0.25),
  paddingBottom: theme.spacing(0.5),
}));

export const StyledDetailsCardTabContainer = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  width: "100%",
}));

export const StyledDetailsCardContent = styled(CardContent)(({ theme }) => ({
  height: "100%",
  width: "100%",
  flex: 1,
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  gap: theme.spacing(2.5),
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
}));

export const StyledDetailsCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "dashVariant",
})<StyledDetailsCardProps>(({ theme, dash }) => ({
  height: dash ? "100%" : "calc(100vh - 80px)",
  display: "flex",
  flexDirection: "column",
  ...(theme.palette.mode === "light" ? {} : {}),
  color: theme.palette.text.primary,
  width: "100%",
  borderRadius: theme.shape.borderRadius,
  overflow: "visible",

  ...(dash
    ? {
        width: "100%",
        borderRadius: 6,
        overflow: "hidden",
        border: "none",
        borderWidth: 0,
        borderColor: "transparent",
      }
    : {
        [theme.breakpoints.up("xs")]: {
          width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
          width: "480px",
        },
        [theme.breakpoints.up("lg")]: {
          width: "400px",
        },
      }),
  backgroundImage: "none",
  "& .MuiCardHeader-title": {
    ...theme.typography.h4,
  },
  "& .MuiCardHeader-subheader": {
    ...theme.typography.body2,
    color: "text.secondary",
    mt: 0.5,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "1",
    overflow: "hidden",
    textOverflow: "-",
  },
  paddingBottom: 0,
}));

export const StyledDetailViewTabs = styled(Tabs)(({ theme }) => ({
  marginLeft: theme.spacing(2.5),
  marginRight: theme.spacing(2.5),
  "& .MuiTab-root": {
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    [theme.breakpoints.up("xs")]: {
      marginRight: theme.spacing(2),
    },
    minWidth: 0,
    "& .MuiSvgIcon-root": {
      [theme.breakpoints.up("xs")]: {
        display: "none",
      },
      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
    },
  },
  "& .Mui-disabled": {
    "& .MuiTab-iconWrapper": {
      color: theme.palette.text.disabled,
    },
  },
}));

export const useAppTabs = (
  initialTab: string,
): [string, (event: React.SyntheticEvent, newValue: string) => void] => {
  const [activeTab, setActiveTab] = React.useState<string>(initialTab);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return [activeTab, handleChangeTab];
};

type DetailViewTabsProps = {
  tabs: string[];
  getTabLabel?: (tab: string) => string;
  activeTab: string;
  onChangeActiveTab: (event: React.SyntheticEvent, newValue: string) => void;
};

export const DetailViewTabs = (props: DetailViewTabsProps) => {
  const { tabs, getTabLabel, activeTab, onChangeActiveTab } = props;

  return (
    <StyledDetailsCardTabContainer>
      <StyledDetailViewTabs
        value={activeTab}
        onChange={onChangeActiveTab}
        aria-label="Detail view tabs"
      >
        {tabs.map((tab: string) => (
          <Tab
            key={tab}
            label={getTabLabel ? getTabLabel(tab) : tab}
            value={tab}
          />
        ))}
      </StyledDetailViewTabs>
    </StyledDetailsCardTabContainer>
  );
};
