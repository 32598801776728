import * as yup from "yup";
import { FormTextField } from "../../../../components/FormInputs";
import type { WithFormMethods } from "../../../../components/Forms";
import { AppForm } from "../../../../components/Forms/AppForm";
import { useCurrentArea } from "../../locationsHooks";
import { useAppDispatch } from "../../../../store";
import { useLoading } from "../../../../common/useLoading";
import { updateAreaDetails } from "../../locationsThunks";
import { getActiveUser } from "../../../../utils/user";

export const editAreaPropertiesFormSchema = yup.object({
  name: yup.string().required(),
});

export type IUpdateAreaDetailsPayload = yup.InferType<
  typeof editAreaPropertiesFormSchema
>;

export const EditAreaPropertiesControls = (
  props: WithFormMethods<IUpdateAreaDetailsPayload>,
) => {
  const { methods } = props;
  const { control, watch, setValue } = methods;

  const user = getActiveUser();
  return (
    <>
      <FormTextField
        control={control}
        name="name"
        label="Area name"
        placeholder="Area name"
      />
    </>
  );
};

type EditAreaPropertiesFormProps = {
  stopEditing?: () => void;
};

export const EditAreaPropertiesForm = (props: EditAreaPropertiesFormProps) => {
  const dispatch = useAppDispatch();
  const area = useCurrentArea();

  const [{ isLoading, loadingMessage }, { startLoading, stopLoading }] =
    useLoading(false);

  const handleSubmit = (data) => {
    const payload = Object.assign({}, data);
    startLoading();
    dispatch(updateAreaDetails({ area_id: area.area_id, payload })).then(
      (resAction) => {
        stopLoading();
      },
    );
  };

  return (
    <AppForm<IUpdateAreaDetailsPayload, typeof editAreaPropertiesFormSchema>
      schema={editAreaPropertiesFormSchema}
      onSubmit={handleSubmit}
      id="update-area-details-form"
      data={{
        name: area.name,
      }}
    >
      {(methods) => <EditAreaPropertiesControls methods={methods} />}
    </AppForm>
  );
};
