import axiosInstance from "./axiosInstance"; // Import your Axios instance
import type { AxiosResponse } from "axios";

type ResponseData = AxiosResponse;

export const httpGet = (url: string): Promise<ResponseData> => {
  return axiosInstance.get(url);
};

export const httpPost = (url: string, data: any): Promise<ResponseData> => {
  return axiosInstance.post(url, data);
};

export const httpPut = (url: string, data: any): Promise<ResponseData> => {
  return axiosInstance.put(url, data);
};

export const httpPatch = (url: string, data: any): Promise<ResponseData> => {
  return axiosInstance.patch(url, data);
};

export const httpDelete = (url: string): Promise<ResponseData> => {
  return axiosInstance.delete(url);
};
