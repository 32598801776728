import * as yup from "yup";
import { useTranslation } from "react-i18next";
import {
  FormDatePicker,
  FormRadioGroup,
  FormTimePicker,
} from "../../../../../components/FormInputs";
import { FormRow, type WithFormMethods } from "../../../../../components/Forms";
import {
  ACCIDENT_TYPE_OPTIONS,
  DAMAGED_BODY_PART_OPTIONS,
  DAMAGE_NATURE_OPTIONS,
} from "../../../components/FormSections";
import { FormAutocompleteWithOrder } from "../../../../../components/FormInputs/FormAutocomplete";
import { TKB_INJURY } from "../../../constants";
import { GENERIC_VALIDATION_MSG } from "../../../constants/mappedInputLabels";
import { getTranslationKeyForSectionFieldLabel } from "../../../helpers/dataMapping";
import { STUDENT_SECTION_NAMES } from "../../../constants/student";

export const STUDENT_ACCIDENT_OCCURED_OPTIONS = [
  "At the place of study",
  "During teaching time",
  "At SFO",
  "On the way to/from school/teaching place",
  "Other",
];

export const STUDENT_ACCIDENT_OCCURED_OPTION_IDS = [
  "67604946-cf85-11ed-afa1-0242ac120002",
  "751d94a8-cf85-11ed-afa1-0242ac120002",
  "7c6476d2-cf85-11ed-afa1-0242ac120002",
  "8369f466-cf85-11ed-afa1-0242ac120002",
  "1d4b309e-cf87-11ed-afa1-0242ac120002",
];

export const studentAccidentSchema = yup.object().shape({
  date: yup.string().required(GENERIC_VALIDATION_MSG),
  time: yup.string().required(GENERIC_VALIDATION_MSG),
  accidentOccured: yup.string().required(GENERIC_VALIDATION_MSG),
  accidentType: yup
    .array()
    .of(yup.string().oneOf(ACCIDENT_TYPE_OPTIONS).required())
    .required(GENERIC_VALIDATION_MSG)
    .min(1, "min 1")
    .max(13, "max 13"),
  damageNature: yup
    .array()
    .of(yup.string().oneOf(DAMAGE_NATURE_OPTIONS).required())
    .required(GENERIC_VALIDATION_MSG)
    .min(1, "min 1")
    .max(11, "max 11"),
  damagedBodyPart: yup
    .array()
    .of(yup.string().oneOf(DAMAGED_BODY_PART_OPTIONS).required())
    .required(GENERIC_VALIDATION_MSG)
    .min(1, "min 1")
    .max(33, "max 33"),
});

export type StudentAccidentSection = yup.InferType<
  typeof studentAccidentSchema
>;

export const StudentAccidentControls = ({
  methods,
  methods: { control },
}: WithFormMethods<StudentAccidentSection>) => {
  const { t } = useTranslation();

  const studentAccidentMapped = {
    "67604946-cf85-11ed-afa1-0242ac120002": t(
      `${TKB_INJURY}.accidentTypesOccurredTags.teachingLocation`,
    ),
    "751d94a8-cf85-11ed-afa1-0242ac120002": t(
      `${TKB_INJURY}.accidentTypesOccurredTags.duringTeaching`,
    ),
    "7c6476d2-cf85-11ed-afa1-0242ac120002": t(
      `${TKB_INJURY}.accidentTypesOccurredTags.sfo`,
    ),
    "8369f466-cf85-11ed-afa1-0242ac120002": t(
      `${TKB_INJURY}.accidentTypesOccurredTags.onTheWay`,
    ),
    "1d4b309e-cf87-11ed-afa1-0242ac120002": t(
      `${TKB_INJURY}.accidentTypesOccurredTags.other`,
    ),
  };

  const translationPart = `${TKB_INJURY}.accidentInfoTab`;
  return (
    <>
      <FormRow>
        <FormDatePicker
          control={control}
          name="date"
          label={t(
            getTranslationKeyForSectionFieldLabel(
              STUDENT_SECTION_NAMES.ACCIDENT,
              "date",
            ),
          )}
          formatTemplate="DD.MM.YYYY"
        />
        <FormTimePicker
          control={control}
          name="time"
          label={t(
            getTranslationKeyForSectionFieldLabel(
              STUDENT_SECTION_NAMES.ACCIDENT,
              "time",
            ),
          )}
        />
      </FormRow>
      <FormAutocompleteWithOrder
        control={control}
        name="accidentType"
        options={ACCIDENT_TYPE_OPTIONS}
        label={t(
          getTranslationKeyForSectionFieldLabel(
            STUDENT_SECTION_NAMES.ACCIDENT,
            "accidentType",
          ),
        )}
        getLabelFromOption={(option) =>
          `${option} ${t(`${translationPart}.${option}`)}`
        }
      />
      <FormAutocompleteWithOrder
        control={control}
        name="damageNature"
        options={DAMAGE_NATURE_OPTIONS}
        getLabelFromOption={(option) =>
          `${option} ${t(`${translationPart}.${option}`)}`
        }
        label={t(
          getTranslationKeyForSectionFieldLabel(
            STUDENT_SECTION_NAMES.ACCIDENT,
            "damageNature",
          ),
        )}
      />
      <FormAutocompleteWithOrder
        control={control}
        name="damagedBodyPart"
        options={DAMAGED_BODY_PART_OPTIONS}
        getLabelFromOption={(option) =>
          `${option} ${t(`${translationPart}.${option}`)}`
        }
        label={t(
          getTranslationKeyForSectionFieldLabel(
            STUDENT_SECTION_NAMES.ACCIDENT,
            "damagedBodyPart",
          ),
        )}
      />
      <FormRadioGroup
        direction="column"
        label={t(
          getTranslationKeyForSectionFieldLabel(
            STUDENT_SECTION_NAMES.ACCIDENT,
            "accidentOccured",
          ),
        )}
        control={control}
        name="accidentOccured"
        options={STUDENT_ACCIDENT_OCCURED_OPTION_IDS}
        mappedLabels={studentAccidentMapped}
      />
    </>
  );
};
