import { useCurrentArea } from "../../../locations/locationsHooks";
import { StyledDetailsCardContent } from "../../../../components/DetailsCard/DetailsCard";
import { InjuryReportsList } from "../InjuryReportsList/InjuryReportsList";

export const AreaInjuryDetailsReportsPanel = () => {
  const area = useCurrentArea();

  return (
    <StyledDetailsCardContent>
      <InjuryReportsList areaId={area.area_id} />
    </StyledDetailsCardContent>
  );
};
