import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { type AlertProps } from "@mui/material/Alert";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectSnackAlert, snackAlertClosed } from "../controlsSlice";
import { AlertTitle, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { APP_COLORS } from "../../../config";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={2} ref={ref} variant="filled" {...props} />;
  },
);

export default function AppSnackBar() {
  const dispatch = useAppDispatch();

  const snackAlert = useAppSelector(selectSnackAlert);
  const theme = useTheme();

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(snackAlertClosed());
  };

  return (
    <Snackbar
      open={snackAlert !== undefined}
      autoHideDuration={snackAlert?.severity === "info" ? 100000 : 5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{
        "& .MuiAlert-filledInfo": {
          ...(theme.palette.mode === "light"
            ? {
                bgcolor: APP_COLORS.dark1,
                color: APP_COLORS.darkTextPrimary,
              }
            : {
                bgcolor: APP_COLORS.light3,
                color: APP_COLORS.textPrimary,
              }),
        },
      }}
    >
      {snackAlert && (
        <Alert
          variant="filled"
          onClose={handleClose}
          severity={snackAlert?.severity}
          sx={{ width: "100%" }}
          iconMapping={{
            info: (
              <CircularProgress
                size={22}
                sx={{
                  color:
                    theme.palette.mode === "light"
                      ? APP_COLORS.darkTextPrimary
                      : APP_COLORS.textPrimary,
                }}
              />
            ),
          }}
        >
          {snackAlert?.title && <AlertTitle>{snackAlert.title}</AlertTitle>}
          {snackAlert?.message}
        </Alert>
      )}
    </Snackbar>
  );
}
