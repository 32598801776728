export interface IRAppliedFilter {
  field: string;
  operator: string;
  value: any;
}

export interface IRFilterControl {
  field: string;
  operator: string;
  label?: string;
  options?: {
    value: any;
    label?: string;
  }[];
}

interface IRFilterOperator {
  value: string;
  applyFilterFunction: (
    appliedFilter: IRAppliedFilter,
    value: any,
  ) => boolean | null;
}

const filterOperators: IRFilterOperator[] = [
  {
    value: "between",
    applyFilterFunction: (appliedFilter: IRAppliedFilter, value: any) => {
      if (
        !Array.isArray(appliedFilter.value) ||
        appliedFilter.value.length !== 2
      ) {
        return null;
      }
      if (appliedFilter.value[0] == null || appliedFilter.value[1] == null) {
        return null;
      }
      return (
        value != null &&
        appliedFilter.value[0] <= value &&
        value <= appliedFilter.value[1]
      );
    },
  },
  {
    value: "isAnyOf",
    applyFilterFunction: (appliedFilter: IRAppliedFilter, value: any) => {
      if (!Array.isArray(appliedFilter.value)) {
        return null;
      }

      if (appliedFilter.value[0] == null) {
        return null;
      }

      return value != null && appliedFilter.value.includes(value);
    },
  },
  {
    value: "is",
    applyFilterFunction: (appliedFilter: IRAppliedFilter, value: any) => {
      return value === appliedFilter.value;
    },
  },
];

export const rPassFilterLogic = (
  appliedFilters: IRAppliedFilter[],
  rowItem: any,
) => {
  for (const appliedFilter of appliedFilters) {
    const value = rowItem[appliedFilter.field];
    if (!value || value == null) {
      return false;
    }
    const filterOperator = filterOperators.find(
      (operator) => operator.value === appliedFilter.operator,
    );

    if (!filterOperator) {
      return false;
    }

    const passedFilterFunction = filterOperator.applyFilterFunction(
      appliedFilter,
      value,
    );
    if (!passedFilterFunction) {
      return false;
    }
  }

  return true;
};
