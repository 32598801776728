import React from "react";
import {
  type NewArea,
  areaDeselected,
  makeSelectLocationAreaById,
} from "../../locationsSlice";
import {
  type RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../store";
import {
  DetailViewTabs,
  DetailsCardHeader,
  StyledDetailsCard,
  useAppTabs,
} from "../../../../components/DetailsCard/DetailsCard";
import { CurrentAreaContext } from "../../locationsHooks";
import { useTranslation } from "react-i18next";
import { AreaDetailsInfoPanel } from "./AreaDetailsInfoPanel";
import { AreaDetailsHistoryPanel } from "./AreaDetailsHistoryPanel";

export const getAreaIconName = (area: NewArea) => {
  if (area?.outline_coordinates) {
    return "polyline";
  }
  return "pin_drop";
};

type AreaDetailsProps = {
  areaId: string;
};

export const AreaDetails = (props: AreaDetailsProps) => {
  const { areaId } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectAreaById = React.useMemo(makeSelectLocationAreaById, [areaId]);
  const area = useAppSelector((state: RootState) =>
    selectAreaById(state, areaId),
  );

  const [tab, handleChangeTab] = useAppTabs("details");

  const handleCloseArea = () => {
    dispatch(areaDeselected());
  };

  const getTabLabel = React.useCallback(
    (tabName: string) => {
      return t(`wif.injury.common.${tabName}`);
    },
    [t],
  );

  return (
    <CurrentAreaContext.Provider value={area}>
      <StyledDetailsCard>
        <DetailsCardHeader
          title={area?.name}
          onClose={handleCloseArea}
          iconName={getAreaIconName(area)}
        />
        <DetailViewTabs
          tabs={["details", "activity"]}
          activeTab={tab}
          onChangeActiveTab={handleChangeTab}
          getTabLabel={getTabLabel}
        />
        {tab === "details" && <AreaDetailsInfoPanel />}
        {tab === "activity" && <AreaDetailsHistoryPanel />}
      </StyledDetailsCard>
    </CurrentAreaContext.Provider>
  );
};
