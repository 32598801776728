import React from "react";
import List from "@mui/material/List";
import { Box, Divider, ListItem, Stack, Typography } from "@mui/material";
import { AppIcon } from "../../../../components/Elements";
import {
  type RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../store";
import {
  type NewArea,
  areaClicked,
  selectAllAreas,
  selectCurrentAreaId,
} from "../../../locations/locationsSlice";
import { makeSelectInjuryReportsByAreaId } from "../../../injuries/store/injuryReportsSlice";
import { REPORT_TYPES } from "../../../injuries/constants";
import { ReportTypeIconNames } from "../../../injuries/constants/mappedProps";

type SiteListItemProps = {
  area: NewArea;
};

const SiteListItem = (props: SiteListItemProps) => {
  const { area } = props;
  const dispatch = useAppDispatch();

  const currentAreaId = useAppSelector(selectCurrentAreaId);

  const selectInjuryReportsByAreaId = React.useMemo(
    makeSelectInjuryReportsByAreaId,
    [],
  );
  const filteredInjuryReports = useAppSelector((state: RootState) =>
    selectInjuryReportsByAreaId(state, area.area_id),
  );

  const accidentsCount = filteredInjuryReports.filter(
    (report: any) => report.type === REPORT_TYPES.ACCIDENT,
  ).length;
  const illnessCount = filteredInjuryReports.filter(
    (report: any) => report.type === REPORT_TYPES.ILLNESS,
  ).length;
  const minorInjuryCount = filteredInjuryReports.filter(
    (report: any) => report.type === REPORT_TYPES.MINOR_INJURY,
  ).length;

  const handleClick = (path: string) => {
    dispatch(areaClicked(area.area_id));
  };

  return (
    <ListItem
      selected={currentAreaId === area.area_id}
      sx={{
        borderColor: "divider",
        p: 2,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
      button
      onClick={() => handleClick(area.area_id)}
    >
      <Stack direction="column" sx={{ flex: 1 }}>
        <Typography variant="h5" sx={{ lineHeight: "20px" }}>
          {area.name}
        </Typography>
        <Box
          sx={{
            mt: 0.25,
            color: "text.secondary",
            typography: "body2",
            display: "flex",
            flexDirection: "row",
            gap: 0.75,
            alignItems: "center",
          }}
        >
          {area.outline_coordinates ? "Area" : "Point"}
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            mt: 1,
          }}
        >
          <Stack direction="row" alignItems="center" spacing={0.75}>
            <AppIcon
              iconName={ReportTypeIconNames[REPORT_TYPES.ACCIDENT]}
              opticalSize={18}
              color={"text.secondary"}
              sx={{ ml: -0.5 }}
            />
            <Typography variant="body2" color="text.primary">
              {accidentsCount}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.75}>
            <AppIcon
              iconName={ReportTypeIconNames[REPORT_TYPES.ILLNESS]}
              opticalSize={18}
              color={"text.secondary"}
              sx={{ ml: -0.5 }}
            />
            <Typography variant="body2" color="text.primary">
              {illnessCount}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.75}>
            <AppIcon
              iconName={ReportTypeIconNames[REPORT_TYPES.MINOR_INJURY]}
              opticalSize={18}
              color={"text.secondary"}
              sx={{ ml: -0.5 }}
            />
            <Typography variant="body2" color="text.primary">
              {minorInjuryCount}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </ListItem>
  );
};

export const SiteList = () => {
  const dispatch = useAppDispatch();
  const areas = useAppSelector(selectAllAreas);

  return (
    <List
      disablePadding
      sx={{
        px: 0,
        py: 0.5,
      }}
    >
      {areas.map((area, i: number) => (
        <React.Fragment key={area.area_id}>
          <SiteListItem area={area} />
          {i < areas.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};
