import { REPORT_TYPES } from "../../injuries/constants";
import type { SitesCollection } from "../sitesSlice";

export const Grodem: SitesCollection = {
  ids: [
    "Grodem/Football field",
    "Grodem/Gymnasium",
    "Grodem/Auditorium",
    "Grodem/Swimming hall",
    "Grodem/Central schoolyard",
    "Grodem/Primary grade schoolyard",
    "Grodem/Middle grade schoolyard",
    "Grodem/Playground",
    "Grodem/Connecting staircase",
    "Grodem/Steep slope",
  ],
  sites: {
    "Grodem/Connecting staircase": {
      domain: "Grodem",
      name: "Connecting staircase",
      lat: Number.parseFloat((59.01047924859296).toFixed(5)),
      lng: Number.parseFloat((5.652942583470406).toFixed(5)),
    },
    "Grodem/Steep slope": {
      domain: "Grodem",
      name: "Steep slope",
      lat: Number.parseFloat((59.01074356891226).toFixed(5)),
      lng: Number.parseFloat((5.652587054851722).toFixed(5)),
    },
    "Grodem/Football field": {
      domain: "Grodem",
      name: "Football field",
      lat: Number.parseFloat((59.00968072158944).toFixed(5)),
      lng: Number.parseFloat((5.652691175598509).toFixed(5)),
      areaCoordinates: [
        [Number.parseFloat((5.652533910516752).toFixed(5)), 59.01011817465775],
        [Number.parseFloat((5.653521945886354).toFixed(5)), 59.0095614390666],
        [Number.parseFloat((5.652839795386399).toFixed(5)), 59.00924414845727],
        [Number.parseFloat((5.651859993259961).toFixed(5)), 59.00979435414371],
        [Number.parseFloat((5.652533910516752).toFixed(5)), 59.01011817465775],
      ],
    },
    "Grodem/Playground": {
      domain: "Grodem",
      name: "Playground",
      lat: Number.parseFloat((59.01002096841506).toFixed(5)),
      lng: Number.parseFloat((5.652085810968998).toFixed(5)),
      areaCoordinates: [
        [Number.parseFloat((5.651680189660254).toFixed(5)), 59.009886489383376],
        [Number.parseFloat((5.651817664082245).toFixed(5)), 59.009812677942364],
        [Number.parseFloat((5.652495284648552).toFixed(5)), 59.0101339909493],
        [Number.parseFloat((5.652366443104739).toFixed(5)), 59.010208497641514],
        [Number.parseFloat((5.651680189660254).toFixed(5)), 59.009886489383376],
      ],
    },
    "Grodem/Gymnasium": {
      domain: "Grodem",
      name: "Gymnasium",
      lat: Number.parseFloat((59.01029511092184).toFixed(5)),
      lng: Number.parseFloat((5.652907600847541).toFixed(5)),
      areaCoordinates: [
        [Number.parseFloat((5.652702422355645).toFixed(5)), 59.010266497656005],
        [Number.parseFloat((5.652861000508921).toFixed(5)), 59.01017925437796],
        [Number.parseFloat((5.653157240826232).toFixed(5)), 59.01032634097598],
        [Number.parseFloat((5.653001685973749).toFixed(5)), 59.01041076260216],
        [Number.parseFloat((5.652702422355645).toFixed(5)), 59.010266497656005],
      ],
    },
    "Grodem/Auditorium": {
      domain: "Grodem",
      name: "Auditorium",
      lat: Number.parseFloat((59.01036).toFixed(5)),
      lng: 5.65421,
    },
    "Grodem/Swimming hall": {
      domain: "Grodem",
      name: "Swimming hall",
      lat: Number.parseFloat((59.01062285834463).toFixed(5)),
      lng: Number.parseFloat((5.652442464184236).toFixed(5)),
      areaCoordinates: [
        [Number.parseFloat((5.652258020515887).toFixed(5)), 59.01059419746119],
        [Number.parseFloat((5.65240418536402).toFixed(5)), 59.010513287489374],
        [Number.parseFloat((5.6527398017003065).toFixed(5)), 59.01066859614804],
        [Number.parseFloat((5.652671029999539).toFixed(5)), 59.01070804267445],
        [
          Number.parseFloat((5.6525980608137445).toFixed(5)),
          59.010676069700196,
        ],
        [Number.parseFloat((5.652517502208752).toFixed(5)), 59.01072085517572],
        [Number.parseFloat((5.652258020515887).toFixed(5)), 59.01059419746119],
      ],
    },
    "Grodem/Central schoolyard": {
      domain: "Grodem",
      name: "Central schoolyard",
      lat: Number.parseFloat((59.01021).toFixed(5)),
      lng: 5.65372,
    },
    "Grodem/Primary grade schoolyard": {
      domain: "Grodem",
      name: "Primary grade schoolyard",
      lat: Number.parseFloat((59.010865510325054).toFixed(5)),
      lng: Number.parseFloat((5.653117390401085).toFixed(5)),
      areaCoordinates: [
        [Number.parseFloat((5.653020338185286).toFixed(5)), 59.0105466225387],
        [Number.parseFloat((5.653603227496092).toFixed(5)), 59.0108239080169],
        [Number.parseFloat((5.6530013903344525).toFixed(5)), 59.01115786897387],
        [Number.parseFloat((5.652794247665167).toFixed(5)), 59.01121316140524],
        [Number.parseFloat((5.652720257173392).toFixed(5)), 59.01112983314632],
        [Number.parseFloat((5.652938231836046).toFixed(5)), 59.011081069227956],
        [Number.parseFloat((5.652674372044004).toFixed(5)), 59.010759258185374],
        [Number.parseFloat((5.653020338185286).toFixed(5)), 59.0105466225387],
      ],
    },
    "Grodem/Middle grade schoolyard": {
      domain: "Grodem",
      name: "Middle grade schoolyard",
      lat: Number.parseFloat((59.0106).toFixed(5)),
      lng: Number.parseFloat((5.6543).toFixed(5)),
      areaCoordinates: [
        [Number.parseFloat((5.654395077181221).toFixed(5)), 59.01009519017413],
        [Number.parseFloat((5.65458087099924).toFixed(5)), 59.00999541397846],
        [Number.parseFloat((5.655177603185649).toFixed(5)), 59.010289294394056],
        [Number.parseFloat((5.653967272208206).toFixed(5)), 59.01094775013766],
        [Number.parseFloat((5.65366649804227).toFixed(5)), 59.0108034542792],
        [Number.parseFloat((5.654696836827981).toFixed(5)), 59.01024196104851],
        [Number.parseFloat((5.654395077181221).toFixed(5)), 59.01009519017413],
      ],
    },
  },
};

type WeightedSites = {
  [siteId: string]: {
    site?: number;
    [REPORT_TYPES.ACCIDENT]?: number;
    [REPORT_TYPES.ILLNESS]?: number;
    [REPORT_TYPES.MINOR_INJURY]?: number;
  };
};

export const AreaWeights: { [domain: string]: number } = {
  Grodem: 3,
  "Kongsvinger ungdomsskole": 2,
  Marikollen: 2,
};

export const GrodemWeights: WeightedSites = {
  "Grodem/Football field": {
    site: 5,
    [REPORT_TYPES.ACCIDENT]: 5,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 2,
  },
  "Grodem/Gymnasium": {
    site: 4,
    [REPORT_TYPES.ACCIDENT]: 6,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 3,
  },
  "Grodem/Auditorium": {
    site: 2,
    [REPORT_TYPES.ACCIDENT]: 3,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 2,
  },
  "Grodem/Swimming hall": {
    site: 2,
    [REPORT_TYPES.ACCIDENT]: 1,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Grodem/Central schoolyard": {
    site: 1,
    [REPORT_TYPES.ACCIDENT]: 1,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Grodem/Primary grade schoolyard": {
    site: 2,
    [REPORT_TYPES.ACCIDENT]: 1,
    [REPORT_TYPES.ILLNESS]: 3,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Grodem/Middle grade schoolyard": {
    site: 1,
    [REPORT_TYPES.ACCIDENT]: 3,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Grodem/Playground": {
    site: 2,
    [REPORT_TYPES.ACCIDENT]: 3,
    [REPORT_TYPES.ILLNESS]: 2,
    [REPORT_TYPES.MINOR_INJURY]: 2,
  },
  "Grodem/Connecting staircase": {
    site: 1,
    [REPORT_TYPES.ACCIDENT]: 4,
    [REPORT_TYPES.ILLNESS]: 0,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Grodem/Steep slope": {
    site: 1,
    [REPORT_TYPES.ACCIDENT]: 4,
    [REPORT_TYPES.ILLNESS]: 0,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
};

export const KongsvingerWeights: WeightedSites = {
  "Kongsvinger ungdomsskole/Parkeringsplass B": {
    site: 4,
    [REPORT_TYPES.ACCIDENT]: 3,
    [REPORT_TYPES.ILLNESS]: 0,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Kongsvinger ungdomsskole/Tråstad idrettsplass": {
    site: 5,
    [REPORT_TYPES.ACCIDENT]: 6,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 3,
  },
  "Kongsvinger ungdomsskole/Idrettshall gavel vest": {
    site: 3,
    [REPORT_TYPES.ACCIDENT]: 4,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Kongsvinger ungdomsskole/Bibliotek": {
    site: 1,
    [REPORT_TYPES.ACCIDENT]: 1,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Kongsvinger ungdomsskole/Idrettshall syd": {
    site: 3,
    [REPORT_TYPES.ACCIDENT]: 4,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 2,
  },
  "Kongsvinger ungdomsskole/Grupperom": {
    site: 1,
    [REPORT_TYPES.ACCIDENT]: 2,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Kongsvinger ungdomsskole/Keramikk": {
    site: 1,
    [REPORT_TYPES.ACCIDENT]: 3,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Kongsvinger ungdomsskole/Lagerrom": {
    site: 2,
    [REPORT_TYPES.ACCIDENT]: 4,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Kongsvinger ungdomsskole/Inngangsparti": {
    site: 1,
    [REPORT_TYPES.ACCIDENT]: 3,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Kongsvinger ungdomsskole/Parkeringsplass A": {
    site: 2,
    [REPORT_TYPES.ACCIDENT]: 3,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
};

export const KongsvingerUngdomsskole: SitesCollection = {
  ids: [
    "Kongsvinger ungdomsskole/Parkeringsplass B",
    "Kongsvinger ungdomsskole/Tråstad idrettsplass",
    "Kongsvinger ungdomsskole/Idrettshall gavel vest",
    "Kongsvinger ungdomsskole/Bibliotek",
    "Kongsvinger ungdomsskole/Idrettshall syd",
    "Kongsvinger ungdomsskole/Grupperom",
    "Kongsvinger ungdomsskole/Keramikk",
    "Kongsvinger ungdomsskole/Lagerrom",
    "Kongsvinger ungdomsskole/Inngangsparti",
    "Kongsvinger ungdomsskole/Parkeringsplass A",
  ],
  sites: {
    "Kongsvinger ungdomsskole/Parkeringsplass B": {
      domain: "Kongsvinger ungdomsskole",
      name: "Parkeringsplass B",
      lat: Number.parseFloat((60.19164).toFixed(5)),
      lng: Number.parseFloat((12.01569).toFixed(5)),
      areaCoordinates: [
        [Number.parseFloat((12.015327289352797).toFixed(5)), 60.19178229122244],
        [Number.parseFloat((12.015798508722668).toFixed(5)), 60.1918466007437],
        [
          Number.parseFloat((12.016042453601528).toFixed(5)),
          60.191341306722705,
        ],
        [
          Number.parseFloat((12.015611901531486).toFixed(5)),
          60.191281029623866,
        ],
        [Number.parseFloat((12.015327289352797).toFixed(5)), 60.19178229122244],
      ],
    },
    "Kongsvinger ungdomsskole/Tråstad idrettsplass": {
      domain: "Kongsvinger ungdomsskole",
      name: "Tråstad idrettsplass",
      lat: Number.parseFloat((60.19165735791522).toFixed(5)),
      lng: Number.parseFloat((12.017834708288865).toFixed(5)),
      areaCoordinates: [
        [Number.parseFloat((12.016772910846669).toFixed(5)), 60.19170250205656],
        [Number.parseFloat((12.017194283843395).toFixed(5)), 60.19119961758753],
        [Number.parseFloat((12.018875073378439).toFixed(5)), 60.19154860024088],
        [Number.parseFloat((12.0184580701779).toFixed(5)), 60.1920507273195],
        [Number.parseFloat((12.016772910846669).toFixed(5)), 60.19170250205656],
      ],
    },
    "Kongsvinger ungdomsskole/Idrettshall gavel vest": {
      domain: "Kongsvinger ungdomsskole",
      name: "Idrettshall gavel vest",
      lat: Number.parseFloat((60.191116258164016).toFixed(5)),
      lng: Number.parseFloat((12.015920716326049).toFixed(5)),
      areaCoordinates: [
        [
          Number.parseFloat((12.015908202619698).toFixed(5)),
          60.191205421296644,
        ],
        [Number.parseFloat((12.016015036937034).toFixed(5)), 60.1910068202605],
        [Number.parseFloat((12.01593599334683).toFixed(5)), 60.19099607736692],
        [
          Number.parseFloat((12.015915875671197).toFixed(5)),
          60.191037708812274,
        ],
        [Number.parseFloat((12.015935155923756).toFixed(5)), 60.19104086838965],
        [Number.parseFloat((12.015879332602765).toFixed(5)), 60.19115009763786],
        [
          Number.parseFloat((12.015824441521204).toFixed(5)),
          60.191143840827124,
        ],
        [Number.parseFloat((12.015798966209303).toFixed(5)), 60.19119185049365],
        [
          Number.parseFloat((12.015908202619698).toFixed(5)),
          60.191205421296644,
        ],
      ],
    },
    "Kongsvinger ungdomsskole/Bibliotek": {
      domain: "Kongsvinger ungdomsskole",
      name: "Bibliotek",
      lat: Number.parseFloat((60.191058557956026).toFixed(5)),
      lng: 12.015571863740613,
    },
    "Kongsvinger ungdomsskole/Idrettshall syd": {
      domain: "Kongsvinger ungdomsskole",
      name: "Idrettshall syd",
      lat: Number.parseFloat((60.1910326912993).toFixed(5)),
      lng: Number.parseFloat((12.015243760989337).toFixed(5)),
      areaCoordinates: [
        [Number.parseFloat((12.015141000710457).toFixed(5)), 60.19110364390846],
        [
          Number.parseFloat((12.015244046287762).toFixed(5)),
          60.190908667809566,
        ],
        [
          Number.parseFloat((12.015405456685926).toFixed(5)),
          60.190929457357356,
        ],
        [Number.parseFloat((12.015308590988099).toFixed(5)), 60.1911226314831],
        [Number.parseFloat((12.015141000710457).toFixed(5)), 60.19110364390846],
      ],
    },
    "Kongsvinger ungdomsskole/Grupperom": {
      domain: "Kongsvinger ungdomsskole",
      name: "Grupperom",
      lat: Number.parseFloat((60.19154).toFixed(5)),
      lng: 12.01507,
    },
    "Kongsvinger ungdomsskole/Keramikk": {
      domain: "Kongsvinger ungdomsskole",
      name: "Keramikk",
      lat: Number.parseFloat((60.19154).toFixed(5)),
      lng: 12.01436,
    },
    "Kongsvinger ungdomsskole/Lagerrom": {
      domain: "Kongsvinger ungdomsskole",
      name: "Lagerrom",
      lat: Number.parseFloat((60.19145).toFixed(5)),
      lng: Number.parseFloat((12.01374).toFixed(5)),
    },
    "Kongsvinger ungdomsskole/Inngangsparti": {
      domain: "Kongsvinger ungdomsskole",
      name: "Inngangsparti",
      lat: Number.parseFloat((60.19116).toFixed(5)),
      lng: Number.parseFloat((12.01356).toFixed(5)),
      areaCoordinates: [
        [Number.parseFloat((12.013217707629451).toFixed(5)), 60.19127312679086],
        [
          Number.parseFloat((12.013377816222288).toFixed(5)),
          60.190987783178855,
        ],
        [Number.parseFloat((12.013855328112214).toFixed(5)), 60.19104983659378],
        [Number.parseFloat((12.01377431050443).toFixed(5)), 60.1911965686015],
        [Number.parseFloat((12.013699782764576).toFixed(5)), 60.19118877724952],
        [Number.parseFloat((12.013618981213682).toFixed(5)), 60.19132420096622],
        [Number.parseFloat((12.013217707629451).toFixed(5)), 60.19127312679086],
      ],
    },
    "Kongsvinger ungdomsskole/Parkeringsplass A": {
      domain: "Kongsvinger ungdomsskole",
      name: "Parkeringsplass A",
      lat: Number.parseFloat((60.19121).toFixed(5)),
      lng: Number.parseFloat((12.0129).toFixed(5)),
      areaCoordinates: [
        [Number.parseFloat((12.0125996080493).toFixed(5)), 60.191343395592924],
        [Number.parseFloat((12.01311769993211).toFixed(5)), 60.19147028082483],
        [Number.parseFloat((12.013076955459326).toFixed(5)), 60.19102963142197],
        [Number.parseFloat((12.012773405238875).toFixed(5)), 60.19098950410674],
        [Number.parseFloat((12.0125996080493).toFixed(5)), 60.191343395592924],
      ],
    },
  },
};

const MarikollenWeights: WeightedSites = {
  "Marikollen/Rom 102": {
    site: 1,
    [REPORT_TYPES.ACCIDENT]: 3,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Marikollen/Rom 202": {
    site: 1,
    [REPORT_TYPES.ACCIDENT]: 3,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Marikollen/Rom 357 musikkrom": {
    site: 2,
    [REPORT_TYPES.ACCIDENT]: 1,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Marikollen/Rom 340": {
    site: 4,
    [REPORT_TYPES.ACCIDENT]: 2,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Marikollen/Rom 332 gymsal": {
    site: 4,
    [REPORT_TYPES.ACCIDENT]: 5,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Marikollen/Rom 402": {
    site: 2,
    [REPORT_TYPES.ACCIDENT]: 3,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Marikollen/Rom 502": {
    site: 1,
    [REPORT_TYPES.ACCIDENT]: 3,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
  "Marikollen/Rom 602": {
    site: 2,
    [REPORT_TYPES.ACCIDENT]: 3,
    [REPORT_TYPES.ILLNESS]: 1,
    [REPORT_TYPES.MINOR_INJURY]: 1,
  },
};

const areaWeightKeys = Object.keys(AreaWeights) as string[];

export const weightedAreas = areaWeightKeys.reduce((acc, cur) => {
  const toInsert: string[] = [];
  for (let i = 0; i < AreaWeights[cur]; i++) {
    toInsert.push(cur);
  }
  return acc.concat(toInsert);
}, [] as string[]);

export const getWeightedSitesByArea = (area: string) => {
  let weightedSitesCollection = GrodemWeights;
  if (area === "Kongsvinger ungdomsskole") {
    weightedSitesCollection = KongsvingerWeights;
  } else if (area === "Marikollen") {
    weightedSitesCollection = MarikollenWeights;
  }
  return Object.keys(weightedSitesCollection).reduce((acc, cur) => {
    const toInsert: string[] = [];
    const stopIndex = weightedSitesCollection[cur].site || 0;
    for (let i = 0; i < stopIndex; i++) {
      toInsert.push(cur);
    }
    return acc.concat(toInsert);
  }, [] as string[]);
};

export const getWeightedTypesByAreaAndSite = (area: string, site: string) => {
  let weightedSitesCollection = GrodemWeights;
  if (area === "Kongsvinger ungdomsskole") {
    weightedSitesCollection = KongsvingerWeights;
  } else if (area === "Marikollen") {
    weightedSitesCollection = MarikollenWeights;
  }
  const siteWeights = weightedSitesCollection[site];
  const weightedTypes: string[] = [];
  for (const typeStr of [
    REPORT_TYPES.ACCIDENT,
    REPORT_TYPES.ILLNESS,
    REPORT_TYPES.MINOR_INJURY,
  ]) {
    const stopIndex = (siteWeights as any)[typeStr] || 0;
    for (let i = 0; i < stopIndex; i++) {
      weightedTypes.push(typeStr);
    }
  }
  return weightedTypes;
};

export const Marikollen: SitesCollection = {
  ids: [
    "Marikollen/Rom 102",
    "Marikollen/Rom 202",
    "Marikollen/Rom 357 musikkrom",
    "Marikollen/Rom 340",
    "Marikollen/Rom 332 gymsal",
    "Marikollen/Rom 402",
    "Marikollen/Rom 502",
    "Marikollen/Rom 602",
  ],
  sites: {
    "Marikollen/Rom 102": {
      lat: Number.parseFloat((60.191574740991825).toFixed(5)),
      lng: Number.parseFloat((11.97085469776377).toFixed(5)),
      domain: "Marikollen",
      name: "Rom 102",
    },
    "Marikollen/Rom 202": {
      lat: Number.parseFloat((60.191527414132764).toFixed(5)),
      lng: Number.parseFloat((11.971271981642928).toFixed(5)),
      domain: "Marikollen",
      name: "Rom 202",
    },
    "Marikollen/Rom 357 musikkrom": {
      lat: Number.parseFloat((60.19132098767224).toFixed(5)),
      lng: Number.parseFloat((11.971525187881554).toFixed(5)),
      domain: "Marikollen",
      name: "Rom 357 musikkrom",
      areaCoordinates: [
        [
          Number.parseFloat((11.971237276090363).toFixed(5)),
          60.191237303569835,
        ],
        [Number.parseFloat((11.971400600019393).toFixed(5)), 60.19118775111434],
        [
          Number.parseFloat((11.971540877878994).toFixed(5)),
          60.191304655242675,
        ],
        [
          Number.parseFloat((11.971415705914666).toFixed(5)),
          60.191341094198236,
        ],
        [Number.parseFloat((11.97133378292068).toFixed(5)), 60.1912740007883],
        [Number.parseFloat((11.971296984193657).toFixed(5)), 60.19128576283646],
        [
          Number.parseFloat((11.971237276090363).toFixed(5)),
          60.191237303569835,
        ],
      ],
    },
    "Marikollen/Rom 340": {
      lat: Number.parseFloat((60.19142269062712).toFixed(5)),
      lng: Number.parseFloat((11.971897907464239).toFixed(5)),
      domain: "Marikollen",
      name: "Rom 340",
    },
    "Marikollen/Rom 332 gymsal": {
      lat: Number.parseFloat((60.19123237493969).toFixed(5)),
      lng: Number.parseFloat((11.97181283016755).toFixed(5)),
      domain: "Marikollen",
      name: "Rom 332 gymsal",
    },
    "Marikollen/Rom 402": {
      lat: Number.parseFloat((60.191235395832194).toFixed(5)),
      lng: Number.parseFloat((11.972319242643493).toFixed(5)),
      domain: "Marikollen",
      name: "Rom 402",
      areaCoordinates: [
        [Number.parseFloat((11.9722680683428).toFixed(5)), 60.19130919588045],
        [Number.parseFloat((11.972164327362975).toFixed(5)), 60.19122590548295],
        [Number.parseFloat((11.972400294072969).toFixed(5)), 60.19115327905939],
        [Number.parseFloat((11.972502811972873).toFixed(5)), 60.19123723634925],
        [Number.parseFloat((11.9722680683428).toFixed(5)), 60.19130919588045],
      ],
    },
    "Marikollen/Rom 502": {
      lat: Number.parseFloat((60.191088378739266).toFixed(5)),
      lng: Number.parseFloat((11.972693987876227).toFixed(5)),
      domain: "Marikollen",
      name: "Rom 502",
    },
    "Marikollen/Rom 602": {
      lat: Number.parseFloat((60.190898061113586).toFixed(5)),
      lng: Number.parseFloat((11.97296137366277).toFixed(5)),
      domain: "Marikollen",
      name: "Rom 602",
      areaCoordinates: [
        [Number.parseFloat((11.972985232765893).toFixed(5)), 60.19098126403753],
        [
          Number.parseFloat((11.973107635011075).toFixed(5)),
          60.190856135948906,
        ],
        [
          Number.parseFloat((11.972927000338387).toFixed(5)),
          60.190812521093534,
        ],
        [
          Number.parseFloat((11.972806209298255).toFixed(5)),
          60.190937495546194,
        ],
        [Number.parseFloat((11.972985232765893).toFixed(5)), 60.19098126403753],
      ],
    },
  },
};
