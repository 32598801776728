import React from "react";
import { useTranslation } from "react-i18next";
import {
  type RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../store";
import {
  areaDeselected,
  makeSelectLocationAreaById,
} from "../../../locations/locationsSlice";
import {
  DetailViewTabs,
  DetailsCardHeader,
  StyledDetailsCard,
  useAppTabs,
} from "../../../../components/DetailsCard/DetailsCard";
import { CurrentAreaContext } from "../../../locations/locationsHooks";
import { getAreaIconName } from "../../../locations/components/AreaDetails/AreaDetails";
import { AreaInjuryDetailsSummaryPanel } from "./AreaInjuryDetailsSummaryPanel";
import { AreaInjuryDetailsReportsPanel } from "./AreaInjuryDetailsReportsPanel";

type AreaInjuryDetailsProps = {
  area_id: string;
};

export const AreaInjuryDetails = (props: AreaInjuryDetailsProps) => {
  const { area_id } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const selectAreaById = React.useMemo(makeSelectLocationAreaById, [area_id]);
  const area = useAppSelector((state: RootState) =>
    selectAreaById(state, area_id),
  );

  const [tab, handleChangeTab] = useAppTabs("details");

  const handleCloseArea = () => {
    dispatch(areaDeselected());
  };

  const getTabLabel = React.useCallback(
    (tabName: string) => {
      return t(`wif.injury.common.${tabName}`);
    },
    [t],
  );

  return (
    <CurrentAreaContext.Provider value={area}>
      <StyledDetailsCard>
        <DetailsCardHeader
          title={area?.name}
          onClose={handleCloseArea}
          iconName={getAreaIconName(area)}
        />
        <DetailViewTabs
          tabs={["details", "reports"]}
          activeTab={tab}
          onChangeActiveTab={handleChangeTab}
          getTabLabel={getTabLabel}
        />
        {tab === "details" && <AreaInjuryDetailsSummaryPanel />}
        {tab === "reports" && <AreaInjuryDetailsReportsPanel />}
      </StyledDetailsCard>
    </CurrentAreaContext.Provider>
  );
};
