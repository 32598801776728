import React from "react";
import { useCurrentInjuryReport } from "../../../routes/CurrentInjuryReportContext";
import { Box, Button, Popover, Stack, Typography } from "@mui/material";
import { AppIcon } from "../../../../../components/Elements";
import { type RootState, useAppSelector } from "../../../../../store";
import { makeSelectLogsByInjuryReportId } from "../../../store/injuryReportsSlice";
import { useTranslation } from "react-i18next";
import { selectEmployees } from "../../../../employees/employeesSlice";
import {
  INJURY_LOG_TYPE_LABELS,
  InjuryHistoryItem,
} from "../../History/InjuryHistoryItem";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import _ from "lodash";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { getActiveUser } from "../../../../../utils/user";

export const HistoryPanel = () => {
  const { t } = useTranslation();

  const injuryReport = useCurrentInjuryReport();
  const activeUser = getActiveUser();

  const selectLogsByInjuryReportId = React.useMemo(
    makeSelectLogsByInjuryReportId,
    [injuryReport],
  );
  const logItems = useAppSelector((state: RootState) =>
    selectLogsByInjuryReportId(state, injuryReport.id),
  );
  const employees = useAppSelector(selectEmployees);

  const [sort, setSort] = React.useState("newest");
  const [activeFilters, setActiveFilters] = React.useState([]);
  const [activeUserFilters, setActiveUserFilters] = React.useState([]);

  const [sortAnchorEl, setSortAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const [filtersAnchorEl, setFiltersAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const sortOpen = Boolean(sortAnchorEl);
  const filtersOpen = Boolean(filtersAnchorEl);

  const handleClickSort = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSortAnchorEl(event.currentTarget);
  };
  const handleClickFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFiltersAnchorEl(event.currentTarget);
  };
  const handleSortClose = () => {
    setSortAnchorEl(null);
  };
  const handleFiltersClose = () => {
    setFiltersAnchorEl(null);
  };
  const handleSort = (newSort: string) => {
    setSort(newSort);
    setSortAnchorEl(null);
  };

  const filteredAndSortedLogItems = React.useMemo(() => {
    let filteredItems = [...logItems];
    if (activeFilters.length > 0) {
      filteredItems = filteredItems.filter((item) => {
        if (activeFilters.indexOf(item?.type) !== -1) {
          return true;
        }
        return false;
      });
    }
    if (activeUserFilters.length > 0) {
      filteredItems = filteredItems.filter((item) => {
        if (activeUserFilters.indexOf(item?.user_id) !== -1) {
          return true;
        }
        return false;
      });
    }
    if (sort === "newest") {
      return filteredItems.sort(
        (a, b) => new Date(b?.time)?.getTime() - new Date(a?.time)?.getTime(),
      );
    } else if (sort === "oldest") {
      return filteredItems.sort(
        (a, b) => new Date(a?.time)?.getTime() - new Date(b?.time)?.getTime(),
      );
    }
    return filteredItems.sort(
      (a, b) => new Date(b?.time)?.getTime() - new Date(a?.time)?.getTime(),
    );
  }, [sort, activeFilters, logItems, activeUserFilters]);

  const handleChangeActiveFilters = (
    event: React.ChangeEvent<HTMLInputElement>,
    filter: string,
  ) => {
    setActiveFilters((prevActiveFilters) => {
      const newFilters = [...prevActiveFilters];
      if (event.target.checked && newFilters.indexOf(filter) === -1) {
        newFilters.push(filter);
      }
      if (!event.target.checked && newFilters.indexOf(filter) !== -1) {
        newFilters.splice(newFilters.indexOf(filter), 1);
      }
      return newFilters;
    });
  };

  const handleClickFilter = (filter: string) => {
    setActiveFilters((prevActiveFilters) => {
      const newFilters = [...prevActiveFilters];
      if (newFilters.indexOf(filter) === -1) {
        newFilters.push(filter);
      } else if (newFilters.indexOf(filter) !== -1) {
        newFilters.splice(newFilters.indexOf(filter), 1);
      }
      return newFilters;
    });
  };

  const handleClickUserFilter = (filter: string) => {
    setActiveUserFilters((prevActiveFilters) => {
      const newFilters = [...prevActiveFilters];
      if (newFilters.indexOf(filter) === -1) {
        newFilters.push(filter);
      } else if (newFilters.indexOf(filter) !== -1) {
        newFilters.splice(newFilters.indexOf(filter), 1);
      }
      return newFilters;
    });
  };

  const isChecked = (filter: string) => {
    return activeFilters.indexOf(filter) !== -1;
  };

  const uniqLogTypes = React.useMemo(() => {
    const types = logItems.map((item) => item?.type);
    return _.uniq(types);
  }, [logItems]);

  const uniqUserIds = React.useMemo(() => {
    const ids = logItems.map((item) => item?.user_id);
    return _.uniq(ids);
  }, [logItems]);

  return (
    <>
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        justifyContent="space-between"
        sx={{
          px: 1.5,
          mt: 0.5,
        }}
      >
        <Button
          variant="text"
          color="primary"
          startIcon={<AppIcon iconName="tune" />}
          onClick={handleClickFilters}
          sx={{
            pl: 1,
          }}
          disabled={uniqLogTypes.length <= 1 && uniqLogTypes.length <= 1}
        >
          {t("wif.injury.filters")}{" "}
          {activeFilters.length + activeUserFilters.length > 0 &&
            ` (${activeFilters.length + activeUserFilters.length})`}
        </Button>
        <Button
          variant="text"
          endIcon={<AppIcon iconName="arrow_drop_down" color="primary.main" />}
          onClick={handleClickSort}
          sx={{
            "& .MuiButton-endIcon": {
              ml: 0.5,
            },
            pr: 1,
          }}
        >
          {t("wif.injury.sortBy") + ": " + t(`wif.injury.${sort}`)}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={sortAnchorEl}
          open={sortOpen}
          onClose={handleSortClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleSort("newest")}>
            {t("wif.injury.newest")}
          </MenuItem>
          <MenuItem onClick={() => handleSort("oldest")}>
            {t("wif.injury.oldest")}
          </MenuItem>
        </Menu>
        <Popover
          id="history-filters-popover"
          open={filtersOpen}
          anchorEl={filtersAnchorEl}
          onClose={handleFiltersClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Stack
            direction="column"
            sx={{
              p: 2,
            }}
            gap={1}
          >
            <FormControl component="fieldset" variant="standard">
              <FormLabel
                sx={{
                  typography: "body2.typography",
                  fontWeight: 600,
                  color: "text.secondary",
                }}
              >
                {t("wif.injury.type")}
              </FormLabel>
              <FormGroup>
                {uniqLogTypes.map((type) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={activeFilters.indexOf(type) !== -1}
                        onChange={(e) => handleChangeActiveFilters(e, type)}
                        onClick={() => handleClickFilter(type)}
                      />
                    }
                    label={INJURY_LOG_TYPE_LABELS[type]}
                  />
                ))}
              </FormGroup>
            </FormControl>
            {uniqUserIds.length > 1 && (
              <FormControl component="fieldset" variant="standard">
                <FormLabel
                  sx={{
                    typography: "body2.typography",
                    fontWeight: 600,
                    color: "text.secondary",
                  }}
                >
                  {t("wif.injury.user")}
                </FormLabel>
                {uniqUserIds.map((userId) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={activeUserFilters.indexOf(userId) !== -1}
                        onClick={() => handleClickUserFilter(userId)}
                      />
                    }
                    label={
                      activeUser?.id === userId
                        ? "You"
                        : employees[userId]?.name || userId
                    }
                  />
                ))}
              </FormControl>
            )}

            {(activeFilters.length > 0 || activeUserFilters.length > 0) && (
              <Button
                onClick={() => {
                  setActiveFilters([]);
                  setActiveUserFilters([]);
                }}
                variant="text"
                color="primary"
              >
                {t("wif.injury.clearAll")}
              </Button>
            )}
          </Stack>
        </Popover>
      </Stack>

      <Stack direction="column" sx={{ overflowY: "auto", flex: 1, py: 1 }}>
        {filteredAndSortedLogItems.length > 0 ? (
          <>
            {filteredAndSortedLogItems.map((item, index) => (
              <>
                <InjuryHistoryItem key={index} log={item} />
              </>
            ))}
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="body1">
              {t("wif.injury.noActivity")}
            </Typography>
          </Box>
        )}
      </Stack>
    </>
  );
};
