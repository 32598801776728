import { Paper } from "@mui/material";
import type { WithChildren } from "../../../../types";

type LocationsControllerToolbarProps = WithChildren<{}>;

export const LocationsControllerToolbar = (
  props: LocationsControllerToolbarProps,
) => {
  const { children } = props;

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        px: 1,
        py: 1,
        bgcolor: "background.default",
        overflow: "visible",
        borderRadius: 0,
      }}
    >
      {children}
    </Paper>
  );
};
