import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { AppDialogTitle } from "../../../components/Display/AppDialogTitle";
import { type RootState, useAppDispatch, useAppSelector } from "../../../store";
import { snackAlertOpened } from "../../global/controlsSlice";
import {
  deleteInjuryReportAction,
  makeSelectInjuryReportById,
} from "../../injuries/store/injuryReportsSlice";
import { LoadingButtonContainer } from "../../../components/Elements/LoadingButtonContainer";
import type { IAction } from "../actionTypes";
import { useTranslation } from "react-i18next";

interface DeleteActionDialogProps {
  action?: IAction;
  onClose: () => void;
  open?: boolean;
}

export default function DeleteActionDialog(props: DeleteActionDialogProps) {
  const { onClose, action, open = false } = props;
  const { t } = useTranslation();

  const selectInjuryReportById = React.useMemo(makeSelectInjuryReportById, [
    action,
  ]);
  const injuryReport = useAppSelector((state: RootState) =>
    selectInjuryReportById(state, action.report_id),
  );

  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState(false);

  const handleDelete = () => {
    const req = {
      reportId: injuryReport.id,
      action_id: action?.action_id,
    };
    setLoading(true);
    dispatch(deleteInjuryReportAction(req))
      .unwrap()
      .then((originalPromiseResult) => {
        setLoading(false);
        dispatch(
          snackAlertOpened({
            severity: "success",
            message: t("wif.injury.snackMessages.actionDeleted.success"),
          }),
        );
        onClose && onClose();
      })
      .catch((rejectedValueOrSerializedError) => {
        setLoading(false);
        dispatch(
          snackAlertOpened({
            severity: "error",
            message: t("wif.injury.snackMessages.genericError"),
          }),
        );
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <AppDialogTitle
        id="delete-injury-action-dialog-title"
        onClose={onClose}
        bgcolor="error.main"
      >
        {t("wif.injury.deleteActionDialog.title")}
      </AppDialogTitle>
      <DialogContent
        sx={{
          width: 600,
        }}
      >
        <DialogContentText sx={{ mt: 2.5 }}>
          {t("wif.injury.deleteActionDialog.contentText", {
            actionName: action?.text || "",
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="text" onClick={onClose} sx={{ mr: 1 }}>
          {t("wif.injury.cancel")}
        </Button>
        <LoadingButtonContainer loading={loading}>
          <Button
            onClick={handleDelete}
            variant="contained"
            color="error"
            disabled={loading}
          >
            {t("wif.injury.delete")}
          </Button>
        </LoadingButtonContainer>
      </DialogActions>
    </Dialog>
  );
}
