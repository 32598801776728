import React from "react";
import { useTranslation } from "react-i18next";
import { useCurrentArea } from "../../../locations/locationsHooks";
import { useAppSelector } from "../../../../store";
import { StyledDetailsCardContent } from "../../../../components/DetailsCard/DetailsCard";
import Grid from "@mui/material/Unstable_Grid2";
import {
  makeSelectInjuryReportsByAreaId,
  makeSelectLogsByInjuryReportIds,
} from "../../store/injuryReportsSlice";
import { REPORT_TYPES } from "../../constants";
import { AppInfoSection } from "../../../../components/AppInfoSection/AppInfoSection";
import { List, Stack, Typography } from "@mui/material";
import {
  DescriptionList,
  DescriptionListItem,
} from "../../../../components/DescriptionList/DescriptionsGrid";
import { AppIcon } from "../../../../components/Elements";
import { ReportTypeIconNames } from "../../constants/mappedProps";
import { InjuryHistoryItem } from "../History/InjuryHistoryItem";

type StatCountProps = {
  count: number;
  title: string;
};

const StatCount = (props: StatCountProps) => {
  const { count, title } = props;
  return (
    <Stack direction="column">
      <Typography variant="h4">{count}</Typography>
      <Typography variant="body2">{title}</Typography>
    </Stack>
  );
};

export const AreaInjuryDetailsSummaryPanel = () => {
  const { t } = useTranslation();

  const area = useCurrentArea();

  const selectInjuryReportsBySiteId = React.useMemo(
    makeSelectInjuryReportsByAreaId,
    [area.area_id],
  );
  const injuryReports = useAppSelector((state) =>
    selectInjuryReportsBySiteId(state, area.area_id),
  );

  const accidentsCount = injuryReports.filter(
    (report: any) => report.type === REPORT_TYPES.ACCIDENT,
  ).length;
  const illnessCount = injuryReports.filter(
    (report: any) => report.type === REPORT_TYPES.ILLNESS,
  ).length;
  const minorInjuryCount = injuryReports.filter(
    (report: any) => report.type === REPORT_TYPES.MINOR_INJURY,
  ).length;
  const ongoingCount = injuryReports.filter(
    (report: any) => report.status === "ongoing",
  ).length;
  const closedCount = injuryReports.filter(
    (report: any) => report.status === "closed",
  ).length;
  const rejectedCount = injuryReports.filter(
    (report: any) => report.status === "rejected",
  ).length;

  const actionsCount = React.useMemo(() => {
    let count = 0;
    for (const injuryReport of injuryReports) {
      if (injuryReport?.actions) {
        count += injuryReport.actions.length;
      }
    }
    return count;
  }, [injuryReports]);

  const assigneeCount = React.useMemo(() => {
    const assigneeIds: string[] = [];
    for (const injuryReport of injuryReports) {
      if (!assigneeIds.includes(injuryReport.assignee_id)) {
        assigneeIds.push(injuryReport.assignee_id);
      }
    }
    return assigneeIds.length;
  }, [injuryReports]);

  const selectInjuryLogsByReportIds = React.useMemo(
    makeSelectLogsByInjuryReportIds,
    [injuryReports],
  );
  const injuryLogs = useAppSelector((state) =>
    selectInjuryLogsByReportIds(
      state,
      injuryReports.map((report) => report.id),
    ),
  );

  const [logsLimit, setLogsLimit] = React.useState(10);

  const observerTarget = React.useRef(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setLogsLimit((prev) => prev + 10);
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  return (
    <StyledDetailsCardContent>
      <AppInfoSection title={t("wif.injury.common.summary")}>
        <Grid container spacing={1} sx={{ mt: -1 }}>
          <Grid xs={6}>
            <StatCount title="Injuries reported" count={injuryReports.length} />
          </Grid>
          <Grid xs={6}>
            <StatCount title="Ongoing cases" count={ongoingCount} />
          </Grid>
          <Grid xs={6}>
            <StatCount title="Actions pending" count={actionsCount} />
          </Grid>
          <Grid xs={6}>
            <StatCount title="Handlers assigned" count={assigneeCount} />
          </Grid>
        </Grid>
      </AppInfoSection>

      <AppInfoSection title={"Types of injuries"}>
        <DescriptionList>
          <DescriptionListItem
            field="Accidents"
            value={accidentsCount.toString()}
            primaryIcon={
              <AppIcon
                iconName={ReportTypeIconNames[REPORT_TYPES.ACCIDENT]}
                opticalSize={18}
                color="text.secondary"
              />
            }
            secondaryAlign="right"
            size="small"
          />
          <DescriptionListItem
            field="Illnesses"
            value={illnessCount.toString()}
            primaryIcon={
              <AppIcon
                iconName={ReportTypeIconNames[REPORT_TYPES.ILLNESS]}
                opticalSize={18}
                color="text.secondary"
              />
            }
            secondaryAlign="right"
            size="small"
          />
          <DescriptionListItem
            field="Minor injuries"
            value={minorInjuryCount.toString()}
            primaryIcon={
              <AppIcon
                iconName={ReportTypeIconNames[REPORT_TYPES.MINOR_INJURY]}
                opticalSize={18}
                color="text.secondary"
              />
            }
            secondaryAlign="right"
            size="small"
          />
        </DescriptionList>
      </AppInfoSection>
      <AppInfoSection title={"Recent activity"} titleSx={{ mb: -0.5 }}>
        <List sx={{ py: 0.5 }}>
          {injuryLogs.slice(0, logsLimit).map((log: any) => (
            <InjuryHistoryItem
              key={log.id}
              log={log}
              disablePx={true}
              includeReportLink
            />
          ))}
        </List>
        {logsLimit < injuryLogs.length && <div ref={observerTarget} />}
      </AppInfoSection>
    </StyledDetailsCardContent>
  );
};
