import { FormHelperText } from "@mui/material";
import type { Control, FieldPath } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import type { FieldValues } from "react-hook-form/dist/types";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useTranslation } from "react-i18next";
import React from "react";

type FormRadioGroupOptions = string[] | number[];

type FormRadioGroupMappedLabels = {
  [val: string | number]: string;
};

type CombinedRadioGroupProps = {
  label?: string;
  options: any[];
  mappedLabels?: any;
  direction?: "row" | "column";
};

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = CombinedRadioGroupProps & {
  control?: Control<TFieldValues, any>;
  name: TName;
  controllerProps?: any;
};

export function FormRadioGroup<TFieldValues extends FieldValues>(
  props: Props<TFieldValues>,
) {
  const {
    control = useFormContext<TFieldValues>().control,
    name,
    controllerProps,
    label,
    options,
    mappedLabels,
    direction = "row",
  } = props;
  const { t } = useTranslation();
  const getTranslation = React.useCallback(
    (key: string) => {
      const keyWithValues = key.split(" ");
      const translationKey = keyWithValues[0];
      if (
        translationKey === "max" ||
        translationKey === "min" ||
        translationKey === "minmax"
      ) {
        return t(`wif.injury.errors.${translationKey}`, {
          i: keyWithValues[1],
        });
      } else if (translationKey === "between") {
        return t(`wif.injury.errors.${translationKey}`, {
          min: keyWithValues[1],
          max: keyWithValues[2],
        });
      }
      return t(`wif.injury.errors.${key}`);
    },
    [t],
  );
  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field, fieldState: { error } }) => (
        <FormControl required={!!error} error={!!error}>
          {label && <FormLabel id={`${name}-label`}>{label}</FormLabel>}

          <RadioGroup
            {...field}
            row={direction === "row"}
            aria-labelledby={label ? `${name}-label` : undefined}
            value={field.value ?? ""}
            onChange={(e) => field.onChange(e.target.value)}
          >
            {options.map((val, i) => (
              <FormControlLabel
                key={val}
                value={val}
                control={<Radio />}
                onClick={() => field.onChange(val)}
                label={mappedLabels ? mappedLabels[val] : val}
              />
            ))}
          </RadioGroup>
          <FormHelperText>
            {error?.message ? getTranslation(error.message) : ""}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}
