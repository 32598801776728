import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import type * as React from "react";
import { APP_COLORS } from "../../config";

interface FContentWrapperProps {
  children?: React.ReactNode;
  sx?: any;
}

export default function ContentWrapper(props: FContentWrapperProps) {
  const { children, sx } = props;
  const theme = useTheme();

  return (
    <Box
      className="FContentWrapper"
      sx={{
        flex: 1,
        height: "100%",
        display: "flex",
        flexDirection: "row",
        overflowY: "auto",
        backgroundColor: theme.palette.background.default,
        "*::-webkit-scrollbar": {
          width: "14px!important",
        },
        "*::-webkit-scrollbar-track": {
          borderRadius: `100!important`,
          ...(theme.palette.mode === "light"
            ? {
                background: `${APP_COLORS.light3}!important`,
              }
            : {
                background: `${APP_COLORS.dark1}!important`,
              }),
          border: (theme) =>
            `3px solid ${theme.palette.background.paper}!important`,
        },
        "*::-webkit-scrollbar-thumb": {
          borderRadius: 100,
          ...(theme.palette.mode === "light"
            ? {
                backgroundColor: "#b9b8c4!important",
              }
            : {
                backgroundColor: "#323144!important",
              }),
          border: "3px solid transparent!important",
          backgroundClip: "content-box!important",
        },
        scrollbarColor: `#d1d5db #f5f5f5!important`,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
