import React, { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { ListBarChart } from "../../../../components/Charts/BarChart";
import {
  getChartColorByIndex,
  getDefaultListBarChartOptions,
  getDefaultMultipleListBarChartOptions,
} from "../../../../components/Charts/helpers";
import _ from "lodash";
import { APP_COLORS } from "../../../../config";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import type { InjuryReportCase } from "../../types";
import type { InjuryReportsFilter } from "../InjuryReportsDataGrid/InjuryReportsDataGrid";
import { useAppSelector } from "../../../../store";
import { makeSelectInjuryControlsByModuleView } from "../../store/injuryControlsSlice";

type InjuryMultipleListBarProps = {
  reports?: InjuryReportCase[];
  labelKeys: string[];
  bars: {
    field: string;
    values: string[];
    colors?: string[];
    labels?: string[];
  };
  filters?: InjuryReportsFilter[];
  limit?: number;
  typeIncluded?: boolean;
  mappedLabels?: { [key: string]: string };
  title?: string;
  subtitle?: string;
};

export const InjuryMultipleListBar = (props: InjuryMultipleListBarProps) => {
  const {
    reports,
    labelKeys,
    bars,
    filters = [],
    limit = 5,
    typeIncluded,
    mappedLabels,
    title,
    subtitle,
  } = props;

  const [showAllReports, setShowAllReports] = useState(false);

  const selectInjuryControlsByViewAndModule = React.useMemo(
    makeSelectInjuryControlsByModuleView,
    [],
  );

  const moduleViewControls = useAppSelector((state) =>
    selectInjuryControlsByViewAndModule(state, `all_handler`),
  );

  const { appliedFilters } = moduleViewControls;

  useEffect(() => {
    setShowAllReports(false);
  }, [appliedFilters]);

  const theme = useTheme() as any;
  const { t } = useTranslation();

  const getInitialFieldValueCounts = () => {
    const fieldValueCounts: { [fieldValue: string]: number } = { total: 0 };
    for (const fieldValue of bars.values) {
      fieldValueCounts[fieldValue] = 0;
    }
    return fieldValueCounts;
  };

  const chartLabelsWithCounts = React.useMemo(() => {
    let labels = [];
    const labelBarCounts: { [label: string]: { [bar: string]: number } } = {};

    for (const report of reports) {
      const fieldValue = _.get(report, bars.field, undefined);

      if (fieldValue) {
        if (bars.values.includes(fieldValue)) {
          for (const labelKey of labelKeys) {
            const labelValue = _.get(report, labelKey, undefined);
            if (labelValue) {
              if (!labels.includes(labelValue)) {
                labels.push(labelValue);
                labelBarCounts[labelValue] = getInitialFieldValueCounts();
              }
              labelBarCounts[labelValue][fieldValue] += 1;
              labelBarCounts[labelValue].total += 1;
            }
          }
        }
      }
    }

    labels = labels.sort(
      (a, b) => labelBarCounts[b].total - labelBarCounts[a].total,
    );
    return {
      labels,
      labelBarCounts,
    };
  }, [reports]);

  const chartData = React.useMemo(() => {
    const options = getDefaultMultipleListBarChartOptions({
      theme,
      title,
      subtitle,
    });
    options.scales.x.stacked = true;
    options.scales.y.stacked = true;
    let visibleLabels = [];
    const datasets: any = [];

    const { labels, labelBarCounts } = chartLabelsWithCounts;

    const highest = labels[0];
    const highestTotal = labelBarCounts[highest].total;

    for (
      let j = 0;
      j < (showAllReports ? chartLabelsWithCounts.labels.length : limit);
      j++
    ) {
      if (j >= labels.length || !labels[j]) {
        break;
      }
      const label = labels[j];
      // UnitID mapped to unitName
      const mappedLabel = mappedLabels[label];

      if (mappedLabel) {
        visibleLabels.push(label);
      }
    }

    for (let i = 0; i < bars.values.length; i++) {
      const bar = bars.values[i];
      const color = bars.colors ? bars.colors[i] : getChartColorByIndex(i);
      const barData: number[] = [];

      for (const label of visibleLabels) {
        barData.push(labelBarCounts[label][bar]);
      }

      datasets.push({
        label: bars.labels[i] || bar,
        data: barData,
        borderWidth: 3,
        borderSkipped: true,
        borderColor: color,
        backgroundColor: color,
        datalabels: {
          labels: {
            value: {
              anchor: "center",
              align: "center",
              color: theme.palette.background.paper,
              formatter: (value, ctx) => {
                if (value > highestTotal / 6) {
                  return value;
                }
                return "";
              },
            },
          },
        },
      });
    }

    visibleLabels = visibleLabels.map((label) =>
      mappedLabels ? mappedLabels[label] : label,
    );

    const data = {
      labels: visibleLabels,
      datasets,
    };

    return {
      data,
      options,
    };
  }, [chartLabelsWithCounts, t, mappedLabels, showAllReports]);

  return (
    <>
      <Box
        sx={{
          height: `calc(260px * (${chartData.data.datasets[0].data.length}/4))`,
          position: "relative",
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              flex: 1,
              width: "100%",
              height: "100%",
              paddingBottom: "20px",
            }}
          >
            <ListBarChart data={chartData.data} options={chartData.options} />
          </Box>

          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: "-15px",
            }}
          >
            {chartLabelsWithCounts.labels.length > limit &&
              (!showAllReports ? (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setShowAllReports(true)}
                >
                  {t("wif.injury.showAll")}
                </Button>
              ) : (
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => setShowAllReports(false)}
                >
                  {t("wif.injury.showLess")}
                </Button>
              ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

type MostCommonValuesBarProps = {
  title?: string;
  subtitle?: string;
  valuesWithCount: {
    value: any;
    count: number;
    type?: string;
    percentage?: any;
  }[];
  color?: string;
  colors?: string[];
  limit?: number;
  typeIncluded?: boolean;
  getValueLabel?: (value: string) => string;
};

export const MostCommonValuesBar = (props: MostCommonValuesBarProps) => {
  const {
    title = "",
    subtitle = "",
    valuesWithCount,
    color = APP_COLORS.primary,
    limit = 5,
    colors,
    typeIncluded,
    getValueLabel,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme() as any;
  const [showAllReports, setShowAllReports] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const selectInjuryControlsByViewAndModule = React.useMemo(
    makeSelectInjuryControlsByModuleView,
    [],
  );

  const moduleViewControls = useAppSelector((state) =>
    selectInjuryControlsByViewAndModule(state, `all_handler`),
  );

  const { appliedFilters } = moduleViewControls;

  const handleShowLess = () => {
    setShowAllReports(false);
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    setShowAllReports(false);
  }, [appliedFilters]);

  const chartData = React.useMemo(() => {
    const labels: any = [];
    const datasetData: any = [];
    const backgrounds: any = [];

    const options = getDefaultListBarChartOptions({ theme, title, subtitle });
    options.interaction.mode = "y";
    const sortedValuesWithCount = valuesWithCount
      .slice()
      .sort((a: any, b: any) => b.count - a.count);

    for (
      let i = 0;
      i < (showAllReports ? sortedValuesWithCount.length : limit);
      i++
    ) {
      if (i >= sortedValuesWithCount.length || !sortedValuesWithCount[i]) {
        break;
      }
      const { value, count } = sortedValuesWithCount[i];
      if (getValueLabel) {
        labels.push(getValueLabel(value));
      } else {
        labels.push(value);
      }

      datasetData.push(count);
      backgrounds.push(getChartColorByIndex(i));
    }

    const data = {
      labels: labels,
      datasets: [
        {
          label: t("wif.injury.reports"),
          data: datasetData,
          backgroundColor: colors ? colors : color,
          datalabels: {
            labels: {
              ...(typeIncluded && {
                name: {
                  anchor: "end",
                  align: "end",
                  color: (ctx) =>
                    colors ? ctx.dataset.backgroundColor[ctx.dataIndex] : color,
                  formatter: (value, ctx) => {
                    if (value < datasetData[0] * 0.9) {
                      return valuesWithCount[ctx.dataIndex].type;
                    }
                    return `${valuesWithCount[ctx.dataIndex].type.substring(
                      0,
                      6,
                    )}...`;
                  },
                },
              }),

              value: {
                anchor: "center",
                align: "center",
                color: theme.palette.background.paper,
                formatter: (value, ctx) => {
                  if (value > datasetData[0] / 2.5) {
                    if (valuesWithCount[ctx.dataIndex]?.percentage) {
                      return `${value} (${
                        valuesWithCount[ctx.dataIndex].percentage
                      }%)`;
                    }
                    return value;
                  }
                  return "";
                },
              },
            },
          },
        },
      ],
    };

    return {
      data,
      options,
    };
  }, [valuesWithCount, theme, t, showAllReports]);

  return (
    <Box
      sx={{
        height: showAllReports
          ? `calc(260px * (${chartData.data.datasets[0].data.length}/4))`
          : "360px",
        position: "relative",
      }}
      ref={divRef}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flex: 1,
            width: "100%",
            height: "100%",
            paddingBottom: "20px",
          }}
        >
          <ListBarChart
            id={title}
            data={chartData.data}
            options={chartData.options}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: "-15px",
          }}
        >
          {valuesWithCount.length > limit &&
            (!showAllReports ? (
              <Button
                variant="text"
                color="primary"
                onClick={() => setShowAllReports(true)}
              >
                {t("wif.injury.showAll")}
              </Button>
            ) : (
              <Button variant="text" color="primary" onClick={handleShowLess}>
                {t("wif.injury.showLess")}
              </Button>
            ))}
        </Box>
      </Box>
    </Box>
  );
};
