import type {
  GridColDef,
  GridColumnVisibilityModel,
  GridEventListener,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RDataGrid } from "../../../../components/DataGrid/RDataGrid";
import {
  type RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../store";
import {
  formatDateFromTimestamp,
  formatDateTimeFromTimestamp,
} from "../../../../utils/format";
import { getActiveUser, getHasHandlerReadClaim } from "../../../../utils/user";
import {
  selectAllDepartments,
  selectDepartmentEntities,
} from "../../../departments/departmentsSlice";
import {
  selectAllEmployees,
  selectEmployeesFetched,
} from "../../../employees/employeesSlice";
import {
  selectAppLoading,
  selectSplitViewEnabled,
} from "../../../global/controlsSlice";
import { selectNonconformityReportEntities } from "../../../nonconformity/nonconformitySlice";
import { selectCurrentSiteId } from "../../../sites/sitesSlice";
import {
  CASE_STATUS,
  REPORT_CATEGORY,
  REPORT_TYPES,
  TKB_INJURY,
} from "../../constants";
import { EMPLOYEE_SECTION_NAMES } from "../../constants/employee";
import { STUDENT_SECTION_NAMES } from "../../constants/student";
import { getTranslatedInjuryReportName } from "../../helpers";
import {
  activeFilterRemoved,
  activeFilterSet,
  filterControlAdded,
  filterControlsReset,
  injuryControlChanged,
  makeSelectInjuryControlsByModuleView,
} from "../../store/injuryControlsSlice";
import {
  injuryReportCleared,
  injuryReportItemClicked,
  selectAllInjuryReports,
  selectAllInjuryUnits,
  selectCurrentInjuryReportId,
  selectInjuryHandlerReportIds,
  selectInjuryRegistrationReportIds,
  selectInjuryReportsFetched,
  selectInjuryUnitEntities,
} from "../../store/injuryReportsSlice";
import type { InjuryReportCase } from "../../types";
import { RenderCopyCell, RenderLinkCell } from "./RenderCell";
import { RenderProgressWithStatusColor } from "./RenderCell/RenderProgressCell";
import {
  RenderCategoryCell,
  RenderDepartmentCell,
  RenderDueDateCell,
  RenderHandlerCell,
  RenderInjuryCommentsCell,
  RenderInjuryMessagesCell,
  RenderNcReportCell,
  RenderStatusCell,
  RenderTypeCell,
  RenderUserCell,
} from "./RenderCell/RenderStatusCell";
import type { MFilterItem } from "../../../filter/filterUtils";
import type { IFilterControl } from "../../../../components/DataGrid/types";
import { getTertiaryDatesForYear } from "../Statistics/StatisticsFilters";

const userClaimId = "b4cb162c-2f5f-11ee-be56-0242ac120002";

export type UnsavedInjuryReportsDataGridFilter = {
  field: any;
  value?: string;
};

export type InjuryReportsFilter = {
  field: any;
  getFieldValue?: (value: any) => any;
  operator?: string; // default to '==' operator
  value: string;
};

type InjuryReportsDataGridProps = {
  isHandler?: boolean;
  categoryFilter?: string;
  userIdFilter?: string;
  reporterFilter?: string;
  // view
  view?: string;
  // or query or list
  module?: string;
};

type adminModuleType = {
  critical: boolean;
  documentHandling: boolean;
  fall: boolean;
  injuryEmployee: boolean;
  injuryStudent: boolean;
  manuals: boolean;
  nonconformity: boolean;
  privacypolicy: boolean;
  scheduler: boolean;
  weblink: boolean;
};

const getFilterBetweenDateTimestamp = (daysBack: number) => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  const tomorrow = new Date().setDate(date.getDate() + 1);
  return [new Date().setDate(date.getDate() - daysBack), tomorrow];
};

export const InjuryReportsDataGrid = (props: InjuryReportsDataGridProps) => {
  const {
    categoryFilter,
    userIdFilter,
    isHandler = false,
    reporterFilter,
    view = "reporter",
    module = "all",
  } = props;
  const [adminModules, setAdminModules] =
    React.useState<adminModuleType>(undefined);
  const [userClaim, setUserClaim] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const { t } = useTranslation();
  const activeUser = getActiveUser();
  const navigate = useNavigate();

  const moduleView = `${module}_${view}`;

  const selectInjuryControlsByViewAndModule = React.useMemo(
    makeSelectInjuryControlsByModuleView,
    [],
  );
  const injuryControls = useAppSelector((state: RootState) =>
    selectInjuryControlsByViewAndModule(state, moduleView),
  );
  const { sortModel, columnVisibilityModel, addedControls, activeFilters } =
    injuryControls ?? {};

  const handleChangeColumnVisibilityModel = (
    newModel: GridColumnVisibilityModel,
  ) => {
    dispatch(
      injuryControlChanged({
        moduleView,
        controlName: "columnVisibilityModel",
        controlValue: newModel,
      }),
    );
  };
  const handleChangeSortModel = (newModel: GridSortModel) => {
    dispatch(
      injuryControlChanged({
        moduleView,
        controlName: "sortModel",
        controlValue: newModel,
      }),
    );
  };

  const handleSetFilter = (newFilterItem: MFilterItem) => {
    dispatch(activeFilterSet({ moduleView, newFilterItem }));
  };

  const handleRemoveFilter = (field: string) => {
    dispatch(activeFilterRemoved({ moduleView, field }));
  };

  if (useAppSelector(selectAppLoading))
    setLoading(useAppSelector(selectAppLoading));

  React.useEffect(() => {
    setLoading(true);
    // Retrieve the item from local storage
    const ngStorageCurrent = localStorage.getItem("ngStorage-current");
    const current = JSON.parse(ngStorageCurrent);
    //set permitted modules by super admin
    if (current?.organization)
      setAdminModules(current.organization.adminModules);
    //set user claim
    if (current?.user?.permissions?.claims?.length)
      current.user.permissions.claims.map((claim) => {
        if (claim.claim_id === userClaimId) setUserClaim(true);
      });
    setLoading(false);
  }, []);

  const injuryReports = useAppSelector(selectAllInjuryReports);
  const ncReports = useAppSelector(selectNonconformityReportEntities);
  const currentSite = useAppSelector(selectCurrentSiteId);
  const currentInjuryReportId = useAppSelector(selectCurrentInjuryReportId);
  const splitViewEnabled = useAppSelector(selectSplitViewEnabled);
  const units = useAppSelector(selectAllInjuryUnits);
  const unitEntities = useAppSelector(selectInjuryUnitEntities);
  const departments = useAppSelector(selectAllDepartments);
  const departmentEntities = useAppSelector(selectDepartmentEntities);
  const employees = useAppSelector(selectAllEmployees);
  const handlerReportIds = useAppSelector(selectInjuryHandlerReportIds);
  const injuryRegistrationReportIds = useAppSelector(
    selectInjuryRegistrationReportIds,
  );
  const reportYears = useAppSelector(
    (state: RootState) => state.injuryReports.reportYears,
  );

  const controls = React.useMemo(() => {
    const controlItems: IFilterControl[] = [
      {
        field: "status",
        label: t(`${TKB_INJURY}.status`),
        paths: ["status"],
        operator: "is",
        options: [
          { value: CASE_STATUS.ONGOING, label: t(`${TKB_INJURY}.ongoing`) },
          { value: CASE_STATUS.CLOSED, label: t(`${TKB_INJURY}.closed`) },
          { value: CASE_STATUS.REJECTED, label: t(`${TKB_INJURY}.rejected`) },
        ],
      },
      {
        field: "type",
        label: t(`${TKB_INJURY}.type`),
        paths: ["type"],
        operator: "is",
        options: [
          {
            value: REPORT_TYPES.ACCIDENT,
            label: t(`${TKB_INJURY}.reportType.accident`),
          },
          {
            value: REPORT_TYPES.ILLNESS,
            label: t(`${TKB_INJURY}.reportType.illness`),
          },
          {
            value: REPORT_TYPES.MINOR_INJURY,
            label: t(`${TKB_INJURY}.reportType.minorInjury`),
          },
        ],
      },
    ];

    if (module === "all") {
      controlItems.unshift({
        field: "category",
        label: t(`${TKB_INJURY}.category`),
        paths: ["category"],
        operator: "is",
        options: [
          {
            value: REPORT_CATEGORY.STUDENT,
            label: t(`${TKB_INJURY}.reportCategory.student`),
          },
          {
            value: REPORT_CATEGORY.EMPLOYEE,
            label: t(`${TKB_INJURY}.reportCategory.employee`),
          },
        ],
      });
    }

    return controlItems;
  }, [module, view, t]);

  const userHasReadAccess = getHasHandlerReadClaim();

  const filtered = React.useMemo(() => {
    const filtered = injuryReports.filter((injuryReport) => {
      if (categoryFilter && injuryReport?.category !== categoryFilter) {
        return false;
      }
      if (view === "reporter") {
        return injuryRegistrationReportIds.indexOf(injuryReport.id) !== -1;
      }
      if (view === "handler") {
        return (
          handlerReportIds.indexOf(injuryReport.id) !== -1 || userHasReadAccess
        );
      }
      return true;
    });
    return filtered;
  }, [
    injuryReports,
    categoryFilter,
    reporterFilter,
    userHasReadAccess,
    handlerReportIds,
    injuryRegistrationReportIds,
  ]);

  const getSectionWithDepartmentAndUnitName = (
    injuryReport: InjuryReportCase,
  ) => {
    let sectionWithUnitAndDepartmentName = undefined;
    if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.MINOR_INJURY;
      } else {
        sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.SCHOOL;
      }
    } else if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.MINOR_INJURY;
      } else {
        sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.EMPLOYER;
      }
    }
    return sectionWithUnitAndDepartmentName;
  };

  const addableControls = React.useMemo(() => {
    const injuryDepartmentPaths: string[] = [];
    const injuryUnitPaths: string[] = [];
    let injuryDepartmentIds: string[] = [];
    let injuryUnitIds: string[] = [];

    for (const report of filtered) {
      const sec = getSectionWithDepartmentAndUnitName(report);
      if (sec && report?.sections[sec]?.departmentId) {
        const path = `sections.${sec}.departmentId`;
        if (injuryDepartmentPaths.indexOf(path) === -1) {
          injuryDepartmentPaths.push(path);
        }
        injuryDepartmentIds.push(report?.sections[sec]?.departmentId);
      }
      if (sec && report?.sections[sec]?.unitId) {
        const path = `sections.${sec}.unitId`;
        if (injuryUnitPaths.indexOf(path) === -1) {
          injuryUnitPaths.push(path);
        }
        injuryUnitIds.push(report?.sections[sec]?.unitId);
      }
    }

    let injuryAssignees = filtered.map((report) => report?.assignee_id);
    let injuryReporters = filtered.map((report) => report?.reporter_id);
    injuryDepartmentIds = _.uniq(injuryDepartmentIds);
    injuryUnitIds = _.uniq(injuryUnitIds);
    injuryAssignees = _.uniq(injuryAssignees);
    injuryReporters = _.uniq(injuryReporters);
    const filteredDepartments = departments.filter(
      (department) => injuryDepartmentIds.indexOf(department.id) !== -1,
    );
    const filteredUnitIds = units.filter(
      (unit) => injuryUnitIds.indexOf(unit.unit_id) !== -1,
    );
    const filteredAssignees = employees.filter(
      (user) => injuryAssignees.indexOf(user.id) !== -1,
    );
    const filteredReporters = employees.filter(
      (user) => injuryReporters.indexOf(user.id) !== -1,
    );
    const controlItems: IFilterControl[] = [];

    if (filteredDepartments.length > 0) {
      controlItems.push({
        field: "departmentId",
        label: t(`${TKB_INJURY}.department`),
        paths: injuryDepartmentPaths,
        operator: "is",
        options: filteredDepartments.map((department) => ({
          value: department.id,
          label: department?.name || department.id,
        })),
      });
    }

    if (filteredUnitIds.length > 0) {
      controlItems.push({
        field: "unit",
        label: t(`${TKB_INJURY}.unit`),
        paths: injuryUnitPaths,
        operator: "is",
        options: filteredUnitIds.map((unit) => ({
          value: unit.unit_name,
          label: unit.unit_name,
        })),
      });
    }
    if (filteredAssignees.length > 0) {
      controlItems.push({
        field: "handler",
        label: t(`${TKB_INJURY}.assignedTo`),
        paths: ["assignee_id"],
        operator: "is",
        options: filteredAssignees.map((employee) => ({
          value: employee.id,
          label:
            activeUser.id === employee.id
              ? "Me"
              : employee?.name || employee.id,
        })),
      });
    }
    if (filteredReporters.length > 0) {
      controlItems.push({
        field: "reporter_id",
        label: t("wif.injury.reportedBy"),
        paths: ["reporter_id"],
        operator: "is",
        options: filteredReporters.map((employee) => ({
          value: employee.id,
          label: employee?.name || employee.id,
        })),
      });
    }
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const tomorrow = new Date().setDate(date.getDate() + 1);

    const tertiaryOptions = reportYears
      .map((year) => {
        return [
          {
            value: getTertiaryDatesForYear(year, 1),
            label: `${t("wif.injury.firstTertiary")} (${year})`,
            key: `1T`,
            year,
          },
          {
            value: getTertiaryDatesForYear(year, 2),
            label: `${t("wif.injury.secondTertiary")} (${year})`,
            key: "2T",
            year,
          },
          {
            value: getTertiaryDatesForYear(year, 3),
            label: `${t("wif.injury.thirdTertiary")} (${year})`,
            key: "3T",
            year,
          },
        ];
      })
      .flat();

    if (filteredReporters.length > 0) {
      controlItems.push({
        field: "reportedTimestamp",
        label: t("wif.injury.reportedDate"),
        paths: ["reportedTimestamp"],
        operator: "between",
        options: [
          {
            value: getFilterBetweenDateTimestamp(7),
            label: t("wif.injury.last7Days"),
          },
          {
            value: getFilterBetweenDateTimestamp(30),
            label: t("wif.injury.lastMonth"),
          },
          {
            value: getFilterBetweenDateTimestamp(183),
            label: t("wif.injury.last6Months"),
          },
          {
            value: getFilterBetweenDateTimestamp(365),
            label: t("wif.injury.lastYear"),
          },
          ...tertiaryOptions,
        ],
      });
    }
    return controlItems.filter(
      (control) =>
        !addedControls.find((added) => added.field === control.field),
    );
  }, [units, departments, employees, addedControls, isHandler, filtered, t]);

  const items = React.useMemo(() => {
    let filteredInjuryReports: any = [...filtered];
    if (categoryFilter) {
      filteredInjuryReports = filteredInjuryReports.filter(
        (injuryReport: any) =>
          injuryReport?.category && injuryReport?.category === categoryFilter,
      );
    }
    if (userIdFilter) {
      filteredInjuryReports = filteredInjuryReports.filter(
        (injuryReport: any) => injuryReport?.reporter_id === userIdFilter,
      );
    }
    if (adminModules && !adminModules.injuryStudent) {
      filteredInjuryReports = filteredInjuryReports.filter(
        (injuryReport: any) =>
          injuryReport?.category &&
          injuryReport?.category !== REPORT_CATEGORY.STUDENT,
      );
    }
    if (adminModules && !adminModules.injuryEmployee) {
      filteredInjuryReports = filteredInjuryReports.filter(
        (injuryReport: any) =>
          injuryReport?.category &&
          injuryReport?.category !== REPORT_CATEGORY.EMPLOYEE,
      );
    }

    return filteredInjuryReports.map((injuryReport: any) => {
      let unit = null;
      let departmentId = null;
      let ncReportId = null;
      let ncReportName = null;
      let handler = null;
      let progress = 0;
      let allActionsCompleted = true;

      if (injuryReport?.actions.length > 0 && progress > 0) {
        const completedActionsCount = injuryReport?.actions.filter(
          (action) => action?.status === "completed",
        ).length;
        if (completedActionsCount < injuryReport?.actions.length) {
          allActionsCompleted = false;
        }
      }
      if (allActionsCompleted) {
        if (injuryReport?.nav_form_url) {
          progress = 0.9;
        } else if (injuryReport?.closing_details) {
          progress = 0.6;
        } else {
          if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
            if (injuryReport?.approved_sections.length > 0) {
              progress = 0.3;
            }
          } else if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
            progress = 0.3 * (injuryReport?.approved_sections.length / 4);
          } else if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
            progress = 0.3 * (injuryReport?.approved_sections.length / 5);
          }
        }
      } else {
        if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
          if (injuryReport?.approved_sections.length > 0) {
            progress = 0.3;
          }
        } else if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
          progress = 0.3 * (injuryReport?.approved_sections.length / 4);
        } else if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
          progress = 0.3 * (injuryReport?.approved_sections.length / 5);
        }
        if (injuryReport?.actions.length > 0 && progress > 0) {
          const completedActionsCount = injuryReport?.actions.filter(
            (action) => action?.status === "completed",
          ).length;
          if (completedActionsCount < injuryReport?.actions.length) {
            progress =
              progress * (completedActionsCount / injuryReport?.actions.length);
          }
        }
      }
      if (injuryReport.status === CASE_STATUS.CLOSED) {
        progress = 1;
      }
      if (injuryReport?.assignee_id) {
        handler = injuryReport.assignee_id;
      }

      let sectionWithUnitAndDepartmentName = "";
      if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
        if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
          sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.MINOR_INJURY;
        } else {
          sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.SCHOOL;
          if (
            injuryReport.sections[STUDENT_SECTION_NAMES.FURTHER_INFORMATION]
              ?.ncReportId
          ) {
            if (
              injuryReport.sections[STUDENT_SECTION_NAMES.FURTHER_INFORMATION]
                .ncReportId !== "None"
            ) {
              const nid =
                injuryReport.sections[STUDENT_SECTION_NAMES.FURTHER_INFORMATION]
                  .ncReportId;
              ncReportId = nid;
              if (ncReports[nid]) {
                ncReportName = `${ncReports[nid].name}`;
              } else {
                ncReportName =
                  injuryReport.sections[
                    STUDENT_SECTION_NAMES.FURTHER_INFORMATION
                  ].ncReportId;
              }
            }
          }
        }
      } else if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
        if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
          sectionWithUnitAndDepartmentName =
            EMPLOYEE_SECTION_NAMES.MINOR_INJURY;
        } else {
          sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.EMPLOYER;
          if (
            injuryReport.sections[EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION]
              ?.ncReportId
          ) {
            if (
              injuryReport.sections[EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION]
                .ncReportId !== "None"
            ) {
              const nid =
                injuryReport.sections[
                  EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION
                ].ncReportId;
              ncReportId = nid;
              if (ncReports[nid]) {
                ncReportName = `${ncReports[nid].name}`;
              } else {
                ncReportName =
                  injuryReport.sections[
                    EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION
                  ].ncReportId;
              }
            }
          }
        }
      }
      if (injuryReport?.sections[sectionWithUnitAndDepartmentName]?.unitId) {
        const unitId =
          injuryReport.sections[sectionWithUnitAndDepartmentName].unitId;
        unit = unitEntities[unitId]?.unit_name || unitId;
      }
      if (
        injuryReport?.sections[sectionWithUnitAndDepartmentName]?.departmentId
      ) {
        departmentId =
          injuryReport.sections[sectionWithUnitAndDepartmentName].departmentId;
      }

      const name = getTranslatedInjuryReportName(injuryReport, t);

      let userIsHandlerForDepartment = false;
      if (injuryReport.category === "employee") {
        if (
          departmentEntities[departmentId]?.injury_employee_users.includes(
            activeUser.id,
          )
        ) {
          userIsHandlerForDepartment = true;
        }
      } else {
        if (
          departmentEntities[departmentId]?.injury_student_users.includes(
            activeUser.id,
          )
        ) {
          userIsHandlerForDepartment = true;
        }
      }

      return {
        id: injuryReport.id,
        reportedTimestamp: injuryReport.reportedTimestamp,
        reporter_id: injuryReport?.reporter_id,
        name: name,
        category: injuryReport.category,
        type: injuryReport.type,
        status: injuryReport.status,
        handler: handler,
        sitePath: injuryReport.sitePath,
        unit: unit,
        departmentId: departmentId,
        ncReportId: ncReportId,
        ncReportName: ncReportName,
        progress: progress,
        userHasReadAccess:
          userHasReadAccess &&
          view === "handler" &&
          !userIsHandlerForDepartment,
        due_date:
          injuryReport?.due_date &&
          formatDateFromTimestamp(injuryReport?.due_date) !== "invalid date"
            ? new Date(injuryReport?.due_date)
            : injuryReport?.due_date
              ? new Date(
                  `${injuryReport?.due_date.split(".")[2]}-${
                    injuryReport?.due_date.split(".")[1]
                  }-${injuryReport?.due_date.split(".")[0]}`,
                )
              : null,
        comments: injuryReport?.comments?.length || 0,
        messages: injuryReport?.messages?.length || 0,
        updated_at: injuryReport?.updated_at,
      };
    });
  }, [
    injuryReports,
    activeFilters,
    currentSite,
    splitViewEnabled,
    departments,
    units,
    employees,
    categoryFilter,
    isHandler,
    filtered,
    t,
  ]);

  React.useEffect(() => {
    return () => {
      dispatch(injuryReportCleared());
    };
  }, []);

  const columns = React.useMemo(() => {
    let columnByField: { [field: string]: GridColDef } = {
      id: {
        field: "id",
        headerName: "ID",
        width: 120,
        renderCell: (params) => <RenderCopyCell {...params} />,
      },
      name: {
        field: "name",
        headerName: t(`${TKB_INJURY}.name`),
        flex: 2,
        minWidth: 240,
        renderCell: (params) => <RenderLinkCell {...params} />,
      },
      reportedTimestamp: {
        field: "reportedTimestamp",
        headerName: t("wif.injury.reportedDate"),
        minWidth: 140,
        flex: 1,
        type: "dateTime",
        valueGetter: (params) => new Date(params?.value),
        valueFormatter: ({ value }) => formatDateFromTimestamp(value),
      },
      updated_at: {
        field: "updated_at",
        headerName: t("wif.injury.lastUpdated"),
        minWidth: 160,
        flex: 1,
        type: "dateTime",
        valueGetter: (params) => new Date(params?.value),
        valueFormatter: ({ value }) => formatDateTimeFromTimestamp(value),
      },
      reporter_id: {
        field: "reporter_id",
        headerName: t("wif.injury.reportedBy"),
        minWidth: 160,
        flex: 1,
        renderCell: (params) => <RenderUserCell {...params} />,
      },
      category: {
        field: "category",
        headerName: t(`${TKB_INJURY}.category`),
        minWidth: 130,
        flex: 1,
        renderCell: (params) => <RenderCategoryCell {...params} />,
        type: "singleSelect",
        valueOptions: [REPORT_CATEGORY.EMPLOYEE, REPORT_CATEGORY.STUDENT],
      },
      type: {
        field: "type",
        headerName: t(`${TKB_INJURY}.type`),
        minWidth: 130,
        flex: 1,
        renderCell: (params) => <RenderTypeCell {...params} />,
        type: "singleSelect",
        valueOptions: [
          REPORT_TYPES.ACCIDENT,
          REPORT_TYPES.ILLNESS,
          REPORT_TYPES.MINOR_INJURY,
        ],
      },
      status: {
        field: "status",
        headerName: t(`${TKB_INJURY}.status`),
        minWidth: 130,
        flex: 1,
        renderCell: (params) => <RenderStatusCell {...params} />,
      },
      departmentId: {
        field: "departmentId",
        headerName: t(`${TKB_INJURY}.department`),
        flex: 1.5,
        minWidth: 200,
        renderCell: (params) => <RenderDepartmentCell {...params} />,
      },
      ncReportName: {
        field: "ncReportName",
        headerName: t(`${TKB_INJURY}.nonconformity`),
        flex: 1,
        minWidth: 160,
        renderCell: (params) => <RenderNcReportCell {...params} />,
      },
      unit: {
        field: "unit",
        headerName: t(`${TKB_INJURY}.unit`),
        flex: 1,
        minWidth: 160,
      },
      progress: {
        field: "progress",
        headerName: t(`${TKB_INJURY}.progress`),
        minWidth: 160,
        renderCell: (params) => <RenderProgressWithStatusColor {...params} />,
      },
      due_date: {
        field: "due_date",
        headerName: t(`${TKB_INJURY}.dueDate`),
        minWidth: 140,
        flex: 1,
        renderCell: (params) => <RenderDueDateCell {...params} />,
        type: "dateTime",
        sortingOrder: ["asc", "desc"],
      },
      comments: {
        field: "comments",
        headerName: t("wif.injury.comments"),
        minWidth: 120,
        flex: 0.75,
        renderCell: (params) => <RenderInjuryCommentsCell {...params} />,
      },
      messages: {
        field: "messages",
        headerName: t("wif.injury.messages"),
        minWidth: 120,
        flex: 0.75,
        renderCell: (params) => <RenderInjuryMessagesCell {...params} />,
      },
    };

    if (view === "handler") {
    }
    const cols = [
      "id",
      "name",
      "type",
      "reportedTimestamp",
      "handler",
      "status",
      "ncReportName",
      "departmentId",
      "unit",
      "comments",
      "messages",
      "due_date",
      "updated_at",
    ];

    if (view === "handler") {
      cols.splice(4, 0, "reporter_id");
      cols.push("progress");
      columnByField = {
        ...columnByField,
        handler: {
          field: "handler",
          headerName: t("wif.injury.assignedTo"),
          flex: 1,
          minWidth: 200,
          renderCell: (params) => <RenderHandlerCell {...params} />,
        },
      };
    } else {
      columnByField = {
        ...columnByField,
        handler: {
          field: "handler",
          headerName: t("wif.injury.assignedTo"),
          flex: 1,
          minWidth: 200,
          renderCell: (params) => <RenderHandlerCell {...params} />,
        },
      };
    }
    if (module === "all") {
      cols.splice(2, 0, "category");
    }
    return cols.map((field) => columnByField[field]);
  }, [t]);

  const dispatch = useAppDispatch();

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>(
      currentInjuryReportId ? [currentInjuryReportId] : [],
    );

  const handleChangeSelectionModel = (
    newRowSelectionModel: GridRowSelectionModel,
  ) => {
    if (newRowSelectionModel.length > 0) {
      if (newRowSelectionModel[0] !== currentInjuryReportId) {
        dispatch(injuryReportItemClicked(newRowSelectionModel[0] as string));
      }
    } else {
      dispatch(injuryReportCleared());
    }
    setRowSelectionModel(newRowSelectionModel);
  };

  React.useEffect(() => {
    if (!currentInjuryReportId) {
      setRowSelectionModel([]);
    }
  }, [currentInjuryReportId]);

  const handleAddControl = (control: IFilterControl) => {
    dispatch(filterControlAdded({ moduleView, control }));
  };

  const handleResetFilterControls = () => {
    dispatch(filterControlsReset({ moduleView }));
  };

  const employeesFetched = useAppSelector(selectEmployeesFetched);
  const injuryReportsFetched = useAppSelector(selectInjuryReportsFetched);

  const handleDoubleClickRow: GridEventListener<"rowDoubleClick"> = (
    params,
  ) => {
    if (userHasReadAccess) return;
    navigate(params.row.id);
  };

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    dispatch(injuryReportItemClicked(params.row.id));
  };

  return (
    <RDataGrid
      onRowDoubleClick={handleDoubleClickRow}
      onRowClick={handleRowClick}
      items={items}
      cols={columns}
      loading={injuryReportsFetched !== "succeeded"}
      filters={{
        controls: [...controls],
        addedControls: addedControls?.length > 0 ? [...addedControls] : [],
        active: activeFilters,
        handleSetFilter,
        handleRemoveFilter,
        handleResetFilterControls,
      }}
      addableControls={{
        controls: addableControls,
        handleAddFilterControl: handleAddControl,
      }}
      sortModel={sortModel}
      handleChangeSortModel={handleChangeSortModel}
      columnVisibilityModel={columnVisibilityModel}
      handleChangeColumnVisibilityModel={handleChangeColumnVisibilityModel}
    />
  );
};
