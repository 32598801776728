import * as React from "react";
import {
  type InjuryLog,
  makeSelectInjuryAttachmentByKey,
  makeSelectInjuryReportById,
  selectEmployeeInjuryTagEntities,
  selectInjuryUnitEntities,
  selectStudentInjuryTagEntities,
} from "../../store/injuryReportsSlice";
import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { type RootState, useAppSelector } from "../../../../store";
import { selectEmployees } from "../../../employees/employeesSlice";
import {
  formatBytes,
  formatDateFromTimestamp,
  nokCurrencyFormatter,
} from "../../../../utils/format";
import { getActiveUser } from "../../../../utils/user";
import { AppIcon } from "../../../../components/Elements";
import { selectActions } from "../../../actions/actionsSlice";
import dayjs from "dayjs";
import { TKB_INJURY } from "../../constants";
import { useTranslation } from "react-i18next";
import {
  MSectionTranslationKeyMapping,
  getReverseMappedSectionNameByCategory,
} from "../../helpers/dataMapping";
import { selectNonconformityReportEntities } from "../../../nonconformity/nonconformitySlice";
import { EMPLOYEE_SECTION_NAMES } from "../../constants/employee";
import { STUDENT_SECTION_NAMES } from "../../constants/student";
import { selectDepartmentEntities } from "../../../departments/departmentsSlice";
import { AppLogItem } from "../../../../components/AppLogItem/AppLogItem";
import { LogItemUpdatePart } from "../../../locations/components/History/SiteLogItem";
import { useTheme } from "@mui/material/styles";
import filePdfBox from "./file-pdf-box.svg";

type FieldValuePartProps = {
  field: string;
  previousValue?: any;
  newValue?: any;
};

const FieldValuePart = (props: FieldValuePartProps) => {
  const { field, previousValue, newValue } = props;

  return (
    <Stack direction="column">
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        {field}
      </Typography>
      <Stack direction="row" alignItems="center" gap={0.75}>
        {previousValue && (
          <Typography
            variant="body2"
            sx={{ color: "text.secondary", textDecoration: "line-through" }}
          >
            {previousValue}
          </Typography>
        )}
        {previousValue && newValue && (
          <AppIcon iconName="trending_flat" color="text.secondary" />
        )}
        {newValue && (
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            {newValue}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

type SectionUpdateTypeLogItemProps = {
  log: InjuryLog;
};

const SectionUpdateTypeLogItem = (props: SectionUpdateTypeLogItemProps) => {
  const { log } = props;
  const { t } = useTranslation();
  const selectInjuryReportById = React.useMemo(makeSelectInjuryReportById, [
    log,
  ]);
  const report = useAppSelector((state) =>
    selectInjuryReportById(state, log?.report_id),
  );

  const employeeTags = useAppSelector(selectEmployeeInjuryTagEntities);
  const studentTags = useAppSelector(selectStudentInjuryTagEntities);
  const departments = useAppSelector(selectDepartmentEntities);
  const units = useAppSelector(selectInjuryUnitEntities);
  const ncReports = useAppSelector(selectNonconformityReportEntities);
  const users = useAppSelector(selectEmployees);

  const mappedSectionValueFuncs = React.useCallback(
    (sectionName: string) => {
      return {
        employeeId: (val: string) => users[val]?.name || val,
        damageNature: (val: any[]) =>
          val
            .map(
              (v) =>
                `${v.id ? v.id : v} ${t(
                  `${TKB_INJURY}.employee.typeTab.natureOfDamage.${
                    v.id ? v.id : v
                  }`,
                )}`,
            )
            .join("; "),
        damagedBodyPart: (val: any[]) =>
          val
            .map(
              (v) =>
                `${v.id ? v.id : v} ${t(
                  `${TKB_INJURY}.employee.typeTab.damagedBodyPart.${
                    v.id ? v.id : v
                  }`,
                )}`,
            )
            .join("; "),
        accidentType: (val: any[]) =>
          val
            .map(
              (v) =>
                `${v.id ? v.id : v} ${t(
                  `${TKB_INJURY}.employee.typeTab.typeOfAccident.${
                    v.id ? v.id : v
                  }`,
                )}`,
            )
            .join("; "),
        background: (val: any[]) =>
          val
            .map(
              (v) =>
                `${v.id ? v.id : v} ${t(
                  `${TKB_INJURY}.employee.typeTab.background.${
                    v.id ? v.id : v
                  }`,
                )}`,
            )
            .join("; "),
        damageMode: (val: any[]) =>
          val
            .map(
              (v) =>
                `${v.id ? v.id : v} ${t(
                  `${TKB_INJURY}.employee.typeTab.damageMode.${
                    v.id ? v.id : v
                  }`,
                )}`,
            )
            .join("; "),
        typeOfWorkplace: (val: any[]) =>
          val
            .map(
              (v) =>
                `${v.id ? v.id : v} ${t(
                  `${TKB_INJURY}.employee.typeTab.typeOfWorkplace.${
                    v.id ? v.id : v
                  }`,
                )}`,
            )
            .join("; "),
        deviation: (val: any[]) =>
          val
            .map(
              (v) =>
                `${v.id ? v.id : v} ${t(
                  `${TKB_INJURY}.employee.typeTab.deviation.${v.id ? v.id : v}`,
                )}`,
            )
            .join("; "),
        assumedAbsence: (val: any[]) =>
          val
            .map(
              (v) =>
                `${v.id ? v.id : v} ${t(
                  `${TKB_INJURY}.employee.typeTab.assumedAbsence.${
                    v.id ? v.id : v
                  }`,
                )}`,
            )
            .join("; "),
        ncReportId: (val: string) => ncReports[val]?.name || val,
        durationOfImpact: (val: string[]) => val.join(", "),
        assumedAnnualIncomeForClaimYear: (val: number) =>
          `kr ${nokCurrencyFormatter.format(val)}`,
        employmentPercentage: (val: number) => `${val} %`,
        competenceLevel: (val: string) =>
          t(`${TKB_INJURY}.employee.employeePersonTab.competenceLevel.${val}`),
        natureOfEmployment: (val: string) =>
          t(`${TKB_INJURY}.employee.employeePersonTab.natureOfWorking.${val}`),
        voluntaryOccupationalInjuryInsurance: (val: string) =>
          yesNoOptions[val],
        workTimeArrangements: (val: string) =>
          t(`${TKB_INJURY}.employee.accidentTab.arrangements.${val}`),
        accidentOccured: (val: string) =>
          sectionName === EMPLOYEE_SECTION_NAMES.ACCIDENT
            ? t(`${TKB_INJURY}.employee.accidentTab.accidentOccurred.${val}`)
            : sectionName === STUDENT_SECTION_NAMES.ACCIDENT
              ? studentAccidentMapped[val]
              : "",
        accidentHappenedAtMainAddress: (val: string) =>
          t(`wif.injury.yesNoOptions.${val}`),
        formOfSalary: (val: any) =>
          t(`${TKB_INJURY}.employee.accidentTab.formOfSalary.${val}`),
        atNormalWorkplace: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
        insideOutside: (val: any) =>
          t(`${TKB_INJURY}.employee.accidentTab.whereAccidentOccurred.${val}`),
        onTheWayToWork: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
        onTheWayBetweenWork: (val: any) =>
          t(`${TKB_INJURY}.yesNoOptions.${val}`),
        deathFromAccident: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
        necessaryTraining: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
        reportedToLIA: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
        departmentId: (val: string) => departments[val]?.name || val,
        unitId: (val: string) => units[val]?.unit_name || val,
        typesOfInjury: (val: any[]) =>
          sectionName === EMPLOYEE_SECTION_NAMES.MINOR_INJURY
            ? val
                ?.map((v: any) => employeeTags?.[v]?.name)
                .map((v: any) =>
                  t(`${TKB_INJURY}.typesOfInjury.${v}`).includes(TKB_INJURY)
                    ? v
                    : t(`${TKB_INJURY}.typesOfInjury.${v}`),
                )
                .join(", ") || ""
            : sectionName === STUDENT_SECTION_NAMES.MINOR_INJURY
              ? val
                  ?.map((v: any) => studentTags?.[v]?.name)
                  .map((v: any) =>
                    t(`${TKB_INJURY}.typesOfInjury.${v}`).includes(TKB_INJURY)
                      ? v
                      : t(`${TKB_INJURY}.typesOfInjury.${v}`),
                  )
                  .join(", ") || ""
              : val?.join(", ") || "",
      };
    },
    [t, users],
  );

  const fieldValuePairs = React.useMemo(() => {
    const pairs: any[] = [];
    for (const update of log?.updates) {
      const oSectionName = update?.key?.split(".")?.[0];
      let fieldName = update?.key?.split(".")?.[1];
      let newValue = update?.new;
      let previousValue = update?.previous;
      if (newValue && fieldName) {
        if (fieldName === "unit") {
          newValue = newValue?.unit_name;
          if (previousValue) {
            previousValue = previousValue?.name;
          }
        }
        if (
          [
            "department",
            "workTimeArrangements",
            "accidentOccured",
            "formOfSalary",
            "atNormalWorkplace",
            "insideOutside",
            "onTheWayToWork",
            "onTheWayBetweenWork",
            "deathFromAccident",
            "competenceLevel",
            "natureOfEmployment",
            "voluntaryOccupationalInjuryInsurance",
            "necessaryTraining",
            "reportedToLIA",
          ].includes(fieldName)
        ) {
          if (newValue?.id) {
            if (previousValue?.id) {
              if (mappedSectionValueFuncs(oSectionName)[fieldName]) {
                previousValue = mappedSectionValueFuncs(oSectionName)[
                  fieldName
                ](previousValue.id);
              } else {
                previousValue = previousValue?.name;
              }
            } else {
              previousValue = undefined;
            }
            if (mappedSectionValueFuncs(oSectionName)[fieldName]) {
              newValue = mappedSectionValueFuncs(oSectionName)[fieldName](
                newValue.id,
              );
            } else {
              newValue = newValue?.name;
            }
          }
        }
        if (
          [
            "typeOfAccident",
            "natureOfDamage",
            "damagedBodyPart",
            "background",
            "damageMode",
            "typeOfWorkplace",
            "deviation",
            "assumedAbsence",
          ].includes(fieldName)
        ) {
          if (newValue?.length) {
            newValue = newValue
              .map((val) => `${val.id} ${val.name}`)
              .join(", ");
          }

          if (previousValue?.length) {
            previousValue = previousValue
              .map((val) => `${val.id} ${val.name}`)
              .join(", ");
          }
        }
        if (
          [
            "date",
            "detectionDate",
            "employmentStartDate",
            "employmentEndDate",
          ].includes(fieldName)
        ) {
          newValue = dayjs(newValue).format("DD.MM.YYYY");
          if (previousValue) {
            previousValue = dayjs(previousValue).format("DD.MM.YYYY");
          }
        }
        if (fieldName === "time") {
          newValue = dayjs(newValue).format("HH:mm");
          if (previousValue) {
            previousValue = dayjs(previousValue).format("HH:mm");
          }
        }
        if (fieldName === "nonconformityReport") {
          if (newValue?.name && newValue?.rep_id) {
            newValue = `${newValue?.name} (${newValue?.rep_id})`;
          } else {
            continue;
          }
          if (previousValue) {
            if (previousValue?.name && previousValue?.rep_id) {
              previousValue = `${previousValue?.name} (${previousValue?.rep_id})`;
            } else {
              continue;
            }
          }
        }
        if (newValue?.id) {
          newValue = newValue?.name;
        }
        if (previousValue?.id) {
          previousValue = previousValue?.name;
        }

        const sectionName = getReverseMappedSectionNameByCategory(
          oSectionName,
          report?.category,
        );
        fieldName = sectionName
          ? t(
              `wif.injury.${MSectionTranslationKeyMapping[sectionName]}.${fieldName}.title`,
            )
          : fieldName;

        pairs.push({
          key: update?.key?.split(".")?.[1],
          field: fieldName,
          previousValue: previousValue,
          newValue: newValue,
        });
      }
    }
    return pairs;
  }, [log, t, mappedSectionValueFuncs]);

  const studentAccidentMapped = {
    "67604946-cf85-11ed-afa1-0242ac120002": t(
      `${TKB_INJURY}.accidentTypesOccurredTags.teachingLocation`,
    ),
    "751d94a8-cf85-11ed-afa1-0242ac120002": t(
      `${TKB_INJURY}.accidentTypesOccurredTags.duringTeaching`,
    ),
    "7c6476d2-cf85-11ed-afa1-0242ac120002": t(
      `${TKB_INJURY}.accidentTypesOccurredTags.sfo`,
    ),
    "8369f466-cf85-11ed-afa1-0242ac120002": t(
      `${TKB_INJURY}.accidentTypesOccurredTags.onTheWay`,
    ),
    "1d4b309e-cf87-11ed-afa1-0242ac120002": t(
      `${TKB_INJURY}.accidentTypesOccurredTags.other`,
    ),
  };

  const yesNoOptions = {
    option1: t(`${TKB_INJURY}.yesNoOptions.yes`),
    option2: t(`${TKB_INJURY}.yesNoOptions.no`),
  };

  return (
    <Stack direction="column" gap={1.25}>
      {fieldValuePairs.map((pair, index) => (
        <LogItemUpdatePart
          key={index}
          field_name={pair.field}
          old_value={pair.previousValue}
          new_value={pair.newValue}
        />
      ))}
    </Stack>
  );
};

const SimpleMessageLogItem = (props: { log: InjuryLog }) => {
  const { log } = props;
  const { t } = useTranslation();

  return (
    <FieldValuePart
      field={`${t("wif.injury.comment")}:`}
      newValue={log?.new?.message}
    />
  );
};

type UpdateCompletionTypeLogItemProps = {
  log: InjuryLog;
};

const UpdateCompletionTypeLogItem = (
  props: UpdateCompletionTypeLogItemProps,
) => {
  const { log } = props;
  const { t } = useTranslation();

  const fieldValuePairs = React.useMemo(() => {
    const update = log?.updates?.[0];
    const pairs: any[] = [];
    if (update?.new?.likelyhood) {
      pairs.push({
        field: t("wif.injury.closeCaseTab.likelihoodOfRecurrence.title"),
        previousValue: update?.previous?.likelyhood,
        newValue: update?.new?.likelyhood,
      });
    }
    if (update?.new?.endingEvaluation) {
      pairs.push({
        field: t("wif.injury.closeCaseTab.endingEvaluation.title"),
        previousValue: update?.previous?.endingEvaluation,
        newValue: update?.new?.endingEvaluation,
      });
    }
    return pairs;
  }, [log]);

  return (
    <Stack direction="column" gap={1.25}>
      {fieldValuePairs
        .filter((pair) => pair.previousValue !== pair.newValue)
        .map((pair) => (
          <LogItemUpdatePart
            key={pair.field}
            field_name={pair.field}
            old_value={pair.previousValue}
            new_value={pair.newValue}
          />
        ))}
      {log?.updates?.[0]?.new?.attachment_keys &&
        log?.updates?.[0]?.new?.attachment_keys?.length > 0 && (
          <Stack direction="column" gap={1}>
            <Typography variant="body2" sx={{ fontWeight: 500 }}>
              Attachments
            </Typography>
            {log?.updates?.[0]?.new?.attachment_keys.map((attachment_key) => (
              <AppAttachmentLineItem
                key={attachment_key}
                attachmentKey={attachment_key}
              />
            ))}
          </Stack>
        )}
    </Stack>
  );
};

type ActionTypeLogProps = {
  log: InjuryLog;
  includeNonChanged?: boolean;
};

const includedActionFields = [
  "text",
  "actionDescription",
  "measureType",
  "deadline",
  "assignee",
  "members",
];

const includedActionCompletionFields = ["evaluation", "workDone"];

const includedReopenedActionFields = ["responsible", "comment"];

const actionReducesOptionLabels = {
  reduces_likelyhood: "Likelyhood",
  reduces_consequence: "Consequence",
  reduces_likelyhood_consequence: "Likelyhood and consequence",
};

const ActionTypeLog = (props: ActionTypeLogProps) => {
  const { log, includeNonChanged = false } = props;
  const { t } = useTranslation();
  const users = useAppSelector(selectEmployees);

  const fieldValuePairs = React.useMemo(() => {
    const pairs: any[] = [];

    if (log?.type === INJURY_LOG_TYPES.COMPLETE_ACTION) {
      for (const field of includedActionCompletionFields) {
        if (log?.new?.completionAndEvaluation?.[field]) {
          const newValue = log?.new?.completionAndEvaluation?.[field];
          const previousValue = log?.previous?.completionAndEvaluation?.[field];
          pairs.push({
            field: field,
            previousValue: previousValue,
            newValue: newValue,
          });
        }
      }
    } else if (log?.type === INJURY_LOG_TYPES.REOPEN_ACTION) {
      for (const field of includedReopenedActionFields) {
        if (log?.new?.[field]) {
          const newValue = log?.new?.[field];
          pairs.push({
            field: field,
            newValue: newValue,
          });
        }
      }
    } else {
      for (const field of includedActionFields) {
        if (log?.new?.[field]) {
          if (
            includeNonChanged ||
            JSON.stringify(log?.previous?.[field]) !==
              JSON.stringify(log?.new?.[field])
          ) {
            let newValue = log?.new?.[field];
            let previousValue = log?.previous?.[field];
            if (field === "measureType") {
              if (newValue) {
                newValue = actionReducesOptionLabels[newValue] || newValue;
              }
              if (previousValue) {
                previousValue =
                  actionReducesOptionLabels[previousValue] || previousValue;
              }
            }
            if (field === "deadline") {
              if (newValue) {
                newValue = formatDateFromTimestamp(newValue);
              }
              if (previousValue) {
                previousValue = formatDateFromTimestamp(previousValue);
              }
            }
            if (field === "assignee" || field === "members") {
              if (newValue) {
                newValue = newValue?.map((val) => users[val]?.name || val);
                newValue = newValue?.join(", ");
              }
              if (previousValue) {
                previousValue = previousValue?.map(
                  (val) => users[val]?.name || val,
                );
                previousValue = previousValue?.join(", ");
              }
            }
            pairs.push({
              field: field,
              previousValue:
                JSON.stringify(log?.previous?.[field]) !==
                  JSON.stringify(log?.new?.[field]) && previousValue,
              newValue: newValue,
            });
          }
        }
      }
    }

    return pairs;
  }, [log, users]);

  return (
    <Stack direction="column" gap={1.25}>
      {fieldValuePairs.map((pair: any) => (
        <LogItemUpdatePart
          key={pair.field}
          field_name={t(`wif.injury.action.fields.${pair.field}.title`)}
          old_value={pair.previousValue}
          new_value={pair.newValue}
        />
      ))}
    </Stack>
  );
};

type CaseUpdateTypeLogProps = {
  log: InjuryLog;
};

const CaseUpdateTypeLog = (props: CaseUpdateTypeLogProps) => {
  const { log } = props;
  const { key, new: updated, previous } = log;
  const { t } = useTranslation();
  const activeUser = getActiveUser();

  const users = useAppSelector(selectEmployees);

  const keyTitle = React.useMemo(() => {
    if (key === "approved_sections") {
      return t("wif.injury.approvedSections");
    }
    if (key === "assignee_id" || key === "assignee") {
      return t("wif.injury.assignee");
    }
    if (key === "due_date") {
      return t("wif.injury.dueDate");
    }
    return "";
  }, [log, t]);

  const formattedVal = React.useMemo(() => {
    if (key === "approved_sections") {
      return updated
        .map((str) => t(`wif.injury.sectionNames.${str}`))
        .join(", ");
    }
    if (key === "assignee_id" || key === "assignee") {
      if (activeUser?.id === updated) {
        return "You";
      }
      return users[updated]?.name || updated;
    }
    if (key === "due_date") {
      return formatDateFromTimestamp(updated);
    }
    return "";
  }, [log, users, t]);

  const formattedPrevious = React.useMemo(() => {
    if (previous) {
      if (key === "approved_sections") {
        return previous
          .map((str) => t(`wif.injury.sectionNames.${str}`))
          .join(", ");
      }
      if (key === "assignee_id" || key === "assignee") {
        if (activeUser?.id === previous) {
          return "You";
        }
        return users[previous]?.name || previous;
      }
      if (key === "due_date") {
        return formatDateFromTimestamp(previous);
      }
    }

    return "";
  }, [log, users]);

  return (
    <LogItemUpdatePart
      field_name={keyTitle}
      old_value={previous ? formattedPrevious : undefined}
      new_value={updated ? formattedVal : undefined}
    />
  );
};

type MessageTypeLogProps = {
  message: string;
  attachmentKeys?: string[];
};

type AppAttachmentLineItemProps = {
  attachmentKey: string;
};

const AppAttachmentLineItem = (props: AppAttachmentLineItemProps) => {
  const { attachmentKey } = props;

  const selectAttachmentByKey = React.useMemo(
    makeSelectInjuryAttachmentByKey,
    [],
  );

  const attachment = useAppSelector((state: RootState) =>
    selectAttachmentByKey(state, attachmentKey),
  );

  const theme = useTheme();
  const previewIcon = React.useMemo(() => {
    let iconName = "note";
    let bgcolor = "background.default";
    let color = "text.secondary";

    if (attachment?.file_type === "application/pdf") {
      iconName = "picture_as_pdf";
      bgcolor = "error.dark";
      color = "#fff";
    }

    if (attachment?.file_type.split("/")[0] === "image") {
      iconName = "image";
      bgcolor = "info.main";
      color = "#fff";
    }

    return {
      iconName,
      bgcolor,
      color,
    };
  }, [attachment?.file_type]);

  if (!attachment) {
    return <></>;
  }

  return (
    <Stack direction="row" gap={0.75} alignItems="center">
      {attachment?.file_type === "application/pdf" ? (
        <img
          style={{
            height: "24px",
            width: "24px",
            color: theme.palette.error.main,
          }}
          src={filePdfBox}
        />
      ) : (
        <AppIcon
          fill={1}
          opticalSize={24}
          color={previewIcon.bgcolor}
          iconName={previewIcon.iconName}
        />
      )}
      <Typography
        variant="body2"
        component="a"
        href={attachment.url}
        sx={{
          fontWeight: 500,
          minWidth: "0px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: "primary.main",
        }}
      >
        {attachment.file_name}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ whiteSpace: "nowrap" }}
      >
        {formatBytes(attachment.file_size, 1)}
      </Typography>
    </Stack>
  );
};

const MessageTypeLog = (props: MessageTypeLogProps) => {
  const { message, attachmentKeys } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <LogItemUpdatePart field_name={"Message"} new_value={message} />
      {attachmentKeys && attachmentKeys?.length > 0 && (
        <>
          <Stack direction="column" gap={0.75}>
            <Typography variant="body2" sx={{ fontWeight: 500, mb: -0.25 }}>
              Attachments
            </Typography>
            {attachmentKeys &&
              attachmentKeys?.length > 0 &&
              attachmentKeys.map((attachmentKey: string) => (
                <AppAttachmentLineItem
                  key={attachmentKey}
                  attachmentKey={attachmentKey}
                />
              ))}
          </Stack>
        </>
      )}
    </Box>
  );
};

type MessageEmailTypeLogProps = {
  message: string;
  receivers?: any[];
};

const MessageEmailTypeLog = (props: MessageEmailTypeLogProps) => {
  const { message, receivers } = props;
  return (
    <Stack direction="column" gap={1}>
      <LogItemUpdatePart
        field_name={"To"}
        new_value={receivers.map((receiver: any) => receiver.name).join(", ")}
      />
      <LogItemUpdatePart field_name={"Message"} new_value={message} />
    </Stack>
  );
};

// TYPES
export const INJURY_LOG_TYPES = {
  CREATE_INJURY_REPORT: "CREATE_INJURY_REPORT",
  CREATE_ACTION: "CREATE_ACTION", // DETAILS ONLY
  UPDATE_ACTION: "UPDATE_ACTION", // DETAILS ONLY
  COMPLETE_ACTION: "COMPLETE_ACTION",
  REOPEN_ACTION: "REOPEN_ACTION",
  DELETE_ACTION: "DELETE_ACTION",
  ADD_ACTION_COMMENT: "ADD_ACTION_COMMENT",
  ADD_COMMENT: "ADD_COMMENT",
  ADD_MESSAGE: "ADD_MESSAGE",
  UPDATE_SECTION_DETAILS: "UPDATE_SECTION_DETAILS",
  UPDATE_CASE: "UPDATE_CASE", // ASSIGNMENT, DUE_DATE OR APPROVED_SECTIONS
  REJECT_INJURY_REPORT: "REJECT_INJURY_REPORT",
  REOPEN_INJURY_REPORT: "REOPEN_INJURY_REPORT",
  ADD_OR_UPDATE_CLOSING_DETAILS: "ADD_OR_UPDATE_CLOSING_DETAILS",
  ADD_OR_UPDATE_FORMS: "ADD_OR_UPDATE_FORMS",
  CLOSE_INJURY_REPORT: "CLOSE_INJURY_REPORT",
  ADD_EMAIL_MESSAGE: "ADD_EMAIL_MESSAGE",
};

export const INJURY_LOG_TYPE_LABELS = {
  CREATE_ACTION: "Created action", // DETAILS ONLY
  UPDATE_ACTION: "Updated action", // DETAILS ONLY
  ADD_ACTION_COMMENT: "Added action comment",
  COMPLETE_ACTION: "Completed action",
  DELETE_ACTION: "Deleted action",
  ADD_COMMENT: "Added comment",
  ADD_MESSAGE: "Sent message",
  UPDATE_SECTION_DETAILS: "Updated section details",
  UPDATE_CASE: "Updated case management", // ASSIGNMENT, DUE_DATE OR APPROVED_SECTIONS
  REJECT_REPORT: "Rejected case",
  REOPEN_REPORT: "Reopened case",
  ADD_OR_UPDATE_CLOSING_DETAILS: "Added or updated closing details",
  ADD_OR_UPDATE_FORMS: "Added or updatet forms",
  CLOSE_REPORT: "Closed report",
  ADD_EMAIL_MESSAGE: "Sent Emails",
};

const ACTION_LOG_ITEMS = [
  INJURY_LOG_TYPES.CREATE_ACTION,
  INJURY_LOG_TYPES.UPDATE_ACTION,
  INJURY_LOG_TYPES.COMPLETE_ACTION,
  INJURY_LOG_TYPES.DELETE_ACTION,
  INJURY_LOG_TYPES.ADD_ACTION_COMMENT,
];

type InjuryHistoryItemProps = {
  log: InjuryLog;
  disablePx?: boolean;
  includeReportLink?: boolean;
};

export const InjuryHistoryItem = (props: InjuryHistoryItemProps) => {
  const { log, disablePx = false, includeReportLink = false } = props;
  const { t } = useTranslation();

  const selectInjuryReportById = React.useMemo(makeSelectInjuryReportById, [
    log,
  ]);
  const report = useAppSelector((state) =>
    selectInjuryReportById(state, log?.report_id),
  );
  const activeUser = getActiveUser();

  const actions = useAppSelector(selectActions);

  const users = useAppSelector(selectEmployees);

  const username = React.useMemo(() => {
    const user = users[log?.user_id];
    if (user) {
      return user?.name || log?.user_id;
    }
    return log?.user_id || "";
  }, [log, users]);

  const formattedLogTypeText = React.useMemo(() => {
    const logType = log?.type;
    let logMessage = "";
    let reportConnectionString = "";
    if (ACTION_LOG_ITEMS.includes(logType)) {
      if (log?.action_id && actions?.[log?.action_id]?.text) {
        logMessage = t(
          `wif.injury.historyLogs.${log?.type}.messageWithActionName`,
          { actionName: actions[log?.action_id]?.text },
        );
      }
      reportConnectionString = t("wif.injury.common.for");
    }
    if (logType === INJURY_LOG_TYPES.ADD_OR_UPDATE_FORMS) {
      let formName;
      if (log?.key && ["navForm", "laborForm"].includes(log?.key)) {
        formName = t(`wif.injury.${log?.key}`);
      }
      if (formName) {
        logMessage = t(
          `wif.injury.historyLogs.${log?.type}.messageWithFormName`,
          { formName: formName },
        );
      }
      logMessage = t(`wif.injury.historyLogs.${log?.type}.message`);
      reportConnectionString = t("wif.injury.common.for");
    }
    if (logType === INJURY_LOG_TYPES.UPDATE_CASE) {
      reportConnectionString = t("wif.injury.common.for");
    }
    if (logType === INJURY_LOG_TYPES.UPDATE_SECTION_DETAILS) {
      const oSectionName = log?.updates?.[0]?.key?.split(".")?.[0];
      let sectionName = getReverseMappedSectionNameByCategory(
        oSectionName,
        report?.category,
      );
      if (sectionName) {
        sectionName = t(`wif.injury.sectionNames.${sectionName}`);
      }
      logMessage = t(
        `wif.injury.historyLogs.${log?.type}.messageWithSectionName`,
        { sectionName: sectionName },
      );
      reportConnectionString = t("wif.injury.common.in");
    }
    logMessage = t(`wif.injury.historyLogs.${log?.type}.message`);
    reportConnectionString = t("wif.injury.common.in");

    if (includeReportLink) {
      return `${logMessage} ${reportConnectionString}`;
    }
    return logMessage;
  }, [log, actions, t]);

  return (
    <AppLogItem
      username={username}
      message={`${
        log?.user_id === activeUser?.id ? t("wif.injury.you") : username
      } ${formattedLogTypeText}`}
      resourceLink={
        includeReportLink
          ? {
              to: log.report_id,
              text: report?.name || log?.report_id,
            }
          : undefined
      }
      time={log.time}
      disablePx={disablePx}
      hideDetails
    >
      {(log?.type === INJURY_LOG_TYPES.ADD_COMMENT ||
        log?.type === INJURY_LOG_TYPES.ADD_MESSAGE ||
        log?.type === INJURY_LOG_TYPES.ADD_ACTION_COMMENT) && (
        <MessageTypeLog
          message={log?.new?.message || ""}
          attachmentKeys={log?.new?.attachment_keys}
        />
      )}
      {log?.type === INJURY_LOG_TYPES.UPDATE_CASE && (
        <CaseUpdateTypeLog log={log} />
      )}
      {(log?.type === INJURY_LOG_TYPES.CREATE_ACTION ||
        log?.type === INJURY_LOG_TYPES.UPDATE_ACTION ||
        log?.type === INJURY_LOG_TYPES.COMPLETE_ACTION ||
        log?.type === INJURY_LOG_TYPES.REOPEN_ACTION) && (
        <ActionTypeLog log={log} />
      )}
      {log?.type === INJURY_LOG_TYPES.ADD_OR_UPDATE_CLOSING_DETAILS && (
        <UpdateCompletionTypeLogItem log={log} />
      )}
      {log?.type === INJURY_LOG_TYPES.UPDATE_SECTION_DETAILS && (
        <SectionUpdateTypeLogItem log={log} />
      )}
      {log?.type === INJURY_LOG_TYPES.REOPEN_INJURY_REPORT && (
        <SimpleMessageLogItem log={log} />
      )}
      {log?.type === INJURY_LOG_TYPES.ADD_EMAIL_MESSAGE && (
        <MessageEmailTypeLog
          message={log?.new?.message || ""}
          receivers={log?.new?.usersReceived || ""}
        />
      )}
    </AppLogItem>
  );
};
