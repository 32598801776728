import InputAdornment from "@mui/material/InputAdornment";
import {
  useGridApiContext,
  useGridSelector,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  gridQuickFilterValuesSelector,
  gridRowCountSelector,
} from "@mui/x-data-grid";
import React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { Button, Stack, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useTranslation } from "react-i18next";
import { AppIcon } from "../Elements";
import { BOX_SHADOWS } from "../../config/theme/boxShadows";
import type { IFilterControl, IFilters } from "./types";
import {
  RSelectedItemToolbar,
  type RSelectedItemToolbarProps,
} from "./RDataGridSelectedToolbar";

import useMediaQuery from "@mui/material/useMediaQuery";
import type { IRAppliedFilter, IRFilterControl } from "./rDataGridFilterUtils";

type RFilterControlProps = {
  control: IRFilterControl;
  appliedValue?: IRAppliedFilter;
  handleRemove: () => void;
  handleUpdateFilterValue: (newValue: any) => void;
  fade?: "in" | "out";
};

export const RFilterControl = (props: RFilterControlProps) => {
  const {
    control: { field: key, label, options },
    appliedValue,
    handleRemove,
    handleUpdateFilterValue: handleUpdateFilter,
    fade,
  } = props;
  const { t } = useTranslation();
  const xlScreen = useMediaQuery("(min-width:1536px)");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme: any = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickMenuItem = (value: string) => {
    handleUpdateFilter(value);
    handleClose();
  };

  const screenSizedLabel = React.useMemo(() => {
    if (xlScreen) {
      return label || key;
    }
    return (label || key).substring(0, 4).concat("...");
  }, [xlScreen, t]);

  const screenSizedAppliedLabel = React.useMemo(() => {
    if (appliedValue) {
      if (xlScreen) {
        return (
          options.find((option) => option.value === appliedValue)?.label ||
          options.find((option) => option.value === appliedValue).value
        );
      }
      return (
        options.find((option) => option.value === appliedValue)?.label ||
        options.find((option) => option.value === appliedValue).value
      )
        .substring(0, 4)
        .concat("...");
    }
    return undefined;
  }, [xlScreen, appliedValue, t]);

  return (
    <>
      {appliedValue ? (
        <Tooltip
          title={`${label || key}: ${
            options.find((option) => option.value === appliedValue)?.label ||
            options.find((option) => option.value === appliedValue).value
          }`}
          placement="bottom"
        >
          <Chip
            color={"secondary"}
            component="button"
            onClick={handleClick}
            label={screenSizedAppliedLabel}
            onDelete={handleRemove}
            deleteIcon={
              <CloseOutlinedIcon
                fontSize="small"
                sx={{
                  color: `${theme.palette.primary.main}!important`,
                  opacity: 0.8,
                  fontSize: "18px!important",
                  marginLeft: "-4px!important",
                }}
              />
            }
            sx={{
              "& .MuiChip-label": {
                mt: -0.25,
              },
              pl: "6px",
              borderRadius: 1.5,
              height: "36px",
              pr: 1,
              fontSize: "body2.fontSize",
              fontWeight: 500,
            }}
          />
        </Tooltip>
      ) : (
        <Button
          sx={{
            pl: 2,
            pr: 1.5,
            height: "36px",
            fontWeight: 500,
            borderRadius: 1.5,
          }}
          variant="outlined"
          endIcon={
            <AppIcon
              iconName="arrow_drop_down"
              color={"primary.main"}
              opticalSize={20}
              sx={{ ml: -0.25 }}
            />
          }
          onClick={handleClick}
        >
          {screenSizedLabel}
        </Button>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options.map((option: any, i: any) => (
          <MenuItem
            key={option.value}
            selected={appliedValue && appliedValue === option.value}
            onClick={() => handleClickMenuItem(option.value)}
          >
            {option?.label || option.value}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

type RAddableFiltersMenuProps = {
  handleAddFilterControl: (control) => void;
  controls: IFilterControl[];
};

const RAddableFiltersMenu = (props: RAddableFiltersMenuProps) => {
  const { controls, handleAddFilterControl } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickedMenuItem = (control: IFilterControl) => {
    handleAddFilterControl(control);
    handleClose();
  };

  return (
    <>
      <Tooltip title={t("wif.injury.addFilter")} placement="bottom">
        <Button
          variant="outlined"
          id="add-filter-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          startIcon={<AppIcon iconName="add" />}
          sx={{
            borderRadius: 1.5,
            height: "36px",
            width: "36px",
            minWidth: 0,
            "& .MuiButton-startIcon": {
              mx: 0,
            },
          }}
        />
      </Tooltip>
      <Menu
        id="addable-filters-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "add-filter-button",
        }}
      >
        {controls.map((control: IFilterControl, i: any) => (
          <MenuItem
            key={control.field}
            onClick={() => handleClickedMenuItem(control)}
          >
            {control?.label || control.field}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

type RDataGridToolbarProps = {
  filters?: IFilters;
  addableControls?: {
    controls: IFilterControl[];
    handleAddFilterControl: (control) => void;
  };
  selected?: RSelectedItemToolbarProps;
};

export const RDataGridToolbar = (props: RDataGridToolbarProps) => {
  const { filters, addableControls, selected } = props;
  const {
    controls,
    addedControls,
    active,
    handleSetFilter,
    handleRemoveFilter,
    handleResetFilterControls,
  } = filters;

  const [quickFilterOpen, setQuickFilterOpen] = React.useState(false);
  const [filterChipsVisible, setFilterChipsVisible] = React.useState(true);
  const { t } = useTranslation();

  const apiRef = useGridApiContext();

  const quickFilterValues = useGridSelector(
    apiRef,
    gridQuickFilterValuesSelector,
  );

  const quickFilterParser = (searchText: string) =>
    searchText.split(" ").filter((word) => word !== "");

  const rowsCount = useGridSelector(apiRef, gridRowCountSelector);

  const handleResetAllFilters = () => {
    apiRef.current.setQuickFilterValues([]);
    if (handleResetFilterControls) {
      handleResetFilterControls();
    }
  };

  const filteringDisabled =
    filters === undefined && addableControls === undefined;

  return (
    <GridToolbarContainer
      sx={{
        pb: 2.5,
        pt: 0,
        ml: -0.5,
        mr: 0.5,
        position: "relative",
      }}
    >
      {selected && selected?.count > 0 && (
        <RSelectedItemToolbar
          actions={selected.actions}
          itemName={selected.itemName}
        />
      )}
      <>
        <Stack
          direction={"column"}
          justifyContent={
            !quickFilterOpen && !filterChipsVisible
              ? "flex-end"
              : "space-between"
          }
          gap={1}
          sx={{ width: "100%" }}
        >
          <Stack
            direction={{
              xs: "column",
              xl: "row",
            }}
            gap={{
              xs: 1.5,
              xl: 1.25,
            }}
            alignItems={{
              xs: "start",
              xl: "center",
            }}
            display={"flex"}
            sx={{
              width: "100%",
              mr: -0.5,
            }}
          >
            <Stack
              direction="row"
              gap={1.25}
              alignItems="center"
              display={"flex"}
              sx={{
                width: {
                  xs: "100%",
                  xl: "auto",
                },
              }}
            >
              <GridToolbarQuickFilter
                InputProps={{
                  sx: {
                    pl: 1.75,
                    borderRadius: 1.5,
                    borderColor: (theme) =>
                      `${theme.palette.primary.main}!important`,
                    "& input": {
                      lineHeight: 20,
                      ml: 0,
                      pl: 0.25,
                      "&::placeholder": {
                        color: "textVariant",
                        opacity: 0.9,
                      },
                    },
                    boxShadow: BOX_SHADOWS.elevation0,
                    height: 36,
                    mb: -0.5,
                  },
                  startAdornment: (
                    <InputAdornment position="start" sx={{ minWidth: "0px" }}>
                      <AppIcon
                        iconName="search"
                        color="primary.main"
                        weight={500}
                      />
                    </InputAdornment>
                  ),
                }}
                in="true"
                variant="outlined"
                size="small"
                sx={{
                  width: {
                    xs: "320px",
                    xl: controls.length < 2 ? "640px" : "420px",
                  },
                  borderColor: (theme) =>
                    `${theme.palette.primary.main}!important`,
                }}
              />
              <Box sx={{ flexGrow: 1, display: { xs: "flex", xl: "none" } }} />
              <Tooltip
                sx={{
                  display: {
                    xs: "flex",
                    xl: "none",
                  },
                }}
                title={t("wif.injury.settings")}
                placement="bottom"
              >
                <GridToolbarColumnsButton
                  variant="text"
                  startIcon={
                    <AppIcon iconName="settings" color="text.secondary" />
                  }
                  sx={{
                    color: "text.primary",
                    "& .MuiButton-startIcon": {
                      mx: 0,
                    },
                    p: "auto",
                    height: "36px",
                    width: "36px",
                    minWidth: 0,
                    display: {
                      xs: "flex",
                      xl: "none",
                    },
                  }}
                />
              </Tooltip>
              <Tooltip
                title={t("wif.injury.export")}
                placement="bottom"
                sx={{
                  display: {
                    xs: "flex",
                    xl: "none",
                  },
                }}
              >
                <GridToolbarExport
                  variant="text"
                  startIcon={
                    <AppIcon
                      iconName="download"
                      color="text.secondary"
                      weight={500}
                    />
                  }
                  sx={{
                    color: "text.primary",
                    "& .MuiButton-startIcon": { mx: 0 },
                    p: "auto",
                    minWidth: 0,
                    height: "36px",
                    width: "36px",
                    display: {
                      xs: "flex",
                      xl: "none",
                    },
                  }}
                />
              </Tooltip>
            </Stack>

            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              display={"flex"}
              sx={{
                flexGrow: 1,
                width: "100%",
              }}
            >
              {!filteringDisabled && (
                <>
                  {filters &&
                    [...controls, ...addedControls].map((control, i) => (
                      <RFilterControl
                        key={control.field}
                        control={control}
                        appliedValue={
                          active.find(
                            (filter) => filter.field === control.field,
                          )?.value || undefined
                        }
                        handleRemove={() => handleRemoveFilter(control.field)}
                        handleUpdateFilterValue={(newValue: any) =>
                          handleSetFilter({
                            field: control.field,
                            operator: control.operator,
                            value: newValue,
                          })
                        }
                        fade={
                          /* i === 0 ? 'in' :  */ i ===
                          [...controls, ...addedControls].length - 1
                            ? "out"
                            : undefined
                        }
                      />
                    ))}
                  {addableControls &&
                    addableControls?.controls?.length > 0 &&
                    rowsCount > 0 && (
                      <RAddableFiltersMenu
                        controls={addableControls.controls}
                        handleAddFilterControl={
                          addableControls.handleAddFilterControl
                        }
                      />
                    )}
                  {(addedControls?.length > 0 ||
                    active?.length > 0 ||
                    quickFilterValues?.length > 0) && (
                    <Button
                      variant="text"
                      color="primary"
                      onClick={handleResetAllFilters}
                    >
                      {t("wif.injury.clearAll", "Clear all")}
                    </Button>
                  )}
                </>
              )}

              <Box sx={{ flexGrow: 1 }} />

              <Tooltip
                sx={{
                  display: {
                    xs: "none",
                    xl: "flex",
                  },
                }}
                title={t("wif.injury.settings")}
                placement="bottom"
              >
                <GridToolbarColumnsButton
                  variant="text"
                  startIcon={
                    <AppIcon iconName="settings" color="text.secondary" />
                  }
                  sx={{
                    color: "text.primary",
                    "& .MuiButton-startIcon": {
                      mx: 0,
                    },
                    p: "auto",
                    height: "36px",
                    width: "36px",
                    minWidth: 0,
                    display: {
                      xs: "none",
                      xl: "flex",
                    },
                  }}
                />
              </Tooltip>
              <Tooltip
                title={t("wif.injury.export")}
                placement="bottom"
                sx={{
                  display: {
                    xs: "none",
                    xl: "flex",
                  },
                }}
              >
                <GridToolbarExport
                  variant="text"
                  startIcon={
                    <AppIcon
                      iconName="download"
                      color="text.secondary"
                      weight={500}
                    />
                  }
                  sx={{
                    color: "text.primary",
                    "& .MuiButton-startIcon": { mx: 0 },
                    p: "auto",
                    minWidth: 0,
                    height: "36px",
                    width: "36px",
                    display: {
                      xs: "none",
                      xl: "flex",
                    },
                  }}
                />
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
      </>
    </GridToolbarContainer>
  );
};
