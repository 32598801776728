import { FormHelperText } from "@mui/material";
import React from "react";
import type { Control, FieldPath } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import type { FieldValues } from "react-hook-form/dist/types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";

type FormSelectOption = {
  value: any;
  label?: string;
  depth?: number;
};

type CombinedSelectProps = {
  label?: string;
  options: FormSelectOption[];
};

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = CombinedSelectProps & {
  control?: Control<TFieldValues, any>;
  name: TName;
  required?: never;
  controllerProps?: any;
  size?: any;
};

export function FormSelect<TFieldValues extends FieldValues>(
  props: Props<TFieldValues>,
) {
  const {
    control = useFormContext<TFieldValues>().control,
    name,
    controllerProps,
    label,
    options,
    size,
  } = props;
  const { t } = useTranslation();

  const getTranslation = React.useCallback(
    (key: string) => {
      const keyWithValues = key.split(" ");
      const translationKey = keyWithValues[0];
      if (
        translationKey === "max" ||
        translationKey === "min" ||
        translationKey === "minmax"
      ) {
        return t(`wif.injury.errors.${translationKey}`, {
          i: keyWithValues[1],
        });
      } else if (translationKey === "between") {
        return t(`wif.injury.errors.${translationKey}`, {
          min: keyWithValues[1],
          max: keyWithValues[2],
        });
      }
      return t(`wif.injury.errors.${key}`);
    },
    [t],
  );
  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      defaultValue={"" as any}
      render={({ field: { value, ...field }, fieldState: { error } }) => (
        <FormControl fullWidth error={!!error} size={size}>
          {label && <InputLabel id={`${name}-label`}>{label}</InputLabel>}
          <Select
            {...field}
            value={value}
            labelId={label ? `${name}-label` : undefined}
            id={`${name}`}
            label={label}
          >
            {options.map((option, i) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  ...(option.depth && {
                    pl: option.depth,
                  }),
                }}
              >
                {option.label ? option.label : option.value}
              </MenuItem>
            ))}
          </Select>
          {error?.message && (
            <FormHelperText>{getTranslation(error.message)}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
