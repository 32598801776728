import React from "react";
import * as yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { AppDialogTitle } from "../../../components/Display/AppDialogTitle";
import { useForm, type SubmitHandler } from "react-hook-form";
import { FormTextField } from "../../../components/FormInputs";
import { Stack } from "@mui/material";
import { type RootState, useAppDispatch, useAppSelector } from "../../../store";
import { yupResolver } from "@hookform/resolvers/yup";
import { snackAlertOpened } from "../../global/controlsSlice";
import { makeSelectInjuryReportById } from "../../injuries/store/injuryReportsSlice";
import { LoadingButtonContainer } from "../../../components/Elements/LoadingButtonContainer";
import type { IAction } from "../actionTypes";
import { completeInjuryAction } from "../../injuries/store/injuryReportThunks";
import { useTranslation } from "react-i18next";

export const completeActionSchema = yup.object().shape({
  evaluation: yup.string().required().min(4, "min 4").max(180, "max 180"),
  workDone: yup.string().notRequired(),
});

export type IActionCompletion = yup.InferType<typeof completeActionSchema>;

interface CompleteActionDialogProps {
  open?: boolean;
  action?: IAction;
  onClose: () => void;
}

export default function CompleteActionDialog(props: CompleteActionDialogProps) {
  const { onClose, action, open = false } = props;

  const selectInjuryReportById = React.useMemo(makeSelectInjuryReportById, [
    action.report_id,
  ]);
  const injuryReport = useAppSelector((state: RootState) =>
    selectInjuryReportById(state, action.report_id),
  );

  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState(false);

  const { handleSubmit, control, reset } = useForm<IActionCompletion>({
    resolver: yupResolver(completeActionSchema),
    defaultValues: React.useMemo(() => {
      return action?.completionAndEvaluation;
    }, [action]),
  });

  const onSubmit: SubmitHandler<IActionCompletion> = (data) => {
    const payload: any = {
      completionAndEvaluation: data,
      report_id: injuryReport?.id,
      action_id: action?.action_id,
    };
    const req = {
      payload: payload,
    };

    let successMessage = t("wif.injury.snackMessages.actionCompleted.success");
    if (injuryReport?.name) {
      successMessage = t(
        "wif.injury.snackMessages.actionCompleted.successWithReportName",
        { reportName: injuryReport?.name },
      );
    }

    setLoading(true);
    dispatch(completeInjuryAction(req))
      .unwrap()
      .then((originalPromiseResult) => {
        setLoading(false);
        dispatch(
          snackAlertOpened({ severity: "success", message: successMessage }),
        );
        onClose && onClose();
      })
      .catch((rejectedValueOrSerializedError) => {
        setLoading(false);
        dispatch(
          snackAlertOpened({
            severity: "error",
            message: t("wif.injury.snackMessages.genericError"),
          }),
        );
      });
  };

  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <AppDialogTitle id="create-injury-action-dialog-title" onClose={onClose}>
        {t("wif.injury.completeActionDialog.title", {
          actionName: action?.text,
        })}
      </AppDialogTitle>
      <DialogContent
        dividers
        sx={{
          width: 600,
        }}
      >
        <form id="complete-injury-action" onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" gap={2.5} width="100%">
            <FormTextField
              minRows={3}
              maxRows={6}
              name="evaluation"
              label={t("wif.injury.evaluation")}
              control={control}
            />
            <FormTextField
              name="workDone"
              label={t("wif.injury.workDone")}
              control={control}
              minRows={3}
              maxRows={6}
              multiline
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="text" onClick={onClose} sx={{ mr: 1 }}>
          {t("wif.injury.cancel")}
        </Button>
        <LoadingButtonContainer loading={loading}>
          <Button
            type="submit"
            form="complete-injury-action"
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            {t("wif.injury.save")}
          </Button>
        </LoadingButtonContainer>
      </DialogActions>
    </Dialog>
  );
}
