import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

const getLDProvider = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.LD_CLIENT_ID,
    timeout: 5,
  });
  return LDProvider;
};

export default getLDProvider;
