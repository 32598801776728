import { useRef } from "react";
import MapGL, { Layer, Source } from "react-map-gl";
import { useAppSelector } from "../../../store";
import {
  selectCurrentSiteId,
  selectSiteFeatures,
  selectSites,
} from "../locationsSlice";
import { MAPBOX_TOKEN, paletteMapStyle } from "../../sites/components/Map";
import React from "react";
import {
  baseClusterCountLayer,
  baseClusterLayer,
} from "../../sites/components/CreateAreaMap/siteLayers";
import { useTheme } from "@mui/material";

const mapStyles: any = {
  width: "100%",
  flex: 1,
  minHeight: 0,
};

export default function SitesOverviewMap() {
  const theme = useTheme();
  const mapRef = useRef<any>(null);

  const sites = useAppSelector(selectSites);
  const siteFeatures = useAppSelector(selectSiteFeatures);

  const currentSiteId = useAppSelector(selectCurrentSiteId);

  const sitesFeatureCollection = React.useMemo(
    () =>
      ({
        type: "FeatureCollection",
        features: [...siteFeatures],
      }) as GeoJSON.FeatureCollection<GeoJSON.Point>,
    [siteFeatures],
  );

  React.useEffect(() => {
    if (currentSiteId) {
      const site = sites[currentSiteId];
      if (site) {
        mapRef.current?.flyTo({
          center: [site.coordinates.lng, site.coordinates.lat],
          zoom: 16,
          duration: 2000,
        });
      }
    }
  }, [currentSiteId]);

  const initViewState = React.useMemo(() => {
    if (currentSiteId) {
      if (sites[currentSiteId]) {
        return {
          latitude: sites[currentSiteId].coordinates.lat,
          longitude: sites[currentSiteId].coordinates.lng,
          zoom: 16,
        };
      }
    }
    return {
      latitude: 60.472,
      longitude: 8.4689,
      zoom: 5,
    };
  }, []);

  return (
    <MapGL
      initialViewState={initViewState}
      style={mapStyles}
      maxPitch={85}
      mapStyle={paletteMapStyle[theme.palette.mode as any]}
      mapboxAccessToken={MAPBOX_TOKEN}
      ref={mapRef}
    >
      <Source
        id="main"
        type="geojson"
        data={sitesFeatureCollection}
        cluster={true}
      >
        <Layer
          id="main-sites"
          type="symbol"
          layout={{
            "icon-size": [
              "case",
              ["==", ["get", "iconImage"], "pin-4"],
              1,
              0.8,
            ],
            "icon-allow-overlap": true,
            "text-allow-overlap": true,
            "text-field": ["get", "name"],
            "text-font": [
              "case",
              ["==", ["get", "iconImage"], "pin-4"],
              ["literal", ["Inter Tight SemiBold"]],
              ["literal", ["Inter Tight Medium"]],
            ],
            "text-offset": [0, 1.9],
            "text-max-width": 15,
            "text-size": [
              "case",
              ["==", ["get", "iconImage"], "pin-4"],
              15,
              13,
            ],
            "icon-image": ["get", "iconImage"],
          }}
          paint={{
            "text-color": [
              "case",
              ["==", ["get", "iconImage"], "pin-4"],
              theme.palette.primary.main,
              theme.palette.text.secondary,
            ],
          }}
        />
        <Layer {...baseClusterLayer} />
        <Layer {...baseClusterCountLayer} />
      </Source>
    </MapGL>
  );
}
