import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

export default function CircularLoader() {
  return (
    <Stack spacing={2} direction="row">
      <CircularProgress color="primary" />
    </Stack>
  );
}
