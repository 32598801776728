import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { AppDialogTitle } from "../../../../../components/Display/AppDialogTitle";
import { useAppDispatch } from "../../../../../store";
import {
  type UpdateInjuryCaseReq,
  updateInjuryCase,
} from "../../../store/injuryReportsSlice";
import { snackAlertOpened } from "../../../../global/controlsSlice";
import { useCurrentInjuryReport } from "../../../routes/CurrentInjuryReportContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { type Dayjs } from "dayjs";
import "dayjs/locale/nb";
import { formatDateFromTimestamp } from "../../../../../utils/format";
import { useTranslation } from "react-i18next";

interface DueDateDialogProps {
  open?: boolean;
  onClose?: () => void;
}

export default function DueDateDialog(props: DueDateDialogProps) {
  const { open = true, onClose } = props;
  const { t } = useTranslation();
  const injuryReport = useCurrentInjuryReport();

  const dispatch = useAppDispatch();

  const [dueDate, setDueDate] = useState<Dayjs | null>(null);

  React.useEffect(() => {
    if (injuryReport?.due_date) {
      if (formatDateFromTimestamp(injuryReport?.due_date) !== "invalid date") {
        setDueDate(dayjs(injuryReport?.due_date));
      } else {
        const timestamp = new Date(
          `${injuryReport?.due_date.split(".")[2]}-${
            injuryReport?.due_date.split(".")[1]
          }-${injuryReport?.due_date.split(".")[0]}`,
        ).getTime();
        setDueDate(dayjs(timestamp));
      }
    }
  }, [injuryReport]);

  const handleUpdateDueDate = async () => {
    const req: UpdateInjuryCaseReq = {
      reportId: injuryReport.id,
      body: {
        due_date: dueDate,
      },
    };
    const resultAction = await dispatch(updateInjuryCase(req));
    if (updateInjuryCase.fulfilled.match(resultAction)) {
      onClose();
      dispatch(
        snackAlertOpened({
          message: t("wif.injury.snackMessages.dueDateUpdated.success"),
          severity: "success",
        }),
      );
    } else {
      if (resultAction.payload) {
        dispatch(
          snackAlertOpened({
            message: t("wif.injury.snackMessages.genericError"),
            severity: "error",
          }),
        );
      } else {
        dispatch(
          snackAlertOpened({
            message: t("wif.injury.snackMessages.errorWithMessage", {
              message: resultAction.error,
            }),
            severity: "error",
          }),
        );
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <AppDialogTitle id="close-report-dialog-title" onClose={onClose}>
        {t("wif.injury.setDueDateDialog.title")}
      </AppDialogTitle>
      <DialogContent
        dividers
        sx={{
          width: 600,
          border: "none",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb">
          <DatePicker
            label={t("wif.injury.dueDate")}
            value={dueDate}
            onChange={(newValue: any) => setDueDate(newValue)}
            sx={{
              width: "100%",
            }}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("wif.injury.cancel")}</Button>
        <Button
          onClick={handleUpdateDueDate}
          variant="contained"
          color="primary"
          disabled={!dueDate}
        >
          {t("wif.injury.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
