import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  type EntityState,
} from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import type {
  NonconformityComparison,
  NonconformityStatistics,
} from "../../types";
import { httpGet, httpPost } from "../../utils/httpService";
import config from "../../config/config";

export type NonconformityReport = {
  rep_id: string;
  name: string;
  type: string;
  account_id: string;
  created_by: string; // id
  description?: string;
  status?: string;
  area?: string;
  eventDate?: string;
  time?: string;
  updated_at?: number;
  assignee?: string;
  nonconformity_tags?: string[]; // Move to other slice
  department?: string;
  created_at?: any;
  date?: any;
};

const ncReportsAdapter = createEntityAdapter<NonconformityReport>({
  selectId: (report) => report.rep_id,
});

const ncAreasAdapter = createEntityAdapter<any>({
  selectId: (area) => area.area_id,
});

type NCTag = {
  id: string;
  tagName: string;
  dueDate?: number;
};

const ncTagsAdapter = createEntityAdapter<NCTag>({});

export interface NcState {
  reports: EntityState<any>;
  areas: EntityState<any>;
  tags: {
    type: EntityState<NCTag>;
    cause: EntityState<NCTag>;
  };
  statistics?: NonconformityStatistics;
  comparison?: NonconformityComparison;
}

const initialState: NcState = {
  reports: ncReportsAdapter.getInitialState(),
  areas: ncAreasAdapter.getInitialState(),
  tags: {
    type: ncTagsAdapter.getInitialState(),
    cause: ncTagsAdapter.getInitialState(),
  },
};

export const fetchNonconformityStatistics = createAsyncThunk(
  "nonconformity/fetchStatistics",
  async (body: any, { getState }) => {
    const response = await httpPost(
      `${config.baseUrl}/nonconformity/getNonConformityStatistics`,
      body,
    );
    return response.data;
  },
);

export const fetchNonconformityComparison = createAsyncThunk(
  "nonconformity/fetchComparison",
  async (body: any, { getState }) => {
    const response = await httpPost(
      `${config.baseUrl}/nonconformity/getComparisonData`,
      body,
    );
    return response.data;
  },
);

export const fetchNonconformityAreas = createAsyncThunk(
  "nonconfirmity/fetchAreas",
  async (thunkAPI) => {
    const response = await httpGet(`${config.baseUrl}/nonconformity/getAreas`);
    return response.data;
  },
);

export const fetchNonconformityReports = createAsyncThunk(
  "nonconfirmity/fetchReports",
  async (thunkAPI) => {
    const body = {
      requests: [
        {
          indexName: "",
          params: {
            query: "",
            tagFilters: "",
            facetFilters: [["type:Nonconformity"]],
            hitsPerPage: 250,
          },
        },
      ],
    };
    const response = await httpPost(
      `${config.baseUrl}/nonconformity/search/query`,
      body,
    );
    return response.data;
  },
);

export const fetchReporterNonconformityReports = createAsyncThunk(
  "nonconfirmity/fetchReporterNonconformityReports",
  async (thunkAPI) => {
    let reports: any = [];
    const body = {
      filterQuery: null,
      cursor: null,
      status: "ongoing",
      limit: 100,
    };
    let response = await httpPost(
      `${config.baseUrl}/nonconformity/getReporterNonConformityReports`,
      body,
    );
    let json = response.data;
    reports = [...reports, ...json.reports];
    while (json.cursor.hasNext) {
      body.cursor = json.cursor.next;
      response = await httpPost(
        `${config.baseUrl}/nonconformity/getReporterNonConformityReports`,
        body,
      );
      json = await response.data;
      reports = [...reports, ...json.reports];
    }
    return {
      reports: reports,
    };
  },
);

export const nonconformitySlice = createSlice({
  name: "nonconformity",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchNonconformityComparison.fulfilled, (state, action) => {
      state.comparison = action.payload;
    });
    builder.addCase(fetchNonconformityStatistics.fulfilled, (state, action) => {
      state.statistics = action.payload;
    });
    builder.addCase(fetchNonconformityAreas.fulfilled, (state, action) => {
      const areasToAdd: any[] = [];
      for (const area of action.payload) {
        if (area.area_id) {
          areasToAdd.push(area);
        }
      }
      ncAreasAdapter.setAll(state.areas, areasToAdd);
    });
    builder.addCase(fetchNonconformityReports.fulfilled, (state, action) => {
      const nonconformityReportsToAdd: any[] = [];
      for (const hit of action.payload.results[0].hits) {
        const ncReport: NonconformityReport = {
          rep_id: hit.rep_id,
          account_id: hit?.account_id,
          name: hit.name,
          type: hit.type,
          status: hit.status.toLowerCase(),
          assignee: hit?.assignee?.id || null,
          created_by: hit?.created_by || null,
          created_at: hit?.created_at || null,
          description: hit?.description || null,
          area: hit?.area || null,
          date: hit?.eventDate || null,
          time: hit?.time || null,
          updated_at: hit?.updated_at || null,
        };
        nonconformityReportsToAdd.push(ncReport);
      }
      ncReportsAdapter.setAll(state.reports, nonconformityReportsToAdd);
    });
    builder.addCase(
      fetchReporterNonconformityReports.fulfilled,
      (state, action) => {
        const nonconformityReportsToAdd: any[] = [];
        for (const report of action.payload.reports) {
          const ncReport: NonconformityReport = {
            rep_id: report.rep_id,
            account_id: report?.account_id,
            name: report.name,
            type: report.type,
            status: report.status.toLowerCase(),
            assignee: report?.assignee || null,
            created_by: report?.created_by || null,
            created_at: report?.created_at || null,
            description: report?.description || null,
            area: report?.area || null,
            date: report?.date || null,
            time: report?.time || null,
            updated_at: report?.updated_at || null,
          };
          nonconformityReportsToAdd.push(ncReport);
        }
        ncReportsAdapter.setAll(state.reports, nonconformityReportsToAdd);
      },
    );
  },
});

export const {
  selectIds: selectNonconformityReportIds,
  selectEntities: selectNonconformityReportEntities,
  selectAll: selectAllNonconformityReports,
  selectTotal: selectTotalNonconformityReports,
  selectById: selectNonconformityReportById,
} = ncReportsAdapter.getSelectors<RootState>(
  (state) => state.nonconformity.reports,
);

export const {
  selectIds: selectNcAreaIds,
  selectEntities: selectNcAreas,
  selectAll: selectAllNcAreas,
  selectTotal: selectTotalNcAreas,
  selectById: selectNcAreaById,
} = ncAreasAdapter.getSelectors<RootState>(
  (state) => state.nonconformity.areas,
);

export const selectNonconformityStatistics = (state: RootState) =>
  state.nonconformity.statistics;
export const selectNonconformityComparison = (state: RootState) =>
  state.nonconformity.comparison;

export default nonconformitySlice.reducer;
