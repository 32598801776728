import {
  createSelector,
  createSlice,
  type PayloadAction,
} from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { REPORT_TYPES } from "../injuries/constants";
import {
  Grodem,
  KongsvingerUngdomsskole,
  Marikollen,
} from "./constants/testSites";

type LocationCoordinates = {
  lat: number;
  lng: number;
};

export type LocationBase = {
  name: string;
  coordinates: LocationCoordinates;
  address: string;
};

export type Site = {
  domain: string;
  name: string;
  lat: number;
  lng: number;
  areaCoordinates?: number[][];
};

export type SitesCollection = {
  ids: string[];
  sites: {
    [id: string]: Site;
  };
};

export type SitesState = {
  basePaths: string[];
  baseLocations: {
    [id: string]: LocationBase;
  };
  ids: string[];
  sites: {
    [id: string]: Site;
  };
  initialCoordinates: {
    lat: number;
    lng: number;
  };
  currentBasePath?: string;
  current?: string;
};

const initialState: SitesState = {
  basePaths: ["Grodem", "Kongsvinger ungdomsskole", "Marikollen"],
  baseLocations: {
    Grodem: {
      name: "Grodem",
      coordinates: { lat: 59.01009, lng: 5.65333 },
      address: "Fjordsolveien 8",
    },
    "Kongsvinger ungdomsskole": {
      name: "Kongsvinger ungdomsskole",
      coordinates: { lat: 60.1914, lng: 12.01479 },
      address: "Markensvegen 20",
    },
    Marikollen: {
      name: "Marikollen",
      coordinates: {
        lat: 60.19127,
        lng: 11.97191,
      },
      address: "Trygve Stokkes veg 57",
    },
  },
  ids: [...Grodem.ids, ...KongsvingerUngdomsskole.ids, ...Marikollen.ids],
  sites: {
    ...Grodem.sites,
    ...KongsvingerUngdomsskole.sites,
    ...Marikollen.sites,
  },
  initialCoordinates: {
    lat: 59.01007,
    lng: 5.65323,
  },
};

type CreateSitePayload = {
  domain: string;
  name: string;
  lat: number;
  lng: number;
  areaCoordinates?: number[][];
};

export const sitesSlice = createSlice({
  name: "sites",
  initialState,
  reducers: {
    siteClicked: (state, action: PayloadAction<string>) => {
      if (state.current === action.payload) {
        state.current = undefined;
      } else {
        state.current = action.payload;
      }
    },
    siteSelected: (state, action: PayloadAction<string>) => {
      state.current = action.payload;
    },
    siteDeselected: (state) => {
      state.current = undefined;
    },
    baseClicked: (state, action: PayloadAction<string>) => {
      if (state.currentBasePath === action.payload) {
        state.currentBasePath = undefined;
      } else {
        state.currentBasePath = action.payload;
      }
    },
    siteCreated: (state, action: PayloadAction<CreateSitePayload>) => {
      const sitePath = `${action.payload.domain}/${action.payload.name}`;
      state.sites[sitePath] = action.payload;
      state.ids.push(sitePath);
    },
  },
  extraReducers: (builder) => {},
});

export const {
  siteSelected,
  siteDeselected,
  siteClicked,
  baseClicked,
  siteCreated,
} = sitesSlice.actions;

export default sitesSlice.reducer;

export const selectSitePaths = (state: RootState) => state.sites.ids;
export const selectSites = (state: RootState) => state.sites.sites;
export const selectInitialCoordinates = (state: RootState) =>
  state.sites.initialCoordinates;
export const selectCurrentSiteId = (state: RootState) => state.sites.current;
export const selectCurrentBasePath = (state: RootState) =>
  state.sites.currentBasePath;
export const selectBasePaths = (state: RootState) => state.sites.basePaths;
export const selectBaseLocations = (state: RootState) =>
  state.sites.baseLocations;

const getId = (_: any, id: string) => id;

export const makeSelectSiteById = () => {
  return createSelector([selectSites, getId], (sites, id) => sites[id]);
};

export const makeSelectBaseLocationByPath = () => {
  return createSelector(
    [selectBaseLocations, getId],
    (baseLocations, path) => baseLocations[path],
  );
};

export const selectAllSites = createSelector(
  [selectSitePaths, selectSites],
  (ids, sites) => ids.map((id) => sites[id]),
);

export const selectSitesInBaseCount = createSelector(
  [selectBasePaths, selectSitePaths],
  (basePaths, sitePaths) => {
    const sitesInBaseCount: any = {};
    for (const basePath of basePaths) {
      sitesInBaseCount[basePath] = sitePaths.filter(
        (sitePath) => sitePath.split("/")[0] === basePath,
      ).length;
    }
    return sitesInBaseCount;
  },
);

export const makeSelectSitesByBasePath = () => {
  return createSelector([selectAllSites, getId], (sites, basePath) =>
    sites.filter((site) => site.domain === basePath),
  );
};

export const selectAllBaseLocations = createSelector(
  [selectBasePaths, selectBaseLocations],
  (paths, baseLocations) => paths.map((path) => baseLocations[path]),
);

export const selectSiteFeatures = createSelector(
  [selectAllSites, selectCurrentSiteId],
  (sites, current) =>
    sites.map(
      (site: Site) =>
        ({
          type: "Feature",
          properties: {
            id: `${site.domain}/${site.name}`,
            iconImage: "pin-0",
            showIcon: site.areaCoordinates ? 0 : 1,
            label: site.name,
            type: "pin",
            current: current === `${site.domain}/${site.name}` ? 1 : 0,
            reportsCount: {
              total: 0,
              [REPORT_TYPES.ACCIDENT]: 0,
              [REPORT_TYPES.ILLNESS]: 0,
              [REPORT_TYPES.MINOR_INJURY]: 0,
            },
            reportsCountStatus: {
              total: 1,
              [REPORT_TYPES.ACCIDENT]: 1,
              [REPORT_TYPES.ILLNESS]: 1,
              [REPORT_TYPES.MINOR_INJURY]: 1,
            },
          },
          geometry: {
            coordinates: [site.lng, site.lat],
            type: "Point",
          },
          id: `${site.domain}/${site.name}`,
        }) as GeoJSON.Feature<GeoJSON.Point>,
    ),
);

export const selectSiteOutlineFeatures = createSelector(
  [selectAllSites, selectCurrentSiteId],
  (sites, current) =>
    sites
      .filter((site: Site) => site.areaCoordinates !== undefined)
      .map(
        (site: Site) =>
          ({
            type: "Feature",
            properties: {
              id: `${site.domain}/${site.name}-outline`,
              siteId: `${site.domain}/${site.name}`,
              type: "outline",
              current: current === `${site.domain}/${site.name}` ? 1 : 0,
              reportsCount: {
                total: 0,
                [REPORT_TYPES.ACCIDENT]: 0,
                [REPORT_TYPES.ILLNESS]: 0,
                [REPORT_TYPES.MINOR_INJURY]: 0,
              },
              reportsCountStatus: {
                total: 1,
                [REPORT_TYPES.ACCIDENT]: 1,
                [REPORT_TYPES.ILLNESS]: 1,
                [REPORT_TYPES.MINOR_INJURY]: 1,
              },
            },
            geometry: {
              coordinates: [site.areaCoordinates],
              type: "Polygon",
            },
            id: `${site.domain}/${site.name}-outline`,
          }) as GeoJSON.Feature<GeoJSON.Polygon>,
      ),
);
