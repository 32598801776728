import * as yup from "yup";
import { BaseMinorInjurySection, baseMinorInjurySchema } from "..";
import type { WithFormMethods } from "../../../../../components/Forms";
import { useAppSelector } from "../../../../../store";
import { selectAllStudentInjuryTags } from "../../../store/injuryReportsSlice";
import { NewFormAutocomplete } from "../../../../../components/FormInputs/NewFormAutocomplete";
import { GENERIC_VALIDATION_MSG } from "../../../constants/mappedInputLabels";
import { useTranslation } from "react-i18next";
import { getTranslationKeyForSectionFieldLabel } from "../../../helpers/dataMapping";
import { STUDENT_SECTION_NAMES } from "../../../constants/student";

export const studentMinorInjurySchema = baseMinorInjurySchema.shape({
  typesOfInjury: yup.array().min(1, GENERIC_VALIDATION_MSG).required(),
});

export type StudentMinorInjurySection = yup.InferType<
  typeof studentMinorInjurySchema
>;

export const StudentMinorInjuryControls = (
  props: WithFormMethods<StudentMinorInjurySection>,
) => {
  const { methods } = props;
  const { control, watch } = methods;
  const { t } = useTranslation();

  const tags = useAppSelector(selectAllStudentInjuryTags);

  return (
    <>
      <BaseMinorInjurySection methods={methods} />
      <NewFormAutocomplete
        control={control}
        name="typesOfInjury"
        label={t(
          getTranslationKeyForSectionFieldLabel(
            STUDENT_SECTION_NAMES.MINOR_INJURY,
            "typesOfInjury",
          ),
        )}
        options={tags.slice().map((tag) => ({ id: tag.id, name: tag.name }))}
      />
    </>
  );
};
