import React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { AppIcon } from "../../../../components/Elements";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";

type LocationsPanelToggleButtonProps = {
  selected?: boolean;
  iconName: string;
  label?: string;
  onClick?: () => void;
};

const LocationsPanelToggleButton = (props: LocationsPanelToggleButtonProps) => {
  const { selected, iconName, label = "Label", onClick } = props;

  return (
    <Box
      sx={{
        height: "44px",
        overflow: "hidden",
        mb: "-4px",
      }}
    >
      <ListItemButton
        onClick={onClick}
        selected={selected}
        sx={{
          ...(!selected
            ? {
                width: "40px",
                height: "40px!important",
                pb: 0,
              }
            : {
                pl: 1.5,
                pr: 0.75,
                height: "48px!important",
              }),
          borderRadius: 1,

          minWidth: 0,
          display: "flex",
          justifyContent: "center",
          pt: 0,
          mr: 1,
          "&.Mui-selected": {
            bgcolor: "background.default",
            "&:hover": {
              bgcolor: "background.default",
              opacity: 1,
            },
          },
        }}
      >
        <AppIcon
          iconName={iconName}
          color={selected ? "primary.main" : "text.secondary"}
          opticalSize={22}
        />
        {selected && (
          <React.Fragment>
            <Typography
              variant="body2"
              sx={{ ml: 1, mr: 1, color: "text.primary" }}
            >
              {label}
            </Typography>
            <IconButton edge="end" size="small">
              <AppIcon iconName="close" opticalSize={18} />
            </IconButton>
          </React.Fragment>
        )}
      </ListItemButton>
    </Box>
  );
};

type LocationsPanelToggleGroupProps = {
  value?: string;
  onItemClick?: (value: string) => void;
};

export const LocationsPanelToggleGroup = (
  props: LocationsPanelToggleGroupProps,
) => {
  const { value, onItemClick } = props;

  const handleItemClick = (value: string) => {
    if (onItemClick) {
      onItemClick(value);
    }
  };

  return (
    <List
      sx={{
        pt: 1,
        ...(value
          ? {
              pb: 0,
            }
          : {
              pb: 1,
            }),
        pl: 1,
        minWidth: 0,
        display: "flex",
        flexDirection: "row",
      }}
    >
      <LocationsPanelToggleButton
        selected={value === "baseLocations"}
        iconName="domain"
        label="Base locations"
        onClick={() => handleItemClick("baseLocations")}
      />
      <LocationsPanelToggleButton
        selected={value === "sites"}
        iconName="pin_drop"
        label="Sites"
        onClick={() => handleItemClick("sites")}
      />
    </List>
  );
};
