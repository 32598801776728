import {
  GridActionsCellItem,
  type GridColDef,
  type GridColumnVisibilityModel,
  type GridEventListener,
} from "@mui/x-data-grid";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { RDataGrid } from "../../../../components/DataGrid/RDataGrid";
import type {
  IActiveFilter,
  IFilterControl,
} from "../../../../components/DataGrid/types";
import {
  areaClicked,
  selectAllAreas,
  selectAllSites,
  selectSites,
} from "../../../locations/locationsSlice";
import { RenderCopyCell } from "../../../injuries/components/InjuryReportsDataGrid/RenderCell";
import { useTranslation } from "react-i18next";
import { formatDateFromTimestamp } from "../../../../utils/format";
import { AppIcon } from "../../../../components/Elements";

const RenderSiteCell = (params: any) => {
  const sites = useAppSelector(selectSites);
  return <>{sites[params.value].name}</>;
};

export type UnsavedInjuryReportsDataGridFilter = {
  field: any;
  value?: string;
};

export type InjuryReportsFilter = {
  field: any;
  getFieldValue?: (value: any) => any;
  operator?: string; // default to '==' operator
  value: string;
};

export const AreasDataGrid = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const sites = useAppSelector(selectAllSites);
  const areas = useAppSelector(selectAllAreas);
  const siteEntities = useAppSelector(selectSites);

  const controls = React.useMemo(() => {
    const controlItems: IFilterControl[] = [
      {
        field: "variant",
        label: "Variant",
        paths: ["variant"],
        operator: "is",
        options: [
          { value: "Area", label: "Area" },
          { value: "Point", label: "Point" },
        ],
      },
      {
        field: "site",
        label: "Site",
        paths: ["site"],
        operator: "is",
        options: sites.map((site) => ({ value: site.name, label: site.name })),
      },
    ];

    return controlItems;
  }, [sites, areas]);

  const [activeFilters, setActiveFilters] = React.useState<any[]>([]);

  const handleClearFilters = () => {
    setActiveFilters([]);
  };

  const handleRemoveFilter = (field: string) => {
    setActiveFilters((prevFilters) => {
      let newFilters = [...prevFilters];
      newFilters = newFilters.filter((filter) => filter.field !== field);
      return newFilters;
    });
  };
  const handleSetFilter = (newFilter: IActiveFilter) => {
    setActiveFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      const filterIndex = newFilters.findIndex(
        (filter) => filter.field === newFilter.field,
      );
      if (filterIndex > -1) {
        newFilters[filterIndex] = newFilter;
      } else {
        newFilters.push(newFilter);
      }
      return newFilters;
    });
  };
  const items = React.useMemo(() => {
    return areas.map((area) => ({
      id: area.area_id,
      name: area.name,
      coordinates: `${area.coordinates.lat}, ${area.coordinates.lng}`,
      variant: area.outline_coordinates ? "Area" : "Point",
      site: siteEntities[area.site_id]?.name || area.site_id,
      created_at: new Date(area.created_at),
    }));
  }, [areas, sites]);

  const columns = React.useMemo(() => {
    const columnByField: { [field: string]: GridColDef } = {
      id: {
        field: "id",
        headerName: "ID",
        width: 120,
        renderCell: (params) => RenderCopyCell(params),
      },
      site: {
        field: "site",
        headerName: "Site",
        minWidth: 180,
        flex: 1.75,
      },
      created_at: {
        field: "created_at",
        headerName: t("wif.injury.createdAt"),
        minWidth: 160,
        flex: 1.5,
        type: "dateTime",
        valueFormatter: ({ value }) =>
          value ? formatDateFromTimestamp(value.getTime()) : null,
      },
      name: {
        field: "name",
        headerName: "Name",
        minWidth: 200,
        flex: 2,
      },
      coordinates: {
        field: "coordinates",
        headerName: "Coordinates",
        minWidth: 200,
        flex: 2,
      },
      variant: {
        field: "variant",
        headerName: "Variant",
        minWidth: 160,
        flex: 1,
      },
      actions: {
        field: "actions",
        type: "actions",
        width: 120,

        align: "right",
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<AppIcon iconName="more_vert" color="text.secondary" />}
              label=""
              sx={{ color: "text.secondary", mr: "-4px" }}
              onClick={() => {}}
            />,
          ];
        },
      },
    };

    const cols = ["id", "name", "created_at", "variant", "site", "actions"];

    return cols.map((field) => columnByField[field]);
  }, []);

  const initialColumnVisibilityModel: GridColumnVisibilityModel = {
    id: false,
  };

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    dispatch(areaClicked(params.row.id));
  };

  return (
    <RDataGrid
      items={items}
      cols={columns}
      onRowClick={handleRowClick}
      filters={{
        controls: controls,
        addedControls: [],
        active: activeFilters,
        handleSetFilter,
        handleRemoveFilter,
        handleResetFilterControls: handleClearFilters,
      }}
      initialColumnVisibilityModel={initialColumnVisibilityModel}
    />
  );
};
