import { useRef } from "react";
import MapGL, { Layer, Source } from "react-map-gl";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  areaClicked,
  selectAllAreas,
  selectCurrentAreaId,
  selectCurrentSiteId,
  selectInitialCoordinates,
  selectSites,
} from "../../locationsSlice";
import { MAPBOX_TOKEN, paletteMapStyle } from "../../../sites/components/Map";
import React from "react";
import { alpha } from "@mui/material/styles";
import {
  baseClusterCountLayer,
  baseClusterLayer,
} from "../../../sites/components/CreateAreaMap/siteLayers";
import { APP_COLORS } from "../../../../config";
import { useTheme } from "@mui/material";

const mapStyles: any = {
  width: "100%",
  flex: 1,
  minHeight: 0,
};

export default function AreasTabMap() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const mapRef = useRef<any>(null);

  const initialCoordinates = useAppSelector(selectInitialCoordinates);
  const currentSiteId = useAppSelector(selectCurrentSiteId);
  const sites = useAppSelector(selectSites);

  const initCoordinates = React.useMemo(() => {
    if (currentSiteId) {
      if (sites[currentSiteId]) {
        return sites[currentSiteId].coordinates;
      }
    }
    return initialCoordinates;
  }, []);

  const areas = useAppSelector(selectAllAreas);
  const currentAreaId = useAppSelector(selectCurrentAreaId);

  const areaFeatures = React.useMemo(() => {
    return areas.map(
      (area) =>
        ({
          type: "Feature",
          properties: {
            id: `${area.area_id}`,
            name: area.name,
            iconImage: area.outline_coordinates
              ? ""
              : currentAreaId === area.area_id
                ? "pin-4"
                : "pin-3",
            showIcon: area.outline_coordinates ? 0 : 1,
            current: currentAreaId === area.area_id ? 1 : 0,
          },
          geometry: {
            type: "Point",
            coordinates: [area.coordinates.lng, area.coordinates.lat],
          },
          id: `${area.site_id}/${area.name}`,
        }) as GeoJSON.Feature<GeoJSON.Point>,
    );
  }, [areas, currentAreaId]);

  const areaBoxFeatures = React.useMemo(() => {
    return areas
      .filter((area) => area.outline_coordinates)
      .map(
        (area) =>
          ({
            type: "Feature",
            properties: {
              id: `${area.area_id}-area`,
              siteId: `${area.site_id}/${area.name}`,
              current: currentAreaId === area.area_id ? 1 : 0,
            },
            geometry: {
              type: "Polygon",
              coordinates: [area.outline_coordinates],
            },
            id: `${area.site_id}/${area.name}-area`,
          }) as GeoJSON.Feature<GeoJSON.Polygon>,
      );
  }, [areas, currentAreaId]);

  const areaFeatureCollection = React.useMemo(
    () =>
      ({
        type: "FeatureCollection",
        features: [...areaFeatures],
      }) as GeoJSON.FeatureCollection<GeoJSON.Point>,
    [areaFeatures],
  );

  const areaBoxFeatureCollection = React.useMemo(
    () =>
      ({
        type: "FeatureCollection",
        features: [...areaBoxFeatures],
      }) as GeoJSON.FeatureCollection<GeoJSON.Polygon>,
    [areaBoxFeatures],
  );

  React.useEffect(() => {
    if (currentSiteId) {
      const site = sites[currentSiteId];
      if (site) {
        mapRef.current?.flyTo({
          center: [site.coordinates.lng, site.coordinates.lat],
          zoom: 16,
          duration: 2000,
        });
      }
    }
  }, [currentSiteId]);

  const onClick = (event: any) => {
    const feature: any = event.features?.[0];
    if (feature) {
      dispatch(areaClicked(feature.properties.id));
    }
  };

  const outlineForPalette = React.useMemo(() => {
    if (theme.palette.mode === "light") {
      return APP_COLORS.darkTextPrimary;
    }
    return APP_COLORS.textPrimary;
  }, [theme.palette.mode]);

  return (
    <MapGL
      initialViewState={{
        latitude: initCoordinates.lat,
        longitude: initCoordinates.lng,
        zoom: 16,
      }}
      style={mapStyles}
      maxPitch={85}
      mapStyle={paletteMapStyle[theme.palette.mode as any]}
      mapboxAccessToken={MAPBOX_TOKEN}
      ref={mapRef}
      onClick={onClick}
      interactiveLayerIds={["main-areas"]}
    >
      <Source
        id="main"
        type="geojson"
        data={areaFeatureCollection}
        cluster={true}
      >
        <Layer
          id="main-areas"
          type="symbol"
          layout={{
            "icon-size": ["case", ["==", ["get", "current"], 1], 0.8, 0.6],
            "icon-allow-overlap": true,
            "text-allow-overlap": true,
            "text-field": ["get", "name"],
            "text-font": [
              "case",
              ["==", ["get", "current"], 1],
              ["literal", ["Inter Tight Bold"]],
              ["literal", ["Inter Tight Medium"]],
            ],
            "text-max-width": 15,
            "text-size": ["case", ["==", ["get", "current"], 1], 14, 13],
            "icon-image": ["get", "iconImage"],
            "text-offset": [
              "case",
              ["==", ["get", "showIcon"], 0],
              ["literal", [0, 0]],
              ["literal", [0, 1.7]],
            ],
          }}
          paint={{
            "text-color": [
              "case",
              ["==", ["get", "current"], 1],
              theme.palette.primary.main,
              theme.palette.text.primary,
            ],
            "text-halo-color": outlineForPalette,
            "text-halo-width": 1,
          }}
        />
        <Layer {...baseClusterLayer} />
        <Layer {...baseClusterCountLayer} />
      </Source>
      <Source id="area-box" type="geojson" data={areaBoxFeatureCollection}>
        <Layer
          id="area-box-fill"
          beforeId="main-areas"
          type="fill"
          paint={{
            "fill-color": [
              "case",
              ["==", ["get", "current"], 1],
              alpha(theme.palette.primary.main, 0.16),
              alpha(theme.palette.warning.main, 0.12),
            ],
          }}
        />
        <Layer
          id="area-box-outline"
          beforeId="area-box-fill"
          type="line"
          paint={{
            "line-color": [
              "case",
              ["==", ["get", "current"], 1],
              theme.palette.primary.main,
              theme.palette.warning.main,
            ],
            "line-width": ["case", ["==", ["get", "current"], 1], 4, 3],
          }}
        />
      </Source>
    </MapGL>
  );
}
