import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useState, useEffect, useMemo } from "react";
import { useAppSelector } from "../../../../store";
import {
  selectAllEmployees,
  selectEmployees,
} from "../../../employees/employeesSlice";
import { AppDialogTitle } from "../../../../components/Display/AppDialogTitle";
import { SIKRI_COLORS } from "../../../../config";
import { LoadingButtonContainer } from "../../../../components/Elements/LoadingButtonContainer";
import { t } from "i18next";
import { TKB_INJURY } from "../../constants";

interface InjuryUserSelectDialogProps {
  open?: boolean;
  injuryReportId?: string;
  onClose?: () => void;
  onCheckboxChange?: (checkboxValues: any[]) => void;
}

export default function InjuryUserSelectDialog(
  props: InjuryUserSelectDialogProps,
) {
  const { onClose, onCheckboxChange, open = false } = props;
  const users = useAppSelector(selectAllEmployees);
  const usersDict = useAppSelector(selectEmployees);
  const [page, setPage] = useState(1);
  const itemsPerPage = 9;
  let startIndex = (page - 1) * itemsPerPage;
  let endIndex = startIndex + itemsPerPage;
  const [searchItem, setSearchItem] = useState("");
  const [paginationFlag, setPaginationFlag] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState(users);

  const initialCheckboxesState = useMemo(() => {
    users.slice(startIndex, endIndex);
  }, [users, startIndex, endIndex]);

  const [checkboxes, setCheckboxes] = useState({});
  const [initialCheckboxes, setInitialCheckboxes] = useState({});
  const [initialCheckboxValues, setInitialCheckboxeValues] = useState([{}]);

  const [usersToDisplay, setUsersToDisplay] = useState(() => {
    return users.slice(startIndex, endIndex);
  });

  useEffect(() => {
    if (open) {
      setUsersToDisplay(users.slice(startIndex, endIndex));
      setCheckboxes(checkboxes);
      setInitialCheckboxes(checkboxes);
      setInitialCheckboxeValues(checkboxValues);
    }
  }, [open, initialCheckboxesState]);

  useEffect(() => {
    endIndex = startIndex + itemsPerPage;
    setUsersToDisplay(users.slice(startIndex, endIndex));
  }, [users, page]);

  const handleCheckboxChange = (event: {
    target: { name: any; checked: any };
  }) => {
    const { name, checked } = event.target;
    const selectedUser = usersDict[name];

    setCheckboxes({
      ...checkboxes,
      [name]: checked,
    });
  };

  let checkboxValues = Object.keys(checkboxes)
    .filter((name) => checkboxes[name])
    .map((name) => ({
      id: name,
      username: usersDict[name]?.name,
    }));

  const onPageChange = (value: number) => {
    setPage(value);
    startIndex = (value - 1) * itemsPerPage;
  };

  const handleSearchInputChange = (e: { target: { value: string } }) => {
    const searchedItem = e.target.value;
    setSearchItem(searchedItem);
  };

  useEffect(() => {
    if (searchItem !== "") setPaginationFlag(false);
    else setPaginationFlag(true);
    const filteredItems = users.filter((user: { email: string }) =>
      user.email?.toLowerCase().includes(searchItem.toLowerCase()),
    );

    setFilteredUsers(filteredItems);
    setUsersToDisplay(
      searchItem ? filteredItems : users.slice(startIndex, endIndex),
    );
  }, [searchItem]);

  const handleClose = () => {
    setPage(1);
    onCheckboxChange(checkboxValues);
    setSearchItem("");
    onClose?.();
  };
  const handleCancel = () => {
    setPage(1);
    setCheckboxes(initialCheckboxes);
    setSearchItem("");
    checkboxValues = [];
    onCheckboxChange(initialCheckboxValues);
    onClose?.();
  };

  if (Object.keys(users).length !== 0) {
    return (
      <Stack direction="column" gap={2} spacing={2}>
        <Dialog open={open} onClose={handleClose}>
          <AppDialogTitle id="close-report-dialog-title" onClose={handleClose}>
            {t(`${TKB_INJURY}.selectUsers`)}
          </AppDialogTitle>
          <DialogContent
            dividers
            sx={{
              overflowY: "auto",
              height: "90vh",
              width: "80vh",
            }}
          >
            <Box
              sx={{
                marginY: 1,
                fontSize: 10,
              }}
            >
              <TextField
                sx={{
                  fontSize: 10,
                  width: "100%",
                }}
                type="text"
                value={searchItem}
                onChange={handleSearchInputChange}
                placeholder="Search by email"
              />
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={6}
                  md={7}
                  lg={7}
                  sx={{
                    overflowY: "auto",
                  }}
                >
                  <FormGroup>
                    {usersToDisplay.map((user) => (
                      <FormControlLabel
                        key={user.email}
                        control={
                          <Checkbox
                            name={user.id}
                            checked={checkboxes[user.id] || false}
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={
                          <Typography sx={{ fontSize: 12 }}>
                            {`${user.email} (${user.name})`}
                          </Typography>
                        }
                      />
                    ))}
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={5}
                  lg={5}
                  sx={{
                    overflowY: "auto",
                    maxHeight: "60vh",
                  }}
                >
                  {checkboxValues.map((item) => (
                    <Box
                      key={item.id}
                      sx={{
                        overflowY: "auto",
                        width: "max-content",
                        display: "flex",
                        flexDirection: "column",
                        borderRadius: 0.2,
                        m: 1,
                        px: 1,
                        py: 0.5,
                        backgroundColor: SIKRI_COLORS.lightGray,
                      }}
                    >
                      {item.username}
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Box>
            {paginationFlag ? (
              <Pagination
                count={Math.ceil(users.length / itemsPerPage)}
                page={page}
                onChange={(event, value) => onPageChange(value)}
                variant="outlined"
                shape="rounded"
                size="medium"
              />
            ) : null}
          </DialogContent>
          <Stack
            direction="row"
            justifyContent="end"
            alignItems="end"
            gap={2}
            width="100%"
          >
            <Box>
              <LoadingButtonContainer
                sx={{
                  px: ".2",
                }}
                loading={false}
              >
                <Button
                  sx={{
                    mx: 0.5,
                    p: 0.2,
                  }}
                  type="reset"
                  variant="contained"
                  onClick={handleCancel}
                >
                  {t(`${TKB_INJURY}.cancel`)}
                </Button>

                <Button
                  sx={{
                    mx: 0.5,
                    p: 0.2,
                  }}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                >
                  {t(`${TKB_INJURY}.save`)}
                </Button>
              </LoadingButtonContainer>
            </Box>
          </Stack>
        </Dialog>
      </Stack>
    );
  }
  return (
    <Backdrop
      open={open}
      onClick={handleClose}
      sx={{
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    </Backdrop>
  );
}
