import { Button, DialogContent } from "@mui/material";
import { useCurrentArea } from "../../locationsHooks";
import { StyledDetailsCardContent } from "../../../../components/DetailsCard/DetailsCard";
import {
  DescriptionList,
  DescriptionListItem,
} from "../../../../components/DescriptionList/DescriptionsGrid";
import { AppInfoSection } from "../../../../components/AppInfoSection/AppInfoSection";
import React from "react";
import { EditSitePropertiesForm } from "../Forms/EditSitePropertiesForm";
import { AppActions } from "../../../../components/AppActions/AppActions";
import { useTranslation } from "react-i18next";
import AppDialog from "../../../../components/AppDialog/AppDialog";
import { AppDialogTitle } from "../../../../components/Display/AppDialogTitle";
import { useAppSelector } from "../../../../store";
import { selectSites } from "../../locationsSlice";
import SingleAreaMap from "./SingleAreaMap";
import { UpdateAreaLocationForm } from "../Forms/UpdateAreaLocationForm";
import { EditAreaPropertiesForm } from "../Forms/UpdateAreaPropertiesForm";

export const AreaDetailsInfoPanel = () => {
  const { t } = useTranslation();

  const area = useCurrentArea();
  const sites = useAppSelector(selectSites);

  const [activeDialogName, setActiveDialogName] = React.useState<
    string | undefined
  >();

  const handleCloseActiveDialog = () => {
    setActiveDialogName(undefined);
  };

  const [editingSection, setEditingSection] = React.useState<
    string | undefined
  >();

  const stopEditing = () => {
    setEditingSection(undefined);
  };

  const getEditForSectionName = (sectionName: string) => {
    return {
      active: editingSection === sectionName,
      onClick: () => setActiveDialogName(sectionName),
      hidden: editingSection && editingSection !== sectionName,
    };
  };

  const site = React.useMemo(() => {
    return sites[area?.site_id];
  }, [area, sites]);

  return (
    <StyledDetailsCardContent>
      <AppInfoSection
        title={t("wif.injury.common.properties")}
        moreMenuItems={[{ name: "Delete area", onClick: () => {} }]}
        edit={getEditForSectionName("properties")}
      >
        {editingSection === "properties" ? (
          <>
            <EditSitePropertiesForm stopEditing={stopEditing} />
            <AppActions>
              <Button color="plain" variant="contained" onClick={stopEditing}>
                {t("wif.injury.cancel")}
              </Button>
            </AppActions>
          </>
        ) : (
          <DescriptionList>
            <DescriptionListItem
              size="small"
              field={"Site"}
              value={site?.name}
            />
            <DescriptionListItem
              size="small"
              field={"Area name"}
              value={area?.name}
            />
            <DescriptionListItem
              size="small"
              field={"Area description"}
              value={area?.description || "No description"}
              disabledOrUndefined={!area?.description}
            />
          </DescriptionList>
        )}
      </AppInfoSection>
      <AppInfoSection
        title={t("wif.injury.common.location")}
        moreMenuItems={[{ name: "Edit Location" }]}
        edit={getEditForSectionName("location")}
      >
        <DescriptionList sx={{ mb: 0.75 }}>
          <DescriptionListItem
            size="small"
            field={t("wif.injury.models.site.fields.address.title")}
            value={site?.address}
          />
          <DescriptionListItem
            size="small"
            field={t("wif.injury.models.site.fields.coordinates.title")}
            value={`${site.coordinates?.lat}, ${site.coordinates?.lng}`}
          />
        </DescriptionList>
        <SingleAreaMap area={area} />
      </AppInfoSection>
      <AppDialog
        open={activeDialogName === "properties"}
        onClose={handleCloseActiveDialog}
        actions={
          <Button color="primary" type="submit" form="update-area-details-form">
            OK
          </Button>
        }
      >
        <AppDialogTitle onClose={handleCloseActiveDialog}>
          Edit properties
        </AppDialogTitle>
        <DialogContent
          sx={{
            width: 600,
          }}
        >
          <EditAreaPropertiesForm stopEditing={handleCloseActiveDialog} />
        </DialogContent>
      </AppDialog>
      <AppDialog
        open={activeDialogName === "location"}
        onClose={handleCloseActiveDialog}
        actions={
          <Button
            color="primary"
            type="submit"
            form="update-area-location-form"
          >
            OK
          </Button>
        }
      >
        <AppDialogTitle onClose={handleCloseActiveDialog}>
          {t("wif.injury.editLocationDialog.title")}
        </AppDialogTitle>
        <DialogContent
          sx={{
            width: 600,
            pt: 2,
          }}
        >
          <UpdateAreaLocationForm stopEditing={handleCloseActiveDialog} />
        </DialogContent>
      </AppDialog>
    </StyledDetailsCardContent>
  );
};
