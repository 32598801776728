import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/material";

export const AppLogItemSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1.5,
        width: "100%",
        py: 1.5,
        px: 2.5,
      }}
    >
      <Skeleton variant="circular" width={32} height={32} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          flexGrow: 1,
        }}
      >
        <Skeleton variant="text" width="100%" height={16} />
        <Skeleton variant="text" width="100%" height={16} />
      </Box>
    </Box>
  );
};
