import {
  GridActionsCellItem,
  type GridColDef,
  type GridEventListener,
} from "@mui/x-data-grid";
import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../store";

import { Stack } from "@mui/material";
import { RDataGrid } from "../../../../components/DataGrid/RDataGrid";
import type {
  IActiveFilter,
  IFilterControl,
} from "../../../../components/DataGrid/types";
import { AppIcon } from "../../../../components/Elements";
import { RenderCopyCell } from "../../../injuries/components/InjuryReportsDataGrid/RenderCell";
import {
  selectAllAreas,
  selectAllSites,
  siteClicked,
} from "../../../locations/locationsSlice";
import { selectDepartmentEntities } from "../../../departments/departmentsSlice";
import { useTranslation } from "react-i18next";
import { TKB_INJURY } from "../../../injuries/constants";

const RenderSiteTypeCell = (params: any) => {
  const { t } = useTranslation();
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={0.75}
      sx={{
        color: "text.primary",
      }}
    >
      {t(`wif.injury.locationTypes.${params.value}`)}
    </Stack>
  );
};

export type UnsavedInjuryReportsDataGridFilter = {
  field: any;
  value?: string;
};

export type InjuryReportsFilter = {
  field: any;
  getFieldValue?: (value: any) => any;
  operator?: string; // default to '==' operator
  value: string;
};

export const SitesDataGrid = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const sites = useAppSelector(selectAllSites);
  const areas = useAppSelector(selectAllAreas);
  const departments = useAppSelector(selectDepartmentEntities);

  const controls = React.useMemo(() => {
    const controlItems: IFilterControl[] = [
      {
        field: "type",
        label: "Type",
        paths: ["type"],
        operator: "is",
        options: [
          { value: "school", label: "School" },
          { value: "office", label: "Office" },
        ],
      },
    ];
    const departmentIds = sites
      .filter((site) => site?.department_id)
      .map((site) => site?.department_id);
    if (departmentIds.length > 0) {
      controlItems.push({
        field: "department",
        paths: ["department"],
        operator: "is",
        label: t(`${TKB_INJURY}.department`),
        options: departmentIds.map((departmentId) => ({
          value: departments?.[departmentId]?.name || departmentId,
          label: departments?.[departmentId]?.name || departmentId,
        })),
      });
    }
    return controlItems;
  }, [sites, areas, departments]);

  const [activeFilters, setActiveFilters] = React.useState<any[]>([]);

  const handleClearFilters = () => {
    setActiveFilters([]);
  };

  const handleRemoveFilter = (field: string) => {
    setActiveFilters((prevFilters) => {
      let newFilters = [...prevFilters];
      newFilters = newFilters.filter((filter) => filter.field !== field);
      return newFilters;
    });
  };
  const handleSetFilter = (newFilter: IActiveFilter) => {
    setActiveFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      const filterIndex = newFilters.findIndex(
        (filter) => filter.field === newFilter.field,
      );
      if (filterIndex > -1) {
        newFilters[filterIndex] = newFilter;
      } else {
        newFilters.push(newFilter);
      }
      return newFilters;
    });
  };

  const items = React.useMemo(() => {
    return sites.map((site) => {
      const departmentName = site?.department_id
        ? departments?.[site?.department_id]?.name
        : "";
      return {
        id: site.site_id,
        name: site.name,
        coordinates: `${site.coordinates.lat}, ${site.coordinates.lng}`,
        department: departmentName || "-",
        type: site.type,
        address: site.address,
        areasCount: areas.filter((area) => area.site_id === site.site_id)
          .length,
      };
    });
  }, [areas, sites, departments]);

  const columns = React.useMemo(() => {
    const columnByField: { [field: string]: GridColDef } = {
      id: {
        field: "id",
        headerName: "ID",
        width: 120,
        renderCell: (params) => RenderCopyCell(params),
      },
      name: {
        field: "name",
        headerName: "Name",
        minWidth: 180,
        flex: 2,
      },
      department: {
        field: "department",
        headerName: "Department",
        minWidth: 180,
        flex: 2,
      },
      type: {
        field: "type",
        headerName: "Type",
        minWidth: 120,
        flex: 1,
        renderCell: (params) => RenderSiteTypeCell(params),
      },
      address: {
        field: "address",
        headerName: "Address",
        minWidth: 200,
        flex: 2.5,
      },
      areasCount: {
        field: "areasCount",
        headerName: "Areas",
        type: "number",
        minWidth: 100,
        flex: 1,
      },
      actions: {
        field: "actions",
        type: "actions",
        width: 120,

        align: "right",
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<AppIcon iconName="more_vert" color="text.secondary" />}
              label=""
              sx={{ color: "text.secondary", mr: "-4px" }}
              onClick={() => {}}
            />,
          ];
        },
      },
    };

    const cols = [
      "id",
      "name",
      "type",
      "department",
      "address",
      "areasCount",
      "actions",
    ];
    return cols.map((field) => columnByField[field]);
  }, []);

  const initialColumnVisibilityModel: any = {
    id: false,
  };

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    dispatch(siteClicked(params.row.id));
  };

  return (
    <RDataGrid
      items={items}
      cols={columns}
      onRowClick={handleRowClick}
      filters={{
        controls: controls,
        addedControls: [],
        active: activeFilters,
        handleSetFilter,
        handleRemoveFilter,
        handleResetFilterControls: handleClearFilters,
      }}
      initialColumnVisibilityModel={initialColumnVisibilityModel}
    />
  );
};
