import Cookies from "js-cookie";
import { Auth } from "aws-amplify";
import { CONSTANTS } from "../common/constants";
import config from "../config/config";

const KEY_PREFIX = "Samsvar:";
type SameSiteValue = "strict" | "Strict" | "lax" | "Lax" | "none" | "None";

/** @class */
export default class CookieStorage {
  static setItem(key: string, value: string): void {
    const tokenType = key.split(".").pop();
    const sameSiteValue: SameSiteValue = "strict";

    const expirationInHours = 24;
    const expirationDate = new Date();
    expirationDate.setTime(
      expirationDate.getTime() + expirationInHours * 60 * 60 * 1000,
    );

    const options = {
      expires: expirationDate,
      secure: true,
      sameSite: sameSiteValue,
      domain: config.baseDomain,
    };

    Cookies.set(KEY_PREFIX + key, value, options);

    if (tokenType === "idToken") {
      Auth.currentSession().then((res) => {
        const idToken = res.getIdToken();
        const clientIdKey = KEY_PREFIX + CONSTANTS.COOKIE_KEYS.CLIENT_ID;
        const expiresInKey = KEY_PREFIX + CONSTANTS.COOKIE_KEYS.EXPIRES_IN;
        Cookies.set(clientIdKey, idToken.payload.aud, options);
        Cookies.set(
          expiresInKey,
          (idToken.getExpiration() - 60).toString(),
          options,
        );
      });
    }
  }

  static getItem(key: string): string | undefined {
    return Cookies.get(KEY_PREFIX + key);
  }

  static removeItem(key: string): void {
    const sameSiteValue: SameSiteValue = "strict";

    const options = {
      secure: true,
      sameSite: sameSiteValue,
      domain: config.baseDomain,
    };
    Cookies.remove(KEY_PREFIX + key, options);
    const tokenType = key.split(".").pop();
    if (tokenType === "idToken") {
      Cookies.remove(KEY_PREFIX + CONSTANTS.COOKIE_KEYS.CLIENT_ID, options);
      Cookies.remove(KEY_PREFIX + CONSTANTS.COOKIE_KEYS.EXPIRES_IN, options);
      Cookies.remove(KEY_PREFIX + CONSTANTS.COOKIE_KEYS.SELECTED_ORG, options);
    }
  }

  static clear(): void {
    // Clearing cookies in the browser
    const cookies = Object.keys(Cookies.get());
    const sameSiteValue: SameSiteValue = "strict";

    const options = {
      secure: true,
      sameSite: sameSiteValue,
      domain: config.baseDomain,
    };
    cookies.forEach((cookie) => {
      if (
        cookie !== KEY_PREFIX + CONSTANTS.COOKIE_KEYS.SUBDOMAIN &&
        cookie !== KEY_PREFIX + CONSTANTS.COOKIE_KEYS.ORG_LANGUAGE
      )
        Cookies.remove(cookie, options);
    });
  }

  static sync(): Promise<void> {
    return Promise.resolve();
  }
}
