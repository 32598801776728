import { useRef } from "react";
import MapGL, { Layer, Source } from "react-map-gl";
import React from "react";
import { alpha } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material";
import type { NewArea } from "../../locationsSlice";
import { APP_COLORS } from "../../../../config";
import { MAPBOX_TOKEN, paletteMapStyle } from "../../../sites/components/Map";

const mapStyles: any = {
  width: "100%",
  height: "100%",
  flex: 1,
  overflow: "hidden",
};

type SingleAreaMapProps = {
  area: NewArea;
};

export default function SingleAreaMap(props: SingleAreaMapProps) {
  const { area } = props;

  const theme = useTheme();
  const mapRef = useRef<any>(null);

  const pinFeatureCollection = React.useMemo(() => {
    const pin_features: any = [];
    if (area?.coordinates) {
      pin_features.push({
        type: "Feature",
        properties: {
          name: area?.name,
          showIcon: area?.outline_coordinates ? 0 : 1,
          iconImage: area?.outline_coordinates ? "" : "pin-4",
        },
        geometry: {
          type: "Point",
          coordinates: [area?.coordinates?.lng, area?.coordinates?.lat],
        },
        id: "pin-feature",
      });
    }
    return {
      type: "FeatureCollection",
      features: pin_features,
    } as GeoJSON.FeatureCollection<GeoJSON.Point>;
  }, [area]);

  const outlineFeatureCollection = React.useMemo(() => {
    const outline_features: GeoJSON.Feature<GeoJSON.Polygon>[] = [];

    if (area?.outline_coordinates) {
      outline_features.push({
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [area?.outline_coordinates],
        },
        id: "outline-feature",
      });
    }
    return {
      type: "FeatureCollection",
      features: outline_features,
    } as GeoJSON.FeatureCollection<GeoJSON.Polygon>;
  }, [area]);

  const outlineForPalette = React.useMemo(() => {
    if (theme.palette.mode === "light") {
      return APP_COLORS.darkTextPrimary;
    }
    return APP_COLORS.textPrimary;
  }, [theme.palette.mode]);

  React.useEffect(() => {
    if (area) {
      mapRef.current?.flyTo({
        center: [area.coordinates.lng, area.coordinates.lat],
        zoom: 16,
        duration: 2000,
      });
    }
  }, [area]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "160px",
        borderRadius: 1,
        overflow: "hidden",
      }}
    >
      <MapGL
        initialViewState={{
          latitude: area.coordinates.lat,
          longitude: area.coordinates.lng,
          zoom: 16,
        }}
        style={mapStyles}
        maxPitch={85}
        mapStyle={paletteMapStyle[theme.palette.mode as any]}
        mapboxAccessToken={MAPBOX_TOKEN}
        ref={mapRef}
      >
        <Source id="area-pin-source" type="geojson" data={pinFeatureCollection}>
          <Layer
            id="area-pin-layer"
            type="symbol"
            layout={{
              "icon-size": 0.8,
              "icon-allow-overlap": true,
              "text-allow-overlap": true,
              "text-field": ["get", "name"],
              "text-font": ["Inter Tight SemiBold"],
              "text-max-width": 15,
              "text-size": 13,
              "icon-image": ["get", "iconImage"],
              "text-offset": [
                "case",
                ["==", ["get", "showIcon"], 0],
                ["literal", [0, 0]],
                ["literal", [0, 1.7]],
              ],
            }}
            paint={{
              "text-color": theme.palette.primary.main,
              "text-halo-color": outlineForPalette,
              "text-halo-width": 1,
            }}
          />
        </Source>
        <Source
          id="area-outline-source"
          type="geojson"
          data={outlineFeatureCollection}
        >
          <Layer
            id="area-outline-fill-layer"
            beforeId="area-pin-layer"
            type="fill"
            paint={{
              "fill-color": alpha(theme.palette.primary.main, 0.16),
            }}
          />
          <Layer
            id="area-outline-border-layer"
            beforeId="area-outline-fill-layer"
            type="line"
            paint={{
              "line-color": theme.palette.primary.main,
              "line-width": 3,
            }}
          />
        </Source>
      </MapGL>
    </Box>
  );
}
