export const APP_LETTER_SPACING = {
  tightest: "-.05em",
  tighter: "-.025em",
  tight: "-.015em",
  normal: "0",
  wide: ".015em",
  wider: ".025em",
  widest: ".05em",
};

export const APP_LINE_HEIGHTS = {
  small: 1,
  smaller: 1.25,
  snug: 1.375,
  base: 1.5,
  larger: 1.625,
  large: 2,
};
