import { Button, DialogContent } from "@mui/material";
import { useCurrentSite } from "../../locationsHooks";
import { StyledDetailsCardContent } from "../../../../components/DetailsCard/DetailsCard";
import {
  DescriptionList,
  DescriptionListItem,
} from "../../../../components/DescriptionList/DescriptionsGrid";
import { AppInfoSection } from "../../../../components/AppInfoSection/AppInfoSection";
import SingleMarkerMap from "../../../../components/SingleMarkerMap/SingleMarkerMap";
import React from "react";
import { EditSitePropertiesForm } from "../Forms/EditSitePropertiesForm";
import { AppActions } from "../../../../components/AppActions/AppActions";
import { useTranslation } from "react-i18next";
import AppDialog from "../../../../components/AppDialog/AppDialog";
import { AppDialogTitle } from "../../../../components/Display/AppDialogTitle";
import { UpdateSiteLocationForm } from "../Forms/UpdateSiteLocationForm";
import { useAppSelector } from "../../../../store";
import { selectDepartmentEntities } from "../../../departments/departmentsSlice";

export const SiteDetailsInfoPanel = () => {
  const { t } = useTranslation();
  const site = useCurrentSite();
  const departments = useAppSelector(selectDepartmentEntities);

  const [activeDialogName, setActiveDialogName] = React.useState<
    string | undefined
  >();

  const handleCloseActiveDialog = () => {
    setActiveDialogName(undefined);
  };

  const [editingSection, setEditingSection] = React.useState<
    string | undefined
  >();

  const stopEditing = () => {
    setEditingSection(undefined);
  };
  const handleEditSection = (sectionName: string) => {
    if (editingSection === sectionName) {
      setEditingSection(undefined);
    } else {
      setEditingSection(sectionName);
    }
  };

  const getEditForSectionName = (sectionName: string) => {
    return {
      active: editingSection === sectionName,
      onClick: () => setActiveDialogName(sectionName),
      hidden: editingSection && editingSection !== sectionName,
    };
  };

  return (
    <StyledDetailsCardContent>
      <AppInfoSection
        title={t("wif.injury.common.properties")}
        edit={getEditForSectionName("properties")}
      >
        {editingSection === "properties" ? (
          <>
            <EditSitePropertiesForm stopEditing={stopEditing} />
            <AppActions>
              <Button color="plain" variant="contained" onClick={stopEditing}>
                {t("wif.injury.cancel")}
              </Button>
            </AppActions>
          </>
        ) : (
          <DescriptionList>
            <DescriptionListItem
              size="small"
              field={t("wif.injury.models.site.fields.name.title")}
              value={site?.name}
            />
            <DescriptionListItem
              size="small"
              field={t("wif.injury.models.site.fields.type.title")}
              value={`${site?.type?.charAt(0).toUpperCase()}${site?.type?.slice(
                1,
              )}`}
            />
            <DescriptionListItem
              size="small"
              field={t("wif.injury.department")}
              value={
                site?.department_id
                  ? departments[site.department_id]?.name
                  : "None"
              }
              disabledOrUndefined={!site?.department_id}
            />
            <DescriptionListItem
              size="small"
              field={t("wif.injury.models.site.fields.description.title")}
              value={site?.description || "No description"}
              disabledOrUndefined={!site?.description}
            />
          </DescriptionList>
        )}
      </AppInfoSection>
      <AppInfoSection
        title={t("wif.injury.common.location")}
        edit={getEditForSectionName("location")}
      >
        <DescriptionList sx={{ mb: 0.75 }}>
          <DescriptionListItem
            size="small"
            field={t("wif.injury.models.site.fields.address.title")}
            value={site?.address}
          />
          <DescriptionListItem
            size="small"
            field={t("wif.injury.models.site.fields.coordinates.title")}
            value={`${site.coordinates?.lat}, ${site.coordinates?.lng}`}
          />
        </DescriptionList>
        <SingleMarkerMap coordinates={site?.coordinates} />
      </AppInfoSection>
      <AppDialog
        open={activeDialogName === "properties"}
        onClose={handleCloseActiveDialog}
        actions={
          <Button color="primary" type="submit" form="update-site-details-form">
            OK
          </Button>
        }
      >
        <AppDialogTitle onClose={handleCloseActiveDialog}>
          Edit properties
        </AppDialogTitle>
        <DialogContent
          sx={{
            width: 600,
          }}
        >
          <EditSitePropertiesForm stopEditing={handleCloseActiveDialog} />
        </DialogContent>
      </AppDialog>
      <AppDialog
        open={activeDialogName === "location"}
        onClose={handleCloseActiveDialog}
        actions={
          <Button
            color="primary"
            type="submit"
            form="update-site-location-form"
          >
            OK
          </Button>
        }
      >
        <AppDialogTitle onClose={handleCloseActiveDialog}>
          {t("wif.injury.editLocationDialog.title")}
        </AppDialogTitle>
        <DialogContent
          sx={{
            width: 600,
            pt: 2,
          }}
        >
          <UpdateSiteLocationForm stopEditing={handleCloseActiveDialog} />
        </DialogContent>
      </AppDialog>
    </StyledDetailsCardContent>
  );
};
