import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  Paper,
  type TextFieldProps,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
} from "@mui/material";
import { TextField } from "@mui/material";
import React from "react";
import type { Control, FieldPath } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import type { FieldValues } from "react-hook-form/dist/types";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { SortableContainer } from "../Sortable/SortableContainer";
import { useTranslation } from "react-i18next";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextFieldProps & {
  control?: Control<TFieldValues, any>;
  name: TName;
  controllerProps?: any;
  options: string[];
  optionLabels?: any;
  getLabelFromOption?: (option: any) => string;
  label?: string;
  selectionLimit?: number;
  loading?: boolean;
};

export function FormAutocomplete<TFieldValues extends FieldValues>(
  props: Props<TFieldValues>,
) {
  const {
    control = useFormContext<TFieldValues>().control,
    name,
    controllerProps,
    options,
    optionLabels,
    getLabelFromOption,
    label,
    selectionLimit,
    loading = false,
    ...rest
  } = props;
  const { t } = useTranslation();

  const getTranslation = React.useCallback(
    (key: string) => {
      const keyWithValues = key.split(" ");
      const translationKey = keyWithValues[0];
      if (
        translationKey === "max" ||
        translationKey === "min" ||
        translationKey === "minmax"
      ) {
        return t(`wif.injury.errors.${translationKey}`, {
          i: keyWithValues[1],
        });
      } else if (translationKey === "between") {
        return t(`wif.injury.errors.${translationKey}`, {
          min: keyWithValues[1],
          max: keyWithValues[2],
        });
      }
      return t(`wif.injury.errors.${key}`);
    },
    [t],
  );

  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field: { value, ...field }, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          multiple
          disableCloseOnSelect
          limitTags={4}
          loading={loading}
          getOptionDisabled={
            selectionLimit
              ? (options) => (value ? value.length >= selectionLimit : false)
              : undefined
          }
          id="tags-standard"
          value={!loading && value ? value : []}
          onChange={(event, newValue) => {
            field.onChange(newValue);
          }}
          options={options}
          getOptionLabel={(option) =>
            optionLabels ? optionLabels[option] : option
          }
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 4, marginLeft: -8 }}
                checked={selected}
              />
              {optionLabels ? optionLabels[option] : option}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              required={!!error}
              error={!!error}
              helperText={error?.message ? getTranslation(error.message) : null}
              variant="outlined"
              {...rest}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
}

export function FormAutocompleteWithOrder<TFieldValues extends FieldValues>(
  props: Props<TFieldValues>,
) {
  const {
    control = useFormContext<TFieldValues>().control,
    name,
    controllerProps,
    options,
    label,
    getLabelFromOption,
    ...rest
  } = props;

  const [orderDialogOpen, setOrderDialogOpen] = React.useState(false);
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field: { value, ...field }, fieldState: { error } }) => (
        <React.Fragment>
          <Autocomplete
            {...field}
            multiple
            disableCloseOnSelect
            limitTags={4}
            id="tags-standard"
            value={value ? value : []}
            getOptionLabel={(option) =>
              getLabelFromOption ? getLabelFromOption(option) : option
            }
            onChange={(event, newValue) => {
              field.onChange(newValue);
            }}
            options={options}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 4, marginLeft: -8 }}
                  checked={selected}
                />
                {getLabelFromOption ? getLabelFromOption(option) : option}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                required={!!error}
                error={!!error}
                helperText={error?.message}
                variant="outlined"
                {...rest}
              />
            )}
          />
          {value && value.length > 1 && (
            <Box>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: -0.75, mb: 1.25 }}
                onClick={() => setOrderDialogOpen(true)}
              >
                {t("wif.injury.reorder")}
              </Button>
            </Box>
          )}
          <Dialog
            open={orderDialogOpen}
            onClose={() => setOrderDialogOpen(false)}
            PaperProps={{
              sx: {
                maxWidth: "464px",
              },
            }}
          >
            <DialogTitle>{`Reorder ${label?.toLowerCase()}`}</DialogTitle>
            <DialogContent sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                {t("wif.injury.reorderText")}
              </Typography>
              <Paper
                variant="outlined"
                sx={{
                  p: 1.5,
                  pb: 0.5,
                  maxHeight: "320px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {value && value.length > 1 ? (
                  <SortableContainer
                    items={value}
                    getLabelFromOption={getLabelFromOption}
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    key={value}
                  />
                ) : (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ pt: 0.5, pb: 1.5, px: 0.5 }}
                  >
                    Select more items to reorder
                  </Typography>
                )}
              </Paper>
            </DialogContent>
            <DialogActions sx={{ mt: -2 }}>
              <Button
                variant="text"
                color="primary"
                onClick={() => setOrderDialogOpen(false)}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      )}
    />
  );
}
