import {
  Avatar,
  AvatarGroup,
  Button,
  Chip,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import type { IAction } from "../actionTypes";
import { AppIcon } from "../../../components/Elements/AppIcon";
import { formatDateFromTimestamp } from "../../../utils/format";
import { AppIconButton } from "../../../components/Elements/AppIconButton";
import { type RootState, useAppSelector } from "../../../store";
import { selectEmployees } from "../../employees/employeesSlice";
import CompleteActionDialog from "./CompleteActionDialog";
import Menu from "@mui/material/Menu";
import ActionCommentsDialog from "./ActionCommentsDialog";
import ActionCompletionDialog from "./ActionCompletionDetailsDialog";
import DeleteActionDialog from "./DeleteActionDialog";
import ReopenActionDialog from "./ReopenActionDialog";
import { Link as RouterLink } from "react-router-dom";
import {
  makeSelectInjuryLogsByActionId,
  makeSelectInjuryReportById,
} from "../../injuries/store/injuryReportsSlice";
import { makeSelectActionById } from "../actionsSlice";
import { generateAvatarColor } from "../../../utils/color";
import { BOX_SHADOWS } from "../../../config/theme/boxShadows";
import { useTheme } from "@mui/material/styles";
import UpdateActionDialog from "./UpdateActionDialog";
import { useTranslation } from "react-i18next";
import { TKB_INJURY } from "../../injuries/constants";

type ActionProps = {
  action: IAction;
  linkToInjuryReport?: boolean;
};

const actionStatus = {
  pending: "pending",
  completed: "completed",
  draft: "draft",
};

export const Action = (props: ActionProps) => {
  const { action, linkToInjuryReport = false } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const selectInjuryReportById = React.useMemo(makeSelectInjuryReportById, []);
  const injuryReport = useAppSelector((state: RootState) =>
    selectInjuryReportById(state, action.report_id),
  );

  const selectActionById = React.useMemo(makeSelectActionById, []);

  const selectInjuryLogsByActionId = React.useMemo(
    makeSelectInjuryLogsByActionId,
    [],
  );
  const logItems = useAppSelector((state: RootState) =>
    selectInjuryLogsByActionId(state, action.action_id),
  );

  const users = useAppSelector(selectEmployees);
  const [openDialog, setOpenDialog] = React.useState<undefined | string>();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const menuOpen = Boolean(menuAnchorEl);

  const handleClickMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMore = () => {
    setMenuAnchorEl(null);
  };

  const label =
    action.status === "pending"
      ? "In progress"
      : action.status === "completed"
        ? "Completed"
        : "Draft";

  const members = React.useMemo(() => {
    if (!action?.members) return [];
    return action?.members.map((member) => {
      const user = users[member];
      return user ? user?.name : member;
    });
  }, [users, action?.members]);

  const deadlineStatus = React.useMemo(() => {
    const deadline = action?.deadline;
    if (deadline) {
      const deadlineDate = new Date(deadline);
      deadlineDate.setHours(0, 0, 0, 0);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (today > deadlineDate) {
        return "overdue";
      } else if (today.getTime() === deadlineDate.getTime()) {
        return "today";
      }
    }
    return "normal";
  }, [action?.deadline]);

  const handleOpenDialog = (dialogName: string) => {
    if (menuAnchorEl) {
      handleCloseMore();
    }
    setOpenDialog(dialogName);
  };

  const statusColors: any = {
    [actionStatus.pending]: "info",
    [actionStatus.completed]: "success",
    [actionStatus.draft]: "error",
  };

  const hasAssignee = action?.assignee && action?.assignee?.length > 0;

  const assigneeName = React.useMemo(() => {
    if (action?.assignee && action?.assignee?.length > 0) {
      return users[action?.assignee[0]]?.name || action?.assignee[0];
    }
    return t("wif.injury.unassigned");
  }, [users, t]);

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          bgcolor: "background.default",
          boxShadow: BOX_SHADOWS.elevation0,
        }}
      >
        <Box sx={{}}>
          <Stack
            direction="row"
            alignItems="center"
            gap={2.5}
            sx={{
              p: 2,
              pb: 1.25,
              pt: 1.25,
            }}
          >
            <Box
              sx={{
                flex: 1,
              }}
            >
              <Chip
                color={statusColors[action?.status]}
                label={t(`wif.injury.statuses.${action?.status}`)}
                size="small"
              />
            </Box>
            <Tooltip
              placement="top"
              title={
                action?.assignee
                  ? `${t("wif.injury.assignedTo")}: ${assigneeName}`
                  : assigneeName
              }
            >
              <Stack direction="row" alignItems="center" gap={0.75} sx={{}}>
                <Avatar
                  sx={{
                    height: 20,
                    width: 20,
                    ...(hasAssignee
                      ? {
                          bgcolor: (theme) =>
                            generateAvatarColor(
                              assigneeName,
                              theme.palette.mode === "dark",
                            ),
                        }
                      : {
                          bgcolor: "text.disabled",
                        }),
                    fontSize: "body2.fontSize",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {hasAssignee ? (
                    `${assigneeName.charAt(0).toUpperCase()}`
                  ) : (
                    <AppIcon iconName="person" opticalSize={18} />
                  )}
                </Avatar>
                <Typography variant="body2">{assigneeName}</Typography>
              </Stack>
            </Tooltip>
            {(action?.deadline ||
              (action?.status === "completed" &&
                action?.updated_at !== undefined)) && (
              <Stack
                direction="row"
                alignItems="center"
                gap={0.75}
                sx={{
                  ...((deadlineStatus === "overdue" ||
                    action?.status === "overdue") && {
                    color: "error.main",
                  }),
                  ...(deadlineStatus === "today" && {
                    color: "warning.main",
                  }),
                  ...(action?.status === "completed" && {
                    color: "success.main",
                  }),
                }}
              >
                <AppIcon
                  iconName={
                    action?.status === "completed"
                      ? `event_available`
                      : deadlineStatus === "today"
                        ? "calendar_today"
                        : "calendar_month"
                  }
                  color={
                    action?.status === "completed"
                      ? "success.main"
                      : deadlineStatus === "today"
                        ? "warning.main"
                        : deadlineStatus === "overdue" ||
                            action?.status === "overdue"
                          ? "error.main"
                          : "textVariant"
                  }
                />
                <Typography variant="body2">
                  {action?.status === "completed"
                    ? formatDateFromTimestamp(action?.updated_at)
                    : formatDateFromTimestamp(action.deadline)}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack pb={2.5} gap={1.5} direction="column" px={2}>
            <Typography variant="h5">{action?.text}</Typography>
            <Typography variant="body2" sx={{ mt: -1, mb: 0.5 }}>
              {action?.actionDescription}
            </Typography>
            {action?.members && action?.members?.length > 0 && (
              <Stack direction="row" alignItems="center" gap={0.75}>
                <Typography variant="body2" fontWeight={500}>
                  {t("wif.injury.action.fields.members.title")}
                </Typography>
                <AvatarGroup
                  total={action?.members?.length}
                  spacing="medium"
                  slotProps={{
                    additionalAvatar: {
                      sx: {
                        height: 24,
                        width: 24,
                        fontSize: "body2.fontSize",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                      },
                    },
                  }}
                  sx={{
                    "& .MuiAvatarGroup-avatar": {
                      borderColor: "background.default",
                    },
                  }}
                >
                  {members.slice(0, 4).map((userName: string) => (
                    <Tooltip title={userName} placement="top" key={userName}>
                      <Avatar
                        sx={{
                          height: 20,
                          width: 20,
                          bgcolor: (theme) =>
                            generateAvatarColor(
                              userName,
                              theme.palette.mode === "dark",
                            ),
                          fontSize: "body2.fontSize",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {userName.charAt(0).toUpperCase() || (
                          <AppIcon iconName="person" color="#fff" />
                        )}
                      </Avatar>
                    </Tooltip>
                  ))}
                </AvatarGroup>
              </Stack>
            )}

            {linkToInjuryReport && (
              <Stack direction="row" spacing={0.75}>
                <Typography variant="body2" fontWeight={500}>
                  {t("wif.injury.injuryReport")}
                  {`: `}
                </Typography>
                <Link
                  component={RouterLink}
                  variant="body2"
                  fontWeight={500}
                  to={`${injuryReport.id}`}
                  underline={"none"}
                  sx={{
                    "&:hover": {
                      textDecoration: "underline",
                      color: "text.primary",
                    },
                    fontWeight: 400,
                    minWidth: 0,
                    color: (theme) =>
                      theme.palette.mode === "light"
                        ? "primary.main"
                        : "#c7d2fe" /* indigo 300 */,
                  }}
                >
                  {injuryReport.name}
                </Link>
              </Stack>
            )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap={1.5}
            sx={{
              p: 2,
              py: 1.5,
              pt: 0,
            }}
          >
            {action?.status !== "completed" && (
              <Button color="primary" onClick={() => setOpenDialog("complete")}>
                {t("wif.injury.complete")}
              </Button>
            )}
            {action?.status === "completed" && (
              <Button
                onClick={() => setOpenDialog("reopen")}
                variant="outlined"
              >
                {t("wif.injury.reopen")}
              </Button>
            )}

            {action?.comments && action?.comments?.length > 0 && (
              <Button
                color="plain"
                sx={{
                  color: (theme) => `${theme.palette.text.primary}!important`,
                }}
                variant={
                  theme.palette.mode === "light" ? "outlined" : "contained"
                }
                onClick={() => handleOpenDialog("comments")}
                startIcon={<AppIcon iconName="forum" color="textVariant" />}
              >
                {action.comments.length}
              </Button>
            )}
            <Box sx={{ flexGrow: 1 }} />
            <AppIconButton
              variant="text"
              color="plain"
              sx={{ mr: -0.75 }}
              onClick={handleClickMore}
            >
              <AppIcon
                iconName="more_horiz"
                opticalSize={22}
                color="text.secondary"
              />
            </AppIconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={handleCloseMore}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {(!action?.comments ||
                (action?.comments && action?.comments?.length === 0)) && (
                <MenuItem onClick={() => handleOpenDialog("comments")}>
                  <AppIcon iconName="add_comment" sx={{ mr: 1 }} />
                  {t("wif.injury.addComment")}
                </MenuItem>
              )}
              {action?.status === "completed" && (
                <MenuItem onClick={() => handleOpenDialog("reopen")}>
                  <AppIcon iconName="replay" sx={{ mr: 1 }} />
                  {t("wif.injury.reopen")}
                </MenuItem>
              )}
              <MenuItem onClick={() => handleOpenDialog("edit")}>
                <AppIcon iconName="edit" sx={{ mr: 1 }} />
                {t("wif.injury.edit")}
              </MenuItem>
              <MenuItem onClick={() => handleOpenDialog("delete")}>
                <AppIcon iconName="delete" sx={{ mr: 1 }} />
                {t("wif.injury.delete")}
              </MenuItem>
              {logItems && logItems?.length > 0 && (
                <MenuItem>
                  <AppIcon iconName="history" sx={{ mr: 1 }} />
                  {t("wif.injury.history")}
                </MenuItem>
              )}
              {action?.status === "completed" && (
                <MenuItem onClick={() => setOpenDialog("completionDetails")}>
                  <AppIcon iconName="description" sx={{ mr: 1 }} />
                  {t("wif.injury.viewCompletionDetails")}
                </MenuItem>
              )}
            </Menu>
          </Stack>
        </Box>
      </Paper>
      {openDialog === "complete" && (
        <CompleteActionDialog
          open={openDialog === "complete"}
          action={action}
          onClose={() => setOpenDialog(undefined)}
        />
      )}

      {openDialog === "comments" && (
        <ActionCommentsDialog
          open={openDialog === "comments"}
          action={action}
          onClose={() => setOpenDialog(undefined)}
        />
      )}

      <ActionCompletionDialog
        open={openDialog === "completionDetails"}
        action={action}
        onClose={() => setOpenDialog(undefined)}
      />
      <DeleteActionDialog
        open={openDialog === "delete"}
        action={action}
        onClose={() => setOpenDialog(undefined)}
      />
      <ReopenActionDialog
        open={openDialog === "reopen"}
        action={action}
        onClose={() => setOpenDialog(undefined)}
      />
      <UpdateActionDialog
        open={openDialog === "edit"}
        action={action}
        onClose={() => setOpenDialog(undefined)}
      />
    </>
  );
};

export const WideAction = (props: ActionProps) => {
  const { action } = props;

  const selectInjuryReportById = React.useMemo(makeSelectInjuryReportById, []);
  const injuryReport = useAppSelector((state: RootState) =>
    selectInjuryReportById(state, action.report_id),
  );

  const users = useAppSelector(selectEmployees);
  const [openDialog, setOpenDialog] = React.useState<undefined | string>();
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const menuOpen = Boolean(menuAnchorEl);

  const { t } = useTranslation();

  const handleClickMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMore = () => {
    setMenuAnchorEl(null);
  };

  const members = React.useMemo(() => {
    if (!action?.members) return [];
    return action?.members.map((member) => {
      const user = users[member];
      return user ? user?.name : member;
    });
  }, [users, action?.members]);

  const deadlineStatus = React.useMemo(() => {
    const deadline = action?.deadline;
    if (deadline) {
      const deadlineDate = new Date(deadline);
      deadlineDate.setHours(0, 0, 0, 0);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      console.log(
        `deadline: ${deadlineDate.getTime()}, today: ${today.getTime()}, > ${
          today.getTime() > deadlineDate.getTime()
        }}`,
      );
      if (today > deadlineDate) {
        return "overdue";
      } else if (today.getTime() === deadlineDate.getTime()) {
        return "today";
      }
    }
    return "normal";
  }, [action?.deadline]);

  const handleOpenDialog = (dialogName: string) => {
    if (menuAnchorEl) {
      handleCloseMore();
    }
    setOpenDialog(dialogName);
  };

  return (
    <>
      <Paper elevation={1}>
        <Box
          sx={{
            display: "flex",
            direction: "column",
            gap: 1,
            p: 2,
          }}
        >
          <Box>
            <Typography variant="h5">{action?.text}</Typography>
          </Box>

          <Stack
            direction="row"
            alignItems="center"
            gap={2.5}
            sx={{
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Box
              sx={{
                flex: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  ...((deadlineStatus === "overdue" ||
                    action?.status === "overdue") && {
                    color: "error.main",
                  }),
                  ...(action?.status === "completed" && {
                    color: "success.main",
                  }),
                  ...(deadlineStatus === "today" && {
                    color: "warning.main",
                  }),
                }}
              >
                {deadlineStatus === "overdue"
                  ? t(`${TKB_INJURY}.statuses.overdue`)
                  : deadlineStatus === "today" && action?.status === "pending"
                    ? "Due today"
                    : action?.status === "pending"
                      ? t(`${TKB_INJURY}.statuses.pending`)
                      : action?.status === "completed"
                        ? t(`${TKB_INJURY}.statuses.completed`)
                        : action?.status === "overdue"
                          ? t(`${TKB_INJURY}.statuses.overdue`)
                          : t(`${TKB_INJURY}.statuses.pending`)}
              </Typography>
            </Box>
          </Stack>
        </Box>
        <Stack pt={2} pb={2.5} gap={1.5} direction="column" px={2}>
          {action.account_id && (
            <Stack direction="row" alignItems="center" gap={0.75}>
              <AppIcon
                iconName="account_circle"
                color="textVariant"
                opticalSize={22}
              />
              <Typography variant="body2">
                {users[action.account_id]
                  ? users[action.account_id].name
                  : action.account_id}
              </Typography>
            </Stack>
          )}
          {(action?.deadline ||
            (action?.status === "completed" &&
              action?.updated_at !== undefined)) && (
            <Stack direction="row" alignItems="center" gap={1}>
              <AppIcon
                opticalSize={22}
                iconName={
                  action?.status === "completed"
                    ? `event_available`
                    : deadlineStatus === "today"
                      ? "calendar_today"
                      : "calendar_month"
                }
              />
              <Typography variant="body2">
                {action?.status === "completed"
                  ? formatDateFromTimestamp(action?.updated_at)
                  : formatDateFromTimestamp(action.deadline)}
              </Typography>
            </Stack>
          )}
          <Typography variant="body1" sx={{ mt: -0.25, mb: 0.5 }}>
            {action?.actionDescription}
          </Typography>
          {action?.members && action?.members?.length > 0 && (
            <Typography variant="body2">
              <Typography
                variant="body2"
                fontWeight={500}
                component="span"
                sx={{ display: "inline" }}
              >
                Members:{" "}
              </Typography>
              {members.join(", ")}
            </Typography>
          )}
          <Stack direction="row" spacing={0.75}>
            <Typography variant="body2" fontWeight={500}>
              Injury report:
            </Typography>
            <Link
              component={RouterLink}
              variant="body2"
              fontWeight={500}
              to={`${injuryReport.id}`}
              underline={"none"}
              sx={{
                "&:hover": {
                  textDecoration: "underline",
                  color: "text.primary",
                },
                fontWeight: 400,
                minWidth: 0,
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "#c7d2fe" /* indigo 300 */,
              }}
            >
              {injuryReport.name}
            </Link>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{
            p: 2,
            pt: 0,
            pb: 1.5,
            borderColor: "divider",
          }}
        >
          {action?.status !== "completed" && (
            <Button color="primary" onClick={() => setOpenDialog("complete")}>
              {t(`${TKB_INJURY}.complete`)}
            </Button>
          )}
          {action?.status === "completed" && (
            <Button onClick={() => setOpenDialog("reopen")}>
              {t(`${TKB_INJURY}.statuses.reopen`)}
            </Button>
          )}

          {action?.comments && action?.comments?.length > 0 && (
            <Button
              color="plain"
              sx={{
                color: (theme) => `${theme.palette.text.primary}!important`,
              }}
              onClick={() => handleOpenDialog("comments")}
              startIcon={<AppIcon iconName="chat" />}
            >
              {action.comments.length}
            </Button>
          )}

          <Box sx={{ flexGrow: 1 }} />
          <AppIconButton
            variant="text"
            color="plain"
            sx={{ mr: -0.75 }}
            onClick={handleClickMore}
          >
            <AppIcon
              iconName="more_horiz"
              opticalSize={22}
              color="text.secondary"
            />
          </AppIconButton>
          <Menu
            anchorEl={menuAnchorEl}
            open={menuOpen}
            onClose={handleCloseMore}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {(!action?.comments ||
              (action?.comments && action?.comments?.length === 0)) && (
              <MenuItem onClick={() => handleOpenDialog("comments")}>
                Comment
              </MenuItem>
            )}
            {action?.status === "completed" && (
              <MenuItem onClick={() => handleOpenDialog("reopen")}>
                Reopen
              </MenuItem>
            )}
            <MenuItem onClick={() => handleOpenDialog("delete")}>
              Delete
            </MenuItem>
            {action?.status === "completed" && (
              <MenuItem onClick={() => setOpenDialog("completionDetails")}>
                {t(`${TKB_INJURY}.completionDetails`)}
              </MenuItem>
            )}
          </Menu>
        </Stack>
      </Paper>
      {openDialog === "complete" && (
        <CompleteActionDialog
          open={openDialog === "complete"}
          action={action}
          onClose={() => setOpenDialog(undefined)}
        />
      )}

      {openDialog === "comments" && (
        <ActionCommentsDialog
          open={openDialog === "comments"}
          action={action}
          onClose={() => setOpenDialog(undefined)}
        />
      )}

      <ActionCompletionDialog
        open={openDialog === "completionDetails"}
        action={action}
        onClose={() => setOpenDialog(undefined)}
      />
      <DeleteActionDialog
        open={openDialog === "delete"}
        action={action}
        onClose={() => setOpenDialog(undefined)}
      />
      <ReopenActionDialog
        open={openDialog === "reopen"}
        action={action}
        onClose={() => setOpenDialog(undefined)}
      />
    </>
  );
};
