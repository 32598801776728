import {
  Avatar,
  Chip,
  Paper,
  Stack,
  Typography,
  Link,
  Button,
  IconButton,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import { type RootState, useAppSelector } from "../../../../store";
import {
  makeSelectInjuryReportById,
  selectAllInjuryActions,
  selectInjuryReports,
} from "../../store/injuryReportsSlice";
import React from "react";
import type { IAction } from "../../../actions/actionTypes";
import { useTranslation } from "react-i18next";
import { BOX_SHADOWS } from "../../../../config/theme/boxShadows";
import { generateAvatarColor } from "../../../../utils/color";
import { selectEmployees } from "../../../employees/employeesSlice";
import { AppIcon } from "../../../../components/Elements";
import { formatDateFromTimestamp } from "../../../../utils/format";
import {
  DescriptionList,
  DescriptionListItem,
} from "../../../../components/DescriptionList/DescriptionsGrid";
import { Link as RouterLink } from "react-router-dom";

const actionStatus = {
  pending: "pending",
  completed: "completed",
  draft: "draft",
};
const statusColors: any = {
  [actionStatus.pending]: "info",
  [actionStatus.completed]: "success",
  [actionStatus.draft]: "error",
};

type InjuryActionTileProps = {
  action: IAction;
};

const InjuryActionTile = (props: InjuryActionTileProps) => {
  const { action } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const hasAssignee = action?.assignee && action?.assignee?.length > 0;

  const selectInjuryReportById = React.useMemo(makeSelectInjuryReportById, [
    action,
  ]);
  const injuryReport = useAppSelector((state: RootState) =>
    selectInjuryReportById(state, action.report_id),
  );
  const users = useAppSelector(selectEmployees);

  const assigneeName = React.useMemo(() => {
    if (action?.assignee && action?.assignee?.length > 0) {
      return users[action?.assignee[0]]?.name || action?.assignee[0];
    }
    return t("wif.injury.unassigned");
  }, [users, t]);

  const members = React.useMemo(() => {
    if (!action?.members) return [];
    return action?.members.map((member) => {
      const user = users[member];
      return user ? user?.name : member;
    });
  }, [users, action?.members]);

  const membersStr =
    members.length < 1
      ? "None"
      : members.slice(0, 2).join(", ") +
        (members.slice(2).length > 0 ? ` +${members.slice(2).length}` : "");
  return (
    <Paper
      elevation={0}
      sx={{
        bgcolor:
          theme.palette.mode === "light"
            ? "background.default"
            : "backgroundVariant",
        boxShadow: BOX_SHADOWS.elevation0,
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
        p: 2,
        pb: 1.5,
        pt: 1.5,
      }}
    >
      <Stack direction="row" alignItems="center" gap={2.5}>
        <Typography variant="h5" sx={{ flex: 1 }}>
          {action?.text}
        </Typography>
        <Stack direction="row" alignItems="center" gap={1} sx={{}}>
          <Avatar
            sx={{
              height: 20,
              width: 20,
              ...(hasAssignee
                ? {
                    bgcolor: (theme) =>
                      generateAvatarColor(
                        assigneeName,
                        theme.palette.mode === "dark",
                      ),
                  }
                : {
                    bgcolor: "text.disabled",
                  }),
              fontSize: "body2.fontSize",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {hasAssignee ? (
              `${assigneeName.charAt(0).toUpperCase()}`
            ) : (
              <AppIcon iconName="person" opticalSize={18} />
            )}
          </Avatar>
          <Typography variant="body2">{assigneeName}</Typography>
        </Stack>
      </Stack>
      <DescriptionList gap={1.5}>
        <DescriptionListItem
          size="small"
          field="Status"
          valueComponent={
            <div style={{ height: "20px", display: "flex", flexWrap: "wrap" }}>
              <Chip
                size="small"
                color={statusColors[action.status]}
                label={t(`wif.injury.statuses.${action?.status}`)}
                variant="filled"
                sx={{ mt: "-2px" }}
              />
            </div>
          }
        />
        <DescriptionListItem
          size="small"
          field="Due date"
          value={formatDateFromTimestamp(action.deadline)}
        />
        <DescriptionListItem
          size="small"
          field="Injury report"
          valueComponent={
            <Link
              component={RouterLink}
              variant="body2"
              fontWeight={500}
              to={`${injuryReport.id}`}
              underline={"none"}
              sx={{
                "&:hover": {
                  textDecoration: "underline",
                  color: "text.primary",
                },
                fontWeight: 400,
                minWidth: 0,
                color: (theme) =>
                  theme.palette.mode === "light"
                    ? "primary.main"
                    : "#c7d2fe" /* indigo 300 */,
              }}
            >
              {injuryReport.name}
            </Link>
          }
        />
        <DescriptionListItem
          field="Members"
          size="small"
          value={membersStr}
          disabledOrUndefined={members.length < 1}
        />
      </DescriptionList>
      <Stack
        direction="row"
        alignItems="center"
        gap={1.25}
        sx={{
          pt: 1,
        }}
      >
        {action?.status !== "completed" && (
          <Button color="primary" variant="contained">
            {t("wif.injury.complete")}
          </Button>
        )}
        {action?.status === "completed" && (
          <Button
            variant="contained"
            color="primary"
            sx={{ boxShadow: BOX_SHADOWS.elevation0 }}
          >
            {"View details"}
          </Button>
        )}
        {action?.status === "completed" && (
          <Button variant="outlined" sx={{ boxShadow: BOX_SHADOWS.elevation0 }}>
            {t("wif.injury.reopen")}
          </Button>
        )}
        {action?.comments && action?.comments?.length > 0 && (
          <Button
            color="plain"
            sx={{
              color: (theme) => `${theme.palette.text.secondary}!important`,
            }}
            variant={theme.palette.mode === "light" ? "text" : "contained"}
            startIcon={<AppIcon iconName="forum" color="textVariant" />}
          >
            {action.comments.length}
          </Button>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          sx={{
            height: "36px",
            width: "36px",
            mr: -0.5,
          }}
        >
          <AppIcon
            iconName="more_horiz"
            opticalSize={22}
            color="text.secondary"
          />
        </IconButton>
      </Stack>
    </Paper>
  );
};

type InjuryActionsProps = {
  category?: string;
};

export const InjuryActions = (props: InjuryActionsProps) => {
  const { category } = props;

  const injuryActions = useAppSelector(selectAllInjuryActions);
  const injuryReports = useAppSelector(selectInjuryReports);

  return (
    <Box
      sx={{
        flex: 1,
        py: {
          xs: 2.5,
        },
        px: {
          xs: 2.5,
          md: 3,
        },
      }}
    >
      <Grid container spacing={2}>
        {injuryActions.map((action) => (
          <Grid xs={4} key={action.action_id}>
            <InjuryActionTile action={action} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
