import { useEffect, useState } from "react";
import { httpGet, httpPost } from "../../../../utils/httpService";
import config from "../../../../config/config";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { getDefaultLineChartOptions } from "../../../../components/Charts/helpers";
import LineChart from "../../../../components/Charts/LineChart";
import { getCustomIbmCategorical } from "../../../../config";
import CircularLoader from "../../../../components/Loader/circularLoader";
import { Box } from "@mui/system";
import { MFilterItem } from "../../../filter/filterUtils";

interface StatusOverTimeChartProps {
  title: string;
  timePeriod: [string, string, string];
  filters?: MFilterItem[];
  module: string;
}

const StatusOverTimeChart = ({
  title,
  timePeriod,
  filters,
  module,
}: StatusOverTimeChartProps) => {
  const { t } = useTranslation();
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });
  const [data, setData] = useState({} as any);
  const [isLoading, setIsLoading] = useState(true);
  const [subtitle, setSubtitle] = useState(t("wif.injury.allTime"));
  const theme = useTheme() as any;
  const options = getDefaultLineChartOptions({ theme, title, subtitle });
  const [timeFrame, year, timeLabel] = timePeriod;
  const getLabel = (month, year) =>
    `${t("wif.injury.stats.months." + month)} ${year}`;

  const colors = getCustomIbmCategorical({
    numberOfCategories: 3,
    palette: 1,
  });

  const processChartData = (data) => {
    const allLabels = [];
    const ongoingData = [];
    const closedData = [];
    const rejectedData = [];

    Object.keys(data).forEach((year) => {
      Object.keys(data[year]).forEach((month) => {
        allLabels.push(getLabel(month, year));
        ongoingData.push(data[year][month].ongoing);
        closedData.push(data[year][month].closed);
        rejectedData.push(data[year][month].rejected);
      });
    });

    let labels = allLabels;
    let ongoing = ongoingData;
    let closed = closedData;
    let rejected = rejectedData;
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();

    if (timeFrame === "6M" || timeFrame === "1Y" || timeFrame === "1M") {
      const endDate = new Date(now.getFullYear(), now.getMonth(), 1);
      const startDate = new Date(endDate);

      if (timeFrame === "6M") {
        startDate.setMonth(endDate.getMonth() - 5);
        setSubtitle(t("wif.injury.last6Months"));
      } else if (timeFrame === "1Y") {
        startDate.setMonth(endDate.getMonth() - 11);
        setSubtitle(t("wif.injury.lastYear"));
      } else if (timeFrame === "1M") {
        startDate.setMonth(endDate.getMonth() - 1);
        setSubtitle(t("wif.injury.lastMonth"));
      }

      labels = [];
      ongoing = [];
      closed = [];
      rejected = [];

      for (let d = startDate; d <= endDate; d.setMonth(d.getMonth() + 1)) {
        const label = getLabel(d.getMonth(), d.getFullYear());
        const index = allLabels.indexOf(label);

        if (index !== -1) {
          labels.push(label);
          ongoing.push(ongoingData[index]);
          closed.push(closedData[index]);
          rejected.push(rejectedData[index]);
        } else {
          labels.push(label);
          ongoing.push(0);
          closed.push(0);
          rejected.push(0);
        }
      }
    } else if (timeFrame === "1T" || timeFrame === "2T" || timeFrame === "3T") {
      setSubtitle(timeLabel);

      const startMonth = {
        "1T": 0,
        "2T": 4,
        "3T": 8,
      }[timeFrame];

      labels = [];
      ongoing = [];
      closed = [];
      rejected = [];

      for (let month = startMonth; month < startMonth + 4; month++) {
        const label = getLabel(month, year);

        const index = allLabels.indexOf(label);
        if (index !== -1) {
          labels.push(label);
          ongoing.push(ongoingData[index]);
          closed.push(closedData[index]);
          rejected.push(rejectedData[index]);
        } else {
          labels.push(label);
          ongoing.push(0);
          closed.push(0);
          rejected.push(0);
        }
      }
    } else {
      options.plugins.subtitle.text = t("wif.injury.allTime");
      labels = [];
      ongoing = [];
      closed = [];
      rejected = [];

      allLabels.forEach((label, index) => {
        const [monthName, year] = label.split(" ");
        const month = new Date(
          Date.parse(`${monthName} 1, ${year}`),
        ).getMonth();
        if (
          Number(year) < currentYear ||
          (Number(year) === currentYear && month <= currentMonth)
        ) {
          labels.push(label);
          ongoing.push(ongoingData[index]);
          closed.push(closedData[index]);
          rejected.push(rejectedData[index]);
        }
      });
    }

    setChartData({
      labels,
      datasets: [
        {
          label: t("wif.injury.statuses.ongoing"),
          data: ongoing,
          borderColor: colors[0],
          backgroundColor: colors[0],
          fill: false,
        },
        {
          label: t("wif.injury.statuses.closed"),
          data: closed,
          borderColor: colors[1],
          backgroundColor: colors[1],
          fill: false,
        },
        {
          label: t("wif.injury.statuses.rejected"),
          data: rejected,
          borderColor: colors[2],
          backgroundColor: colors[2],
          fill: false,
        },
      ],
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const filterData = filters.map((filter) => ({
        field: filter.field,
        value: filter.value,
      }));

      if (module === "employee") {
        filterData.push({
          field: "category",
          value: {
            value: "employee",
            label: "Employee",
          },
        });
      }

      if (module === "student") {
        filterData.push({
          field: "category",
          value: {
            value: "student",
            label: "Student",
          },
        });
      }

      try {
        const response = await httpPost(
          `${config.baseUrl}/injury/getInjuryReportStatusesByYear`,
          { filters: filterData },
        );
        const data = response.data;

        setData(data);
        processChartData(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [filters]);

  useEffect(() => {
    processChartData(data);
  }, [data, timePeriod, t]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="300px"
      >
        <CircularLoader />
      </Box>
    );
  }

  return <LineChart data={chartData} options={options} />;
};

export default StatusOverTimeChart;
