import React from "react";
import * as yup from "yup";
import Button from "@mui/material/Button";
import { useCurrentInjuryReport } from "../../../routes/CurrentInjuryReportContext";
import { Stack } from "@mui/system";
import Box from "@mui/material/Box";
import { AppIcon } from "../../../../../components/Elements";
import { useAppDispatch } from "../../../../../store";
import { snackAlertOpened } from "../../../../global/controlsSlice";
import {
  type UpdateInjuryReportRequest,
  updateInjuryReport,
} from "../../../store/injuryReportsSlice";
import { useForm, type SubmitHandler, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormSelect,
  FormTextField,
} from "../../../../../components/FormInputs";
import { FormFileInput } from "../../../../../components/FormInputs/FormFileInput";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const closeInjuryReportPayloadSchema = yup.object().shape({
  likelihoodOfRecurrence: yup.string().required(),
  endingEvaluation: yup
    .string()
    .required()
    .min(4, "min 4")
    .max(1000, "max 1000"),
  attachments: yup.lazy(() =>
    yup.array().of(
      yup.object({
        file: yup.mixed().notRequired(),
      }),
    ),
  ),
});

export type CloseInjuryReportPayload = yup.InferType<
  typeof closeInjuryReportPayloadSchema
>;

export const likelihoodOfRecurrenceOptions = [
  "not_evaluated",
  "unlikely",
  "likely",
  "very_likely",
];

export const oldLikelyhoodOptionsMappedToNew = {
  "Not evaluated": "not_evaluated",
  Unlikely: "unlikely",
  Likely: "likely",
  "Very likely": "very_likely",
};

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[arr.length - 1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export default function CloseCasePanel() {
  const injuryReport = useCurrentInjuryReport();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState(false);

  const { handleSubmit, control, watch } = useForm<CloseInjuryReportPayload>({
    resolver: yupResolver(closeInjuryReportPayloadSchema),
    defaultValues: React.useMemo(() => {
      let likelyhood =
        injuryReport?.closing_details?.likelyhood || "not_evaluated";
      if (likelyhood && !likelihoodOfRecurrenceOptions.includes(likelyhood)) {
        likelyhood =
          oldLikelyhoodOptionsMappedToNew[likelyhood] || "not_evaluated";
      }
      const ending_evaluation = injuryReport?.closing_details?.endingEvaluation;
      const attachments = injuryReport?.closing_details?.attachments;
      const fixedAttachments = [];
      const includedFileNames = [];

      if (attachments) {
        for (const attachment of attachments) {
          if (!includedFileNames.includes(attachment.file_name)) {
            fixedAttachments.push({
              file: dataURLtoFile(attachment.file, attachment.file_name),
            });
            includedFileNames.push(attachment.file_name);
          }
        }
      }

      return {
        likelihoodOfRecurrence: likelyhood,
        endingEvaluation: ending_evaluation,
        attachments: fixedAttachments,
      };
    }, [injuryReport]),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "attachments",
  });

  const handleSave = async (req: UpdateInjuryReportRequest) => {
    setLoading(true);
    const resultAction = await dispatch(updateInjuryReport(req));
    if (updateInjuryReport.fulfilled.match(resultAction)) {
      dispatch(
        snackAlertOpened({
          message: t("wif.injury.snackMessages.closingDetailsSaved.success"),
          severity: "success",
        }),
      );
    } else {
      if (resultAction.payload) {
        dispatch(
          snackAlertOpened({
            message: t("wif.injury.snackMessages.genericError"),
            severity: "error",
          }),
        );
      } else {
        dispatch(
          snackAlertOpened({
            message: t("wif.injury.snackMessages.errorWithMessage", {
              message: resultAction.error,
            }),
            severity: "error",
          }),
        );
      }
    }
  };

  const getBase64 = (file: any) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        res({
          file: reader.result,
          file_name: file.name,
          file_type: file.type,
          file_size: file.size,
        });
      reader.onerror = (error) => rej(error);
    });
  };

  const getAttachmentFiles = async (attachments: any[]) => {
    const promises = attachments
      .filter((attachment) => attachment?.file)
      .map((attachment) => getBase64(attachment.file));
    const attachment_files = await Promise.all(promises);

    return attachment_files;
  };

  const onSubmit: SubmitHandler<CloseInjuryReportPayload> = (data) => {
    getAttachmentFiles(data.attachments).then((attachment_files: any[]) => {
      const req: any = {
        reportId: injuryReport.id,
        body: {
          closing_details: {
            likelyhood: data.likelihoodOfRecurrence,
            endingEvaluation: data.endingEvaluation,
            attachments: attachment_files,
          },
        },
      };
      handleSave(req);
    });
  };
  return (
    <Stack
      direction="column"
      gap={2}
      sx={{
        width: "100%",
        py: 2.5,
        flex: 1,
      }}
    >
      <form id="close-injury-report-form" onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" gap={2} width="100%">
          <Stack direction="column" gap={3} width="100%">
            <FormSelect
              name="likelihoodOfRecurrence"
              label={t("wif.injury.closeCaseTab.likelihoodOfRecurrence.title")}
              control={control}
              options={likelihoodOfRecurrenceOptions.map((option) => ({
                label: t(
                  `wif.injury.closeCaseTab.likelihoodOfRecurrence.options.${option}`,
                ),
                value: option,
              }))}
            />
            <FormTextField
              name="endingEvaluation"
              label={t("wif.injury.closeCaseTab.endingEvaluation.title")}
              control={control}
              multiline
              minRows={2}
              maxRows={6}
              placeholder={t(
                "wif.injury.closeCaseTab.endingEvaluation.placeholder",
              )}
            />
          </Stack>

          {fields.length > 0 && (
            <>
              <Typography variant="h5" mt={1} mb={-0.75}>
                {"wif.injury.attachments"}
              </Typography>
              <Stack direction="column" gap={1.5} width="100%">
                {fields.map((field, index) => (
                  <Paper
                    variant="outlined"
                    key={field.id}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "start",
                      py: 1.5,
                      px: 2,
                      pr: 1.75,
                    }}
                  >
                    <FormFileInput
                      label={t("wif.injury.upload")}
                      name={`attachments.${index}.file` as const}
                      watch={watch}
                      control={control}
                      index={index}
                    />
                    <IconButton onClick={() => remove(index)}>
                      <AppIcon iconName="delete" color="error.main" />
                    </IconButton>
                  </Paper>
                ))}
              </Stack>
            </>
          )}

          <Box>
            <Button
              variant="text"
              color="primary"
              onClick={() => append({ file: undefined })}
              startIcon={<AppIcon iconName="add" />}
            >
              {t("wif.injury.addAttachment")}
            </Button>
          </Box>
        </Stack>
      </form>
      <Box></Box>
    </Stack>
  );
}
