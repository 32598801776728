import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useCurrentInjuryReport } from "../../../routes/CurrentInjuryReportContext";
import { createInjuryReportComment } from "../../../store/injuryReportsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { selectEmployees } from "../../../../employees/employeesSlice";
import * as yup from "yup";
import {
  useForm,
  type SubmitHandler,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { snackAlertOpened } from "../../../../global/controlsSlice";
import { useTranslation } from "react-i18next";

import {
  AppMessage,
  useChatScroll,
} from "../../../../../components/Messages/AppMessage";
import { SendMessage } from "../../../../../components/Messages/SendMessage";

export const createMessageSchema = yup.object().shape({
  message: yup.string().required().min(4, "min 4").max(1000, "max 1000"),
  attachments: yup.lazy(() =>
    yup.array().of(
      yup.object({
        file: yup.mixed().notRequired(),
        file_type: yup.string().notRequired(),
        file_name: yup.string().notRequired(),
        file_size: yup.string().notRequired(),
      }),
    ),
  ),
});

export type CreateMessagePayload = yup.InferType<typeof createMessageSchema>;

export function ReportComments() {
  const { t } = useTranslation();
  const injuryReport = useCurrentInjuryReport();
  const comments = injuryReport?.comments || [];
  const chatRef = useChatScroll(comments);

  const employees = useAppSelector(selectEmployees);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();

  const { handleSubmit, control, reset, setValue, watch } =
    useForm<CreateMessagePayload>({
      resolver: yupResolver(createMessageSchema),
      defaultValues: {
        attachments: [],
      },
    });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "attachments",
    },
  );

  const attachments = useWatch({ name: "attachments", control: control });

  const handleSave = async (req: any) => {
    setLoading(true);
    const resultAction = await dispatch(createInjuryReportComment(req));
    if (createInjuryReportComment.fulfilled.match(resultAction)) {
      dispatch(
        snackAlertOpened({
          message: t("wif.injury.snackMessages.messageSaved.success"),
          severity: "success",
        }),
      );
      setLoading(false);
      reset();
    } else {
      if (resultAction.payload) {
        dispatch(
          snackAlertOpened({
            message: t("wif.injury.snackMessages.genericError"),
            severity: "error",
          }),
        );
        setLoading(false);
      } else {
        dispatch(
          snackAlertOpened({
            message: t("wif.injury.snackMessages.errorWithMessage", {
              message: resultAction.error,
            }),
            severity: "error",
          }),
        );
        setLoading(false);
      }
    }
  };

  const getBase64 = (file: any) => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        res({
          file: reader.result,
          file_name: file.name,
          file_type: file.type,
          file_size: file.size,
        });
      reader.onerror = (error) => rej(error);
    });
  };

  const getAttachmentFiles = async (attachments: any[]) => {
    const promises = attachments
      .filter((attachment) => attachment?.file)
      .map((attachment) => getBase64(attachment.file));
    const attachment_files = await Promise.all(promises);

    return attachment_files;
  };
  const onSubmit: SubmitHandler<CreateMessagePayload> = (data) => {
    setLoading(true);
    getAttachmentFiles(data.attachments).then((attachment_files: any[]) => {
      const req: any = {
        reportId: injuryReport.id,
        payload: {
          message: data.message,
          attachments: attachment_files,
        },
      };
      handleSave(req);
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        minHeight: 0,
      }}
    >
      {comments.length > 0 ? (
        <Box
          ref={chatRef}
          sx={{
            flexGrow: 1,
            zIndex: 0,
            borderRadius: 1,
            overflowY: "auto",
            gap: 2.5,
            py: 2,
            pt: 2.5,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {comments.map((comment: any) => (
            <AppMessage
              key={comment.comment_id}
              timestamp={comment.created_at}
              sender={employees[comment.account_id]?.name || "Unknown"}
              message={comment.message}
              attachmentKeys={comment?.attachment_keys}
              sx={{ px: 2.5 }}
            />
          ))}
        </Box>
      ) : (
        <Box sx={{ pt: 2, pb: 2.5, px: 2.5 }}>
          <Typography color="textVariant">
            {t("wif.injury.noComments")}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          px: 1.5,
        }}
      >
        <SendMessage
          formId="add-injury-comment"
          onSubmit={handleSubmit(onSubmit)}
          watch={watch}
          control={control}
          append={append}
          remove={remove}
          fields={fields}
          attachments={attachments}
          loading={loading}
        />
      </Box>
    </Box>
  );
}
