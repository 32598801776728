import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import type { InferType } from "yup";
import Button from "@mui/material/Button";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import type { StepIconProps } from "@mui/material/StepIcon";
import { styled } from "@mui/material/styles";
import { StepButton } from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormSectionPanel } from "../../../../components/Forms";
import { AllSectionDetails, SCHEMA_BY_SECTION_NAME } from "../../constants";
import { injuredStudentSchema } from "../FormSections/student";
import { InjuryForm } from "./InjuryForm";
import { SectionControlsMapper } from "./SectionControlsMapper";
import { AppIcon } from "../../../../components/Elements";
import { APP_COLORS } from "../../../../config";
import { useAppDispatch } from "../../../../store";
import { registrationSubmitted } from "../../store/injuryReportsSlice";

const QontoStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  borderWidth: "2px",
  borderStyle: "solid",
  ...(theme.palette.mode === "dark"
    ? {
        backgroundColor: APP_COLORS.dark1,
        borderColor: APP_COLORS.dark1,
        color: theme.palette.text.secondary,
      }
    : {
        backgroundColor: APP_COLORS.light5,
        borderColor: APP_COLORS.light5,
        color: theme.palette.text.secondary,
      }),
  width: 32,
  height: 32,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.completed && {
    color:
      theme.palette.mode === "light"
        ? "#fff"
        : theme.palette.background.default,
    backgroundColor: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  }),
  ...(ownerState.active && {
    color:
      theme.palette.mode === "light"
        ? "#fff"
        : theme.palette.background.default,
    backgroundColor: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  }),
}));

export const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.divider,
    borderTopWidth: 1,
    borderRadius: 4,
  },
}));
export function CustomStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      {completed ? (
        <AppIcon iconName="check" opticalSize={20} weight={500} />
      ) : (
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {props.icon}
        </Typography>
      )}
    </QontoStepIconRoot>
  );
}

const SquareStepIcon = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: "#e4e3ea",
  borderColor: "#e4e3ea",
  color: theme.palette.text.secondary,
  width: 44,
  height: 44,
  position: "relative",
  zIndex: 3,
  marginTop: -8,
  marginBottom: 8,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.completed && {
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main,
  }),
  ...(ownerState.active && {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  }),
}));

export function CustomSquareStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <SquareStepIcon ownerState={{ active, completed }} className={className}>
      {completed ? (
        <AppIcon iconName="check" opticalSize={20} weight={500} />
      ) : (
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {props.icon}
        </Typography>
      )}
    </SquareStepIcon>
  );
}

type InjuryWizardProps = {
  category?: string;
  type?: string;
  sectionNames: string[];
};

export const InjuryWizard = (props: InjuryWizardProps) => {
  const { sectionNames, category, type } = props;

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const navigate = useNavigate();

  const activeSectionName = React.useMemo(() => {
    return sectionNames[activeStep];
  }, [sectionNames, activeStep]);

  const [sectionData, setSectionData] = React.useState<{
    [sectionName: string]: any;
  }>({});

  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return sectionNames.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const handleSubmitData = (data: any) => {
    setSectionData((prevSectionData) => ({
      ...prevSectionData,
      [activeSectionName]: data,
    }));

    if (activeStep < sectionNames.length - 1) {
      handleNext();
    } else {
      const formData = {
        category,
        type,
        sections: {
          ...sectionData,
          [activeSectionName]: data,
        },
      };
      dispatch(registrationSubmitted(formData));
      navigate("/injuries");
    }
  };

  const getSchemaBySectionName = (sectionName: string) => {
    if (SCHEMA_BY_SECTION_NAME[sectionName]) {
      return SCHEMA_BY_SECTION_NAME[sectionName];
    }

    return injuredStudentSchema;
  };

  const allSectionsCompleted = React.useMemo(() => {
    for (const sectionName of sectionNames) {
      if (!sectionData[sectionName]) {
        return false;
      }
    }
    return true;
  }, [sectionNames, sectionData]);

  const canSubmitForm =
    allSectionsCompleted && activeStep === sectionNames.length;

  const handleFormSubmit = () => {
    const data = {
      category,
      type,
      sections: sectionData,
    };
  };

  return (
    <Box sx={{ width: "100%", py: 0.5 }}>
      <Stepper
        activeStep={activeStep}
        nonLinear
        connector={<CustomStepConnector />}
        sx={{ mx: -1 }}
      >
        {sectionNames.map((sectionName: string, index: number) => (
          <Step key={sectionName} completed={sectionData[sectionName]}>
            <StepButton onClick={handleStep(index)}>
              <StepLabel
                StepIconComponent={CustomStepIcon}
                sx={{
                  "& .MuiStepLabel-label": {
                    "&.Mui-completed": {
                      fontWeight: 600,
                    },
                    "&.Mui-active": {
                      color: "text.primary",
                      fontWeight: 600,
                    },
                    fontWeight: 500,
                  },
                }}
              >
                {t(AllSectionDetails[sectionName].title)}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ width: "100%", mt: 3.5, mb: 4 }}>
        <Typography variant="h2">
          {t(AllSectionDetails[activeSectionName].title)}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" mt={0.25}>
          {t(AllSectionDetails[activeSectionName].description)}
        </Typography>
      </Box>

      {sectionNames.map((sectionName, index) => {
        const FormSchema = getSchemaBySectionName(sectionName);
        return (
          <FormSectionPanel
            key={`${sectionName}`}
            sectionName={sectionName}
            activeSectionName={activeSectionName}
          >
            <InjuryForm<InferType<typeof FormSchema>, typeof FormSchema>
              schema={FormSchema}
              onSubmit={handleSubmitData}
              id={sectionName}
            >
              {(methods) => (
                <SectionControlsMapper
                  methods={methods}
                  sectionName={sectionName}
                />
              )}
            </InjuryForm>
            <React.Fragment>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1.5,
                  mt: 5,
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                  }}
                >
                  {index > 0 && (
                    <Button
                      variant="contained"
                      color="plain"
                      size="large"
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                  )}
                </Box>

                {index === sectionNames.length - 1 && (
                  <Typography variant="body2" color="text.secondary">
                    {allSectionsCompleted
                      ? "All steps completed"
                      : "Complete all steps to submit"}
                  </Typography>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  size="large"
                  form={sectionName}
                >
                  {index < sectionNames.length - 1 ? "Complete step" : "Submit"}
                </Button>
              </Box>
            </React.Fragment>
          </FormSectionPanel>
        );
      })}
    </Box>
  );
};
