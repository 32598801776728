import type { LayerProps } from "react-map-gl";
import { APP_COLORS } from "../../../config";
import { retrieveSiteCurrent } from "../../locations/helpers/mapExpressions";

export const mainLayer: any = {
  layout: {
    "icon-size": ["case", ["==", retrieveSiteCurrent, 1], 0.9, 0.6],
    "icon-allow-overlap": true,
    "text-allow-overlap": true,
    "text-field": ["get", "name"],
    "text-font": ["Inter Tight Medium"],
    "text-max-width": 15,
    "text-size": ["case", ["==", retrieveSiteCurrent, 1], 15, 13],
  },
};

export const clusterLayer: LayerProps = {
  id: "clusters",
  type: "circle",
  source: "earthquakes",
  filter: ["has", "point_count"],
  minzoom: 14,
  paint: {
    "circle-color": [
      "case",
      [">", ["get", "healthStatus3Count"], 0],
      APP_COLORS.error,
      [">", ["get", "healthStatus2Count"], 0],
      APP_COLORS.warning,
      [">", ["get", "healthStatus1Count"], 0],
      APP_COLORS.success,
      APP_COLORS.secondary,
    ],
    "circle-radius": ["step", ["get", "point_count"], 20, 5, 30, 10, 40],
  },
};

export const clusterCountLayer: LayerProps = {
  id: "cluster-count",
  type: "symbol",
  source: "earthquakes",
  filter: ["has", "point_count"],
  minzoom: 14,
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["Inter Tight Medium", "Arial Unicode MS Bold"],
    "text-size": 12,
  },
  paint: {
    "text-color": [
      "case",
      [">", ["get", "healthStatus3Count"], 0],
      "#fff",
      [">", ["get", "healthStatus2Count"], 0],
      "#fff",
      [">", ["get", "healthStatus1Count"], 0],
      "#fff",
      APP_COLORS.primary,
    ],
  },
};

export const unclusteredPointLayer: LayerProps = {
  id: "unclustered-point",
  type: "circle",
  source: "earthquakes",
  filter: ["!", ["has", "point_count"]],
  paint: {
    "circle-color": "#11b4da",
    "circle-radius": 4,
    "circle-stroke-width": 1,
    "circle-stroke-color": "#fff",
  },
};
