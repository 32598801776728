import type { WithChildren } from "../../types";
import { Box, Paper } from "@mui/material";

type RightContainerProps = WithChildren<{
  width?: any;
  sx?: any;
}>;

export const RightContainer = (props: RightContainerProps) => {
  const { children, width, sx } = props;

  return (
    <Box
      sx={{
        display: "flex",
        pr: 1.5,
        height: "100%",
        flexDirection: "column",
      }}
    >
      <Paper
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          minHeight: 0,
          mb: 1.5,
          mt: 0.5,
          ...sx,
          ...(width && {
            width: width,
          }),
        }}
      >
        {children}
      </Paper>
    </Box>
  );
};
