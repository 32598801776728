import * as yup from "yup";
import React from "react";
import {
  FormRadioGroup,
  FormTextField,
  YES_NO_OPTIONS,
} from "../../../../../components/FormInputs";
import type { WithFormMethods } from "../../../../../components/Forms";
import { FormSectionSubHeader } from "../../../../../components/FormInputs/FormSectionSubheader";
import { useAppSelector } from "../../../../../store";
import {
  selectAllDepartments,
  selectDepartmentEntities,
} from "../../../../departments/departmentsSlice";
import {
  selectAllInjuryUnits,
  selectInjuryUnitEntities,
} from "../../../store/injuryReportsSlice";
import { useTranslation } from "react-i18next";
import { UnitPaper } from "../UnitPaper";
import { getTranslationKeyForSectionFieldLabel } from "../../../helpers/dataMapping";
import { EMPLOYEE_SECTION_NAMES } from "../../../constants/employee";
import _ from "lodash";
import { FormSingleAutocomplete } from "../../../../../components/FormInputs/FormSingleAutocomplete";

export const employerSchema = yup.object().shape({
  name: yup.string().required("between 4 63").min(4, "min 4").max(63, "max 63"),
  departmentId: yup.string().min(4, "min 4").max(110, "max 110"), // ID or name
  unitId: yup.string().min(4, "min 4").max(112, "max 112"), // ID or name
  accidentHappenedAtMainAddress: yup.string().oneOf(YES_NO_OPTIONS).required(),
  addressOfAccident: yup.string().min(4, "min 4").max(42, "max 42").optional(),
  insuranceCompanyName: yup
    .string()
    .required("between 2 86")
    .min(2, "min 2")
    .max(86, "max 86"),
  insuranceCompanyAddress: yup
    .string()
    .required("between 4 86")
    .min(4, "min 4")
    .max(86, "max 86"),
});

export type EmployerSection = yup.InferType<typeof employerSchema>;

export const EmployerControls = (props: WithFormMethods<EmployerSection>) => {
  const { methods } = props;
  const { control, watch, setValue } = methods;
  const { t } = useTranslation();
  const departments = useAppSelector(selectAllDepartments);
  const departmentEntities = useAppSelector(selectDepartmentEntities);
  const units = useAppSelector(selectAllInjuryUnits);
  const unitEntities = useAppSelector(selectInjuryUnitEntities);
  const departmentId = watch("departmentId");
  const accidentHappenedAtMainAddress = watch("accidentHappenedAtMainAddress");

  const filteredDepartments = React.useMemo(() => {
    const unitDepartmentIds: string[] = [];
    for (const unit of units) {
      if (!unitDepartmentIds.includes(unit.department_id)) {
        unitDepartmentIds.push(unit.department_id);
      }
    }
    return departments.filter(
      (department) => unitDepartmentIds.indexOf(department.id) !== -1,
    );
  }, [units]);

  const departmentFilteredUnits = React.useMemo(() => {
    if (departmentId) {
      return units.filter(
        (unit) => unit.department_id === departmentId || !unit.department_id,
      );
    }
    return [];
  }, [departmentId]);

  const unitId = watch("unitId");

  React.useEffect(() => {
    const validUnitIds = departmentFilteredUnits.map((unit) => unit.unit_id);
    if (!validUnitIds.includes(unitId)) {
      if (validUnitIds.length > 0) {
        setValue("unitId", validUnitIds[0]);
      } else {
        setValue("unitId", "");
      }
    }
  }, [departmentFilteredUnits]);

  const translatedYesNoOptions = React.useMemo(() => {
    const obj: any = {};
    for (const opt of YES_NO_OPTIONS) {
      obj[opt] = t(`wif.injury.yesNoOptions.${opt}`);
    }
    return obj;
  }, [t]);

  const departmentNames: any = _.mapValues(
    departmentEntities,
    (dep) => dep.name,
  );
  const unitNames: any = _.mapValues(unitEntities, (unit) => unit.unit_name);

  return (
    <>
      <FormTextField
        control={control}
        name="name"
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.EMPLOYER,
            "name",
          ),
        )}
        placeholder="Samsvar AS"
      />
      <FormSingleAutocomplete
        control={control}
        name="departmentId"
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.EMPLOYER,
            "department",
          ),
        )}
        options={filteredDepartments.map((department, i) => department.id)}
        optionLabels={departmentNames}
      />
      {departmentFilteredUnits.length > 0 && (
        <FormSingleAutocomplete
          control={control}
          name="unitId"
          label={t(
            getTranslationKeyForSectionFieldLabel(
              EMPLOYEE_SECTION_NAMES.EMPLOYER,
              "unit",
            ),
          )}
          options={departmentFilteredUnits.map((unit, i) => unit.unit_id)}
          optionLabels={unitNames}
        />
      )}
      {unitId && <UnitPaper unitId={unitId} />}
      <FormRadioGroup
        control={control}
        name="accidentHappenedAtMainAddress"
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.EMPLOYER,
            "accidentHappenedAtMainAddress",
          ),
        )}
        options={YES_NO_OPTIONS}
        mappedLabels={translatedYesNoOptions}
      />
      {accidentHappenedAtMainAddress !== "yes" && (
        <FormTextField
          control={control}
          name="addressOfAccident"
          label={t(
            getTranslationKeyForSectionFieldLabel(
              EMPLOYEE_SECTION_NAMES.EMPLOYER,
              "addressOfAccident",
            ),
          )}
          placeholder="Boligveien 1"
          sx={{ flex: 1, mt: -0.75, mb: 0.75 }}
        />
      )}
      <FormSectionSubHeader>
        {t("wif.injury.insuranceCompany")}
      </FormSectionSubHeader>
      <FormTextField
        control={control}
        name="insuranceCompanyName"
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.EMPLOYER,
            "insuranceCompanyName",
          ),
        )}
        placeholder="Insurance CO"
      />
      <FormTextField
        control={control}
        name="insuranceCompanyAddress"
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.EMPLOYER,
            "insuranceCompanyAddress",
          ),
        )}
        placeholder="Boligveien 2"
      />
    </>
  );
};
