import { Paper, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import { type RootState, useAppSelector } from "../../../../store";
import {
  CASE_STATUS,
  REPORT_CATEGORY,
  REPORT_TYPES,
  TKB_INJURY,
} from "../../constants";
import React from "react";
import { APP_COLORS } from "../../../../config";
import {
  InjuryReportTimeOfDayBars,
  InjuryReportsByMonthBars,
} from "./InjuryReportBars";
import { getCustomIbmCategorical } from "../../../../config/colors";
import {
  InjuryMultipleListBar,
  MostCommonValuesBar,
} from "./InjuryReportTopValuesBar";
import {
  makeSelectFilteredInjuryReportsByModule,
  selectAllInjuryUnits,
  selectInjuryUnitEntities,
} from "../../store/injuryReportsSlice";
import { SDoughnut } from "../../../statistics/components/SDoughnut";
import { useTranslation } from "react-i18next";
import { makeSelectInjuryControlsByModuleView } from "../../store/injuryControlsSlice";
import _ from "lodash";
import {
  selectDepartmentEntities,
  selectAllDepartments,
} from "../../../departments/departmentsSlice";
import { EMPLOYEE_SECTION_NAMES } from "../../constants/employee";
import { STUDENT_SECTION_NAMES } from "../../constants/student";
import { AppCardChartContainer, StyledChartCard } from "./Tiles/ChartCard";
import {
  EMPLOYEE_INJURY_THREE_OPTIONS,
  EMPLOYEE_INJURY_TWO_OPTIONS,
  EMPLOYE_INJURY_YES_NO_OPTIONS,
} from "../FormSections/employee";
import { getChartRangeByTimestampsRange } from "../../store/controlsHelpers";
import { STUDENT_ACCIDENT_OCCURED_OPTION_IDS } from "../FormSections/student";
import { StatisticsAppliedFilterChips } from "./StatisticsFilters";
import StatusOverTimeChart from "./StatusOverTimeChart";

type SingleCountItemProps = {
  count: number;
  title: string;
  titleTypography?: any;
  countTypography?: any;
  flexDirection?: any;
  sx?: any;
  secondaryColor?: string;
};

export const SingleCountItem = (props: SingleCountItemProps) => {
  const {
    count,
    title,
    titleTypography = "h2",
    countTypography = "body2",
    flexDirection = "column",
    sx,
    secondaryColor = true,
  } = props;
  return (
    <Paper
      sx={{
        borderRadius: 0,
        px: 2.5,
        py: 2,
        pb: 2.25,
        bgcolor: (theme) =>
          theme.palette.mode === "dark"
            ? theme.palette.backgroundVariant
            : "background.paper",
      }}
    >
      <Stack direction={flexDirection}>
        <Typography variant={titleTypography}>{count}</Typography>
        <Typography variant={countTypography}>{title}</Typography>
      </Stack>
    </Paper>
  );
};

type InjuryInsightsProps = {
  module?: string;
};

export const Insights = (props: InjuryInsightsProps) => {
  const { module = "all" } = props;

  const selectInjuryReportsByModule = React.useMemo(
    makeSelectFilteredInjuryReportsByModule,
    [module],
  );
  const departments = useAppSelector(selectDepartmentEntities);
  const allDepartments = useAppSelector(selectAllDepartments);
  const allUnits = useAppSelector(selectAllInjuryUnits);
  const units = useAppSelector(selectInjuryUnitEntities);

  const { t } = useTranslation();
  const theme = useTheme();
  const injuryReports = useAppSelector((state: RootState) =>
    selectInjuryReportsByModule(state, module),
  );

  const selectInjuryControlsByViewAndModule = React.useMemo(
    makeSelectInjuryControlsByModuleView,
    [],
  );
  const injuryControls = useAppSelector((state: RootState) =>
    selectInjuryControlsByViewAndModule(state, `${module}_handler`),
  );

  const statisticsFilters = injuryControls.appliedFilters;

  const reportedTimestamp = statisticsFilters.find(
    (filter) => filter.field === "reportedTimestamp",
  )?.value.label;

  const selectDepartmentCountStatistics = () => {
    const reports = [...injuryReports.slice()];
    const count: any = {};
    const registered: string[] = [];
    for (const report of reports) {
      if (
        report.type === REPORT_TYPES.ACCIDENT ||
        report.type === REPORT_TYPES.ILLNESS ||
        report.type === REPORT_TYPES.MINOR_INJURY
      ) {
        if (report.category === REPORT_CATEGORY.EMPLOYEE) {
          const departmentId =
            report.type === REPORT_TYPES.MINOR_INJURY
              ? report.sections[EMPLOYEE_SECTION_NAMES.MINOR_INJURY]
                  ?.departmentId
              : report.sections[EMPLOYEE_SECTION_NAMES.EMPLOYER]?.departmentId;
          if (departmentId) {
            if (!registered.includes(departmentId)) {
              registered.push(departmentId);
              count[departmentId] = 1;
            } else {
              count[departmentId]++;
            }
          }
        }
        if (report.category === REPORT_CATEGORY.STUDENT) {
          const departmentId =
            report.type === REPORT_TYPES.MINOR_INJURY
              ? report.sections[STUDENT_SECTION_NAMES.MINOR_INJURY]
                  ?.departmentId
              : report.sections[STUDENT_SECTION_NAMES.SCHOOL]?.departmentId;
          if (departmentId) {
            if (!registered.includes(departmentId)) {
              registered.push(departmentId);
              count[departmentId] = 1;
            } else {
              count[departmentId]++;
            }
          }
        }
      }
    }

    return registered.map((registeredVal) => ({
      value: departments[registeredVal]?.name || registeredVal,
      count: count[registeredVal],
    }));
  };

  const selectUnitCountStatistics = () => {
    const reports = [...injuryReports.slice()];
    const count: any = {};
    const registered: string[] = [];
    for (const report of reports) {
      if (
        report.type === REPORT_TYPES.ACCIDENT ||
        report.type === REPORT_TYPES.ILLNESS ||
        report.type === REPORT_TYPES.MINOR_INJURY
      ) {
        let unitId = undefined;
        if (report.category === REPORT_CATEGORY.EMPLOYEE) {
          unitId =
            report.type === REPORT_TYPES.MINOR_INJURY
              ? report.sections[EMPLOYEE_SECTION_NAMES.MINOR_INJURY]?.unitId
              : report.sections[EMPLOYEE_SECTION_NAMES.EMPLOYER]?.unitId;
        }
        if (report.category === REPORT_CATEGORY.STUDENT) {
          unitId =
            report.type === REPORT_TYPES.MINOR_INJURY
              ? report.sections[STUDENT_SECTION_NAMES.MINOR_INJURY]?.unitId
              : report.sections[STUDENT_SECTION_NAMES.SCHOOL]?.unitId;
        }
        if (unitId) {
          if (units[unitId]?.unit_name) {
            if (!registered.includes(unitId)) {
              registered.push(unitId);
              count[unitId] = 1;
            } else {
              count[unitId]++;
            }
          }
        }
      }
    }

    return registered.map((registeredVal) => ({
      value: units[registeredVal]?.unit_name,
      count: count[registeredVal],
    }));
  };

  const selectTypeCount = (keyOfType: string) => {
    const reports = [...injuryReports.slice()];
    const count: any = {};
    const registered: string[] = [];
    for (const report of reports) {
      if (report.type === REPORT_TYPES.ACCIDENT) {
        if (report.category === REPORT_CATEGORY.EMPLOYEE) {
          const typeInfo = _.get(
            report,
            `sections.employeeTypes.${keyOfType}`,
            null,
          );
          if (typeInfo) {
            for (const value of typeInfo) {
              if (!registered.includes(value)) {
                registered.push(value);
                count[value] = 1;
              } else {
                count[value]++;
              }
            }
          }
        } else if (report.category === REPORT_CATEGORY.STUDENT) {
          const typeInfo = _.get(
            report,
            `sections.studentAccident.${keyOfType}`,
            null,
          );
          if (typeInfo) {
            for (const value of typeInfo) {
              if (!registered.includes(value)) {
                registered.push(value);
                count[value] = 1;
              } else {
                count[value]++;
              }
            }
          }
        }
      }
    }

    return registered.map((registeredVal) => ({
      value: registeredVal,
      count: count[registeredVal],
    }));
  };

  const counts = React.useMemo(() => {
    const counts: any = {};
    counts.accidentTypeWithCount = selectTypeCount("accidentType");
    counts.damagedBodyPartWithCount = selectTypeCount("damagedBodyPart");
    counts.backgroundWithCount = selectTypeCount("background");
    counts.damageNatureWithCount = selectTypeCount("damageNature");
    counts.typeOfWorkplaceWithCount = selectTypeCount("typeOfWorkplace");
    counts.deviationWithCount = selectTypeCount("deviation");
    counts.assumedAbsenceWithCount = selectTypeCount("assumedAbsence");
    counts.damageModeWithCount = selectTypeCount("damageMode");
    counts.totalCount = injuryReports.length;
    counts.ongoingCount = injuryReports.filter(
      (injuryReport) => injuryReport.status === CASE_STATUS.ONGOING,
    ).length;
    counts.closedCount = injuryReports.filter(
      (injuryReport) => injuryReport.status === CASE_STATUS.CLOSED,
    ).length;
    counts.rejectedCount = injuryReports.filter(
      (injuryReport) => injuryReport.status === CASE_STATUS.REJECTED,
    ).length;
    counts.accidentCount = injuryReports.filter(
      (injuryReport) => injuryReport.type === REPORT_TYPES.ACCIDENT,
    ).length;
    counts.illnessCount = injuryReports.filter(
      (injuryReport) => injuryReport.type === REPORT_TYPES.ILLNESS,
    ).length;
    counts.minorInjuryCount = injuryReports.filter(
      (injuryReport) => injuryReport.type === REPORT_TYPES.MINOR_INJURY,
    ).length;
    counts.departmentCountStatistics = selectDepartmentCountStatistics();
    counts.unitCountStatistics = selectUnitCountStatistics();
    return counts;
  }, [injuryReports, module, departments, units]);

  const {
    accidentTypeWithCount,
    damageNatureWithCount,
    damagedBodyPartWithCount,
    backgroundWithCount,
    deviationWithCount,
    assumedAbsenceWithCount,
    damageModeWithCount,
    typeOfWorkplaceWithCount,
    totalCount,
    ongoingCount,
    closedCount,
    rejectedCount,
    accidentCount,
    illnessCount,
    minorInjuryCount,
    departmentCountStatistics,
    unitCountStatistics,
  } = counts;

  const getNatureLabel = (s: any) => {
    return t(`${TKB_INJURY}.employee.typeTab.natureOfDamage.${s}`);
  };

  const getDamagedBodyPartLabel = (s: any) => {
    return t(`${TKB_INJURY}.employee.typeTab.damagedBodyPart.${s}`);
  };

  const getAccidentTypeLabel = React.useCallback(
    (s: any) => {
      return t(`${TKB_INJURY}.employee.typeTab.typeOfAccident.${s}`);
    },
    [t],
  );

  const getTypeLabel = React.useCallback(
    (s: any, keyOfType: string) => {
      return t(`${TKB_INJURY}.employee.typeTab.${keyOfType}.${s}`);
    },
    [t],
  );

  const getTranslatedMostCommonWithFieldLabel = React.useCallback(
    (fieldKey: string) => {
      return `${t("wif.injury.stats.mostCommon")}: ${t(
        `wif.injury.employee.typeTab.${fieldKey}.title`,
      )}`;
    },
    [t],
  );

  const mappedDepartmentLabels = React.useMemo(() => {
    const departmentIdLabelPairs = allDepartments.map((department) => ({
      id: department.id,
      label: department.name,
    }));
    return departmentIdLabelPairs.reduce((acc, pair) => {
      const { id, label } = pair;
      return { ...acc, [id]: label };
    }, {}) as any;
  }, [allDepartments]);

  const mappedUnitLabels = React.useMemo(() => {
    const arr = allUnits.map((unit) => ({
      id: unit.unit_id,
      label: unit.unit_name,
    }));
    return arr.reduce((acc, pair) => {
      const { id, label } = pair;
      return { ...acc, [id]: label };
    }, {}) as any;
  }, [allDepartments]);

  const appliedStatisticFilters = injuryControls.appliedFilters;

  // Touple of period, year and label
  const calculatedTimePeriod: [string, string, string] = React.useMemo(() => {
    const reportedAppliedFilter = appliedStatisticFilters.find(
      (appliedFilter) => appliedFilter.field === "reportedTimestamp",
    );

    return [
      reportedAppliedFilter?.value?.key || "1Y",
      reportedAppliedFilter?.value?.year,
      reportedAppliedFilter?.value?.label,
    ];
  }, [injuryControls]);

  const studentAccidentCases = injuryReports.filter(
    (injuryReport) =>
      injuryReport.type === REPORT_TYPES.ACCIDENT &&
      injuryReport.category === "student",
  );
  const employeeAccidentCases = injuryReports.filter(
    (injuryReport) =>
      injuryReport.type === REPORT_TYPES.ACCIDENT &&
      injuryReport.category === "employee",
  );

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {appliedStatisticFilters.length > 0 && (
        <Box
          sx={{
            pt: {
              xs: 2.5,
            },
            bgcolor: (theme) =>
              theme.palette.mode === "light"
                ? APP_COLORS.light2
                : theme.palette.background.paper,
          }}
        >
          <StatisticsAppliedFilterChips module={module} />
        </Box>
      )}

      <Box
        sx={{
          flex: 1,
          py: {
            xs: 2.5,
          },
          px: {
            xs: 2.5,
            md: 3,
          },
          bgcolor: (theme) =>
            theme.palette.mode === "light"
              ? APP_COLORS.light2
              : theme.palette.background.paper,
          /* overflowY: 'auto', */
        }}
        className="BackgroundVariant"
      >
        <Paper
          sx={{
            borderRadius: 0,
            bgcolor: (theme) =>
              theme.palette.mode === "light"
                ? "background.paper"
                : "backgroundVariant",
            p: {
              xs: 2,
            },
            py: 2.5,
            my: {
              xs: 2.5,
              md: 2,
            },
          }}
        >
          <Grid
            container
            spacing={{
              xs: 1.5,
              md: 2,
            }}
            rowSpacing={{
              xs: 2,
            }}
            sx={{
              minHeight: 0,
              pb: {
                xs: 1,
                md: 0,
              },
            }}
          >
            <Grid xs={12} md={3}>
              <Paper
                sx={{
                  bgcolor:
                    theme.palette.mode === "dark"
                      ? APP_COLORS.dark3
                      : APP_COLORS.light3,
                  boxShadow: "none",
                  borderRadius: 1,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography variant="h1" color="primary.main">
                  {totalCount}
                </Typography>
                <Typography variant="body1">
                  {t("wif.injury.stats.reportsTotal", { count: totalCount })}
                </Typography>
              </Paper>
            </Grid>
            <Grid container xs={12} md={9}>
              <Grid xs={6} md={4}>
                <SingleCountItem
                  count={accidentCount}
                  title={t("wif.injury.stats.accidentsReported", {
                    count: accidentCount,
                  })}
                />
              </Grid>
              <Grid xs={6} md={4}>
                <SingleCountItem
                  count={illnessCount}
                  title={t("wif.injury.stats.illnessesReported", {
                    count: illnessCount,
                  })}
                />
              </Grid>
              <Grid xs={6} md={4}>
                <SingleCountItem
                  count={minorInjuryCount}
                  title={t("wif.injury.stats.minorInjuriesReported", {
                    count: minorInjuryCount,
                  })}
                />
              </Grid>
              <Grid xs={6} md={4}>
                <SingleCountItem
                  count={ongoingCount}
                  title={t("wif.injury.stats.ongoingCases", {
                    count: ongoingCount,
                  })}
                />
              </Grid>
              <Grid xs={6} md={4}>
                <SingleCountItem
                  count={closedCount}
                  title={t("wif.injury.stats.closedCases", {
                    count: closedCount,
                  })}
                />
              </Grid>
              <Grid xs={6} md={4}>
                <SingleCountItem
                  count={rejectedCount}
                  title={t("wif.injury.stats.rejectedCases", {
                    count: rejectedCount,
                  })}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        {totalCount > 0 && (
          <Grid
            container
            spacing={2.5}
            rowSpacing={2.5}
            sx={{
              minHeight: 0,
            }}
          >
            <Grid xs={12} md={module === "all" ? 4 : 6}>
              <StyledChartCard>
                <AppCardChartContainer size="small">
                  <SDoughnut
                    title={t("wif.injury.stats.reportsByStatus")}
                    subtitle={reportedTimestamp}
                    label={t("wif.injury.reports")}
                    position="right"
                    arcs={{
                      key: "status",
                      labels: [
                        t("wif.injury.statuses.ongoing"),
                        t("wif.injury.statuses.rejected"),
                        t("wif.injury.statuses.closed"),
                      ],
                      values: [
                        CASE_STATUS.ONGOING,
                        CASE_STATUS.REJECTED,
                        CASE_STATUS.CLOSED,
                      ],
                      colors: [
                        theme.palette.warning.main,
                        theme.palette.error.main,
                        theme.palette.success.main,
                      ],
                    }}
                    dataSource={injuryReports}
                  />
                </AppCardChartContainer>
              </StyledChartCard>
            </Grid>
            <Grid xs={12} md={module === "all" ? 4 : 6}>
              <StyledChartCard>
                <AppCardChartContainer size="small">
                  <SDoughnut
                    title={t("wif.injury.stats.reportsByType")}
                    subtitle={reportedTimestamp}
                    label={t("wif.injury.reports")}
                    position="right"
                    arcs={{
                      key: "type",
                      labels: [
                        t("wif.injury.reportType.accident"),
                        t("wif.injury.reportType.minorInjury"),
                        t("wif.injury.reportType.illness"),
                      ],
                      values: [
                        REPORT_TYPES.ACCIDENT,
                        REPORT_TYPES.MINOR_INJURY,
                        REPORT_TYPES.ILLNESS,
                      ],
                      colors: getCustomIbmCategorical({
                        numberOfCategories: 3,
                        palette: 2,
                      }),
                    }}
                    dataSource={injuryReports}
                  />
                </AppCardChartContainer>
              </StyledChartCard>
            </Grid>
            {module === "all" && (
              <Grid xs={12} md={4}>
                <StyledChartCard>
                  <AppCardChartContainer size="small">
                    <SDoughnut
                      title={t("wif.injury.stats.reportsByCategory")}
                      subtitle={reportedTimestamp}
                      label={t("wif.injury.reports")}
                      position="right"
                      arcs={{
                        key: "category",
                        values: [
                          REPORT_CATEGORY.STUDENT,
                          REPORT_CATEGORY.EMPLOYEE,
                        ],
                        labels: [
                          t("wif.injury.reportCategory.student"),
                          t("wif.injury.reportCategory.employee"),
                        ],
                        colors: getCustomIbmCategorical({
                          numberOfCategories: 2,
                          palette: 1,
                        }),
                      }}
                      dataSource={injuryReports}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
            )}
            {departmentCountStatistics &&
              departmentCountStatistics.length > 1 && (
                <Grid xs={12} lg={6}>
                  <StyledChartCard>
                    <AppCardChartContainer size="full">
                      <MostCommonValuesBar
                        title={t("wif.injury.stats.departmentsWithMostReports")}
                        subtitle={reportedTimestamp}
                        color={theme.palette.primary.main}
                        valuesWithCount={departmentCountStatistics}
                      />
                    </AppCardChartContainer>
                  </StyledChartCard>
                </Grid>
              )}

            {unitCountStatistics && unitCountStatistics.length > 1 && (
              <Grid xs={12} lg={6}>
                <StyledChartCard>
                  <AppCardChartContainer size="full">
                    <MostCommonValuesBar
                      title={t("wif.injury.stats.unitsWithMostReports")}
                      subtitle={reportedTimestamp}
                      color={theme.palette.warning.main}
                      valuesWithCount={unitCountStatistics}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
            )}

            <Grid xs={12} lg={6}>
              <StyledChartCard>
                <AppCardChartContainer>
                  <StatusOverTimeChart
                    title={t("wif.injury.stats.reportsByStatus")}
                    timePeriod={calculatedTimePeriod}
                    filters={appliedStatisticFilters}
                    module={module}
                  />
                </AppCardChartContainer>
              </StyledChartCard>
            </Grid>

            <Grid xs={12} lg={6}>
              <StyledChartCard>
                <AppCardChartContainer>
                  <InjuryReportsByMonthBars
                    reports={injuryReports}
                    timePeriod={calculatedTimePeriod}
                    title={t("wif.injury.stats.reportsByStatusPerMonth")}
                    subtitle={reportedTimestamp}
                    bars={{
                      field: "status",
                      values: [
                        CASE_STATUS.ONGOING,
                        CASE_STATUS.REJECTED,
                        CASE_STATUS.CLOSED,
                      ],
                      labels: [
                        t("wif.injury.statuses.ongoing"),
                        t("wif.injury.statuses.rejected"),
                        t("wif.injury.statuses.closed"),
                      ],
                      colors: getCustomIbmCategorical({
                        numberOfCategories: 3,
                        palette: 2,
                      }),
                    }}
                  />
                </AppCardChartContainer>
              </StyledChartCard>
            </Grid>
            <Grid xs={12} lg={6}>
              <StyledChartCard>
                <AppCardChartContainer>
                  <InjuryReportsByMonthBars
                    reports={injuryReports}
                    timePeriod={calculatedTimePeriod}
                    title={t("wif.injury.stats.reportsByTypePerMonth")}
                    subtitle={reportedTimestamp}
                    bars={{
                      field: "type",
                      values: [
                        REPORT_TYPES.ACCIDENT,
                        REPORT_TYPES.ILLNESS,
                        REPORT_TYPES.MINOR_INJURY,
                      ],
                      labels: [
                        t("wif.injury.reportType.accident"),
                        t("wif.injury.reportType.minorInjury"),
                        t("wif.injury.reportType.illness"),
                      ],
                      colors: getCustomIbmCategorical({
                        numberOfCategories: 3,
                        palette: 2,
                      }),
                    }}
                  />
                </AppCardChartContainer>
              </StyledChartCard>
            </Grid>
            {module === "all" && (
              <Grid xs={12} lg={6}>
                <StyledChartCard>
                  <AppCardChartContainer>
                    <InjuryReportsByMonthBars
                      reports={injuryReports}
                      timePeriod={calculatedTimePeriod}
                      title={t("wif.injury.stats.reportsByCategoryPerMonth")}
                      subtitle={reportedTimestamp}
                      bars={{
                        field: "category",
                        values: [
                          REPORT_CATEGORY.STUDENT,
                          REPORT_CATEGORY.EMPLOYEE,
                        ],
                        labels: [
                          t("wif.injury.reportCategory.student"),
                          t("wif.injury.reportCategory.employee"),
                        ],
                        colors: getCustomIbmCategorical({
                          numberOfCategories: 2,
                          palette: 1,
                        }),
                      }}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
            )}
          </Grid>
        )}
        {departmentCountStatistics && departmentCountStatistics.length > 3 && (
          <>
            <Typography variant="h3" sx={{ mt: 4, mb: 2 }}>
              {t("wif.injury.department")}
            </Typography>
            <Grid
              container
              spacing={2.5}
              rowSpacing={2.5}
              sx={{
                minHeight: 0,
              }}
            >
              <Grid xs={12} lg={6}>
                <StyledChartCard>
                  <AppCardChartContainer size="full">
                    <InjuryMultipleListBar
                      title={t("wif.injury.stats.departmentsWithMostReports")}
                      subtitle={reportedTimestamp}
                      reports={injuryReports}
                      labelKeys={[
                        `sections.${EMPLOYEE_SECTION_NAMES.EMPLOYER}.departmentId`,
                        `sections.${EMPLOYEE_SECTION_NAMES.MINOR_INJURY}.departmentId`,
                        `sections.${STUDENT_SECTION_NAMES.SCHOOL}.departmentId`,
                        `sections.${STUDENT_SECTION_NAMES.MINOR_INJURY}.departmentId`,
                      ]}
                      mappedLabels={mappedDepartmentLabels}
                      bars={{
                        field: "type",
                        values: [
                          REPORT_TYPES.ACCIDENT,
                          REPORT_TYPES.ILLNESS,
                          REPORT_TYPES.MINOR_INJURY,
                        ],
                        labels: [
                          t("wif.injury.reportType.accident"),
                          t("wif.injury.reportType.illness"),
                          t("wif.injury.reportType.minorInjury"),
                        ],
                        colors: getCustomIbmCategorical({
                          numberOfCategories: 3,
                          palette: 2,
                        }),
                      }}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
              <Grid xs={12} lg={6}>
                <StyledChartCard>
                  <AppCardChartContainer size="full">
                    <InjuryMultipleListBar
                      title={t("wif.injury.stats.departmentsWithMostReports")}
                      subtitle={reportedTimestamp}
                      reports={injuryReports}
                      labelKeys={[
                        `sections.${EMPLOYEE_SECTION_NAMES.EMPLOYER}.departmentId`,
                        `sections.${STUDENT_SECTION_NAMES.SCHOOL}.departmentId`,
                        `sections.${EMPLOYEE_SECTION_NAMES.MINOR_INJURY}.departmentId`,
                        `sections.${STUDENT_SECTION_NAMES.MINOR_INJURY}.departmentId`,
                      ]}
                      mappedLabels={mappedDepartmentLabels}
                      bars={{
                        field: "status",
                        labels: [
                          t("wif.injury.statuses.ongoing"),
                          t("wif.injury.statuses.rejected"),
                          t("wif.injury.statuses.closed"),
                        ],
                        values: [
                          CASE_STATUS.ONGOING,
                          CASE_STATUS.REJECTED,
                          CASE_STATUS.CLOSED,
                        ],
                        colors: [
                          theme.palette.warning.main,
                          theme.palette.error.main,
                          theme.palette.success.main,
                        ],
                      }}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
            </Grid>
          </>
        )}
        {unitCountStatistics && unitCountStatistics.length > 3 && (
          <>
            <Typography variant="h3" sx={{ mt: 4, mb: 2 }}>
              {t("wif.injury.unit")}
            </Typography>
            <Grid
              container
              spacing={2.5}
              rowSpacing={2.5}
              sx={{
                minHeight: 0,
              }}
            >
              <Grid xs={12} lg={6}>
                <StyledChartCard>
                  <AppCardChartContainer size="full">
                    <InjuryMultipleListBar
                      title={t("wif.injury.stats.unitsWithMostReports")}
                      subtitle={reportedTimestamp}
                      reports={injuryReports}
                      labelKeys={[
                        `sections.${EMPLOYEE_SECTION_NAMES.EMPLOYER}.unitId`,
                        `sections.${STUDENT_SECTION_NAMES.SCHOOL}.unitId`,
                        `sections.${EMPLOYEE_SECTION_NAMES.MINOR_INJURY}.unitId`,
                        `sections.${STUDENT_SECTION_NAMES.MINOR_INJURY}.unitId`,
                      ]}
                      mappedLabels={mappedUnitLabels}
                      bars={{
                        field: "type",
                        values: [
                          REPORT_TYPES.ACCIDENT,
                          REPORT_TYPES.ILLNESS,
                          REPORT_TYPES.MINOR_INJURY,
                        ],
                        labels: [
                          t("wif.injury.reportType.accident"),
                          t("wif.injury.reportType.illness"),
                          t("wif.injury.reportType.minorInjury"),
                        ],
                        colors: getCustomIbmCategorical({
                          numberOfCategories: 3,
                          palette: 2,
                        }),
                      }}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
              <Grid xs={12} lg={6}>
                <StyledChartCard>
                  <AppCardChartContainer size="full">
                    <InjuryMultipleListBar
                      title={t("wif.injury.stats.unitsWithMostReports")}
                      subtitle={reportedTimestamp}
                      reports={injuryReports}
                      labelKeys={[
                        `sections.${EMPLOYEE_SECTION_NAMES.EMPLOYER}.unitId`,
                        `sections.${STUDENT_SECTION_NAMES.SCHOOL}.unitId`,
                        `sections.${EMPLOYEE_SECTION_NAMES.MINOR_INJURY}.unitId`,
                        `sections.${STUDENT_SECTION_NAMES.MINOR_INJURY}.unitId`,
                      ]}
                      mappedLabels={mappedUnitLabels}
                      bars={{
                        field: "status",
                        labels: [
                          t("wif.injury.statuses.ongoing"),
                          t("wif.injury.statuses.rejected"),
                          t("wif.injury.statuses.closed"),
                        ],
                        values: [
                          CASE_STATUS.ONGOING,
                          CASE_STATUS.REJECTED,
                          CASE_STATUS.CLOSED,
                        ],
                        colors: [
                          theme.palette.warning.main,
                          theme.palette.error.main,
                          theme.palette.success.main,
                        ],
                      }}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
            </Grid>
          </>
        )}
        {module !== "employee" && studentAccidentCases.length >= 3 && (
          <>
            <Typography variant="h3" sx={{ mt: 4, mb: 2 }}>
              {t("wif.injury.studentInjuries")}
            </Typography>
            <Grid
              container
              spacing={2.5}
              rowSpacing={2.5}
              sx={{
                minHeight: 0,
              }}
            >
              <Grid xs={12} md={8}>
                <StyledChartCard>
                  <AppCardChartContainer size="small">
                    <InjuryReportTimeOfDayBars
                      title={t("wif.injury.stats.accidentTime")}
                      subtitle={reportedTimestamp}
                      reports={injuryReports}
                      fieldPath={`sections.${STUDENT_SECTION_NAMES.ACCIDENT}.time`}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
              <Grid xs={12} md={4}>
                <StyledChartCard>
                  <AppCardChartContainer size="small">
                    <SDoughnut
                      title={t(
                        "wif.injury.accidentInfoTab.accidentOccurred.short",
                      )}
                      subtitle={reportedTimestamp}
                      label={t("wif.injury.reports")}
                      arcs={{
                        key: "sections.studentAccident.accidentOccured",
                        labels: [
                          t(
                            `${TKB_INJURY}.accidentTypesOccurredTags.teachingLocation`,
                          ),
                          t(
                            `${TKB_INJURY}.accidentTypesOccurredTags.duringTeaching`,
                          ),
                          t(`${TKB_INJURY}.accidentTypesOccurredTags.sfo`),
                          t(`${TKB_INJURY}.accidentTypesOccurredTags.onTheWay`),
                          t(`${TKB_INJURY}.accidentTypesOccurredTags.other`),
                        ],
                        values: STUDENT_ACCIDENT_OCCURED_OPTION_IDS,
                        colors: getCustomIbmCategorical({
                          numberOfCategories: 5,
                          palette: 1,
                        }),
                      }}
                      dataSource={injuryReports}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
            </Grid>
          </>
        )}
        {module !== "student" && employeeAccidentCases.length >= 3 && (
          <>
            <Typography variant="h3" sx={{ mt: 4, mb: 2 }}>
              {t("wif.injury.employeeInjuries")}
            </Typography>
            <Grid
              container
              spacing={2.5}
              rowSpacing={2.5}
              sx={{
                minHeight: 0,
              }}
            >
              <Grid xs={12} md={6}>
                <StyledChartCard>
                  <AppCardChartContainer size="small">
                    <SDoughnut
                      title={t(
                        "wif.injury.employee.employeePersonTab.natureOfWorking.short",
                      )}
                      subtitle={reportedTimestamp}
                      label={t("wif.injury.reports")}
                      arcs={{
                        key: "sections.employee.natureOfEmployment",
                        labels: [
                          t(
                            "wif.injury.employee.employeePersonTab.natureOfWorking.option1",
                          ),
                          t(
                            "wif.injury.employee.employeePersonTab.natureOfWorking.option2",
                          ),
                          t(
                            "wif.injury.employee.employeePersonTab.natureOfWorking.option3",
                          ),
                        ],
                        values: EMPLOYEE_INJURY_THREE_OPTIONS,
                        colors: getCustomIbmCategorical({
                          numberOfCategories: 3,
                          palette: 2,
                        }),
                      }}
                      dataSource={injuryReports}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
              <Grid xs={12} md={6}>
                <StyledChartCard>
                  <AppCardChartContainer size="small">
                    <SDoughnut
                      title={t(
                        "wif.injury.employee.employeePersonTab.competenceLevel.title",
                      )}
                      subtitle={reportedTimestamp}
                      label={t("wif.injury.reports")}
                      arcs={{
                        key: "sections.employee.competenceLevel",
                        labels: [
                          t(
                            "wif.injury.employee.employeePersonTab.competenceLevel.option1",
                          ),
                          t(
                            "wif.injury.employee.employeePersonTab.competenceLevel.option2",
                          ),
                          t(
                            "wif.injury.employee.employeePersonTab.competenceLevel.option3",
                          ),
                        ],
                        values: EMPLOYEE_INJURY_THREE_OPTIONS,
                        colors: getCustomIbmCategorical({
                          numberOfCategories: 3,
                          palette: 2,
                        }),
                      }}
                      dataSource={injuryReports}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
              <Grid xs={12} md={4}>
                <StyledChartCard>
                  <AppCardChartContainer size="small">
                    <SDoughnut
                      title={t(
                        "wif.injury.accidentInfoTab.accidentOccurred.short",
                      )}
                      subtitle={reportedTimestamp}
                      label={t("wif.injury.reports")}
                      arcs={{
                        key: "sections.employeeAccident.accidentOccured",
                        labels: [
                          t(
                            "wif.injury.employee.accidentTab.accidentOccurred.option1",
                          ),
                          t(
                            "wif.injury.employee.accidentTab.accidentOccurred.option2",
                          ),
                          t(
                            "wif.injury.employee.accidentTab.accidentOccurred.option3",
                          ),
                        ],
                        values: EMPLOYEE_INJURY_THREE_OPTIONS,
                        colors: getCustomIbmCategorical({
                          numberOfCategories: 3,
                          palette: 2,
                        }),
                      }}
                      dataSource={injuryReports}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
              <Grid xs={12} md={8}>
                <StyledChartCard>
                  <AppCardChartContainer size="small">
                    <InjuryReportTimeOfDayBars
                      title={t("wif.injury.stats.accidentTime")}
                      subtitle={reportedTimestamp}
                      reports={injuryReports}
                      fieldPath={`sections.${EMPLOYEE_SECTION_NAMES.ACCIDENT}.time`}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
              <Grid xs={12} md={4}>
                <StyledChartCard>
                  <AppCardChartContainer size="small">
                    <SDoughnut
                      title={t(
                        "wif.injury.employee.accidentTab.arrangements.title",
                      )}
                      subtitle={reportedTimestamp}
                      label={t("wif.injury.reports")}
                      position="right"
                      arcs={{
                        key: "sections.employeeAccident.workTimeArrangements",
                        labels: [
                          t(
                            "wif.injury.employee.accidentTab.arrangements.option1",
                          ),
                          t(
                            "wif.injury.employee.accidentTab.arrangements.option2",
                          ),
                        ],
                        values: EMPLOYEE_INJURY_TWO_OPTIONS,
                        colors: getCustomIbmCategorical({
                          numberOfCategories: 2,
                          palette: 3,
                        }),
                      }}
                      dataSource={injuryReports}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>

              <Grid xs={12} md={4}>
                <StyledChartCard>
                  <AppCardChartContainer size="small">
                    <SDoughnut
                      title={`${t(
                        "wif.injury.employee.accidentTab.whereAccidentOccurred.option1",
                      )}/${t(
                        "wif.injury.employee.accidentTab.whereAccidentOccurred.option2",
                      )}
                    `}
                      subtitle={reportedTimestamp}
                      label={t("wif.injury.reports")}
                      position="right"
                      arcs={{
                        key: "sections.employeeAccident.insideOutside",
                        labels: [
                          t(
                            `${TKB_INJURY}.employee.accidentTab.whereAccidentOccurred.option1`,
                          ),
                          t(
                            `${TKB_INJURY}.employee.accidentTab.whereAccidentOccurred.option2`,
                          ),
                        ],
                        values: EMPLOYEE_INJURY_TWO_OPTIONS,
                        colors: getCustomIbmCategorical({
                          numberOfCategories: 2,
                          palette: 5,
                        }),
                      }}
                      dataSource={injuryReports}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
              <Grid xs={12} md={4}>
                <StyledChartCard>
                  <AppCardChartContainer size="small">
                    <SDoughnut
                      title={t(
                        "wif.injury.employee.accidentTab.onTheWay.title",
                      )}
                      subtitle={reportedTimestamp}
                      label={t("wif.injury.reports")}
                      position="right"
                      arcs={{
                        key: "sections.employeeAccident.onTheWayToWork",
                        labels: [
                          t(`${TKB_INJURY}.yesNoOptions.yes`),
                          t(`${TKB_INJURY}.yesNoOptions.no`),
                        ],
                        values: EMPLOYE_INJURY_YES_NO_OPTIONS,
                        colors: getCustomIbmCategorical({
                          numberOfCategories: 2,
                          palette: 5,
                        }),
                      }}
                      dataSource={injuryReports}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
              <Grid xs={12} md={4}>
                <StyledChartCard>
                  <AppCardChartContainer size="small">
                    <SDoughnut
                      title={t(
                        "wif.injury.employee.accidentTab.onTheWayBetween.title",
                      )}
                      subtitle={reportedTimestamp}
                      label={t("wif.injury.reports")}
                      position="right"
                      arcs={{
                        key: "sections.employeeAccident.onTheWayBetweenWork",
                        labels: [
                          t(`${TKB_INJURY}.yesNoOptions.yes`),
                          t(`${TKB_INJURY}.yesNoOptions.no`),
                        ],
                        values: EMPLOYE_INJURY_YES_NO_OPTIONS,
                        colors: getCustomIbmCategorical({
                          numberOfCategories: 2,
                          palette: 5,
                        }),
                      }}
                      dataSource={injuryReports}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
              <Grid xs={12} md={4}>
                <StyledChartCard>
                  <AppCardChartContainer size="small">
                    <SDoughnut
                      title={t(
                        "wif.injury.employee.accidentTab.deathFromInjury.title",
                      )}
                      subtitle={reportedTimestamp}
                      label={t("wif.injury.reports")}
                      position="right"
                      arcs={{
                        key: "sections.employeeAccident.deathFromAccident",
                        labels: [
                          t(`${TKB_INJURY}.yesNoOptions.yes`),
                          t(`${TKB_INJURY}.yesNoOptions.no`),
                        ],
                        values: EMPLOYE_INJURY_YES_NO_OPTIONS,
                        colors: getCustomIbmCategorical({
                          numberOfCategories: 2,
                          palette: 5,
                        }),
                      }}
                      dataSource={injuryReports}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
              <Grid xs={12} md={4}>
                <StyledChartCard>
                  <AppCardChartContainer size="small">
                    <SDoughnut
                      title={t(
                        "wif.injury.employee.accidentTab.necessaryTraining.short",
                      )}
                      subtitle={reportedTimestamp}
                      label={t("wif.injury.reports")}
                      position="right"
                      arcs={{
                        key: "sections.employeeAccident.necessaryTraining",
                        labels: [
                          t(`${TKB_INJURY}.yesNoOptions.yes`),
                          t(`${TKB_INJURY}.yesNoOptions.no`),
                        ],
                        values: EMPLOYE_INJURY_YES_NO_OPTIONS,
                        colors: getCustomIbmCategorical({
                          numberOfCategories: 2,
                          palette: 5,
                        }),
                      }}
                      dataSource={injuryReports}
                    />
                  </AppCardChartContainer>
                </StyledChartCard>
              </Grid>
            </Grid>
          </>
        )}

        {accidentTypeWithCount && accidentTypeWithCount.length > 1 && (
          <Typography variant="h3" sx={{ mt: 4, mb: 2 }}>
            {t("wif.injury.stats.accidentClassification")}
          </Typography>
        )}
        <Grid
          container
          spacing={2.5}
          rowSpacing={2.5}
          sx={{
            minHeight: 0,
          }}
        >
          {accidentTypeWithCount && accidentTypeWithCount.length > 1 && (
            <Grid xs={12} lg={6}>
              <StyledChartCard>
                <AppCardChartContainer size="full">
                  <MostCommonValuesBar
                    getValueLabel={(value: string) =>
                      getAccidentTypeLabel(value)
                    }
                    title={getTranslatedMostCommonWithFieldLabel(
                      "typeOfAccident",
                    )}
                    subtitle={reportedTimestamp}
                    color={theme.palette.primary.main}
                    valuesWithCount={accidentTypeWithCount}
                  />
                </AppCardChartContainer>
              </StyledChartCard>
            </Grid>
          )}
          {backgroundWithCount && backgroundWithCount.length > 1 && (
            <Grid xs={12} lg={6}>
              <StyledChartCard>
                <AppCardChartContainer size="full">
                  <MostCommonValuesBar
                    getValueLabel={(value: string) =>
                      getTypeLabel(value, "background")
                    }
                    title={getTranslatedMostCommonWithFieldLabel("background")}
                    subtitle={reportedTimestamp}
                    valuesWithCount={backgroundWithCount}
                    color={theme.palette.error.main}
                    /* colors={SIX_VAR[2]} */
                  />
                </AppCardChartContainer>
              </StyledChartCard>
            </Grid>
          )}
          {damageNatureWithCount && damageNatureWithCount.length > 1 && (
            <Grid xs={12} lg={6}>
              <StyledChartCard>
                <AppCardChartContainer size="full">
                  <MostCommonValuesBar
                    getValueLabel={(value: string) => getNatureLabel(value)}
                    title={getTranslatedMostCommonWithFieldLabel(
                      "natureOfDamage",
                    )}
                    subtitle={reportedTimestamp}
                    valuesWithCount={damageNatureWithCount}
                    color={theme.palette.success.main}
                  />
                </AppCardChartContainer>
              </StyledChartCard>
            </Grid>
          )}
          {damagedBodyPartWithCount && damagedBodyPartWithCount.length > 1 && (
            <Grid xs={12} lg={6}>
              <StyledChartCard>
                <AppCardChartContainer size="full">
                  <MostCommonValuesBar
                    getValueLabel={(value: string) =>
                      getDamagedBodyPartLabel(value)
                    }
                    title={getTranslatedMostCommonWithFieldLabel(
                      "damagedBodyPart",
                    )}
                    subtitle={reportedTimestamp}
                    valuesWithCount={damagedBodyPartWithCount}
                    color={theme.palette.warning.main}
                  />
                </AppCardChartContainer>
              </StyledChartCard>
            </Grid>
          )}
          {damageModeWithCount && damageModeWithCount.length > 1 && (
            <Grid xs={12} lg={6}>
              <StyledChartCard>
                <AppCardChartContainer size="full">
                  <MostCommonValuesBar
                    getValueLabel={(value: string) =>
                      getTypeLabel(value, "damageMode")
                    }
                    title={getTranslatedMostCommonWithFieldLabel("damageMode")}
                    subtitle={reportedTimestamp}
                    valuesWithCount={damageModeWithCount}
                    color={theme.palette.primary.main}
                  />
                </AppCardChartContainer>
              </StyledChartCard>
            </Grid>
          )}
          {typeOfWorkplaceWithCount && typeOfWorkplaceWithCount.length > 1 && (
            <Grid xs={12} lg={6}>
              <StyledChartCard>
                <AppCardChartContainer size="full">
                  <MostCommonValuesBar
                    getValueLabel={(value: string) =>
                      getTypeLabel(value, "typeOfWorkplace")
                    }
                    title={getTranslatedMostCommonWithFieldLabel(
                      "typeOfWorkplace",
                    )}
                    subtitle={reportedTimestamp}
                    valuesWithCount={typeOfWorkplaceWithCount}
                    color={theme.palette.error.main}
                  />
                </AppCardChartContainer>
              </StyledChartCard>
            </Grid>
          )}
          {deviationWithCount && deviationWithCount.length > 1 && (
            <Grid xs={12} lg={6}>
              <StyledChartCard>
                <AppCardChartContainer size="full">
                  <MostCommonValuesBar
                    getValueLabel={(value: string) =>
                      getTypeLabel(value, "deviation")
                    }
                    title={getTranslatedMostCommonWithFieldLabel("deviation")}
                    subtitle={reportedTimestamp}
                    valuesWithCount={deviationWithCount}
                    color={theme.palette.success.main}
                  />
                </AppCardChartContainer>
              </StyledChartCard>
            </Grid>
          )}
          {assumedAbsenceWithCount && assumedAbsenceWithCount.length > 1 && (
            <Grid xs={12} lg={6}>
              <StyledChartCard>
                <AppCardChartContainer size="full">
                  <MostCommonValuesBar
                    getValueLabel={(value: string) =>
                      getTypeLabel(value, "assumedAbsence")
                    }
                    title={getTranslatedMostCommonWithFieldLabel(
                      "assumedAbsence",
                    )}
                    subtitle={reportedTimestamp}
                    valuesWithCount={assumedAbsenceWithCount}
                    color={theme.palette.warning.main}
                  />
                </AppCardChartContainer>
              </StyledChartCard>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};
