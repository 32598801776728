import React from "react";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Typography,
} from "@mui/material";
import { AppIcon } from "../../../components/Elements";
import { REPORT_TYPES } from "../../injuries/constants";
import {
  ReportsCountStatusColors,
  ReportTypeIconNames,
} from "../../injuries/constants/mappedProps";
import { useTranslation } from "react-i18next";

export type PopupCardProps = {
  label: string;
  reportsCount: {
    total?: number;
    [REPORT_TYPES.ACCIDENT]?: number;
    [REPORT_TYPES.ILLNESS]?: number;
    [REPORT_TYPES.MINOR_INJURY]?: number;
  };
  reportsCountStatus: {
    total?: number;
    [REPORT_TYPES.ACCIDENT]?: number;
    [REPORT_TYPES.ILLNESS]?: number;
    [REPORT_TYPES.MINOR_INJURY]?: number;
  };
};

export const PopupCard = (props: PopupCardProps) => {
  const { label, reportsCount, reportsCountStatus } = props;
  const { t } = useTranslation();

  const reportsCountItems = React.useMemo(() => {
    return Object.keys(reportsCount)
      .filter((type: any) => type !== "total")
      .map((type: any) => ({
        type: type,
        count: reportsCount[type as keyof typeof reportsCount] || 0,
        status: reportsCountStatus[type as keyof typeof reportsCount] || 0,
      }));
  }, [reportsCount, reportsCountStatus]);

  const getTypeText = (type: string) => {
    if (type === REPORT_TYPES.ACCIDENT) {
      return t("wif.injury.accidents");
    }
    if (type === REPORT_TYPES.ILLNESS) {
      return t("wif.injury.illnesses");
    }
    return t("wif.injury.minorInjuries");
  };

  return (
    <Paper
      elevation={1}
      sx={{
        pt: 1.5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <List
        dense
        disablePadding
        sx={{
          pb: 1.5,
          px: 2,
        }}
      >
        {reportsCountItems.map((item, index) => (
          <ListItem disableGutters key={item.type}>
            <ListItemIcon sx={{ width: "26px", minWidth: 0, mt: "-1px" }}>
              <AppIcon
                sx={{ ml: -0.25 }}
                iconName={ReportTypeIconNames[item.type]}
                color={item.status > 0 ? "text.secondary" : "textVariant"}
                opticalSize={18}
              />
            </ListItemIcon>
            <Typography
              variant="body2"
              sx={{
                color: item.status > 0 ? "text.primary" : "text.secondary",
                flex: 1,
                mr: 3,
              }}
            >
              {getTypeText(item.type)}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: ReportsCountStatusColors[item.status], mr: 0.5 }}
            >
              {item.count}
            </Typography>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Typography variant="h5" sx={{ py: 2, pt: 1.75, px: 2 }}>
        {label}
      </Typography>
    </Paper>
  );
};
