import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Title,
  SubTitle,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  Title,
  SubTitle,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
);

const labels = ["January", "February", "March", "April", "May", "June", "July"];

type BarChartProps = {
  options?: any;
  data?: any;
  id?: any;
};

export const BarChart = (props: BarChartProps) => {
  const { options, data, id } = props;
  return <Bar options={options} data={data} id={id} />;
};

export const ListBarChart = (props: BarChartProps) => {
  const { options, data, id } = props;
  return (
    <Bar options={options} data={data} id={id} plugins={[ChartDataLabels]} />
  );
};
