import { Box, Stack, Typography } from "@mui/material";
import { useCurrentSite } from "../../locationsHooks";
import { StyledDetailsCardContent } from "../../../../components/DetailsCard/DetailsCard";
import React from "react";
import { useTranslation } from "react-i18next";
import { makeSelectSiteLogsBySiteId } from "../../locationsSlice";
import {
  type RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../store";
import { fetchSiteLogs } from "../../locationsThunks";
import { SiteLogItem } from "../History/SiteLogItem";
import { useLoading } from "../../../../common/useLoading";
import { AppLogItemSkeleton } from "../../../../components/AppLogItem/AppLogItemSkeleton";

export const SiteDetailsHistoryPanel = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const site = useCurrentSite();

  const [{ isLoading, loadingMessage }, { startLoading, stopLoading }] =
    useLoading(false);

  const selectSiteLogsBySiteId = React.useMemo(makeSelectSiteLogsBySiteId, [
    site?.site_id,
  ]);

  const siteLogs = useAppSelector((state: RootState) =>
    selectSiteLogsBySiteId(state, site?.site_id),
  );

  React.useEffect(() => {
    if (siteLogs.length === 0) {
      startLoading();
      dispatch(fetchSiteLogs(site?.site_id)).then((resAction) => {
        stopLoading();
      });
    }
  }, [siteLogs]);

  return (
    <StyledDetailsCardContent
      sx={{
        px: "0px!important",
        py: 1,
        overflowY: "auto",
      }}
    >
      <Stack direction="column">
        {isLoading ? (
          <>
            <AppLogItemSkeleton />
            <AppLogItemSkeleton />
            <AppLogItemSkeleton />
          </>
        ) : (
          <>
            {siteLogs.length > 0 ? (
              <>
                {siteLogs.map((log, index) => (
                  <SiteLogItem key={index} log={log} />
                ))}
              </>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body1">
                  {t("wif.injury.noActivity")}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Stack>
    </StyledDetailsCardContent>
  );
};
