import * as yup from "yup";
import { BaseMinorInjurySection, baseMinorInjurySchema } from "..";
import type { WithFormMethods } from "../../../../../components/Forms";
import { useAppSelector } from "../../../../../store";
import { selectAllEmployeeInjuryTags } from "../../../store/injuryReportsSlice";
import { NewFormAutocomplete } from "../../../../../components/FormInputs/NewFormAutocomplete";
import { getTranslationKeyForSectionFieldLabel } from "../../../helpers/dataMapping";
import { EMPLOYEE_SECTION_NAMES } from "../../../constants/employee";
import { useTranslation } from "react-i18next";

export const employeeMinorInjurySchema = baseMinorInjurySchema.shape({
  typesOfInjury: yup.array().min(1, "min 1").required(),
});

export type EmployeeMinorInjurySection = yup.InferType<
  typeof employeeMinorInjurySchema
>;

export const EmployeeMinorInjuryControls = (
  props: WithFormMethods<EmployeeMinorInjurySection>,
) => {
  const { methods } = props;
  const { control } = methods;
  const { t } = useTranslation();

  const tags = useAppSelector(selectAllEmployeeInjuryTags);

  return (
    <>
      <BaseMinorInjurySection methods={methods} />
      <NewFormAutocomplete
        control={control}
        name="typesOfInjury"
        label={t(
          getTranslationKeyForSectionFieldLabel(
            EMPLOYEE_SECTION_NAMES.MINOR_INJURY,
            "typesOfInjury",
          ),
        )}
        options={tags.slice().map((tag) => ({ id: tag.id, name: tag.name }))}
      />
    </>
  );
};
