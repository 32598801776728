import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import React from "react";
import {
  type FieldValues,
  type SubmitHandler,
  useForm,
  type UseFormProps,
  type UseFormReturn,
} from "react-hook-form";
import type * as yup from "yup";

type InjuryFormProps<TFieldValues extends FieldValues, TSchema> = {
  className?: string;
  onSubmit?: SubmitHandler<TFieldValues>;
  children?: (methods: UseFormReturn<TFieldValues>) => React.ReactNode;
  options?: UseFormProps<TFieldValues>;
  data?: any;
  id?: string;
  schema?: TSchema;
  loading?: boolean;
};

export const InjuryForm = <
  TFieldValues extends Record<string, unknown> = Record<string, unknown>,
  TSchema = yup.ObjectSchema<any>,
>(
  props: InjuryFormProps<TFieldValues, TSchema>,
) => {
  const {
    className = "",
    onSubmit,
    children,
    options,
    id,
    schema,
    data,
    loading,
  } = props;
  const methods = useForm<TFieldValues>({
    ...options,
    resolver: schema && yupResolver(schema),
    defaultValues: React.useMemo(() => {
      if (data) {
        return data;
      }
      if (options?.defaultValues) {
        return options.defaultValues;
      }
    }, [data]),
  });

  const {
    formState: { errors },
  } = methods;
  const scrollRef = React.useRef<any>(null);

  React.useEffect(() => {
    if (scrollRef?.current) {
      scrollRef.current.scrollIntoView();
    }
  }, [errors]);

  return (
    <form
      onSubmit={
        onSubmit !== undefined ? methods.handleSubmit(onSubmit) : undefined
      }
      id={id}
      style={{
        position: "relative",
        ...(loading && {
          display: "none",
        }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        {children?.(methods)}
      </Box>
    </form>
  );
};
