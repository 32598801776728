import type { TextFieldProps } from "@mui/material";
import { TextField } from "@mui/material";
import React from "react";
import type { Control, FieldPath } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import type { FieldValues } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import { TKB_INJURY } from "../../features/injuries/constants";

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextFieldProps & {
  control?: Control<TFieldValues, any>;
  name: TName;
  controllerProps?: any;
};

export function FormTextField<TFieldValues extends FieldValues>(
  props: Props<TFieldValues>,
) {
  const {
    control = useFormContext<TFieldValues>().control,
    name,
    controllerProps,
    ...rest
  } = props;
  const { t } = useTranslation();

  const getTranslation = React.useCallback(
    (key: string) => {
      const keyWithValues = key.split(" ");
      const translationKey = keyWithValues[0];
      if (
        translationKey === "max" ||
        translationKey === "min" ||
        translationKey === "minmax"
      ) {
        return t(`${TKB_INJURY}.errors.${translationKey}`, {
          i: keyWithValues[1],
        });
      } else if (translationKey === "between") {
        return t(`${TKB_INJURY}.errors.${translationKey}`, {
          min: keyWithValues[1],
          max: keyWithValues[2],
        });
      }
      return t(`${TKB_INJURY}.errors.generic`);
    },
    [t],
  );

  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field: { value, ...field }, fieldState: { error } }) => (
        <TextField
          required={!!error}
          error={!!error}
          helperText={error?.message ? getTranslation(error.message) : null}
          value={value ?? ""}
          {...field}
          {...rest}
        />
      )}
    />
  );
}
