import React from "react";
import { useTheme, alpha } from "@mui/material/styles";
import _ from "lodash";
import type { InjuryReportsFilter } from "../InjuryReportsDataGrid/InjuryReportsDataGrid";
import LineChart from "../../../../components/Charts/LineChart";
import {
  getChartColorByIndex,
  getDefaultLineChartOptions,
  longMonthLabelTicksCallback,
  shortMonthLabelTicksCallback,
} from "../../../../components/Charts/helpers";
import { useTranslation } from "react-i18next";
import type { InjuryReportCase } from "../../types";

export const getInitialMonthCounts = (monthLabels: string[]) => {
  const monthCount: { [month: string]: number } = {};

  monthLabels.forEach((month) => {
    monthCount[month] = 0;
  });

  return monthCount;
};

export const monthLabels = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
];

// !TODO Move to helpers or common
export const getInitialMonthLabelArrForYear = (lastMonth: number) => {
  const labels: string[] = [];
  let curMonth = lastMonth + 1;
  for (let i = 0; i < monthLabels.length; i++) {
    labels.push(monthLabels[curMonth]);
    if (curMonth < 11) {
      curMonth++;
    } else {
      curMonth = 0;
    }
  }
  return labels;
};

export const getInitialByMonthForYear = (lastMonth: number) => {
  const labels = getInitialMonthLabelArrForYear(lastMonth);
  const data: any = {};
  for (const label of labels) {
    data[label] = 0;
  }
  return data;
};

type InjuryReportsByMonthChartProps = {
  reports?: InjuryReportCase[];
  filters?: InjuryReportsFilter[];
  lines?: {
    field: string;
    values: string[];
    colors?: string[];
    labels?: string[];
  };
  areas?: boolean;
  title?: string;
  subtitle?: string;
  timePeriod?: string;
};

export const InjuryReportsByMonthChart = (
  props: InjuryReportsByMonthChartProps,
) => {
  const {
    reports,
    filters = [],
    lines = { field: "type", values: ["Accident", "Illness", "Minor injury"] },
    areas = false,
    title = "",
    subtitle = "",
    timePeriod = "6M",
  } = props;

  const theme = useTheme() as any;
  const { t } = useTranslation();

  const chartData = React.useMemo(() => {
    const datasets: any = [];
    const lineMonthCounts: { [line: string]: { [month: string]: number } } = {};
    const options = getDefaultLineChartOptions({ theme, title, subtitle });

    const monthLabels = getInitialMonthLabelArrForYear(new Date().getMonth());

    for (const line of lines.values) {
      lineMonthCounts[line] = getInitialMonthCounts(monthLabels);
    }

    for (const report of reports) {
      const reportDate = new Date(report.reportedTimestamp);
      if (
        reportDate.getFullYear() === new Date().getFullYear() ||
        (reportDate.getFullYear() === new Date().getFullYear() - 1 &&
          reportDate.getMonth() > new Date().getMonth())
      ) {
        const reportedMonth = reportDate.getMonth();
        const key = lines.field as any;
        if (lines.values.includes(report[key])) {
          lineMonthCounts[report[lines.field]][monthLabels[reportedMonth]] += 1;
        }
      }
    }

    let sortedLines = lines.values.slice();

    sortedLines = sortedLines.filter((value) => {
      const lineVals = Object.values(lineMonthCounts[value]);
      const sum = lineVals.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0,
      );
      return sum > 0;
    });

    let monthLabelForTimePeriod = getInitialMonthLabelArrForYear(
      new Date().getMonth(),
    );
    if (timePeriod === "6M") {
      _.set(options, "scales.x.ticks.callback", longMonthLabelTicksCallback);
      monthLabelForTimePeriod = monthLabelForTimePeriod
        .reverse()
        .splice(0, 6)
        .reverse();
    } else {
      _.set(options, "scales.x.ticks.callback", shortMonthLabelTicksCallback);
    }

    for (let i = 0; i < sortedLines.length; i++) {
      const line = sortedLines[i];
      const colorIndex = lines.values.indexOf(line);
      const color = lines.colors
        ? lines.colors[colorIndex]
        : getChartColorByIndex(colorIndex);
      const lineData: { x: any; y: any }[] = [];

      for (const monthLabel of monthLabels) {
        lineData.push({ x: monthLabel, y: lineMonthCounts[line][monthLabel] });
      }

      const timePeriodData: any = {};
      for (const monthLabel of monthLabelForTimePeriod) {
        timePeriodData[monthLabel] = lineMonthCounts[line][monthLabel];
      }

      datasets.push({
        data: timePeriodData,
        borderColor: color,
        label: lines?.labels?.[lines.values.indexOf(line)] || line,
        pointBackgroundColor: color,
        pointRadius: 0,
        lineTension: 0.4,
        fill: areas && i > 0 ? "-1" : areas ? "origin" : false,
        backgroundColor: areas ? alpha(color, 0.8) : color,
      });
    }

    const data = {
      labels: monthLabelForTimePeriod,
      datasets: datasets,
    };

    return {
      data,
      options,
    };
  }, [reports, t]);

  return <LineChart data={chartData.data} options={chartData.options} />;
};
