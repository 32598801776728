import { useState, useCallback } from "react";

export type LoadingHookValues = { loadingMessage?: string; isLoading: boolean };

export type LoadingHookMethods = {
  startLoading: (loadingMessage?: string) => void;
  stopLoading: () => void;
};

export const useLoading = (
  initState = false,
  initMessage?: string,
): [LoadingHookValues, LoadingHookMethods] => {
  const [isLoading, setIsLoading] = useState<boolean>(initState);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>(
    initMessage,
  );

  const startLoading = useCallback((loadingMessage?: string) => {
    setIsLoading(true);
    setLoadingMessage(loadingMessage);
  }, []);

  const stopLoading = useCallback(() => {
    setIsLoading(false);
    setLoadingMessage(undefined);
  }, []);

  return [
    { isLoading, loadingMessage },
    { startLoading, stopLoading },
  ];
};
