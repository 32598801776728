import { Avatar, Tooltip } from "@mui/material";
import { generateAvatarColor } from "../../utils/color";
import { AppIcon } from "./AppIcon";

type AppUserAvatarProps = {
  username?: string;
  size?: "xs" | "small" | "medium" | "large";
};

export const AppUserAvatar = (props: AppUserAvatarProps) => {
  const { username, size = "medium" } = props;

  return (
    <Tooltip title={username} placement="top">
      <Avatar
        sx={{
          ...(username
            ? {
                bgcolor: (theme) =>
                  generateAvatarColor(username, theme.palette.mode === "dark"),
              }
            : {
                bgcolor: "text.disabled",
              }),
          ...(size === "xs" && {
            height: 20,
            width: 20,
            fontSize: "body2.fontSize",
          }),
          ...(size === "small" && {
            height: 24,
            width: 24,
            fontSize: "body2.fontSize",
          }),
          ...(size === "medium" && {
            height: 32,
            width: 32,
          }),
          ...(size === "large" && {
            height: 40,
            width: 40,
          }),
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {username ? (
          username?.charAt(0)?.toUpperCase()
        ) : (
          <AppIcon
            iconName="person"
            opticalSize={
              size === "xs"
                ? 18
                : size === "small"
                  ? 20
                  : size === "medium"
                    ? 24
                    : 28
            }
            fill={0}
          />
        )}
      </Avatar>
    </Tooltip>
  );
};
