import * as yup from "yup";
import { Controller } from "react-hook-form";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { FormRow, type WithFormMethods } from "../../../../../components/Forms";
import {
  FormDatePicker,
  FormRadioGroup,
  FormTextField,
  YES_NO_OPTIONS,
} from "../../../../../components/FormInputs";
import { nokCurrencyFormatter } from "../../../../../utils/format";
import { GENERIC_VALIDATION_MSG } from "../../../constants/mappedInputLabels";

export const COMPETENCE_LEVEL_OPTIONS = [
  "Primary school",
  "1-3 years at upper secondary school level",
  "1-3 years of education beyond upper secondary school level",
  "University/college education with a duration of 4 years or more",
];

export const NATURE_OF_EMPLOYMENT_OPTIONS = [
  "Employee",
  "Self-employed",
  "Freelancer",
];

export const employmentSchema = yup.object().shape({
  profession: yup
    .string()
    .required("between 4 86")
    .min(4, "min 4")
    .max(86, "max 86"),
  occupation: yup
    .string()
    .required("between 4 86")
    .min(4, "min 4")
    .max(86, "max 86"),
  employmentPercentage: yup
    .number()
    .required("between 0 100")
    .min(0, "min 0")
    .max(100, "max 100"),
  employmentStartDate: yup.string().required("min 4").min(4, "min 4"),
  employmentEndDate: yup.string(),
  assumedAnnualIncomeForClaimYear: yup
    .number()
    .required("between 0 10000000")
    .min(0, "min 0")
    .max(10000000, "max 10000000"),
  competenceLevel: yup
    .string()
    .required("between 4 86")
    .min(4, "min 4")
    .max(86, "max 86"),
  natureOfEmployment: yup
    .string()
    .required(GENERIC_VALIDATION_MSG)
    .oneOf(NATURE_OF_EMPLOYMENT_OPTIONS),
  voluntaryOccupationalInjuryInsurance: yup
    .string()
    .required(GENERIC_VALIDATION_MSG)
    .oneOf(YES_NO_OPTIONS),
});

export type EmploymentSection = yup.InferType<typeof employmentSchema>;

export const EmploymentControls = (
  props: WithFormMethods<EmploymentSection>,
) => {
  const { methods } = props;
  const { control } = methods;
  return (
    <>
      <FormRow>
        <FormDatePicker
          control={control}
          name="employmentStartDate"
          label="Employment start date"
        />
        <FormDatePicker
          control={control}
          name="employmentEndDate"
          label="Employment end date"
        />
      </FormRow>
      <Controller
        name="assumedAnnualIncomeForClaimYear"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            color={"primary"}
            label="Assumed annual income for claim year"
            placeholder="500 000"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">kr</InputAdornment>
              ),
            }}
            sx={{ width: "400px" }}
            required={!!fieldState.error}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
            value={
              field.value ? nokCurrencyFormatter.format(field.value) : undefined
            }
            onChange={(e) =>
              field.onChange(
                Number.parseInt(e.target.value.replace(/[^0-9]/g, "")),
              )
            }
          />
        )}
      />
      <FormTextField
        control={control}
        name="profession"
        label="Profession at the time of the injury"
        placeholder="Enter Profession"
      />
      <FormTextField
        control={control}
        name="occupation"
        label="Occupation at the time of the injury"
        placeholder="Enter Occupation"
      />
      <Controller
        name="employmentPercentage"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            color={"primary"}
            label="Employment percentage"
            sx={{ width: "240px" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            required={!!fieldState.error}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
            value={field.value ? field.value.toString() : undefined}
            onChange={(e) =>
              field.onChange(
                Number.parseInt(e.target.value.replace(/[^0-9]/g, "")),
              )
            }
          />
        )}
      />

      <div />
      <FormRadioGroup
        control={control}
        name="competenceLevel"
        label="Competence level"
        options={COMPETENCE_LEVEL_OPTIONS}
        direction="column"
      />
      <FormRadioGroup
        control={control}
        name="natureOfEmployment"
        label="Nature of employment at the time of the injury"
        options={NATURE_OF_EMPLOYMENT_OPTIONS}
        direction="column"
      />
      <FormRadioGroup
        control={control}
        name="voluntaryOccupationalInjuryInsurance"
        label="If self-employed or freelancing, voluntary occupational injury insurance?"
        options={YES_NO_OPTIONS}
      />
    </>
  );
};
