import React from "react";
import type { GridColDef, GridEventListener } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { getActiveUser } from "../../../utils/user";
import { useAppSelector } from "../../../store";
import {
  selectAllInjuryActions,
  selectInjuryReports,
} from "../../injuries/store/injuryReportsSlice";
import {
  RenderCopyCell,
  RenderHandlerCell,
} from "../../injuries/components/InjuryReportsDataGrid/RenderCell";
import type {
  IActiveFilter,
  IFilterControl,
} from "../../../components/DataGrid/types";
import { RDataGrid } from "../../../components/DataGrid/RDataGrid";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { Avatar, IconButton, Stack, Typography } from "@mui/material";
import { AppIcon } from "../../../components/Elements";
import { Box } from "@mui/system";
import AvatarGroup from "@mui/material/AvatarGroup";
import { selectEmployees } from "../../employees/employeesSlice";
import { generateAvatarColor } from "../../../utils/color";
import Tooltip from "@mui/material/Tooltip";
import { formatDateFromTimestamp } from "../../../utils/format";
import _ from "lodash";
import CompleteActionDialog from "./CompleteActionDialog";
import ActionCommentsDialog from "./ActionCommentsDialog";
import ActionCompletionDialog from "./ActionCompletionDetailsDialog";
import DeleteActionDialog from "./DeleteActionDialog";
import ReopenActionDialog from "./ReopenActionDialog";
import UpdateActionDialog from "./UpdateActionDialog";

const RenderActionTitleCell = (params: any) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={0.75}
      sx={{
        width: "100%",
        display: "flex",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flexGrow: 1,
        }}
      >
        {params.value}
      </Typography>
      <Box
        className="visible-on-hover"
        sx={{
          display: "none",
        }}
      >
        <Tooltip title="More actions" placement="bottom">
          <IconButton size="small">
            <AppIcon iconName="more_vert" />
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
  );
};

const RenderDeadlineCell = (params: any) => {
  const getDeadlineStatus = () => {
    const deadline = params.value;

    if (
      deadline &&
      deadline !== null &&
      deadline < Number.MAX_SAFE_INTEGER - 1000
    ) {
      const deadlineDate = new Date(deadline);
      if (deadlineDate) {
        deadlineDate.setHours(0, 0, 0, 0);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        console.log(
          `deadline: ${deadlineDate.getTime()}, today: ${today.getTime()}, > ${
            today.getTime() > deadlineDate.getTime()
          }}`,
        );
        if (today > deadlineDate) {
          return "overdue";
        }
        if (today.getTime() === deadlineDate.getTime()) {
          return "today";
        }
      }
    }
    return "normal";
  };
  return (
    <Box
      sx={{
        ...(params.value >= Number.MAX_SAFE_INTEGER && {
          color: "text.disabled",
        }),
        ...(getDeadlineStatus() === "overdue" && {
          color: "error.main",
        }),
        ...(getDeadlineStatus() === "today" && {
          color: "warning.main",
        }),
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={0.75}
        sx={{
          ...(params.value >= Number.MAX_SAFE_INTEGER && {
            color: "text.disabled",
          }),
          ...(getDeadlineStatus() === "overdue" && {
            color: (theme) =>
              theme.palette.mode === "light" ? "error.dark" : "error.main",
          }),
          ...(getDeadlineStatus() === "today" && {
            color: (theme) =>
              theme.palette.mode === "light" ? "warning.dark" : "warning.main",
          }),
        }}
      >
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value && params.value < Number.MAX_SAFE_INTEGER
            ? formatDateFromTimestamp(params.value)
            : ""}
        </Typography>
      </Stack>
    </Box>
  );
};

const RenderMembersCell = (params: any) => {
  const users = useAppSelector(selectEmployees);

  if (!params.value) {
    return <></>;
  }

  return (
    <AvatarGroup
      total={params.value.length}
      spacing="medium"
      slotProps={{
        additionalAvatar: {
          sx: {
            height: 24,
            width: 24,
            fontSize: "body2.fontSize",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: "text.disabled",
          },
        },
      }}
      sx={{
        "& .MuiAvatarGroup-avatar": {
          borderColor: "background.paper",
        },
      }}
    >
      {params.value.slice(0, 4).map((userId: string) => (
        <Tooltip title={users[userId]?.name || userId} placement="top">
          <Avatar
            sx={{
              height: 24,
              width: 24,
              bgcolor: (theme) =>
                generateAvatarColor(
                  users[userId]?.name || userId,
                  theme.palette.mode === "dark",
                ),
              fontSize: "body2.fontSize",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {users[userId]?.name?.charAt(0).toUpperCase() || (
              <AppIcon iconName="person" color="#fff" />
            )}
          </Avatar>
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};

const RenderCommentsCell = (params: any) => {
  if (!params.value) {
    return <></>;
  }
  return (
    <Stack direction="row" alignItems="center" gap={0.75} sx={{}}>
      <AppIcon iconName="forum" color="textVariant" opticalSize={20} />
      <Typography
        variant="body2"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Typography>
    </Stack>
  );
};

const RenderInjuryLinkCell = (params: any) => {
  const { t } = useTranslation();
  return (
    <Link
      component={RouterLink}
      variant="body2"
      to={`${params.row.injuryReportId}`}
      underline={"none"}
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        "&:hover": {
          textDecoration: "underline",
          color: "text.primary",
        },
        fontWeight: 400,
        minWidth: 0,
        color: (theme) =>
          theme.palette.mode === "light" ? "primary.main" : "#c7d2fe",
      }}
    >
      {`${params.value}`}
    </Link>
  );
};
const actionStatus = {
  pending: "pending",
  completed: "completed",
  draft: "draft",
};

const RenderActionStatusCell = (params: any) => {
  const { t } = useTranslation();

  const statusColors: any = {
    [actionStatus.pending]: "info.main",
    [actionStatus.completed]: "success.main",
    [actionStatus.draft]: "textVariant",
  };

  return (
    <Stack direction="row" alignItems="center" gap={0.75} sx={{}}>
      <Box
        sx={{
          height: 8,
          width: 8,
          borderRadius: "50%",
          bgcolor: `${statusColors[params.value]}`,
        }}
      />
      <Typography
        variant="body2"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          ...(params.value === "draft" && {
            color: "text.secondary",
          }),
        }}
      >
        {params.value && t(`wif.injury.statuses.${params.value}`)}
      </Typography>
    </Stack>
  );
};

export const InjuryReportActionsDataGrid = (props) => {
  const { module = "all" } = props;
  const { t } = useTranslation();

  const activeUser = getActiveUser();

  const injuryActions = useAppSelector(selectAllInjuryActions);
  const injuryReports = useAppSelector(selectInjuryReports);
  const users = useAppSelector(selectEmployees);

  const categoryFilter = React.useMemo(() => {
    if (module === "all") return undefined;
    if (module === "student") return "student";
    if (module === "employee") return "employee";
    return undefined;
  }, [module]);

  const [activeFilters, setActiveFilters] = React.useState<any[]>([]);

  const handleClearFilters = () => {
    setActiveFilters([]);
  };

  const handleRemoveFilter = (field: string) => {
    setActiveFilters((prevFilters) => {
      let newFilters = [...prevFilters];
      newFilters = newFilters.filter((filter) => filter.field !== field);
      return newFilters;
    });
  };
  const handleSetFilter = (newFilter: IActiveFilter) => {
    setActiveFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      const filterIndex = newFilters.findIndex(
        (filter) => filter.field === newFilter.field,
      );
      if (filterIndex > -1) {
        newFilters[filterIndex] = newFilter;
      } else {
        newFilters.push(newFilter);
      }
      return newFilters;
    });
  };

  const filtered = React.useMemo(() => {
    const filtered = injuryActions.filter((action) => {
      const reportId = action?.report_id;
      const injuryReport = injuryReports[reportId];
      if (injuryReport) {
        if (categoryFilter && injuryReport?.category !== categoryFilter) {
          return false;
        }
      } else {
        return false;
      }
      return true;
    });
    return filtered;
  }, [injuryActions, categoryFilter, injuryReports]);

  const controls = React.useMemo(() => {
    const controlItems: IFilterControl[] = [
      {
        field: "status",
        label: t("wif.injury.status"),
        paths: ["status"],
        operator: "is",
        options: [
          { value: "pending", label: t("wif.injury.statuses.pending") },
          { value: "completed", label: t("wif.injury.statuses.completed") },
          { value: "Draft", label: t("wif.injury.statuses.draft") },
        ],
      },
    ];

    const injuryReportIds = filtered.map((action) => action.report_id);
    const uniqReportIds = _.uniq(injuryReportIds);
    if (uniqReportIds.length > 0) {
      const injuryReportOptions = uniqReportIds.map((reportId) => {
        const injuryReport = injuryReports[reportId];
        return { value: reportId, label: injuryReport?.name || reportId };
      });
      controlItems.push({
        field: "injuryReportId",
        paths: ["injuryReportId"],
        label: t("wif.injury.injuryReport"),
        operator: "is",
        options: injuryReportOptions,
      });
    }

    let assigneeIds = filtered.map((action) => action?.assignee);
    assigneeIds = _.flatten(assigneeIds);
    assigneeIds = _.uniq(assigneeIds);
    assigneeIds = assigneeIds.filter((id) => id);
    if (assigneeIds.length > 0) {
      const assigneeOptions = assigneeIds
        .filter((userId) => userId !== null)
        .map((userId) => {
          if (userId === activeUser?.id) {
            return { value: userId, label: t("wif.injury.me") };
          }
          const user = users[userId];
          return { value: userId, label: user?.name || userId };
        });
      console.log(`assigneeIds: ${JSON.stringify(assigneeIds)}`);
      console.log(
        `assigneeOptions: ${JSON.stringify(assigneeOptions, null, 2)}`,
      );
      controlItems.push({
        field: "assignee",
        paths: ["assignee"],
        label: t("wif.injury.assignedTo"),
        operator: "is",
        options: assigneeOptions,
      });
    }

    return controlItems;
  }, [filtered, users, t]);

  const items = React.useMemo(() => {
    const filteredActions: any = [...filtered];

    return filteredActions.map((action: any) => {
      return {
        id: action.action_id,
        text: action.text,
        injuryReportId: action.report_id,
        injuryReportName: injuryReports[action.report_id]?.name,
        status: action.status,
        type: action?.type || "injury",
        comments: action?.comments?.length,
        deadline: action?.deadline
          ? new Date(action?.deadline)?.getTime()
          : Number.MAX_SAFE_INTEGER,
        assignee:
          action?.assignee && action?.assignee?.length > 0
            ? action?.assignee[0]
            : undefined,
        members: action?.members,
        action: action,
      };
    });
  }, [module, injuryActions]);

  const columns = React.useMemo(() => {
    const columnByField: { [field: string]: GridColDef } = {
      id: {
        field: "id",
        headerName: "ID",
        width: 120,
        renderCell: (params) => <RenderCopyCell {...params} />,
      },
      text: {
        field: "text",
        headerName: t("wif.injury.name"),
        flex: 2,
        minWidth: 240,
        renderCell: (params) => <RenderActionTitleCell {...params} />,
      },
      injuryReport: {
        field: "injuryReportName",
        headerName: t("wif.injury.injuryReport"),
        flex: 2,
        minWidth: 220,
        renderCell: (params) => <RenderInjuryLinkCell {...params} />,
      },
      type: {
        field: "type",
        headerName: t("wif.injury.type"),
        minWidth: 130,
        flex: 1,
      },
      status: {
        field: "status",
        headerName: t("wif.injury.status"),
        minWidth: 130,
        flex: 1,
        renderCell: (params) => <RenderActionStatusCell {...params} />,
      },
      members: {
        field: "members",
        headerName: t("wif.injury.members"),
        minWidth: 140,
        flex: 1.25,
        renderCell: (params) => <RenderMembersCell {...params} />,
      },
      assignee: {
        field: "assignee",
        headerName: t("wif.injury.assignedTo"),
        flex: 1,
        minWidth: 180,
        renderCell: (params) => <RenderHandlerCell {...params} />,
      },
      comments: {
        field: "comments",
        headerName: t("wif.injury.comments"),
        minWidth: 120,
        flex: 0.75,
        renderCell: (params) => <RenderCommentsCell {...params} />,
      },
      deadline: {
        field: "deadline",
        headerName: t("wif.injury.deadline"),
        minWidth: 140,
        flex: 1,
        renderCell: (params) => <RenderDeadlineCell {...params} />,
      },
    };
    const cols = [
      "id",
      "text",
      "assignee",
      "status",
      "injuryReport",
      "members",
      "comments",
      "deadline",
    ];
    return cols.map((field) => columnByField[field]);
  }, [t]);

  const [openDialog, setOpenDialog] = React.useState<undefined | string>();

  const handleOpenDialog = (dialogName: string) => {
    setOpenDialog(dialogName);
  };

  const [currentActionId, setCurrentActionId] = React.useState<
    string | undefined
  >(undefined);

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    const clickedId = params.row.id;
    if (clickedId) {
      if (currentActionId !== clickedId) {
        setCurrentActionId(clickedId);
      } else {
        setCurrentActionId(undefined);
      }
    }
  };

  const currentAction = React.useMemo(() => {
    if (currentActionId) {
      return filtered.find(
        (action: any) => action.action_id === currentActionId,
      );
    }
    return undefined;
  }, [currentActionId, injuryReports, injuryActions]);

  const selected = React.useMemo(() => {
    if (currentAction) {
      const selectedActions = [];

      if (currentAction?.status !== "draft") {
        selectedActions.push({
          label: t("wif.injury.addComment"),
          onClick: () => handleOpenDialog("comments"),
          iconName: "forum",
        });
      }
      if (
        currentAction?.status === "draft" ||
        currentAction?.status === "pending"
      ) {
        selectedActions.push({
          label: t("wif.injury.edit"),
          onClick: () => handleOpenDialog("edit"),
          iconName: "edit",
        });
      }
      if (currentAction?.status === "completed") {
        selectedActions.push({
          label: t("wif.injury.viewDetails"),
          onClick: () => handleOpenDialog("completionDetails"),
          iconName: "description",
        });
        selectedActions.push({
          label: t("wif.injury.reopen"),
          onClick: () => handleOpenDialog("reopen"),
          variant: "outlined",
          iconName: "restart_alt",
        });
      }
      selectedActions.push({
        label: t("wif.injury.delete"),
        onClick: () => handleOpenDialog("delete"),
        iconName: "delete",
      });
      if (currentAction?.status === "pending") {
        selectedActions.push({
          label: t("wif.injury.close"),
          onClick: () => handleOpenDialog("complete"),
          iconName: "check_circle",
          color: "primary",
          variant: "contained",
        });
      }

      return {
        count: 1,
        actions: selectedActions,
        itemName: t("wif.injury.actions").toLowerCase(),
      };
    }
  }, [currentActionId, t]);

  return (
    <>
      <RDataGrid
        items={items}
        cols={columns}
        onRowClick={handleRowClick}
        filters={{
          controls: [...controls],
          addedControls: [],
          active: activeFilters,
          handleSetFilter,
          handleRemoveFilter,
          handleResetFilterControls: handleClearFilters,
        }}
        initialSortModel={{
          field: "deadline",
          sort: "desc",
        }}
        selected={selected}
      />
      {currentAction && (
        <>
          {openDialog === "complete" && (
            <CompleteActionDialog
              open={openDialog === "complete"}
              action={currentAction}
              onClose={() => setOpenDialog(undefined)}
            />
          )}

          {openDialog === "comments" && (
            <ActionCommentsDialog
              open={openDialog === "comments"}
              action={currentAction}
              onClose={() => setOpenDialog(undefined)}
            />
          )}

          <ActionCompletionDialog
            open={openDialog === "completionDetails"}
            action={currentAction}
            onClose={() => setOpenDialog(undefined)}
          />
          <DeleteActionDialog
            open={openDialog === "delete"}
            action={currentAction}
            onClose={() => setOpenDialog(undefined)}
          />
          <ReopenActionDialog
            open={openDialog === "reopen"}
            action={currentAction}
            onClose={() => setOpenDialog(undefined)}
          />
          <UpdateActionDialog
            open={openDialog === "edit"}
            action={currentAction}
            onClose={() => setOpenDialog(undefined)}
          />
        </>
      )}
    </>
  );
};
