import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpGet, httpPost, httpPut } from "../../utils/httpService";
import config from "../../config/config";
import type { ICreateSitePayload } from "./components/Forms/CreateSiteForm";
import type { IUpdateSiteDetailsPayload } from "./components/Forms/EditSitePropertiesForm";
import type { IUpdateSiteLocationPayload } from "./components/Forms/UpdateSiteLocationForm";
import type { ICreateAreaPayload } from "./components/Forms/CreateAreaForm";
import type { IUpdateAreaDetailsPayload } from "./components/Forms/UpdateAreaPropertiesForm";

const locationsPath = "injury/locations";

export const fetchAllSites = createAsyncThunk(
  "locations/fetchAllSites",
  async (_, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `${config.baseUrl}/${locationsPath}/sites`,
      );
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log("error", error);
      console.log("data", error.response.data);
      console.log("message", error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const fetchAllAreas = createAsyncThunk(
  "locations/fetchAllAreas",
  async (_, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `${config.baseUrl}/${locationsPath}/areas`,
      );
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log("error", error);
      console.log("data", error.response.data);
      console.log("message", error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const fetchSiteLogs = createAsyncThunk(
  "locations/fetchSiteLogs",
  async (site_id: string, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `${config.baseUrl}/${locationsPath}/get_site_logs/${site_id}`,
      );
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log("error", error);
      console.log("data", error.response.data);
      console.log("message", error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const fetchAreaLogs = createAsyncThunk(
  "locations/fetchAreaLogs",
  async (area_id: string, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `${config.baseUrl}/${locationsPath}/get_area_logs/${area_id}`,
      );
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log("error", error);
      console.log("data", error.response.data);
      console.log("message", error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createSite = createAsyncThunk(
  "locations/createSite",
  async (payload: ICreateSitePayload, { rejectWithValue }) => {
    try {
      const response = await httpPost(
        `${config.baseUrl}/${locationsPath}/create_site`,
        payload,
      );
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log("error", error);
      console.log("data", error.response.data);
      console.log("message", error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface WithSiteId {
  site_id: string;
}

interface WithAreaId {
  area_id: string;
}

export interface IUpdateSiteDetailsRequest extends WithSiteId {
  payload: IUpdateSiteDetailsPayload;
}

export const updateSiteDetails = createAsyncThunk(
  "locations/updateSiteDetails",
  async (req: IUpdateSiteDetailsRequest, { rejectWithValue }) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const response = await new Promise((resolve) =>
        setTimeout(resolve, 4000),
      ).then(async () => {
        const res = await httpPut(
          `${config.baseUrl}/${locationsPath}/update_site_details/${req.site_id}`,
          req.payload,
        );
        return res;
      });

      setTimeout(() => {
        console.log("Delayed for 1 second.");
      }, 1000);
      const data = response.data;
      if (response.status !== 200) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log("error", error);
      console.log("data", error.response.data);
      console.log("message", error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  },
);

export interface IUpdateSiteLocationRequest extends WithSiteId {
  payload: IUpdateSiteLocationPayload;
}

export const updateSiteLocation = createAsyncThunk(
  "locations/updateSiteLocation",
  async (req: IUpdateSiteLocationRequest, { rejectWithValue }) => {
    try {
      const response = await httpPut(
        `${config.baseUrl}/${locationsPath}/update_site_location/${req.site_id}`,
        req.payload,
      );
      const data = response.data;
      if (response.status !== 200) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log("error", error);
      console.log("data", error.response.data);
      console.log("message", error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createArea = createAsyncThunk(
  "locations/createArea",
  async (payload: ICreateAreaPayload, { rejectWithValue }) => {
    try {
      const response = await httpPost(
        `${config.baseUrl}/${locationsPath}/create_area`,
        payload,
      );
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log("error", error);
      console.log("data", error.response.data);
      console.log("message", error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  },
);

export interface IUpdateAreaDetailsRequest extends WithAreaId {
  payload: IUpdateAreaDetailsPayload;
}

export const updateAreaDetails = createAsyncThunk(
  "locations/updateAreaDetails",
  async (req: IUpdateAreaDetailsRequest, { rejectWithValue }) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const response = await new Promise((resolve) =>
        setTimeout(resolve, 4000),
      ).then(async () => {
        const res = await httpPut(
          `${config.baseUrl}/${locationsPath}/update_area_details/${req.area_id}`,
          req.payload,
        );
        return res;
      });

      setTimeout(() => {
        console.log("Delayed for 1 second.");
      }, 1000);
      const data = response.data;
      if (response.status !== 200) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log("error", error);
      console.log("data", error.response.data);
      console.log("message", error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  },
);

export interface IDeleteAreaReq {
  area_id: string;
}

export const deleteArea = createAsyncThunk(
  "locations/deleteArea",
  async (req: IUpdateAreaDetailsRequest, { rejectWithValue }) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const response = await new Promise((resolve) =>
        setTimeout(resolve, 4000),
      ).then(async () => {
        const res = await httpPut(
          `${config.baseUrl}/${locationsPath}/update_area_details/${req.area_id}`,
          req.payload,
        );
        return res;
      });

      setTimeout(() => {
        console.log("Delayed for 1 second.");
      }, 1000);
      const data = response.data;
      if (response.status !== 200) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log("error", error);
      console.log("data", error.response.data);
      console.log("message", error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  },
);
