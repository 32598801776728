import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { styled } from "@mui/material/styles";
import type { WithChildren } from "../../../../../types";

export const StyledAppCardHeader = styled(CardHeader)(({ theme }) => ({
  "& .MuiCardHeader-title": {
    ...theme.typography.h4,
  },
  "& .MuiCardHeader-avatar": {
    minWidth: 0,
    marginRight: theme.spacing(1.5),
  },
  "& .MuiCardHeader-subheader": {
    ...theme.typography.body2,
    color: "text.secondary",
    mt: 0.25,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: "1",
    overflow: "hidden",
    textOverflow: "-",
  },
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
  borderBottomWidth: 0,
}));

export const StyledAppCardHeaderActions = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
}));

export const StyledChartCardContent = styled(CardContent)(({ theme }) => ({
  paddingTop: theme.spacing(2.5),
  paddingBottom: theme.spacing(2.5),
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
}));

type AppCardChartContainerProps = WithChildren<{
  size?: "small" | "medium" | "large" | "full";
  sx?: any;
}>;

export const AppCardChartContainer = (props: AppCardChartContainerProps) => {
  const { children, size = "medium", sx } = props;

  const isSmall = size === "small";
  const isMedium = size === "medium";
  const isLarge = size === "large";
  const isFull = size === "full";

  return (
    <StyledChartCardContent>
      <Box
        sx={{
          width: "100%",
          height: isSmall
            ? "220px"
            : isMedium
              ? "260px"
              : "320px"
                ? isFull
                : "100%",
          display: "flex",
          flexDirection: "column",
          ...sx,
        }}
      >
        {children}
      </Box>
    </StyledChartCardContent>
  );
};

export const StyledChartCard = styled(Card)(({ theme }) => ({
  borderRadius: 0,
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.backgroundVariant
      : theme.palette.background.paper,
}));
