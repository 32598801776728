import Button from "@mui/material/Button";
import { useAppDispatch } from "../../../../store";
import { Container, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { snackAlertOpened } from "../../../global/controlsSlice";
import { useTranslation } from "react-i18next";
import {
  CreateSiteControls,
  type ICreateSitePayload,
  createSiteFormSchema,
} from "../../../locations/components/Forms/CreateSiteForm";
import { AppActions } from "../../../../components/AppActions/AppActions";
import FullScreenDialog from "../../../../components/Elements/FullScreenDialog";
import { useLoading } from "../../../../common/useLoading";
import { createSite } from "../../../locations/locationsThunks";
import { AppForm } from "../../../../components/Forms/AppForm";

const AppFullScreenDialogContentHeader = () => {};

interface CreateSiteDialogProps {
  open?: boolean;
  handleClose: () => void;
}

export default function CreateSiteDialog(props: CreateSiteDialogProps) {
  const { open = true, handleClose } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [{ isLoading, loadingMessage }, { startLoading, stopLoading }] =
    useLoading(false);

  const handleSubmit = (data) => {
    const payload = Object.assign({}, data, {
      coordinates: {
        lat: +data.coordinates.lat.toFixed(5),
        lng: +data.coordinates.lng.toFixed(5),
      },
    });
    startLoading();
    dispatch(createSite(payload)).then((resAction) => {
      stopLoading();
      if (createSite.fulfilled.match(resAction)) {
        dispatch(
          snackAlertOpened({
            message: t("wif.injury.snackMessages.reportReopened.success"),
            severity: "success",
          }),
        );
        handleClose();
      } else {
        if (resAction.payload) {
          dispatch(
            snackAlertOpened({
              message: t("wif.injury.snackMessages.reportReopened.", {
                message: JSON.stringify(resAction.payload),
              }),
              severity: "error",
            }),
          );
        } else {
          dispatch(
            snackAlertOpened({
              message: t("wif.injury.snackMessages.errorWithMessage", {
                message: JSON.stringify(resAction.error),
              }),
              severity: "error",
            }),
          );
        }
      }
    });
  };

  return (
    <FullScreenDialog open={open} handleClose={handleClose} title="New site">
      <Container maxWidth="md">
        <Stack direction="column" gap={3} pt={5} pb={3}>
          <Stack direction="column" gap={0.5}>
            <Typography variant="h1">Create a new site</Typography>
            <Typography variant="body1" color="text.secondary">
              A site marks a distinct place such as a workplace or a school, and
              can be further divided into smaller areas.
            </Typography>
          </Stack>
          <AppForm<ICreateSitePayload, typeof createSiteFormSchema>
            schema={createSiteFormSchema}
            onSubmit={handleSubmit}
            id="create-site-form"
          >
            {(methods) => <CreateSiteControls methods={methods} />}
          </AppForm>
          <Divider sx={{ mb: -1, mt: 1 }} />
          <AppActions>
            <Button color="plain" variant="contained" onClick={handleClose}>
              {t("wif.injury.cancel")}
            </Button>
            <Button color="primary" type="submit" form="create-site-form">
              Create site
            </Button>
          </AppActions>
        </Stack>
      </Container>
    </FullScreenDialog>
  );
}
