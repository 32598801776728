import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { BOX_SHADOWS } from "../../config/theme/boxShadows";
import { AppIcon } from "../Elements";
import { useTranslation } from "react-i18next";
import { APP_COLORS } from "../../config";
import { Box, Tooltip } from "@mui/material";
import { Fragment } from "react/jsx-runtime";

type RDataGridSelection = {
  count?: number;
  actions?: {
    label: string;
    onClick?: any;
    iconName?: string;
    color?: any;
    variant?: any;
  }[];
  itemName?: string;
};

export type RSelectedItemToolbarProps = {
  count?: number;
  actions?: {
    label: string;
    onClick?: any;
    iconName?: string;
    color?: any;
    variant?: any;
  }[];
  itemName?: string;
};

export const RSelectedItemToolbar = (props: RSelectedItemToolbarProps) => {
  const { count, actions, itemName } = props;

  const { t } = useTranslation();
  return (
    <Stack
      position="absolute"
      bottom={-48}
      zIndex={3}
      direction="row"
      gap={2}
      alignItems="center"
      sx={{
        width: "100%",
        height: 48,
        bgcolor: (theme) =>
          theme.palette.mode === "light"
            ? APP_COLORS.light2point5
            : "backgroundVariant",
        boxShadow: BOX_SHADOWS.elevation0,
        borderRadius: 1,
        px: 2.5,
        pr: 1.5,
      }}
    >
      <Stack direction="row" alignItems="center" gap={1.25}>
        <Typography
          variant="body2"
          sx={{ fontWeight: 500, mr: 1, color: "text.primary" }}
        >
          {t("wif.injury.selectedWithItemNameAndCount", {
            itemName: props?.itemName,
            count: 1,
          })}
        </Typography>
      </Stack>

      <Stack direction="row" gap={0.75} flex={1}>
        {actions.map((action, index) => (
          <Fragment key={`${action}-${index}`}>
            {action?.variant && action?.variant !== "text" && (
              <Box sx={{ flexGrow: 1 }} />
            )}
            <Tooltip title={action.label} placement="bottom">
              <Button
                variant={action?.variant || "text"}
                startIcon={
                  action?.iconName ? (
                    <AppIcon
                      iconName={action?.iconName}
                      color={
                        !action?.variant && action?.variant === "text"
                          ? "text.secondary"
                          : undefined
                      }
                    />
                  ) : undefined
                }
                color={(action?.color as any) || undefined}
                sx={{
                  ...(!action?.variant || action?.variant === "text"
                    ? {
                        color: "text.primary",
                        "& .MuiButton-startIcon": {
                          mx: 0,
                        },
                        p: "auto",

                        minWidth: 0,
                        fontWeight: 500,
                        width: "36px",
                      }
                    : {
                        ml: 1,
                      }),
                  height: "36px",
                }}
              >
                {action?.variant && action?.variant !== "text" && action.label}
              </Button>
            </Tooltip>
          </Fragment>
        ))}
      </Stack>
    </Stack>
  );
};
