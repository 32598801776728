import * as React from "react";
import type { SamLogUpdate, SiteLog } from "../../locationsTypes";
import { useAppSelector } from "../../../../store";
import { selectEmployees } from "../../../employees/employeesSlice";
import { AppLogItem } from "../../../../components/AppLogItem/AppLogItem";
import { getActiveUser } from "../../../../utils/user";
import { Stack, Typography } from "@mui/material";
import { AppIcon } from "../../../../components/Elements";
import { useTranslation } from "react-i18next";
import PinMovedMap from "../PinMovedMap/PinMovedMap";
import { selectDepartmentEntities } from "../../../departments/departmentsSlice";

type UpdatePartProps = {
  field_name: string;
  old_value?: any;
  new_value: any;
};

export const LogItemUpdatePart = (props: UpdatePartProps) => {
  const { field_name, old_value, new_value } = props;

  const combinedOverflow =
    old_value && new_value && old_value?.length + new_value?.length > 32;
  return (
    <Stack direction="column" gap={0.25}>
      <Typography
        variant="body2"
        sx={{
          fontWeight: 500,
          color: "text.secondary",
          minWidth: 0,
          wordWrap: "break-word",
        }}
      >
        {field_name}
      </Typography>
      <Stack
        direction={combinedOverflow ? "column" : "row"}
        alignItems="start"
        gap={combinedOverflow ? 0 : 0.75}
      >
        {old_value && (
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              textDecoration: "line-through",
              minWidth: 0,
              wordWrap: "break-word",
            }}
          >
            {old_value}
          </Typography>
        )}
        {combinedOverflow ? (
          <Typography
            variant="body2"
            sx={{
              color: "text.primary",
              wordWrap: "break-word",
              minWidth: 0,
            }}
          >
            {new_value}
          </Typography>
        ) : (
          <>
            {old_value && new_value && (
              <AppIcon iconName="trending_flat" color="text.secondary" />
            )}
            {new_value && (
              <Typography
                variant="body2"
                sx={{
                  color: "text.primary",
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  minWidth: 0,
                  ...(old_value &&
                    new_value && {
                      pl: 0.25,
                    }),
                }}
              >
                {new_value}
              </Typography>
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
};

export const SITE_LOG_TYPES = {
  CREATE: "create",
  UPDATE_DETAILS: "update_details",
  UPDATE_LOCATION: "update_location",
  DELETE: "delete",
};

const siteUpdateLogTypes = [
  SITE_LOG_TYPES.UPDATE_DETAILS,
  SITE_LOG_TYPES.UPDATE_LOCATION,
];

const MappedSiteLogMessages = {
  [SITE_LOG_TYPES.CREATE]: "created a site",
  [SITE_LOG_TYPES.UPDATE_DETAILS]: "updated site details",
  [SITE_LOG_TYPES.UPDATE_LOCATION]: "updated location",
  [SITE_LOG_TYPES.DELETE]: "deleted site",
};

type LogUpdatesProps = {
  updates: SamLogUpdate[];
  formatFieldValues?: {
    [field_name: string]: (value: any) => any;
  };
  getFieldLabel?: (field_name: string) => string;
};

export const LogUpdates = (props: LogUpdatesProps) => {
  const { updates, formatFieldValues, getFieldLabel } = props;

  return (
    <Stack direction="column" gap={1}>
      {updates.map((update, index) => {
        const { field_name, old_value, new_value } = update;
        const formattedOldValue =
          formatFieldValues?.[field_name]?.(old_value) || old_value;
        const formattedNewValue =
          formatFieldValues?.[field_name]?.(new_value) || new_value;
        const fieldLabel = getFieldLabel?.(field_name) || field_name;
        return (
          <LogItemUpdatePart
            key={index}
            field_name={fieldLabel}
            old_value={formattedOldValue || undefined}
            new_value={formattedNewValue || undefined}
          />
        );
      })}
    </Stack>
  );
};

type SiteLogItemProps = {
  log: SiteLog;
};

export const SiteLogItem = (props: SiteLogItemProps) => {
  const { log } = props;
  const { t } = useTranslation();

  const activeUser = getActiveUser();

  const users = useAppSelector(selectEmployees);
  const departments = useAppSelector(selectDepartmentEntities);

  const username = React.useMemo(() => {
    const user = users[log?.user_id];
    if (user) {
      return user?.name || log?.user_id;
    }
    return log?.user_id || "";
  }, [log, users]);

  const message = React.useMemo(() => {
    const who = log?.user_id === activeUser?.id ? "You" : username;
    const what = MappedSiteLogMessages[log.type] || "did something";
    return `${who} ${what}`;
  }, [log, username]);

  const formatFieldValues = React.useMemo(() => {
    if (log.type === SITE_LOG_TYPES.UPDATE_LOCATION && log.updates) {
      return {
        coordinates: (value) =>
          `${value?.lat.toFixed(5)}, ${value?.lng.toFixed(5)}`,
      };
    }
    if (log.type === SITE_LOG_TYPES.UPDATE_DETAILS && log.updates) {
      return {
        department_id: (value) => departments[value]?.name || value,
      };
    }
    return undefined;
  }, [log]);

  const coordinatesUpdate = React.useMemo(() => {
    if (log.type === SITE_LOG_TYPES.UPDATE_LOCATION) {
      return log.updates?.find(
        (update) =>
          update.field_name === "coordinates" &&
          update?.new_value &&
          update?.old_value,
      );
    }
    return undefined;
  }, [log]);

  return (
    <AppLogItem
      username={username}
      message={message}
      time={log?.time}
      hideDetails
    >
      {siteUpdateLogTypes.includes(log.type) && (
        <LogUpdates
          updates={log.updates}
          formatFieldValues={formatFieldValues}
          getFieldLabel={(field_name) =>
            t(`wif.injury.models.site.fields.${field_name}.title`)
          }
        />
      )}
      {coordinatesUpdate && (
        <PinMovedMap
          oldCoordinates={coordinatesUpdate.old_value}
          newCoordinates={coordinatesUpdate.new_value}
        />
      )}
    </AppLogItem>
  );
};
