import { Page, Text, Document, StyleSheet, Font } from "@react-pdf/renderer";
import { APP_COLORS } from "../../../../config";
import {
  DocListContainer,
  DocListDescriptionItem,
  DocListHeader,
} from "./DocListDescriptionItem";
import {
  DocChapterHeader,
  DocGridDescriptionItem,
  DocGridRow,
} from "./DocGridDescriptionItem";
import { DocDivider } from "./DocSpacing";
import { useTranslation } from "react-i18next";
import type { InjuryReportCase } from "../../types";
import { formatDateTimeFromTimestamp } from "../../../../utils/format";
import { AllSectionDetails, TKB_INJURY } from "../../constants";
import { getTranslatedInjuryReportName } from "../../helpers";
import { DocAction } from "./DocActions";
import { Fragment } from "react/jsx-runtime";

Font.register({
  family: "Inter",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf",
      fontWeight: 100,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf",
      fontWeight: 200,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf",
      fontWeight: 300,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf",
      fontWeight: 400,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf",
      fontWeight: 500,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf",
      fontWeight: 600,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf",
      fontWeight: 700,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf",
      fontWeight: 800,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf",
      fontWeight: 900,
    },
  ],
});

export const DocColors = {
  primary: APP_COLORS.primary,
  error: APP_COLORS.error,
  warning: APP_COLORS.warning,
  success: APP_COLORS.success,
  secondary: APP_COLORS.secondary,
  background: {
    level4: APP_COLORS.light4,
    level3: APP_COLORS.light3,
    level2: APP_COLORS.light2,
    level1: APP_COLORS.light1,
  },
  text: {
    primary: APP_COLORS.textPrimary,
    secondary: APP_COLORS.textSecondary,
    disabled: APP_COLORS.textDisabled,
  },
  textVariant: APP_COLORS.textThird,
  divider: APP_COLORS.dividerVariant,
};
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    color: DocColors.text.primary,
    fontFamily: "Inter",
    paddingVertical: 24,
    paddingHorizontal: 32,
  },
  primaryDivider: {
    width: "100%",
    borderBottom: `1px solid ${DocColors.primary}`,
    marginBottom: 16,
  },
  divider: {
    width: "100%",
    borderBottom: `1px solid ${DocColors.divider}`,
  },
  header: {
    fontSize: 18,
    color: DocColors.primary,
    fontWeight: 700,
    marginBottom: 8,
  },
  headerSubtitle: {
    fontSize: 10,
    color: DocColors.primary,
    opacity: 0.9,
    fontWeight: 600,
    marginBottom: 2,
  },
  sectionHeaderContainer2: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: 12,
    paddingVertical: 10,
    marginTop: 14,
    marginBottom: 6,
    color: DocColors.primary,
    borderBottom: `1px solid ${DocColors.primary}`,
  },
  sectionHeaderText2: {
    fontSize: 13,
    fontWeight: 700,
  },
  sectionHeaderContainer1: {
    paddingVertical: 10,
    paddingHorizontal: 12,
    borderBottom: `1px solid ${DocColors.divider}`,
    width: "100%",
  },
  sectionHeaderText1: {
    paddingHorizontal: 12,
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 8,
    color: DocColors.primary,
  },
  sectionContainer: {
    backgroundColor: DocColors.background.level1,
    border: `1px solid ${DocColors.divider}`,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  descriptionItemContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 6,
    width: "20%",
  },
  descriptionItemPrimaryText: {
    fontSize: 12,
    fontWeight: 500,
    color: DocColors.text.primary,
  },
  descriptionItemSecondaryText: {
    fontSize: 11,
    fontWeight: 400,
    color: DocColors.text.secondary,
  },
  spacing1: {
    marginTop: 8,
  },
  spacing2: {
    marginTop: 16,
  },
});

// Create Document Component
type InjuryDocumentProps = {
  injuryReport: InjuryReportCase;
  formattedValues: {
    unitName: string;
    departmentName: string;
    reporterName: string;
    assigneeName: string;
  };
  formattedSections: {
    [sectionName: string]: { field: string; value: any }[];
  };
  formattedActions: any[];
};

export const InjuryDocument = (props: InjuryDocumentProps) => {
  const { injuryReport, formattedValues, formattedSections, formattedActions } =
    props;
  const { unitName, departmentName, reporterName, assigneeName } =
    formattedValues;

  const { t } = useTranslation();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.header}>
          {getTranslatedInjuryReportName(injuryReport, t)}
        </Text>
        <Text style={styles.headerSubtitle}>{`${t("wif.injury.caseId")}: ${
          injuryReport?.id
        }`}</Text>
        <DocDivider
          color={DocColors.primary}
          marginTop={1.75}
          marginBottom={2.25}
        />
        <DocGridRow>
          <DocGridDescriptionItem
            field={t("wif.injury.category")}
            value={t(`${TKB_INJURY}.reportCategory.${injuryReport.category}`)}
          />
          <DocGridDescriptionItem
            field={t("wif.injury.type")}
            value={t(`${TKB_INJURY}.reportType.${injuryReport.type}`)}
          />
          <DocGridDescriptionItem
            field={t(`${TKB_INJURY}.assignee`)}
            value={assigneeName}
          />
          <DocGridDescriptionItem
            field={t("wif.injury.status")}
            value={t(`${TKB_INJURY}.${injuryReport.status}`)}
          />
        </DocGridRow>
        <DocGridRow>
          <DocGridDescriptionItem
            field={t("wif.injury.reportedDate")}
            value={formatDateTimeFromTimestamp(injuryReport.reported)}
          />
          <DocGridDescriptionItem
            field={t("wif.injury.reportedBy")}
            value={reporterName}
          />

          <DocGridDescriptionItem
            field={t("wif.injury.department")}
            value={departmentName}
          />
          <DocGridDescriptionItem
            field={t("wif.injury.unit")}
            value={unitName}
          />
        </DocGridRow>

        <DocChapterHeader title={t("wif.injury.summary")} />
        {Object.keys(formattedSections).map((sectionName, index) => (
          <Fragment key={`${sectionName}-${index}`}>
            <DocListHeader
              title={
                AllSectionDetails[sectionName]
                  ? t(AllSectionDetails[sectionName].title)
                  : sectionName
              }
            />
            <DocListContainer>
              {formattedSections[sectionName].map((entry, i) => (
                <DocListDescriptionItem
                  key={`${entry.field}-${i}`}
                  field={entry.field}
                  value={
                    entry.field.replace(/\s+/g, "").toLowerCase() ===
                    "registrationappliesto"
                      ? entry.value.id
                        ? entry.value.id
                        : entry.value
                      : entry.value
                  }
                  bottomDivider={
                    i !== formattedSections[sectionName].length - 1
                  }
                />
              ))}
            </DocListContainer>
          </Fragment>
        ))}
        {formattedActions.length > 0 && (
          <>
            <DocChapterHeader title={t("wif.injury.actions")} />
            {formattedActions.map((action, index) => (
              <DocAction key={`${action}-${index}`} action={action} />
            ))}
          </>
        )}
      </Page>
    </Document>
  );
};
