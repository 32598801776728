import { CONSTANTS } from "../common/constants";
import config from "../config/config";
import CookieStorage from "./CookieStorage";

export const getSubDomain = () => {
  const link = window.location.href;
  const subDomain = link.substring(8, 1000).split(".")[0];
  return subDomain;
};

export const redirectToCorrectSubDomain = () => {
  if (config.useSubdomain) {
    const currentSubDomain = getSubDomain();
    const subDomainInCookie = CookieStorage.getItem(
      CONSTANTS.COOKIE_KEYS.SUBDOMAIN,
    );
    const urlParts = window.location.href.split("/#/");

    if (urlParts.length > 0) {
      const path = urlParts[1];
      if (
        subDomainInCookie !== "null" &&
        currentSubDomain !== subDomainInCookie
      ) {
        window.location.replace(
          `https://${subDomainInCookie}${config.baseDomain}/#/${path}`,
        );
      } else if (subDomainInCookie === "null") {
        window.location.replace(`${config.homeUrl}/#/${path}`);
      }
    }
  }
};
