import dayjs from "dayjs";

export const formatDateFromTimestamp = (
  timestamp: number | string,
  format?: string,
) =>
  dayjs(timestamp)
    .format(format ? format : "DD.MM.YYYY")
    .toLowerCase();
export const formatDateTimeFromTimestamp = (timestamp: number | string) =>
  dayjs(timestamp).format("DD.MM.YYYY, HH:mm");
export const nokCurrencyFormatter = new Intl.NumberFormat("nb-NO", {
  currency: "NOK",
});
export function formatBytes(a, b = 2) {
  if (!+a) return "0 Bytes";
  const c = 0 > b ? 0 : b;
  const d = Math.floor(Math.log(a) / Math.log(1024));
  return `${Number.parseFloat((a / 1024 ** d).toFixed(c))} ${
    ["Bytes", "KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"][d]
  }`;
}

export const getTranslateFriendlyUpdatedStr = (timestamp: any) => {
  const seconds = Math.floor((new Date().getTime() - timestamp) / 1000);

  let interval = seconds / 31536000;
  if (interval > 1) {
    return ["wif.injury.yearsAgoWithCount", { count: Math.floor(interval) }];
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return ["wif.injury.monthsAgoWithCount", { count: Math.floor(interval) }];
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return ["wif.injury.daysAgoWithCount", { count: Math.floor(interval) }];
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return ["wif.injury.hoursAgoWithCount", { count: Math.floor(interval) }];
  }
  interval = seconds / 60;
  if (interval > 1) {
    return ["wif.injury.minutesAgoWithCount", { count: Math.floor(interval) }];
  }
  if (seconds > 1) {
    return ["wif.injury.justNow"];
  }
  return ["wif.injury.justNow"];
};

export const getLastUpdatedStr = (timestamp: any) => {
  const date = new Date(timestamp);
  const seconds = Math.floor((new Date().getTime() - timestamp) / 1000);

  let interval = seconds / 31536000;
  if (interval > 1) {
    return `${Math.floor(interval)} years ago`;
  }
  interval = seconds / 2592000;
  if (interval > 2) {
    return `${Math.floor(interval)} months ago`;
  }
  if (interval > 1) {
    return `${Math.floor(interval)} month ago`;
  }
  interval = seconds / 86400;
  if (interval > 2) {
    return `${Math.floor(interval)} days ago`;
  }
  if (interval > 1) {
    return `${Math.floor(interval)} day ago`;
  }
  interval = seconds / 3600;
  if (interval > 2) {
    return `${Math.floor(interval)} hours ago`;
  }
  if (interval > 1) {
    return `${Math.floor(interval)} hour ago`;
  }
  interval = seconds / 60;
  if (interval > 2) {
    return `${Math.floor(interval)} minutes ago`;
  }
  if (interval > 1) {
    return `${Math.floor(interval)} minute ago`;
  }
  if (seconds > 1) {
    return "Now";
  }
  return "Now";
};

export const getFormattedMonthLabel = (
  monthNumber: number | string,
  locales: string | string[] = "nb",
  format: "long" | "short" = "long",
): string => {
  const year = new Date().getFullYear();

  const formatter = new Intl.DateTimeFormat(locales, {
    month: format,
  });

  return formatter.format(
    new Date(year, Number.parseInt(monthNumber.toString())),
  );
};
