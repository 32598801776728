import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

interface LineChartProps {
  options?: any;
  data?: any;
}

export default function LineChart(props: LineChartProps) {
  const { options, data } = props;

  return <Line options={options} data={data} />;
}
