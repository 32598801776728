import { useRef } from "react";
import MapGL, { Marker } from "react-map-gl";
import mapMarkerImg from "../../../assets/mapmarker.svg";
import { Button, Stack, StepButton, Typography, useTheme } from "@mui/material";
import { Paper } from "@mui/material";
import { MAPBOX_TOKEN } from "../../../features/sites/components/Map";
import { Box } from "@mui/system";
import React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import DrawControl from "../../../features/sites/components/CreateAreaDialog/DrawControls";
import buffer from "@turf/buffer";
import bbox from "@turf/bbox";
import { point } from "@turf/helpers";
import _ from "lodash";

const steps = [
  {
    label: "Mark center of location",
    description: "Click on the map to mark the center location of your site.",
  },
  {
    label: "Draw outline of site (optional)",
    description:
      "Use the draw controls in the upper right corner to draw a polygon for the outline of the site.",
  },
];

const mapStyles: any = {
  width: "100%",
  height: "100%",
  position: "relative",
  minHeight: "320px",
  minWidth: "480px",
  flex: 1,
};

type FormCoordinateMapWithOutlinePartProps = {
  baseCoordinates?: any;
  coordinates?: any;
  onChangeCoordinates: any;
  error?: boolean;
  errorMessage?: string;
  outline?: {
    onChange: any;
    value: any;
  };
};

export default function FormCoordinateMapWithOutlinePart(
  props: FormCoordinateMapWithOutlinePartProps,
) {
  const {
    baseCoordinates,
    coordinates,
    onChangeCoordinates,
    error = false,
    errorMessage,
    outline,
  } = props;

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [polygon, setPolygon] = React.useState<any>();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClick = (event: any) => {
    if (!coordinates) {
      if (event.lngLat) {
        const newCoordinates = { lat: event.lngLat.lat, lng: event.lngLat.lng };
        onChangeCoordinates(newCoordinates);
      }
    }
  };

  const handleMarkerDrag = (event: any) => {
    if (event.lngLat) {
      const newCoordinates = { lat: event.lngLat.lat, lng: event.lngLat.lng };
      onChangeCoordinates(newCoordinates);
    }
  };

  const cursor = React.useMemo(() => {
    if (coordinates) {
      return "auto";
    }
    return "pointer";
  }, [coordinates]);

  const [drawFeatures, setDrawFeatures] = React.useState<any>({});

  const outlineIsDrawn = React.useMemo(() => {
    if (polygon && polygon) {
      return Object.keys(polygon).length > 0;
    }
    return false;
  }, [polygon]);

  const handleUpdatePolygon = React.useCallback((e: any) => {
    setPolygon((currFeatures: any) => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const handleDeletePolygon = React.useCallback((e: any) => {
    setPolygon({});
  }, []);

  const baseMaxBounds = React.useCallback(() => {
    const areaPoint = point([baseCoordinates.lng, baseCoordinates.lat]);
    const buffered = buffer(areaPoint, 1, { units: "kilometers" });
    const baseArea = bbox(buffered);
    console.log(`baseArea: ${JSON.stringify(baseArea)}`);
    return baseArea as any;
  }, [baseCoordinates]);

  const handleResetPin = () => {
    onChangeCoordinates(undefined);
  };

  const completedSteps = React.useMemo(() => {
    const completed = {
      0: false,
      1: false,
    };
    if (coordinates) {
      completed[0] = true;
      if (outlineIsDrawn) {
        completed[1] = true;
      }
    }
    return completed;
  }, [coordinates, outlineIsDrawn]);

  const drawRef = useRef<any>(null);

  const initalizeDraw = () => {
    if (polygon === undefined) {
      if (outline?.value) {
        drawRef.current.add({
          id: "initial-outline",
          type: "Feature",
          properties: {},
          geometry: {
            coordinates: [outline.value],
            type: "Polygon",
          },
        });
        console.log(
          `drawRef.current.getAll(): ${JSON.stringify(
            drawRef?.current?.getAll(),
            null,
            2,
          )}`,
        );
        setPolygon(drawRef?.current.getAll()?.features);
        setActiveStep(1);
      } else {
        setPolygon({});
      }
    }
  };

  React.useEffect(() => {
    _.debounce(initalizeDraw, 500)();
  }, [outline, polygon]);

  React.useEffect(() => {
    if (polygon) {
      if (Object.keys(polygon).length > 0) {
        console.log(`polygon: ${JSON.stringify(polygon, null, 2)}`);
        const outlineFeatureId = Object.keys(polygon)[0];
        const outlineCoordinates =
          polygon[outlineFeatureId].geometry.coordinates[0];
        outline.onChange(outlineCoordinates);
      } else {
        outline.onChange(undefined);
      }
    }
  }, [polygon]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        ...(activeStep !== 1 &&
          !outlineIsDrawn && {
            "& .mapboxgl-ctrl-group": {
              display: "none",
            },
          }),
        ...(outlineIsDrawn && {
          "& .mapbox-gl-draw_polygon": {
            display: "none",
          },
        }),
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          position: "relative",
          flex: 1,
          borderRadius: 1,
          overflow: "hidden",
          ...(error
            ? {
                borderColor: "error.main",
              }
            : {
                border: 0,
              }),
        }}
      >
        <MapGL
          maxBounds={baseMaxBounds()}
          style={mapStyles}
          onClick={handleClick}
          mapStyle={"mapbox://styles/emkf/clhleppw801np01pg76wi2jkx"}
          mapboxAccessToken={MAPBOX_TOKEN}
          initialViewState={{
            latitude: baseCoordinates.lat,
            longitude: baseCoordinates.lng,
            zoom: 17,
          }}
          cursor={cursor}
          maxPitch={85}
        >
          <DrawControl
            position="top-right"
            displayControlsDefault={false}
            controls={{
              polygon: true,
              trash: true,
            }}
            onCreate={handleUpdatePolygon}
            onUpdate={handleUpdatePolygon}
            onDelete={handleDeletePolygon}
            ref={drawRef}
          />
          {coordinates && (
            <Marker
              longitude={coordinates.lng}
              latitude={coordinates.lat}
              draggable
              onDrag={handleMarkerDrag}
            >
              <img src={mapMarkerImg} />
            </Marker>
          )}
        </MapGL>
      </Paper>
      {errorMessage && (
        <Typography
          variant="body2"
          color="error"
          sx={{ fontSize: "10.5px", ml: 1.75, mt: "3px" }}
        >
          {errorMessage}
        </Typography>
      )}
      <Paper
        sx={{
          position: "absolute",
          top: 8,
          left: 8,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          maxWidth: "320px",
        }}
      >
        <Stepper activeStep={activeStep} orientation="vertical" nonLinear>
          {steps.map((step, index) => (
            <Step key={step.label} completed={completedSteps[index]}>
              <StepButton color="inherit" onClick={() => setActiveStep(index)}>
                <StepLabel>{step.label}</StepLabel>
              </StepButton>

              <StepContent>
                <Typography>{step.description}</Typography>
                <Box sx={{ mb: 2 }}>
                  {index === 0 && (
                    <Stack direction="row" gap={1} mt={1}>
                      {coordinates && (
                        <Button
                          variant="contained"
                          onClick={handleResetPin}
                          disabled={!coordinates || outlineIsDrawn}
                        >
                          Reset placement
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        disabled={!coordinates}
                      >
                        Next
                      </Button>
                    </Stack>
                  )}
                  {index === 1 && (
                    <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      Back
                    </Button>
                  )}
                  <div />
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Paper>
    </Box>
  );
}
