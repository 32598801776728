import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import type { FetchedStatus } from "../../types";
import { httpGet } from "../../utils/httpService";
import config from "../../config/config";
import _ from "lodash";

const recursive = (departments: any[], prefix: string[] = []): string[] => {
  return departments.reduce((acc: string[], val: any) => {
    acc.push(`/${prefix.join("/")}${prefix.length > 0 ? "/" : ""}${val.id}`);

    if (val?.items && val?.items?.length > 0) {
      acc.push(...recursive(val.items, [...prefix, val.id]));
    }

    return acc;
  }, []);
};

const getPathByDepartmentId = (
  departmentId: string,
  paths: string[],
): string => {
  for (const path of paths) {
    if (path.endsWith(departmentId)) {
      return path;
    }
  }
  return "departmentId";
};

export type DepartmentChild = {
  id: string;
  name: string;
};

export type SamDepartment = {
  id: string;
  name?: string;
  users?: string[];
  nc_users?: string[];
  fall_users?: string[];
  injury_student_users?: string[];
  injury_employee_users?: string[];
  dept_leaders?: string[];
  // Child department ids
  // children?: string[];
  children?: DepartmentChild[];
  depth?: number;
  injury_dept_leaders?: string[];
  path?: string;
};

export interface FetchedState {
  fetched: FetchedStatus;
}

const departmentsAdapter = createEntityAdapter<SamDepartment>();

export const fetchAllDepartments = createAsyncThunk(
  "departments/fetchAll",
  async (thunkAPI) => {
    const response = await httpGet(`${config.baseUrl}/departments/getAll`);
    const data = await response.data;
    return data;
  },
);

const initialState = departmentsAdapter.getInitialState({
  fetched: "idle",
} as FetchedState);

export const departmentsSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllDepartments.pending, (state, action) => {
      state.fetched = "pending";
    });
    builder.addCase(fetchAllDepartments.fulfilled, (state, action) => {
      const departmentsToAdd: SamDepartment[] = [];

      const dep_with_injury_dept_leaders: {
        department: SamDepartment;
        injury_dept_leaders: [];
      }[] = [];
      const injury_dept_leaders_by_department_id: { [key: string]: string[] } =
        {};

      const departmentPaths = recursive(action.payload[0].company_tree);

      const flatten = (arr: any[], prevDepth: number) => {
        const depth = prevDepth + 1;
        for (const department of arr) {
          if (department?.dept_leaders) {
            dep_with_injury_dept_leaders.push({
              department: department,
              injury_dept_leaders: department.dept_leaders,
            });
            injury_dept_leaders_by_department_id[department.id] = [
              ...department.dept_leaders,
            ];
          }
          departmentsToAdd.push({
            id: department.id,
            name: department.name,
            users: department?.users || [],
            nc_users: department?.nc_users || [],
            fall_users: department?.fall_users || [],
            injury_student_users: department?.injury_student_users || [],
            injury_employee_users: department?.injury_employee_users || [],
            children: department.items
              ? department.items.map((child: DepartmentChild) => {
                  return { id: child.id, name: child.name };
                })
              : [],
            dept_leaders: department?.dept_leaders || [],
            depth: depth,
          });
          if (department.items) {
            flatten(department.items, depth);
          }
        }
      };

      flatten(action.payload[0].company_tree, 0);

      const flattenArrChildren = (
        children: DepartmentChild[],
        userIds: string[],
      ) => {
        for (const child of children) {
          if (injury_dept_leaders_by_department_id[child.id]) {
            injury_dept_leaders_by_department_id[child.id] = [
              ...injury_dept_leaders_by_department_id[child.id],
              ...userIds,
            ];
          } else {
            injury_dept_leaders_by_department_id[child.id] = [...userIds];
          }
          const department = departmentsToAdd.find(
            (department) => department.id === child.id,
          );
          if (department.children) {
            flattenArrChildren(department.children, userIds);
          }
        }
      };

      for (const [departmentId, i_dept_leaders] of Object.entries(
        injury_dept_leaders_by_department_id,
      )) {
        flattenArrChildren(
          departmentsToAdd.find((department) => department.id === departmentId)
            ?.children || [],
          i_dept_leaders,
        );
      }

      const finalDepartments: SamDepartment[] = [];

      for (const department of departmentsToAdd) {
        const updatedDepartment = { ...department };

        const path = getPathByDepartmentId(department.id, departmentPaths);

        updatedDepartment.path = path;

        const injury_dept_leaders =
          injury_dept_leaders_by_department_id[department.id];
        if (injury_dept_leaders) {
          finalDepartments.push({
            ...updatedDepartment,
            injury_dept_leaders: [...injury_dept_leaders],
          });
        } else {
          finalDepartments.push(updatedDepartment);
        }
      }

      finalDepartments.sort((a, b) => a.path.localeCompare(b.path));
      departmentsAdapter.setAll(state, finalDepartments);
      state.fetched = "succeeded";
    });
  },
});

export const {
  selectIds: selectDepartmentIds,
  selectEntities: selectDepartmentEntities,
  selectAll: selectAllDepartments,
  selectTotal: selectTotalDepartments,
  selectById: selectDepartmentById,
} = departmentsAdapter.getSelectors<RootState>((state) => state.departments);

export const selectDepartmentsFetchedStatus = (state: RootState) =>
  state.departments.fetched;

const getNumber = (_: any, num: number) => num;
const getStrKey = (_: any, key: string) => key;

export const makeSelectDepartmentIdsLeadByUser = () => {
  return createSelector(
    [selectAllDepartments, getStrKey],
    (departments, userId) => {
      const departmentIds: string[] = [];
      const directlyAssigned = departments.filter((department) =>
        department.dept_leaders?.includes(userId),
      );

      for (const department of directlyAssigned) {
        departmentIds.push(department.id);
        const subDepartments = departments.filter((department) =>
          department.path.split("/").includes(department.id),
        );
        departmentIds.push(
          ...subDepartments.map((department) => department.id),
        );
      }
      return _.uniq(departmentIds);
    },
  );
};

export const makeSelectDepartmentsByDepth = () => {
  return createSelector(
    [selectAllDepartments, getNumber],
    (departments, depth) => {
      return departments.filter((department) => department.depth === depth);
    },
  );
};

export const makeSelectHighestHandlingDepthByUserId = () => {
  return createSelector(
    [selectAllDepartments, getStrKey],
    (departments, userId) => {
      let depth = 1;
      let userFoundOrMaximumDepthReached = false;
      while (!userFoundOrMaximumDepthReached) {
        const depthFilteredDepartments = departments.filter(
          (department) => department.depth === depth,
        );
        if (depthFilteredDepartments.length > 0) {
          for (const department of depthFilteredDepartments) {
            if (department?.injury_student_users?.includes(userId)) {
              userFoundOrMaximumDepthReached = true;
            }
          }
        } else {
          userFoundOrMaximumDepthReached = true;
          depth = -1;
        }

        depth++;
      }
      return depth;
    },
  );
};

export const makeSelectEmployeeHandlerDepartmentIdsByUserId = () => {
  return createSelector(
    [selectAllDepartments, getStrKey],
    (departments, userId) => {
      const departmentIds: string[] = [];
      for (const department of departments) {
        if (department?.injury_employee_users?.includes(userId)) {
          departmentIds.push(department.id);
        }
      }
      return departmentIds;
    },
  );
};

export const makeSelectStudentHandlerDepartmentIdsByUserId = () => {
  return createSelector(
    [selectAllDepartments, getStrKey],
    (departments, userId) => {
      const departmentIds: string[] = [];
      for (const department of departments) {
        if (department?.injury_student_users?.includes(userId)) {
          departmentIds.push(department.id);
        }
      }
      return departmentIds;
    },
  );
};

export const makeSelectEmployeeHandlerDepartmentIdsByTenantAdminUserId = () => {
  return createSelector(
    [selectAllDepartments, getStrKey],
    (departments, userId) => {
      let depth = 1;
      let bedrockDepth = 1;
      departments.forEach((department) => {
        if (department.depth > bedrockDepth) {
          bedrockDepth = department.depth;
        }
      });
      let userFound = false;
      let handlerAccessDepartments: SamDepartment[] = [];
      while (!userFound && depth <= bedrockDepth) {
        const depthFilteredDepartments = departments.filter(
          (department) =>
            department.depth === depth &&
            department?.injury_employee_users &&
            department.injury_employee_users?.indexOf(userId) > -1,
        );
        if (depthFilteredDepartments.length > 0) {
          userFound = true;
          handlerAccessDepartments = [...depthFilteredDepartments];
        } else {
          userFound = true;
          depth = -1;
        }

        depth++;
      }

      const departmentIds: string[] = [];

      const flatten = (arr: SamDepartment[]) => {
        for (const department of arr) {
          departmentIds.push(department.id);
          if (department.children) {
            flatten(
              department.children.map(
                (child) =>
                  departments.find(
                    (department) => department.id === child.id,
                  ) as SamDepartment,
              ),
            );
          }
        }
      };

      if (depth !== -1) {
        flatten(handlerAccessDepartments);
      }

      departments.forEach((department) => {
        if (
          department?.injury_employee_users?.indexOf(userId) > -1 ||
          department?.dept_leaders?.indexOf(userId) > -1
        ) {
          if (departmentIds.indexOf(department.id) === -1) {
            departmentIds.push(department.id);
          }
        }
      });

      return departmentIds;
    },
  );
};

export const makeSelectStudentHandlerDepartmentIdsByTenantAdminUserId = () => {
  return createSelector(
    [selectAllDepartments, getStrKey],
    (departments, userId) => {
      let depth = 1;
      let bedrockDepth = 1;
      departments.forEach((department) => {
        if (department.depth > bedrockDepth) {
          bedrockDepth = department.depth;
        }
      });
      let userFound = false;
      let handlerAccessDepartments: SamDepartment[] = [];
      while (!userFound && depth <= bedrockDepth) {
        const depthFilteredDepartments = departments.filter(
          (department) =>
            department.depth === depth &&
            department?.injury_student_users &&
            department.injury_student_users?.indexOf(userId) > -1,
        );
        if (depthFilteredDepartments.length > 0) {
          userFound = true;
          handlerAccessDepartments = [...depthFilteredDepartments];
        } else {
          userFound = true;
          depth = -1;
        }

        depth++;
      }

      const departmentIds: string[] = [];

      const flatten = (arr: SamDepartment[]) => {
        for (const department of arr) {
          departmentIds.push(department.id);
          if (department.children) {
            flatten(
              department.children.map(
                (child) =>
                  departments.find(
                    (department) => department.id === child.id,
                  ) as SamDepartment,
              ),
            );
          }
        }
      };

      if (depth !== -1) {
        flatten(handlerAccessDepartments);
      }

      departments.forEach((department) => {
        if (
          department?.injury_student_users?.indexOf(userId) > -1 ||
          department?.dept_leaders?.indexOf(userId) > -1
        ) {
          if (departmentIds.indexOf(department.id) === -1) {
            departmentIds.push(department.id);
          }
        }
      });

      return departmentIds;
    },
  );
};

export default departmentsSlice.reducer;
