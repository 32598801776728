import { createAsyncThunk } from "@reduxjs/toolkit";
import type { CreateMessagePayload } from "../components/ReportDetails/CasePanels/Comments";
import { httpGet, httpPost } from "../../../utils/httpService";
import config from "../../../config/config";
import type { IReopenInjuryReportPayload } from "../components/ReportDetails/CaseHandlingDialogs/ReopenInjuryReportDialog";
import type { IInjuryLocationPayload } from "../components/ReportDetails/CaseHandlingDialogs/InjuryLocationDialog";

export const fetchInjuryReportById = createAsyncThunk(
  "injuryReports/getReportById",
  async (reportId: string, { rejectWithValue }) => {
    try {
      const response = await httpGet(
        `${config.baseUrl}/injury/getReportById/${reportId}`,
      );
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log("error", error);
      console.log("data", error.response.data);
      console.log("message", error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface IReopenInjuryReportReq {
  reportId: string;
  payload: IReopenInjuryReportPayload;
}

export const reopenInjuryReport = createAsyncThunk(
  "injuryReports/reopenInjuryReport",
  async (req: IReopenInjuryReportReq, { rejectWithValue }) => {
    try {
      const response = await httpPost(
        `${config.baseUrl}/injury/reopenInjuryReport/${req.reportId}`,
        req.payload,
      );
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log("error", error);
      console.log("data", error.response.data);
      console.log("message", error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const fetchInjuryAttachments = createAsyncThunk(
  "injuryReports/fetchAttachments",
  async (thunkAPI) => {
    const response = await httpGet(`${config.baseUrl}/injury/attachments`);
    return response.data;
  },
);

export interface CreateInjuryActionCommentReq {
  reportId: string;
  actionId: string;
  payload: CreateMessagePayload;
}

export const createInjuryActionComment = createAsyncThunk(
  "injuryReports/createInjuryActionComment",
  async (req: CreateInjuryActionCommentReq, { rejectWithValue }) => {
    try {
      const response = await httpPost(
        `${config.baseUrl}/injury/reports/${req.reportId}/actions/${req.actionId}/comments`,
        req.payload,
      );
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log("error", error);
      console.log("data", error.response.data);
      console.log("message", error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const completeInjuryAction = createAsyncThunk(
  "injuryReports/completeInjuryAction",
  async (req: any, { rejectWithValue }) => {
    try {
      const response = await httpPost(
        `${config.baseUrl}/injury/completeAction`,
        req.payload,
      );
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log("error", error);
      console.log("data", error.response.data);
      console.log("message", error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const reopenInjuryAction = createAsyncThunk(
  "injuryReports/reopenAction",
  async (req: any, { rejectWithValue }) => {
    try {
      const response = await httpPost(
        `${config.baseUrl}/injury/reopenAction`,
        req.payload,
      );
      const data = response.data;
      if (response.status !== 200 && response.status !== 201) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log("error", error);
      console.log("data", error.response.data);
      console.log("message", error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface IUpdateInjuryReportLocationReq {
  reportId: string;
  payload: IInjuryLocationPayload;
}

export const updateInjuryReportLocation = createAsyncThunk(
  "injuryReports/updateInjuryReportLocation",
  async (req: IUpdateInjuryReportLocationReq, { rejectWithValue }) => {
    try {
      const response = await httpPost(
        `${config.baseUrl}/injury/set_injury_report_location/${req.reportId}`,
        req.payload,
      );
      const data = response.data;
      if (response.status !== 200) {
        return rejectWithValue(data);
      }
      return data;
    } catch (error) {
      console.log("error", error);
      console.log("data", error.response.data);
      console.log("message", error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  },
);
