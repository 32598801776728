import { nokCurrencyFormatter } from "../../../utils/format";
import {
  FORM_OF_SALARY_LABELS,
  WORK_TIME_ARRANGEMENTS_LABELS,
} from "../components/FormSections/employee";

export const mappedInputLabels: any = {
  name: "Full name",
  address: "Address",
  title: "Title",
  nameOfInjuredParty: "Name of injured party",
  ssn: "SSN",
  typesOfInjury: "Types of injury",
  municipality: "Municipality",
  nationalIdentityNumber: "National identity number",
  citizenship: "Citizenship",
  siteOfDamage: "Site of damage",
  unit: "Unit",
  department: "Department",
  date: "Date",
  time: "Time",
  accidentType: "Accident type",
  damageNature: "Damage nature",
  damagedBodyPart: "Damaged body parts",
  accidentOccured: "When did the accident occur?",
  description: "Description",
  phoneNumber: "Phone number",
  bankAccountNumber: "Bank account number",
  profession: "Profession at the time of the injury",
  occupation: "Occupation at the time of the injury",
  employmentPercentage: "Employment percentage",
  employmentStartDate: "Employment start date",
  employmentEndDate: "Employment end date",
  assumedAnnualIncomeForClaimYear: "Assumed annual income for claim year",
  competenceLevel: "Competence level",
  natureOfEmployment: "Nature of employment at the time of the injury",
  voluntaryOccupationalInjuryInsurance:
    "If self-employed or freelancing, voluntary occupational injury insurance?",
  organizationNumber: "Organization number",
  insuranceCompanyName: "Name of employers insurance company",
  insuranceCompanyAddress: "Address of employers insurance company",
  linkToInsurance: "Link to insurance company",
  accidentHappenedAtMainAddress: "Did the injury occur at the above address?",
  ncReportId: "Nonconformity Report",
  addressOfAccident: "Address of accident",
  durationOfImpact: "Duration of impact",
  workTimeArrangements:
    "What was the work time arrangement when the accident occurred?",
  formOfSalary: "What was the form of salary when the accident occurred?",
  atNormalWorkplace: "Did the accident occur at the normal workplace?",
  insideOutside: "Did the accident occur inside or outside?",
  onTheWayToWork: "Did the accident occur on the way to work?",
  onTheWayBetweenWork: "DId the accident occur on the way between workplaces?",
  deathFromAccident: "DId the accident result in death?",
  diseaseName: "Name of the disease",
  detectionDate: "Detection date",
  deathFromDisease: "Did the disease result in death?",
  provokingInfluence: "Influence that provoked the disease",
  diseaseCause: "Disease cause",
  workAroundImpact: "What did the work consist of around the time of impact?",
  unitId: "Unit",
  departmentId: "Department",
  employeeId: "Employee ID",
  background: "Background",
  damageMode: "Damage mode",
  typeOfWorkplace: "Type of workplace",
  deviation: "Deviation",
  assumedAbsence: "Assumed absence",
};

export const mappedSectionValueGetters: any = {
  damageNature: (val: string[]) => val.join(", "),
  damagedBodyPart: (val: string[]) => val.join(", "),
  accidentType: (val: string[]) => val.join(", "),
  durationOfImpact: (val: string[]) => val.join(", "),
  assumedAnnualIncomeForClaimYear: (val: number) =>
    `kr ${nokCurrencyFormatter.format(val)}`,
  employmentPercentage: (val: number) => `${val} %`,
  formOfSalary: (val: string) => FORM_OF_SALARY_LABELS[val],
  workTimeArrangements: (val: string) => WORK_TIME_ARRANGEMENTS_LABELS[val],
  typesOfInjury: (val: any[]) => val.map((v: any) => v.name).join(", "),
};

export const SUBHEADER_ABOVE_FIELD: any = {
  profession: "Employment",
  insuranceCompanyName: "Insurance company",
  workTimeArrangements: "Work arrangements",
  atNormalWorkplace: "Location",
};

export const GENERIC_VALIDATION_MSG = "generic";
