import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { AppDialogTitle } from "../../../../../components/Display/AppDialogTitle";
import { useAppDispatch } from "../../../../../store";
import { snackAlertOpened } from "../../../../global/controlsSlice";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useForm, type SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { reopenInjuryReport } from "../../../store/injuryReportThunks";
import { FormTextField } from "../../../../../components/FormInputs";
import { useLoading } from "../../../../../common/useLoading";
import { LoadingButtonContainer } from "../../../../../components/Elements/LoadingButtonContainer";

export const reopenInjuryReportPayloadSchema = yup.object().shape({
  message: yup.string().required().min(4, "min 4").max(1200, "max 1200"),
});

export type IReopenInjuryReportPayload = yup.InferType<
  typeof reopenInjuryReportPayloadSchema
>;

interface ReopenInjuryReportDialogProps {
  open?: boolean;
  injuryReportId: string;
  onClose?: () => void;
}

export default function ReopenInjuryReportDialog(
  props: ReopenInjuryReportDialogProps,
) {
  const { open = true, injuryReportId, onClose } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { control, handleSubmit } = useForm<IReopenInjuryReportPayload>({
    resolver: yupResolver(reopenInjuryReportPayloadSchema),
  });

  const [{ isLoading, loadingMessage }, { startLoading, stopLoading }] =
    useLoading(false);

  const onSubmit: SubmitHandler<IReopenInjuryReportPayload> = (data) => {
    const req = {
      reportId: injuryReportId,
      payload: data,
    };
    startLoading();
    dispatch(reopenInjuryReport(req)).then((resAction) => {
      stopLoading();
      if (reopenInjuryReport.fulfilled.match(resAction)) {
        dispatch(
          snackAlertOpened({
            message: t("wif.injury.snackMessages.reportReopened.success"),
            severity: "success",
          }),
        );
        onClose();
      } else {
        if (resAction.payload) {
          dispatch(
            snackAlertOpened({
              message: t("wif.injury.snackMessages.reportReopened.", {
                message: JSON.stringify(resAction.payload),
              }),
              severity: "error",
            }),
          );
        } else {
          dispatch(
            snackAlertOpened({
              message: t("wif.injury.snackMessages.errorWithMessage", {
                message: JSON.stringify(resAction.error),
              }),
              severity: "error",
            }),
          );
        }
      }
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <AppDialogTitle id="reopen-report-dialog-title" onClose={onClose}>
        {t("wif.injury.reopenReportDialog.title")}
      </AppDialogTitle>
      <DialogContent
        dividers
        sx={{
          width: 600,
        }}
      >
        <form id="reopen-report-form" onSubmit={handleSubmit(onSubmit)}>
          <FormTextField
            control={control}
            name="message"
            label={t("wif.injury.message")}
            maxRows={6}
            multiline
            fullWidth
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="text"
          onClick={onClose}
          disabled={isLoading}
        >
          {t("wif.injury.cancel")}
        </Button>
        <LoadingButtonContainer loading={isLoading}>
          <Button
            type="submit"
            form="reopen-report-form"
            variant="contained"
            color="primary"
            disabled={isLoading}
          >
            {t("wif.injury.reopen")}
          </Button>
        </LoadingButtonContainer>
      </DialogActions>
    </Dialog>
  );
}
