import type React from "react";
interface MapLocationsPanelProps {
  value: string;
  selectedPanel?: string;
  children?: React.ReactNode;
}

export const MapLocationsPanel = (props: MapLocationsPanelProps) => {
  const { value, selectedPanel, children } = props;

  const visible = value === selectedPanel;
  return (
    <div
      role="tabpanel"
      hidden={!visible}
      id={`MapLocationsPanel-${value}`}
      style={{
        flex: 1,
        display: visible ? "flex" : "none",
        flexDirection: "column",
        overflow: "hidden",
        minHeight: 0,
      }}
    >
      {visible && children}
    </div>
  );
};
