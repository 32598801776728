import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { useAppSelector } from "../../../../../store";
import { selectInjuryHandlerReportIds } from "../../../store/injuryReportsSlice";

export const RenderLinkCell = (params: any) => {
  const handlerReportIds = useAppSelector(selectInjuryHandlerReportIds);

  if (
    params.row.userHasReadAccess ||
    handlerReportIds.indexOf(params.row.id) === -1
  ) {
    return <>{`${params.value}`}</>;
  }

  return (
    <Link
      component={RouterLink}
      variant="body2"
      to={`${params.row.id}`}
      underline={"none"}
      sx={{
        "&:hover": {
          textDecoration: "underline",
          color: "text.primary",
        },
        fontWeight: 400,
        minWidth: 0,
        color: (theme) =>
          theme.palette.mode === "light"
            ? "primary.main"
            : "#c7d2fe" /* indigo 300 */,
      }}
    >
      {`${params.value}`}
    </Link>
  );
};
