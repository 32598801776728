import type { WithChildren } from "../../types";

type FormSectionPanelProps = WithChildren<{
  sectionName: string;
  activeSectionName: string;
}>;

export const FormSectionPanel = (props: FormSectionPanelProps) => {
  const { sectionName, activeSectionName, children } = props;

  return <div hidden={sectionName !== activeSectionName}>{children}</div>;
};
