import Typography from "@mui/material/Typography";
import type { WithChildren } from "../../types";

export const FormSectionSubHeader = ({ children }: WithChildren<{}>) => {
  return (
    <Typography mt={2} variant="h4" className="FormSectionSubHeader">
      {children}
    </Typography>
  );
};
