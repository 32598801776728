import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  SubTitle,
  Title,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(ArcElement, Tooltip, Legend, Title, SubTitle);

type args = {
  theme: any;
  title?: string;
  subtitle?: string;
  position?: "top" | "bottom" | "left" | "right";
};

export const getDefaultDoughnutChartOptionsWithTitle = ({
  theme,
  title,
  subtitle,
  position = "right",
}: args) => {
  return {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position,
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          usePointStyle: false,
          color: theme.palette.text.secondary,
          font: {
            size: 14,
            family: "Inter var",
            opacity: 1,
          },
        },
      },
      title: {
        padding: {
          bottom: -10,
        },
        display: true,
        align: "start",
        color: theme.palette.text.primary,
        font: {
          family: "Inter var",
          size: theme.typography.h4.fontSize,
          weight: 600,
          lineHeight: theme.typography.h4.lineHeight,
        },
        text: title ? title : "Title",
      },
      subtitle: {
        display: true,
        text: subtitle,
        align: "start",
        color: theme.palette.text.secondary,
        font: {
          family: "Inter var",
          size: theme.typography.body2.fontSize,
          lineHeight: theme.typography.body2.fontSize,
        },
        padding: {
          top: 14,
          bottom: 20,
        },
      },
      datalabels: {
        backgroundColor: (context) => context.dataset.backgroundColor,
        borderColor:
          theme.palette.mode === "light"
            ? theme.palette.background.paper
            : theme.palette.backgroundVariant,
        borderRadius: 25,
        borderWidth: 2,
        color: theme.palette.background.paper,
        display: (context) => {
          const dataset = context.dataset;
          const value = dataset.data[context.dataIndex];
          const total = dataset.data.reduce((sum, val) => sum + val, 0);

          // Calculate the percentage of the segment
          const percentage = (value / total) * 100;
          const threshold = 5;

          return percentage > threshold;
        },
        font: {
          weight: "bold",
        },
        padding: 6,
        formatter: Math.round,
      },
    },
  } as any;
};

const defaultOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Doughnut Chart",
    },
  },
} as any;

interface DoughnutChartProps {
  options?: any;
  data?: any;
}

export default function DoughnutChart(props: DoughnutChartProps) {
  const { options = defaultOptions, data } = props;

  const plugins = [ChartDataLabels as any];

  return <Doughnut options={options} data={data} plugins={plugins} />;
}
