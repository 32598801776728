import React from "react";
import { useTheme } from "@mui/material/styles";
import type { InjuryReportsFilter } from "../InjuryReportsDataGrid/InjuryReportsDataGrid";
import { BarChart } from "../../../../components/Charts/BarChart";
import {
  getChartColorByIndex,
  getDefaultBarChartOptions,
  getXLabelTicksSettings,
} from "../../../../components/Charts/helpers";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  getInitialMonthCounts,
  getInitialMonthLabelArrForYear,
} from "./InjuryReportsByMonthChart";
import type { InjuryReportCase } from "../../types";

type InjuryReportsByMonthBarsProps = {
  reports?: InjuryReportCase[];
  title?: string;
  subtitle?: string;
  filters?: InjuryReportsFilter[];
  bars?: {
    field: string;
    values: string[];
    colors?: string[];
    labels?: string[];
  };
  timePeriod: [string, string, string];
};

export const InjuryReportsByMonthBars = (
  props: InjuryReportsByMonthBarsProps,
) => {
  const {
    reports,
    filters = [],
    bars = { field: "type", values: ["Accident", "Illness", "Minor injury"] },
    title = "",
    subtitle = "",
    timePeriod,
  } = props;

  const theme = useTheme() as any;
  const { t } = useTranslation();
  const [timeFrame, year, label] = timePeriod;

  const chartData = React.useMemo(() => {
    const datasets: any = [];
    const lineMonthCounts: { [line: string]: { [month: string]: number } } = {};
    const defaultOptions = getDefaultBarChartOptions({
      theme,
      title,
      subtitle,
      disableTooltip: false,
    });

    const xLabelTicks = getXLabelTicksSettings();

    const options = _.merge(defaultOptions, xLabelTicks);

    const monthLabels = Array.from(Array(12).keys()).map((month) =>
      month.toString(),
    );
    options.plugins.tooltip.callbacks = {
      title: (context) => t(`wif.injury.stats.months.${context[0].label}`),
    };

    for (const line of bars.values) {
      lineMonthCounts[line] = getInitialMonthCounts(monthLabels);
    }

    let monthLabelForTimePeriod = getInitialMonthLabelArrForYear(
      new Date().getMonth(),
    );

    const getReportDate = (timestamp) => new Date(timestamp);
    const isCurrentYear = (date) =>
      date.getFullYear() === new Date().getFullYear();
    const isLastYear = (date) =>
      date.getFullYear() === new Date().getFullYear() - 1;
    const isWithinSixMonths = (date) =>
      isCurrentYear(date) ||
      (isLastYear(date) && date.getMonth() > new Date().getMonth());

    const processReports = (reports, condition) => {
      for (const report of reports) {
        const reportDate = getReportDate(report.reportedTimestamp);
        if (condition(reportDate)) {
          const reportedMonth = reportDate.getMonth();
          const key = bars.field;
          if (bars.values.includes(report[key])) {
            lineMonthCounts[report[bars.field]][monthLabels[reportedMonth]] +=
              1;
          }
        }
      }
    };

    switch (timeFrame) {
      case "6M":
        processReports(reports, isWithinSixMonths);
        monthLabelForTimePeriod = monthLabelForTimePeriod.slice(-6);
        break;

      case "1M":
        processReports(reports, isWithinSixMonths);
        monthLabelForTimePeriod = monthLabelForTimePeriod.slice(-2);
        break;

      case "1T":
        processReports(reports, (date) => date.getFullYear() === +year);
        monthLabelForTimePeriod = ["0", "1", "2", "3"];
        break;

      case "2T":
        processReports(reports, (date) => date.getFullYear() === +year);
        monthLabelForTimePeriod = ["4", "5", "6", "7"];
        break;

      case "3T":
        processReports(reports, (date) => date.getFullYear() === +year);
        monthLabelForTimePeriod = ["8", "9", "10", "11"];
        break;

      default:
        processReports(reports, isWithinSixMonths);
        options.plugins.subtitle.text = t("wif.injury.lastYear");
        break;
    }

    for (let i = 0; i < bars.values.length; i++) {
      const line = bars.values[i];
      const color = bars.colors ? bars.colors[i] : getChartColorByIndex(i);
      const lineData: { x: any; y: any }[] = [];
      for (const monthLabel of monthLabelForTimePeriod) {
        lineData.push({ x: monthLabel, y: lineMonthCounts[line][monthLabel] });
      }
      datasets.push({
        data: lineData,
        label: bars?.labels[i] || line,
        borderWidth: 3,
        borderSkipped: true,
        borderColor: color,
        backgroundColor: color,
      });
    }

    const data = {
      labels: monthLabelForTimePeriod,
      datasets: datasets,
    };

    return {
      data,
      options,
    };
  }, [reports, t, timePeriod]);

  return <BarChart data={chartData.data} options={chartData.options} />;
};

type InjuryReportTimeOfDayBarsProps = {
  reports?: InjuryReportCase[];
  fieldPath: string;
  title?: string;
  subtitle?: string;
};

export const InjuryReportTimeOfDayBars = (
  props: InjuryReportTimeOfDayBarsProps,
) => {
  const { reports, fieldPath, title = "", subtitle = "" } = props;

  const theme = useTheme() as any;
  const { t } = useTranslation();

  const chartData = React.useMemo(() => {
    const options = getDefaultBarChartOptions({ theme, title, subtitle });
    options.plugins.legend.display = false;
    const labels = Array.from(Array(24).keys());
    const labelCount: any = {};
    for (const label of labels) {
      labelCount[label] = 0;
    }
    for (const report of reports) {
      const fieldValue = _.get(report, fieldPath, undefined);
      if (fieldValue) {
        if (typeof fieldValue === "string") {
          if (fieldValue.split(":").length === 2) {
            const hour = Number.parseInt(fieldValue.split(":")[0]);
            const minutes = Number.parseInt(fieldValue.split(":")[1]);
            let countedHour = hour;
            if (minutes >= 30) {
              if (hour === 23) {
                countedHour = 0;
              } else {
                countedHour += 1;
              }
            }
            labelCount[countedHour] += 1;
          }
        }
      }
    }
    const data = {
      labels: labels.map((label) => `${label < 10 ? "0" : ""}${label}`),
      datasets: [
        {
          data: labels.map((label) => labelCount[label]),
          label: t("wif.injury.stats.count"),
          borderWidth: 0,
          borderSkipped: true,
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.main,
        },
      ],
    };

    return {
      data,
      options,
    };
  }, [reports, fieldPath, t]);

  return <BarChart data={chartData.data} options={chartData.options} />;
};
