import { CONSTANTS } from "../common/constants";
import CookieStorage from "./CookieStorage";

export const getActiveUser = () => {
  const userAndOrg = JSON.parse(localStorage.getItem("ngStorage-current"));
  return userAndOrg?.user;
};

export const getHasHandlerReadClaim = () => {
  const user = getActiveUser();
  if (user.permissions?.claims?.length) {
    return (
      user.permissions.claims
        .map((claim) => claim.claim_id)
        .indexOf("b4cb162c-2f5f-11ee-be56-0242ac120002") > -1 &&
      user.role !== "tenantadmin" &&
      user.role !== "admin"
    );
  }
};

export const getSelectedOrgId = () => {
  const selectedOrg: any =
    CookieStorage.getItem(CONSTANTS.COOKIE_KEYS.SELECTED_ORG) || false;
  if (selectedOrg) {
    const parsedOrg = JSON.parse(selectedOrg);
    return parsedOrg?.org_id || undefined;
  }
  return undefined;
};

export const getSelectedOrg = () => {
  let selectedOrgHeader = "";
  const selectedOrg: any =
    CookieStorage.getItem(CONSTANTS.COOKIE_KEYS.SELECTED_ORG) || false;
  if (selectedOrg) {
    const parsedOrg = JSON.parse(selectedOrg);
    parsedOrg.org_name = undefined;
    parsedOrg.name = undefined;
    selectedOrgHeader = JSON.stringify(parsedOrg);
  }
  return selectedOrgHeader;
};
