import update from "immutability-helper";
import { useCallback } from "react";
import { SortableCard } from "./SortableCard";

const style = {
  width: 400,
};

export interface Item {
  id: string;
  text: string;
}

export interface ContainerState {
  cards: Item[];
}

type SortableContainerProps = {
  items: string[];
  setItems?: any;
  getLabelFromOption?: (option: any) => string;
  onChange: (items: string[]) => void;
};

export const SortableContainer = (props: SortableContainerProps) => {
  const { items, getLabelFromOption, onChange } = props;

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    const prevItems = [...items];
    const newItems = update(prevItems, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, prevItems[dragIndex]],
      ],
    });
    if (newItems) {
      onChange(newItems);
    }
  }, []);

  const removeCard = (index: number) => {
    const prevItems = items.slice();
    const newItems = update(prevItems, {
      $splice: [[index, 1]],
    });
    if (newItems) {
      onChange(newItems);
    }
  };

  const renderCard = useCallback((card: string, index: number) => {
    return (
      <SortableCard
        key={card}
        index={index}
        id={card}
        text={getLabelFromOption ? getLabelFromOption(card) : card}
        moveCard={moveCard}
        onRemove={removeCard}
        sx={index > 3 && { color: "text.disabled", opacity: 0.9 }}
      />
    );
  }, []);

  return (
    <>
      <div style={style}>{items.map((card, i) => renderCard(card, i))}</div>
    </>
  );
};
