import type {
  GridColumnVisibilityModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { CASE_STATUS } from "../constants";
import type { MFilterControl, MFilterItem } from "../../filter/filterUtils";

const getInitialCols = (
  view: string,
  module: string,
): GridColumnVisibilityModel => {
  let initialCols = {
    ncReportName: false,
    unit: false,
    type: false,
    comments: false,
    updated_at: false,
  } as any;
  if (view === "handler") {
    initialCols = {
      ...initialCols,
      reporter_id: false,
      progress: false,
      messages: false,
    };
  }
  if (view === "reporter") {
    initialCols = {
      ...initialCols,
      departmentId: false,
    };
  }
  if (module === "all") {
    initialCols = {
      ...initialCols,
      category: false,
    };
  }
  return {
    ...initialCols,
  };
};

const getInitialAddedControls = (
  view: string,
  module: string,
): MFilterControl[] => {
  const controls: MFilterControl[] = [];
  return controls;
};

const getInitialAddedFilters = (
  view: string,
  module: string,
): MFilterItem[] => {
  const initialFilters = [];

  if (view === "handler") {
    initialFilters.push({
      field: "status",
      paths: ["status"],
      operator: "is",
      value: CASE_STATUS.ONGOING,
    });
  }
  return initialFilters;
};

// view: handler, reporter
// if handler, sort by due date
// if reporter, sort by date created
const getInitialSortModel = (view: string): GridSortModel => {
  if (view === "handler") {
    return [
      {
        field: "due_date",
        sort: "desc",
      },
    ];
  }
  if (view === "reporter") {
    return [
      {
        field: "reportedTimestamp",
        sort: "desc",
      },
    ];
  }
  return [
    {
      field: "reportedTimestamp",
      sort: "desc",
    },
  ];
};

const getInitialControlsStateByViewAndQuery = (
  view: string,
  module: string,
) => {
  return {
    addedControls: getInitialAddedControls(view, module),
    appliedFilters: [],
    selectedFilters: [],
    activeFilters: getInitialAddedFilters(view, module),
    sortModel: getInitialSortModel(view),
    columnVisibilityModel: getInitialCols(view, module),
  };
};

export const getInitialInjuryControlsState = () => {
  const initialState = {
    student_reporter: getInitialControlsStateByViewAndQuery(
      "reporter",
      "student",
    ),
    student_handler: getInitialControlsStateByViewAndQuery(
      "handler",
      "student",
    ),
    employee_reporter: getInitialControlsStateByViewAndQuery(
      "reporter",
      "employee",
    ),
    employee_handler: getInitialControlsStateByViewAndQuery(
      "handler",
      "employee",
    ),
    all_reporter: getInitialControlsStateByViewAndQuery("reporter", "all"),
    all_handler: getInitialControlsStateByViewAndQuery("handler", "all"),
  };
  return initialState;
};

export const getChartRangeByTimestampsRange = (timestamps: number[]) => {
  const days7 = 7 * 24 * 60 * 60;
  const days30 = 30 * 24 * 60 * 60;
  const months6 = 183 * 24 * 60 * 60;
  const year = 364 * 24 * 60 * 60;

  const timePeriods = ["7D", "1M", "6M", "1Y"];
  const chartRanges = [days7, days30, months6, year];

  const timestampsDiff = timestamps[1] / 1000 - timestamps[0] / 1000;

  const indexArr = chartRanges.map((num) => {
    return Math.abs(num - timestampsDiff);
  });
  const min = Math.min(...indexArr);
  return timePeriods[indexArr.indexOf(min)];
};
