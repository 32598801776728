import * as yup from "yup";
import { FormTextField } from "../../../../components/FormInputs";
import type { WithFormMethods } from "../../../../components/Forms";
import { AppForm } from "../../../../components/Forms/AppForm";
import { useCurrentSite } from "../../locationsHooks";
import { useAppDispatch } from "../../../../store";
import { useLoading } from "../../../../common/useLoading";
import { updateSiteLocation } from "../../locationsThunks";
import { snackAlertOpened } from "../../../global/controlsSlice";
import { useTranslation } from "react-i18next";
import { FormCoordinateMap } from "../../../../components/FormInputs/FormCoordinateMap";

export const updateSiteLocationPayloadSchema = yup.object({
  address: yup.string().required(),
  coordinates: yup
    .object()
    .shape({
      lat: yup.number().required("Latitude is required"),
      lng: yup.number().required("Longitude is required"),
    })
    .required("Coordinates are required"),
});

export type IUpdateSiteLocationPayload = yup.InferType<
  typeof updateSiteLocationPayloadSchema
>;

export const UpdateSiteLocationControls = (
  props: WithFormMethods<IUpdateSiteLocationPayload>,
) => {
  const { methods } = props;
  const { control, watch, setValue } = methods;

  const coordinates = watch("coordinates");

  return (
    <>
      <FormCoordinateMap
        control={control}
        name="coordinates"
        addressFieldName="address"
        setValue={setValue}
      />

      <FormTextField
        control={control}
        name="address"
        label="Address"
        placeholder="Address"
      />
    </>
  );
};

type UpdateSiteLocationFormProps = {
  stopEditing?: () => void;
};

export const UpdateSiteLocationForm = (props: UpdateSiteLocationFormProps) => {
  const { stopEditing } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const site = useCurrentSite();

  const [{ isLoading, loadingMessage }, { startLoading, stopLoading }] =
    useLoading(false);

  const handleSubmit = (data) => {
    const payload = Object.assign({}, data);

    if (payload.coordinates) {
      payload.coordinates = {
        lat: +payload.coordinates.lat.toFixed(5),
        lng: +payload.coordinates.lng.toFixed(5),
      };
    }
    startLoading();
    dispatch(updateSiteLocation({ site_id: site.site_id, payload })).then(
      (resAction) => {
        stopLoading();
        if (updateSiteLocation.fulfilled.match(resAction)) {
          dispatch(
            snackAlertOpened({
              message: t("wif.injury.snackMessages.reportReopened.success"),
              severity: "success",
            }),
          );
          if (stopEditing) {
            stopEditing();
          }
        } else {
          if (resAction.payload) {
            dispatch(
              snackAlertOpened({
                message: t("wif.injury.snackMessages.reportReopened.", {
                  message: JSON.stringify(resAction.payload),
                }),
                severity: "error",
              }),
            );
          } else {
            dispatch(
              snackAlertOpened({
                message: t("wif.injury.snackMessages.errorWithMessage", {
                  message: JSON.stringify(resAction.error),
                }),
                severity: "error",
              }),
            );
          }
        }
      },
    );
  };

  return (
    <AppForm<IUpdateSiteLocationPayload, typeof updateSiteLocationPayloadSchema>
      schema={updateSiteLocationPayloadSchema}
      onSubmit={handleSubmit}
      id="update-site-location-form"
      data={{
        coordinates: site.coordinates,
        address: site.address,
      }}
    >
      {(methods) => <UpdateSiteLocationControls methods={methods} />}
    </AppForm>
  );
};
