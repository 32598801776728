import * as React from "react";
import * as yup from "yup";
import { FormTextField } from "../../../../components/FormInputs";
import type { WithFormMethods } from "../../../../components/Forms";
import { FormSingleAutocomplete } from "../../../../components/FormInputs/FormSingleAutocomplete";
import { useAppSelector } from "../../../../store";
import { FormCoordinateMapWithOutline } from "../../../../components/FormInputs/FormCoordinateMapWithOutline";
import { selectSiteIds, selectSites } from "../../locationsSlice";

export const createAreaPayloadSchema = yup.object({
  name: yup.string().required(),
  site_id: yup.string().required(),
  coordinates: yup
    .object()
    .shape({
      lat: yup.number().required("Latitude is required"),
      lng: yup.number().required("Longitude is required"),
    })
    .required("Coordinates are required"),
  outline_coordinates: yup.array().optional().notRequired(),
});

export type ICreateAreaPayload = yup.InferType<typeof createAreaPayloadSchema>;

export const CreateAreaControls = (
  props: WithFormMethods<ICreateAreaPayload>,
) => {
  const { methods } = props;
  const { control, watch, setValue } = methods;

  const siteId = watch("site_id");
  const siteIds = useAppSelector(selectSiteIds);
  const sites = useAppSelector(selectSites);

  const siteOptionNames = React.useMemo(() => {
    const names: any = {};
    siteIds.forEach((id) => {
      const site = sites[id];
      if (site) {
        names[id] = site?.name || id;
      }
    });
    return names;
  }, [siteIds, sites]);

  const baseCoordinates = React.useMemo(() => {
    if (siteId) {
      const site = sites[siteId];
      if (site) {
        return site?.coordinates || undefined;
      }
    }
    return undefined;
  }, [siteId]);

  return (
    <>
      <FormTextField
        control={control}
        name="name"
        label="Area name"
        placeholder="Area name"
      />
      <FormSingleAutocomplete
        name="site_id"
        label="Site"
        control={control}
        options={siteIds as string[]}
        optionLabels={siteOptionNames}
      />
      {baseCoordinates && (
        <FormCoordinateMapWithOutline
          control={control}
          name="coordinates"
          outlineFieldName="outline_coordinates"
          baseCoordinates={baseCoordinates}
          setValue={setValue}
          watch={watch}
        />
      )}
    </>
  );
};
