import React from "react";
import * as yup from "yup";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { AppDialogTitle } from "../../../components/Display/AppDialogTitle";
import { useForm, type SubmitHandler } from "react-hook-form";
import { Box, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectEmployees } from "../../employees/employeesSlice";
import _ from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCurrentInjuryReport } from "../routes/CurrentInjuryReportContext";
import {
  type CreateInjuryActionReq,
  createInjuryReportAction,
} from "../store/injuryReportsSlice";
import { snackAlertOpened } from "../../global/controlsSlice";
import { LoadingButtonContainer } from "../../../components/Elements/LoadingButtonContainer";
import { useTranslation } from "react-i18next";
import { CreateOrUpdateActionFormControls } from "../../actions/components/CreateOrUpdateActionFormControls";

export const createActionPayloadSchema = yup.object().shape({
  text: yup.string().required().min(4, "min 4").max(180, "max 180"),
  actionDescription: yup
    .string()
    .required()
    .min(4, "min 4")
    .max(1000, "max 1000"),
  deadline: yup.date().notRequired(),
  measureType: yup.string().notRequired(),
  members: yup.array().notRequired(),
  assignee: yup.string().notRequired(),
});

export type CreateInjuryActionPayload = yup.InferType<
  typeof createActionPayloadSchema
>;

interface RejectInjuryReportDialogProps {
  open: boolean;
  onClose: () => void;
}

const measureTypeOptions = [
  "reduces_consequence",
  "reduces_likelyhood",
  "reduces_likelyhood_consequence",
];

export default function CreateInjuryActionDialog(
  props: RejectInjuryReportDialogProps,
) {
  const { open, onClose } = props;
  const injuryReport = useCurrentInjuryReport();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [loading, setLoading] = React.useState(false);

  const { handleSubmit, control, reset } = useForm<CreateInjuryActionPayload>({
    resolver: yupResolver(createActionPayloadSchema),
    defaultValues: {
      deadline: null,
      measureType: null,
    },
  });

  const handleSave = async (req: CreateInjuryActionReq) => {
    let successMessage = t("wif.injury.snackMessages.actionCreated.success");
    if (injuryReport?.name) {
      successMessage = t(
        "wif.injury.snackMessages.actionCreated.successWithReportName",
        { reportName: injuryReport?.name },
      );
    }
    setLoading(true);
    const resultAction = await dispatch(createInjuryReportAction(req));
    if (createInjuryReportAction.fulfilled.match(resultAction)) {
      dispatch(
        snackAlertOpened({ message: successMessage, severity: "success" }),
      );
      setLoading(false);
      onClose();
    } else {
      if (resultAction.payload) {
        dispatch(
          snackAlertOpened({
            message: t("wif.injury.snackMessages.genericError"),
            severity: "error",
          }),
        );
        setLoading(false);
      } else {
        dispatch(
          snackAlertOpened({
            message: t("wif.injury.snackMessages.errorWithMessage", {
              message: resultAction.error,
            }),
            severity: "error",
          }),
        );
        setLoading(false);
      }
    }
  };

  const onSubmit: SubmitHandler<CreateInjuryActionPayload> = (data) => {
    const payload: any = _.clone(data);
    if (payload?.assignee) {
      payload.assignee = [payload.assignee];
    }
    const req = {
      reportId: injuryReport.id,
      payload: {
        ...payload,
        status: "pending",
      },
    };
    handleSave(req);
  };

  const onDraftSubmit: SubmitHandler<CreateInjuryActionPayload> = (data) => {
    const payload: any = _.clone(data);
    if (payload?.assignee) {
      payload.assignee = [payload.assignee];
    }
    const req = {
      reportId: injuryReport.id,
      payload: {
        ...payload,
        status: "draft",
      },
    };
    handleSave(req);
  };

  const employees = useAppSelector(selectEmployees); // Replace with access controlled

  let employeeNames: any = _.clone(employees);
  employeeNames = _.mapValues(employeeNames, (e) => e.name);
  return (
    <Dialog open={open} onClose={onClose} keepMounted={false}>
      <AppDialogTitle id="create-injury-action-dialog-title" onClose={onClose}>
        {t("wif.injury.createActionDialog.title")}
      </AppDialogTitle>
      <DialogContent
        sx={{
          width: 600,
        }}
      >
        {open && (
          <form id="create-injury-action">
            <Stack direction="column" gap={2} width="100%">
              {control && (
                <CreateOrUpdateActionFormControls control={control} />
              )}
            </Stack>
          </form>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButtonContainer loading={loading}>
          <Button
            variant="contained"
            color="plain"
            disabled={loading}
            id="save-as-draft"
            onClick={handleSubmit(onDraftSubmit)}
          >
            {t("wif.injury.saveAsDraft")}
          </Button>
        </LoadingButtonContainer>
        <Box sx={{ flexGrow: 1 }} />
        <Button color="primary" variant="text" onClick={onClose} sx={{ mr: 1 }}>
          {t("wif.injury.cancel")}
        </Button>

        <LoadingButtonContainer loading={loading}>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="secondary"
            disabled={loading}
            id="save"
          >
            {t("wif.injury.publish")}
          </Button>
        </LoadingButtonContainer>
      </DialogActions>
    </Dialog>
  );
}
