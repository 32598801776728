import { CASE_STATUS, REPORT_CATEGORY, REPORT_TYPES } from ".";

export const ReportCategoryIconNames: any = {
  [REPORT_CATEGORY.EMPLOYEE]: "badge",
  [REPORT_CATEGORY.STUDENT]: "school",
};

export const ReportTypeIconNames: any = {
  [REPORT_TYPES.ACCIDENT]: "personal_injury",
  [REPORT_TYPES.ILLNESS]: "coronavirus",
  [REPORT_TYPES.MINOR_INJURY]: "healing",
};

export const CaseStatusColors = {
  [CASE_STATUS.ONGOING]: "warning",
  [CASE_STATUS.CLOSED]: "success",
  [CASE_STATUS.REJECTED]: "error",
};

export const ReportsCountStatusColors: any = {
  0: "textVariant",
  1: "success.main",
  2: "warning.main",
  3: "error.main",
};
