import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useTheme, alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SamsvarLogo from "../../assets/samsvarLogo.svg";
import SamsvarLogoWhite from "../../assets/samsvarLogoWhite.svg";
import {
  paletteModeToggled,
  toggleTestUserRole,
} from "../../features/global/controlsSlice";
import { useAppDispatch } from "../../store";
import { AppIcon } from "../Elements/AppIcon";
import LanguageSelectorMenu from "./LanguageSelectorMenu";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { generateAvatarColor } from "../../utils/color";
import _ from "lodash";
import { userRoles } from "../../config/constants";
import config from "../../config/config";

interface ElevationScrollProps {
  children: React.ReactElement;
}

function ElevationScroll(props: ElevationScrollProps) {
  const { children } = props;
  const theme = useTheme();

  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger && theme.palette.mode === "light" ? 1 : 0,
    sx: trigger
      ? {
          ...(theme.palette.mode === "dark"
            ? {
                bgcolor: "transparent",
              }
            : {
                bgcolor: alpha(theme.palette.background.paper, 0.8),
              }),
          borderRadius: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          backdropFilter: "blur(8px)",
        }
      : {
          borderRadius: 0,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          bgcolor: "transparent",
        },
  });
}

export const GlobalHeader = () => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openedMenu, setOpenedMenu] = React.useState<null | number>(null);
  const [username, setUsername] = React.useState<string>("name");
  const [avatar, setAvatar] = React.useState<string>("");
  const [avatarLabel, setAvatarLabel] = React.useState<string>("");
  const [organization, setOrganization] =
    React.useState<string>("organization");
  const [userRole, setUserRole] = React.useState<string>(userRoles.user);

  React.useEffect(() => {
    const ngStorageCurrent = JSON.parse(
      localStorage.getItem("ngStorage-current"),
    );
    const ngStorageUser = ngStorageCurrent?.user;
    const ngStorageOrganization = ngStorageCurrent?.organization;
    if (ngStorageUser) {
      if (ngStorageUser?.name) {
        setUsername(ngStorageUser.name);
        const label = ngStorageUser?.name
          ?.split(" ")
          .slice(0, 2)
          .map((word: string) => word.charAt(0).toUpperCase())
          .join("");
        setAvatarLabel(label);
      }
      if (ngStorageUser?.avatar) {
        setAvatar(config.samsvarUploadsUrl + ngStorageUser.avatar);
      }
      if (ngStorageOrganization?.name) {
        setOrganization(ngStorageOrganization.name);
      }
      if (ngStorageUser?.role) setUserRole(ngStorageUser.role);
    }
  }, []);

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    menu: number,
  ) => {
    setOpenedMenu(menu);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setOpenedMenu(null);
  };

  const dispatch = useAppDispatch();

  const handleTogglePalette = () => {
    dispatch(paletteModeToggled());
  };

  const handleToggleUser = () => {
    dispatch(toggleTestUserRole());
  };

  const avatarColors = [
    theme.palette.primary.main,
    theme.palette.success.main,
    theme.palette.secondary.dark,
    theme.palette.warning.main,
    theme.palette.error.main,
    theme.palette.info.main,
  ];

  const backToSamsvar = () => {
    if (userRole === "superadmin") {
      window.location.hash = `#/dashboardlist`;
      window.location.reload();
    } else {
      window.location.hash = `#/mainDashboard`;
      window.location.reload();
    }
  };

  return (
    <ElevationScroll>
      <AppBar sx={{ p: 0 }} elevation={3}>
        <Toolbar
          sx={{
            ...(theme.palette.mode === "dark"
              ? {
                  borderBottom: 1,
                  borderBottomColor: "divider",
                }
              : {
                  borderBottom: 0,
                  color: theme.palette.text.secondary,
                }),

            "& .MuiPaper-root": {
              borderRadius: 0,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <img
              src={
                theme.palette.mode === "light" ? SamsvarLogo : SamsvarLogoWhite
              }
              style={{
                height: "18px",
                marginTop: "-4px",
              }}
              onClick={backToSamsvar}
            />
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            sx={{ mr: 1, color: theme.palette.text.secondary }}
            onClick={(e: any) => handleOpenMenu(e, 1)}
          >
            <AppIcon iconName="translate" weight={500} />
          </IconButton>
          <IconButton
            sx={{ mr: 2, color: theme.palette.text.secondary }}
            onClick={handleTogglePalette}
          >
            <AppIcon iconName="dark_mode" weight={500} />
          </IconButton>
          <Avatar
            sx={{
              height: 32,
              width: 32,
              bgcolor: (theme) =>
                generateAvatarColor(username, theme.palette.mode === "dark"),
            }}
            src={avatar}
          >
            {avatarLabel}
          </Avatar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              ml: 1.25,
            }}
          >
            <Typography variant="body2" color={theme.palette.text.primary}>
              {_.startCase(username)}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mt: "-2px",
                fontSize: "13px",
                color: theme.palette.text.secondary,
              }}
            >
              {organization}
            </Typography>
          </Box>
        </Toolbar>
        <LanguageSelectorMenu
          anchorEl={anchorEl}
          handleClose={handleCloseMenu}
          open={openedMenu === 1}
        />
      </AppBar>
    </ElevationScroll>
  );
};
