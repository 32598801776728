import React from "react";
import { Chip, Paper, Stack, Typography, useTheme } from "@mui/material";
import { makeSelectInjuryReportsByAreaId } from "../../store/injuryReportsSlice";
import { useAppSelector } from "../../../../store";
import { formatDateFromTimestamp } from "../../../../utils/format";
import { CASE_STATUS, TKB_INJURY } from "../../constants";
import { useTranslation } from "react-i18next";
import { getTranslatedInjuryReportName } from "../../helpers";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import type { InjuryReportCase } from "../../types";
import { BOX_SHADOWS } from "../../../../config/theme/boxShadows";
import { selectEmployees } from "../../../employees/employeesSlice";
import { AppUserAvatar } from "../../../../components/Elements/AppAvatar";
const iconNames: any = {
  Accident: "personal_injury",
  Illness: "medical_services",
  "Minor injury": "healing",
  [CASE_STATUS.ONGOING]: "pending",
  [CASE_STATUS.REJECTED]: "do_not_disturb_on",
  [CASE_STATUS.CLOSED]: "task_alt",
};

type InjuryReportListItemProps = {
  injuryReport: InjuryReportCase;
};

const InjuryReportListItem = (props: InjuryReportListItemProps) => {
  const { injuryReport } = props;
  const { t } = useTranslation();

  const users = useAppSelector(selectEmployees);

  const theme = useTheme();

  const name = getTranslatedInjuryReportName(injuryReport, t);

  const statusColors: any = {
    [CASE_STATUS.ONGOING]: "info",
    [CASE_STATUS.CLOSED]: "success",
    [CASE_STATUS.REJECTED]: "error",
  };

  const assigneeName = React.useMemo(() => {
    if (injuryReport.assignee_id) {
      return users[injuryReport.assignee_id]?.name || injuryReport.assignee_id;
    }
    return t("wif.injury.unassigned");
  }, [users, t]);

  return (
    <Paper
      elevation={0}
      sx={{
        bgcolor:
          theme.palette.mode === "light"
            ? "background.default"
            : "backgroundVariant",
        boxShadow: BOX_SHADOWS.elevation0,
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
        p: 2,
        pb: 1.5,
        pt: 1.5,
      }}
    >
      <Link
        component={RouterLink}
        variant="h6"
        to={injuryReport.id}
        underline={"none"}
        sx={{
          "&:hover": {
            textDecoration: "underline",
            color: "text.primary",
          },
          fontWeight: 400,
          minWidth: 0,
          color: (theme) =>
            theme.palette.mode === "light"
              ? "primary.main"
              : "#c7d2fe" /* indigo 300 */,
        }}
      >
        {name}
      </Link>
      <Typography variant="body2" mt={-1} color="text.secondary">
        {formatDateFromTimestamp(injuryReport.reportedTimestamp)}
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
        mt={-0.25}
      >
        <Chip
          size="small"
          color={statusColors[injuryReport.status]}
          label={t(`${TKB_INJURY}.${injuryReport.status}`)}
        />
        <Stack direction="row" alignItems="center" gap={2.5}>
          <Stack direction="row" alignItems="center" gap={1} sx={{}}>
            <AppUserAvatar
              username={injuryReport?.assignee_id ? assigneeName : undefined}
              size="xs"
            />
            <Typography variant="body2">{assigneeName}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Paper>
  );
};

type InjuryReportsListProps = {
  areaId: string;
};

export const InjuryReportsList = (props: InjuryReportsListProps) => {
  const { areaId } = props;

  const selectInjuryReportsByAreaId = React.useMemo(
    makeSelectInjuryReportsByAreaId,
    [areaId],
  );
  const injuryReports = useAppSelector((state) =>
    selectInjuryReportsByAreaId(state, areaId),
  );

  return (
    <Stack direction="column" gap={2}>
      {injuryReports.map((injuryReport: any) => (
        <InjuryReportListItem injuryReport={injuryReport} />
      ))}
    </Stack>
  );
};
