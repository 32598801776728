import type * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import type { WithChildren } from "../../types";

type AppDialogProps = WithChildren<{
  open?: boolean;
  onClose: () => void;
  disableDefaultCancel?: boolean;
  actions?: React.ReactNode;
  sx?: any;
}>;

export default function AppDialog(props: AppDialogProps) {
  const {
    open = false,
    onClose,
    children,
    disableDefaultCancel = false,
    actions,
    sx,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{ sx }}
    >
      {children}
      {(actions || !disableDefaultCancel) && (
        <DialogActions>
          {!disableDefaultCancel && (
            <Button onClick={onClose} variant="text" color="primary">
              Cancel
            </Button>
          )}
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
}
