import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { AppDialogTitle } from "../../../../../components/Display/AppDialogTitle";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import {
  type UpdateInjuryCaseReq,
  updateInjuryCase,
} from "../../../store/injuryReportsSlice";
import { snackAlertOpened } from "../../../../global/controlsSlice";
import { REPORT_CATEGORY, REPORT_TYPES } from "../../../constants";
import { useCurrentInjuryReport } from "../../../routes/CurrentInjuryReportContext";
import "dayjs/locale/nb";
import {
  selectEmployees,
  selectEmployeesFetched,
} from "../../../../employees/employeesSlice";
import {
  selectAllDepartments,
  selectDepartmentEntities,
} from "../../../../departments/departmentsSlice";
import { uniq } from "lodash";
import { STUDENT_SECTION_NAMES } from "../../../constants/student";
import { EMPLOYEE_SECTION_NAMES } from "../../../constants/employee";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";
interface AssigneeDialogProps {
  open?: boolean;
  onClose?: () => void;
}

export default function AssigneeDialog(props: AssigneeDialogProps) {
  const { open = true, onClose } = props;
  const { t } = useTranslation();

  const injuryReport = useCurrentInjuryReport();

  const dispatch = useAppDispatch();

  const [assignee, setAssignee] = React.useState<string | null>(null);

  const departments = useAppSelector(selectAllDepartments);
  const departmentEntities = useAppSelector(selectDepartmentEntities);
  const employees = useAppSelector(selectEmployees);
  const employeesFetched = useAppSelector(selectEmployeesFetched);

  const departmentId = React.useMemo(() => {
    let sectionWithUnitAndDepartmentName = "";
    let departmentId = undefined;

    if (injuryReport.category === REPORT_CATEGORY.STUDENT) {
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.MINOR_INJURY;
      } else {
        sectionWithUnitAndDepartmentName = STUDENT_SECTION_NAMES.SCHOOL;
      }
    } else if (injuryReport.category === REPORT_CATEGORY.EMPLOYEE) {
      if (injuryReport.type === REPORT_TYPES.MINOR_INJURY) {
        sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.MINOR_INJURY;
      } else {
        sectionWithUnitAndDepartmentName = EMPLOYEE_SECTION_NAMES.EMPLOYER;
      }
    }
    if (
      injuryReport?.sections[sectionWithUnitAndDepartmentName]?.departmentId
    ) {
      departmentId =
        injuryReport.sections[sectionWithUnitAndDepartmentName].departmentId;
    }

    if (departmentId !== undefined) {
      return departmentId;
    }
    return undefined;
  }, [injuryReport]);

  const injuryHandlerIds = React.useMemo(() => {
    let ids: string[] = [];

    const department = departmentEntities[departmentId];

    if (
      department?.injury_dept_leaders &&
      department?.injury_dept_leaders?.length > 0
    ) {
      ids.push(...department.injury_dept_leaders);
    }

    if (injuryReport?.category === "student") {
      if (department?.injury_student_users?.length > 0) {
        ids.push(...department.injury_student_users);
      }
    } else if (injuryReport?.category === "employee") {
      if (department?.injury_employee_users?.length > 0) {
        ids.push(...department.injury_employee_users);
      }
    }

    ids = uniq(ids);
    return ids;
  }, [departments, employees, departmentId]);

  const employeesWithSameName = React.useMemo(() => {
    const allNames: string[] = [];
    const repeatedNames: string[] = [];
    for (const id of injuryHandlerIds) {
      if (employees[id]?.name) {
        if (allNames.indexOf(employees[id]?.name) !== -1) {
          repeatedNames.push(employees[id]?.name);
        } else {
          allNames.push(employees[id]?.name);
        }
      }
    }
    return repeatedNames;
  }, [injuryHandlerIds]);

  const handleUpdateDueDate = async () => {
    if (assignee && assignee?.length > 1) {
      const req: UpdateInjuryCaseReq = {
        reportId: injuryReport.id,
        body: {
          assignee_id: assignee,
        },
      };
      const resultAction = await dispatch(updateInjuryCase(req));
      if (updateInjuryCase.fulfilled.match(resultAction)) {
        onClose();
        dispatch(
          snackAlertOpened({
            message: t("wif.injury.snackMessages.assigneeUpdated.success"),
            severity: "success",
          }),
        );
      } else {
        if (resultAction.payload) {
          dispatch(
            snackAlertOpened({
              message: t("wif.injury.snackMessages.genericError"),
              severity: "error",
            }),
          );
        } else {
          dispatch(
            snackAlertOpened({
              message: t("wif.injury.snackMessages.errorWithMessage", {
                message: resultAction.error,
              }),
              severity: "error",
            }),
          );
        }
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <AppDialogTitle id="close-report-dialog-title" onClose={onClose}>
        {t("wif.injury.setReportAssigneeDialog.title")}
      </AppDialogTitle>
      <DialogContent
        dividers
        sx={{
          width: 600,
          border: "none",
        }}
      >
        <Autocomplete
          options={injuryHandlerIds}
          getOptionLabel={(id) => {
            let label = employees?.[id]?.name || id;
            if (employeesWithSameName.indexOf(label) !== -1) {
              label = `${label} (${employees?.[id]?.email})`;
            }
            return label;
          }}
          onChange={(e: any, newValue: string | null) => setAssignee(newValue)}
          loading={employeesFetched !== "succeeded"}
          value={employeesFetched === "succeeded" ? assignee : null}
          renderInput={(params) => (
            <TextField {...params} label={t("wif.injury.user")} />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("wif.injury.cancel")}</Button>
        <Button
          onClick={handleUpdateDueDate}
          variant="contained"
          color="primary"
          disabled={!assignee || assignee?.length < 1}
        >
          {t("wif.injury.save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
