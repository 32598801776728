import { type ThemeOptions, alpha, hexToRgb } from "@mui/material/styles";
import { APP_COLORS, SIKRI_COLORS } from "../colors";
import { BOX_SHADOWS } from "./boxShadows";
import type { PaletteMode } from "@mui/material";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    plain: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    plain: Palette["primary"];
    textVariant: Palette["divider"];
    backgroundVariant: Palette["divider"];
    dividerVariant: Palette["divider"];
  }

  interface PaletteOptions {
    plain: PaletteOptions["primary"];
    textVariant: PaletteOptions["divider"];
    backgroundVariant: PaletteOptions["divider"];
    dividerVariant: PaletteOptions["divider"];
  }
}

export const getDesignTokens = (mode: PaletteMode) =>
  ({
    palette: {
      mode,
      ...(mode === "light"
        ? {
            primary: {
              main: APP_COLORS.primary,
            },
            background: {
              default: APP_COLORS.light3,
              paper: APP_COLORS.light1,
            },
            backgroundVariant: APP_COLORS.light2point5,
            error: {
              main: APP_COLORS.error,
            },
            success: {
              main: APP_COLORS.success,
            },
            warning: {
              main: APP_COLORS.warning,
            },
            info: {
              main: APP_COLORS.info,
            },
            secondary: {
              main: APP_COLORS.secondary,
            },
            plain: {
              main: APP_COLORS.light3,
            },
            text: {
              primary: APP_COLORS.textPrimary,
              secondary: APP_COLORS.textSecondary,
              disabled: APP_COLORS.textDisabled,
            },
            textVariant: APP_COLORS.textThird,
            divider: APP_COLORS.divider,
            dividerVariant: APP_COLORS.dividerVariant,
            action: {
              selected: `rgba(${
                hexToRgb(APP_COLORS.secondary).slice(4).split(")")[0]
              }, 0.12)`,
              selectedOpacity: 0.12,
              focus: `rgba(${
                hexToRgb(APP_COLORS.secondary).slice(4).split(")")[0]
              }, 0.12)`,
              focusOpacity: 0.12,
            },
          }
        : {
            primary: {
              main: APP_COLORS.darkPrimary,
            },
            background: {
              default: APP_COLORS.dark3,
              paper: APP_COLORS.dark2,
            },
            backgroundVariant: APP_COLORS.dark1,
            dividerVariant: APP_COLORS.dividerVariant,
            error: {
              main: APP_COLORS.darkError,
            },
            success: {
              main: APP_COLORS.darkSuccess,
            },
            warning: {
              main: APP_COLORS.darkWarning,
            },
            info: {
              main: APP_COLORS.darkInfo,
            },
            secondary: {
              main: "#6bedb5",
            },
            plain: {
              main: APP_COLORS.dark1,
            },
            text: {
              primary: "#fff",
              secondary: APP_COLORS.darkTextSecondary,
              disabled: APP_COLORS.darkTextDisabled,
            },
            textVariant: APP_COLORS.darkTextVariant,
            divider: APP_COLORS.darkDivider,
            action: {
              selected: "rgba(107, 237, 181, 0.12)",
              selectedOpacity: 0.12,
              focus: "rgba(107, 237, 181, 0.12)",
              focusOpacity: 0.12,
            },
          }),
    },
    typography: {
      fontFamily: "Inter var",
      fontSize: 14,
      htmlFontSize: 16,
      h1: {
        fontSize: "30px",
        lineHeight: "36px",
        fontFamily: "Inter var",
        fontWeight: 700,
      },
      h2: {
        fontSize: "24px",
        lineHeight: "32px",
        fontFamily: "Inter var",
        fontWeight: 700,
      },
      h3: {
        fontSize: "20px",
        lineHeight: "28px",
        fontFamily: "Inter var",
        fontWeight: 700,
      },
      h4: {
        fontSize: "18px",
        lineHeight: "28px",
        fontFamily: "Inter var",
        fontWeight: 600,
      },
      h5: {
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Inter var",
        fontWeight: 600,
      },
      h6: {
        fontSize: "14px",
        lineHeight: "20px",
        fontFamily: "Inter var",
        fontWeight: 600,
      },
      subtitle1: {
        fontSize: "16px",
        lineHeight: "20px",
        fontFamily: "Inter var",
      },
      subtitle2: {
        fontSize: "14px",
        lineHeight: "20px",
        fontFamily: "Inter var",
      },
      button: {
        fontSize: "14px",
        lineHeight: "20px",
        fontFamily: "Inter var",
        textTransform: "none",
        fontWeight: 600,
        letterSpacing: 0,
      },
      body1: {
        fontSize: "16px",
        lineHeight: "24px",
        fontFamily: "Inter var",
      },
      body2: {
        fontSize: "14px",
        lineHeight: "20px",
        fontFamily: "Inter var",
      },
    },
    shape: {
      borderRadius: 4,
    },
    shadows: [
      "none",
      BOX_SHADOWS.elevation1,
      BOX_SHADOWS.elevation2,
      BOX_SHADOWS.elevation3,
      BOX_SHADOWS.elevation4,
      "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
      "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
      "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
      "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
      "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
      "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px…gba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
      "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px…gba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
      "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px…gba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
      "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px…gba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
      "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px…gba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
      "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px…gba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
      "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2p…gba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
      "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2p…gba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
      "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2p…gba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
      "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2p…gba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
      "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3…gba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
      "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3…gba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
      "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3…gba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
      "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3…gba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
      "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3…gba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
    ],
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            ...(mode === "dark" && {
              backgroundColor: APP_COLORS.dark1,
            }),
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            height: "56px!important",
            minHeight: "0px!important",
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          sizeSmall: {
            height: "24px",
          },
          sizeMedium: {
            height: "28px",
          },
          root: {
            minWidth: 0,
            fontWeight: 500,
            borderRadius: 6,
            "&.MuiChip-filledDefault": {
              ...(mode === "light"
                ? {
                    backgroundColor: APP_COLORS.light3,
                    color: APP_COLORS.textSecondary,
                  }
                : {
                    backgroundColor: APP_COLORS.dark3,
                    color: APP_COLORS.darkTextSecondary,
                  }),
            },
            "&.MuiChip-filledPrimary": {
              ...(mode === "light"
                ? {
                    backgroundColor: alpha(APP_COLORS.primary, 0.08),
                    color: APP_COLORS.primary,
                  }
                : {
                    backgroundColor: APP_COLORS.dark3,
                    color: APP_COLORS.darkTextSecondary,
                  }),
            },
            "&.MuiChip-filledSecondary": {
              ...(mode === "light"
                ? {
                    backgroundColor: SIKRI_COLORS.galaxyGreenSelected,
                    color: APP_COLORS.primary,
                  }
                : {
                    backgroundColor: SIKRI_COLORS.galaxyGreenSelectedDark,
                    color: APP_COLORS.darkTextSecondary,
                  }),
            },
            "&.MuiChip-filledWarning": {
              ...(mode === "light"
                ? {
                    backgroundColor: alpha(APP_COLORS.warning, 0.12),

                    color: APP_COLORS.warningDarkText,
                  }
                : {
                    backgroundColor: APP_COLORS.dark3,
                    color: APP_COLORS.darkTextSecondary,
                  }),
            },
            "&.MuiChip-filledInfo": {
              ...(mode === "light"
                ? {
                    backgroundColor: alpha(APP_COLORS.info, 0.08),
                    color: APP_COLORS.info,
                  }
                : {
                    backgroundColor: alpha(APP_COLORS.darkInfo, 0.08),
                    color: APP_COLORS.darkInfo,
                  }),
            },
            "&.MuiChip-filledError": {
              ...(mode === "light"
                ? {
                    backgroundColor: alpha(APP_COLORS.error, 0.12),

                    color: APP_COLORS.errorDarkText,
                  }
                : {
                    backgroundColor: APP_COLORS.dark3,
                    color: APP_COLORS.darkTextSecondary,
                  }),
            },
            "&.MuiChip-filledSuccess": {
              ...(mode === "light"
                ? {
                    backgroundColor: alpha(APP_COLORS.success, 0.12),
                    color: APP_COLORS.successDarkText,
                  }
                : {
                    backgroundColor: APP_COLORS.dark3,
                    color: APP_COLORS.darkTextSecondary,
                  }),
            },
            "&.MuiChip-outlinedPrimary": {
              backgroundColor: alpha(APP_COLORS.primary, 0.08),
              color: APP_COLORS.primary,
              "& .MuiBox-root": {
                color: APP_COLORS.primary,
              },
              fontWeight: 500,
              borderColor: alpha(APP_COLORS.primary, 0.32),
            },
            "&.MuiChip-outlinedWarning": {
              backgroundColor: alpha(APP_COLORS.warning, 0.08),
              color: APP_COLORS.textPrimary,
              "& .MuiBox-root": {
                color: APP_COLORS.warning,
              },
              fontWeight: 500,
              borderColor: alpha(APP_COLORS.warning, 0.32),
            },
            "&.MuiChip-outlinedSuccess": {
              backgroundColor: alpha(APP_COLORS.success, 0.08),
              fontWeight: 500,
              color: APP_COLORS.textPrimary,
              borderColor: alpha(APP_COLORS.success, 0.32),
              "& .MuiBox-root": {
                color: APP_COLORS.success,
              },
            },
            "&.MuiChip-outlinedError": {
              backgroundColor: alpha(APP_COLORS.error, 0.08),
              color: APP_COLORS.textPrimary,
              "& .MuiBox-root": {
                color: APP_COLORS.error,
              },
              borderColor: alpha(APP_COLORS.error, 0.32),
              fontWeight: 500,
            },
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: APP_COLORS.primary,
            color: "#fff",
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            padding: "20px 20px",
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: "16px 20px",
            gap: "8px",
          },
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "contained",
          color: "plain",
        },
        styleOverrides: {
          root: {
            borderRadius: 4,
            "&.MuiButton-containedPlain": {
              ...(mode === "light"
                ? {
                    boxShadow: BOX_SHADOWS.elevation0,
                    color: APP_COLORS.primary,
                  }
                : {
                    color: APP_COLORS.darkPrimary,
                    boxShadow: BOX_SHADOWS.elevation0,
                  }),
            },
            "&.MuiButton-textPlain": {
              ...(mode === "light"
                ? {
                    color: APP_COLORS.textSecondary,
                  }
                : {
                    color: APP_COLORS.darkTextPrimary,
                  }),
              "&:hover": {
                backgroundColor:
                  mode === "light"
                    ? alpha(APP_COLORS.light5, 0.5)
                    : alpha(APP_COLORS.light5, 0.24),
              },
            },
            "&.MuiButton-outlinedPlain": {
              ...(mode === "light"
                ? {
                    color: APP_COLORS.primary,
                  }
                : {
                    color: APP_COLORS.darkTextPrimary,
                  }),
              ...(mode === "light"
                ? {
                    borderColor: APP_COLORS.inputBorder,
                  }
                : {
                    borderColor: APP_COLORS.darkInputBorder,
                  }),
            },
          },
          outlined: {
            boxShadow: "none",
            paddingLeft: "16px",
            paddingRight: "16px",
          },
          contained: {
            boxShadow: BOX_SHADOWS.elevation0,
            paddingLeft: "16px",
            paddingRight: "16px",
          },
          containedSecondary: {
            color: "#1b116e",
          },
          sizeMedium: {
            height: 36,
          },
          sizeSmall: {
            height: 32,
          },
          sizeLarge: {
            height: 44,
          },
        },
      },

      MuiFilledInput: {
        styleOverrides: {
          root: {
            borderRadius: 4,
            backgroundColor: APP_COLORS.light4,
            "& .Mui-focused": {
              backgroundColor: APP_COLORS.light4,
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:last-child td": {
              borderBottom: 0,
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            padding: 1,
            ...(mode === "light"
              ? {
                  backgroundColor: APP_COLORS.light4,
                  border: `1px solid ${APP_COLORS.light4}`,
                }
              : {
                  backgroundColor: APP_COLORS.dark3,
                  border: `1px solid ${APP_COLORS.dark3}`,
                }),

            gap: 2,
            height: "36px",
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            borderWidth: 0,
            borderRadius: "3px!important",
            "&.Mui-selected": {
              ...(mode === "light"
                ? {
                    background: APP_COLORS.light1,
                    boxShadow: BOX_SHADOWS.elevation1,
                  }
                : {
                    background: APP_COLORS.dark1,
                    boxShadow: BOX_SHADOWS.elevation1,
                  }),
            },
          },
          /* selected: {
          backgroundColor: 'rgba(0, 7, 10, 0.08)',
        } */
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color:
              mode === "light"
                ? APP_COLORS.textSecondary
                : APP_COLORS.darkTextSecondary,
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            borderRadius: 4,
            "& .Mui-disabled": {
              color: `${APP_COLORS.textPrimary}!important`,
              WebkitTextFillColor: `${APP_COLORS.textPrimary}!important`,
            },
            "&:before": {
              borderBottomStyle: "solid!important",
              borderColor: "transparent",
              borderBottomWidth: "0px",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 4,
            ...(mode === "light"
              ? {
                  borderColor: `${APP_COLORS.inputBorder}!important`,
                }
              : {
                  borderColor: `${APP_COLORS.darkInputBorder}!important`,
                }),
            "& .MuiOutlinedInput-notchedOutline": {
              ...(mode === "light"
                ? {
                    borderColor: APP_COLORS.inputBorder,
                  }
                : {
                    borderColor: APP_COLORS.darkInputBorder,
                  }),
            },
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            "& .MuiCardHeader-subheader": {
              fontSize: "0.875rem",
              fontWeight: 400,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "1",
              overflow: "hidden",
              textOverflow: "-",
            },
            padding: 20,
            borderBottom: `1px solid ${APP_COLORS.divider}`,
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            paddingLeft: 20,
            paddingRight: 20,
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: "outlined",
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            width: "34px",
            minWidth: "0px",
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            [`& .MuiDrawer-paper`]: {
              backgroundColor: "transparent",
              boxShadow: "none",
              border: "none",
            },
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            height: "48px",
            minHeight: "48px",
            ...(mode === "dark" && {
              " & .MuiTabs-indicator": {
                height: "2px!important",
                backgroundColor: `${APP_COLORS.secondary}!important`,
              },
            }),
            "& .MuiTab-root": {
              letterSpacing: `0.01071em!important`,
              height: "48px",
              minHeight: "48px",
              paddingLeft: "0px",
              paddingRight: "0px",
              minWidth: "0px",
              marginRight: "20px",
              fontWeight: 500,
              color:
                mode === "light"
                  ? APP_COLORS.textThird
                  : APP_COLORS.darkTextVariant,
              "&.Mui-selected": {
                fontWeight: 600,
              },
            },
            "& .MuiTab-textColorPrimary": {
              "&.Mui-selected": {
                ...(mode === "dark"
                  ? {
                      color: APP_COLORS.darkTextPrimary,
                    }
                  : {
                      color: APP_COLORS.primary,
                    }),
              },
              ...(mode === "dark" && {
                " & .MuiTabs-indicator": {
                  height: "2px!important",
                  backgroundColor: `${APP_COLORS.secondary}!important`,
                },
              }),
            },
            "& .MuiTab-textColorSecondary": {
              color: APP_COLORS.darkTextSecondary,
              "&.Mui-selected": {
                ...(mode === "dark"
                  ? {
                      color: APP_COLORS.secondary,
                    }
                  : {
                      color: APP_COLORS.darkTextPrimary,
                    }),
              },
              ...(mode === "dark" && {
                " & .MuiTabs-indicator": {
                  height: "2px!important",
                  backgroundColor: `${APP_COLORS.secondary}!important`,
                },
              }),
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color:
              mode === "dark" ? APP_COLORS.darkPrimary : APP_COLORS.primary,
          },
        },
      },
      MuiTab: {
        defaultProps: {
          iconPosition: "start",
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            "& .MuiListItemText-primary": {
              fontSize: "14px",
              fontWeight: 400,
            },
            "& .MuiListItemText-secondary": {
              fontSize: "13px",
              fontWeight: 400,
              marginTop: "-2px",
              color: "text.secondary",
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            ...(mode === "dark" && {
              backgroundImage: "none",
            }),
            borderRadius: 6,
            "::-webkit-scrollbar": {
              width: "14px!important",
            },
            "::-webkit-scrollbar-track": {
              borderRadius: `100!important`,
              ...(mode === "light"
                ? {
                    background: `${APP_COLORS.light3}!important`,
                  }
                : {
                    background: `${APP_COLORS.dark1}!important`,
                  }),
              border: `3px solid ${
                mode === "light" ? APP_COLORS.light1 : APP_COLORS.dark2
              }!important`,
            },
            "::-webkit-scrollbar-thumb": {
              borderRadius: 100,
              ...(mode === "light"
                ? {
                    backgroundColor: "#b9b8c4!important",
                  }
                : {
                    backgroundColor: "#323144!important",
                  }),
              border: "3px solid transparent!important",
              backgroundClip: "content-box!important",
            },
            scrollbarColor: `#d1d5db #f5f5f5!important`,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            "*::-webkit-scrollbar": {
              width: "14px",
            },
            "*::-webkit-scrollbar-track": {
              borderRadius: 100,
              ...(mode === "light"
                ? {
                    background: APP_COLORS.light3,
                    border: `3px solid ${APP_COLORS.light1}`,
                  }
                : {
                    background: APP_COLORS.dark2,
                    border: `3px solid ${APP_COLORS.dark3}`,
                  }),
            },
            "*::-webkit-scrollbar-thumb": {
              borderRadius: 100,
              ...(mode === "light"
                ? {
                    backgroundColor: "#b9b8c4",
                  }
                : {
                    backgroundColor: "#323144",
                  }),
              border: "3px solid transparent",
              backgroundClip: "content-box",
            },
            scrollbarColor: `#d1d5db #f5f5f5`,
          },
        },
      },
    },
  }) as ThemeOptions;
