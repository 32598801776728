import React from "react";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import { getChartColorByIndex } from "../../../components/Charts/helpers";
import DoughnutChart, {
  getDefaultDoughnutChartOptionsWithTitle,
} from "../../../components/Charts/DoughnutChart";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

type SDoughnutProps = {
  title?: string;
  subtitle?: string;
  position?: "top" | "bottom" | "left" | "right";
  label?: string;
  arcs?: {
    key: string;
    values: any[];
    labels?: string[];
    colors?: string[];
  };
  dataSource?: any;
};

export const SDoughnut = (props: SDoughnutProps) => {
  const {
    title = "",
    subtitle = "",
    label = "",
    arcs,
    dataSource,
    position,
  } = props;
  const theme = useTheme() as any;
  const { t } = useTranslation();

  const chartData = React.useMemo(() => {
    const options = getDefaultDoughnutChartOptionsWithTitle({
      theme,
      title,
      subtitle,
      position,
    });
    const backgroundColor = [];

    const countByValue: any = {};
    let filteredArcs = [...arcs.values];
    const filteredLabels = [];
    for (let i = 0; i < arcs.values.length; i++) {
      const value = arcs.values[i];
      countByValue[value] = 0;
    }
    for (const value of arcs.values) {
      countByValue[value] = 0;
    }
    for (const o of dataSource) {
      const oKey = _.get(o, arcs.key, null);
      if (oKey) {
        if (arcs.values.includes(oKey)) {
          countByValue[oKey]++;
        }
      }
    }

    filteredArcs = filteredArcs.filter((value) => countByValue[value] > 0);
    for (let i = 0; i < arcs.values.length; i++) {
      const value = arcs.values[i];
      if (filteredArcs.includes(value)) {
        filteredLabels.push(arcs?.labels[i] || arcs?.values[i]);
        if (arcs.colors) {
          backgroundColor.push(arcs.colors[i]);
        } else {
          backgroundColor.push(getChartColorByIndex(i));
        }
      }
    }
    const chartData = {
      data: {
        labels: filteredLabels,
        datasets: [
          {
            label: label,
            data: arcs.values
              .filter((value) => countByValue[value] !== 0)
              .map((value) => countByValue[value]),
            backgroundColor: backgroundColor,
            borderColor:
              theme.palette.mode === "light"
                ? theme.palette.background.paper
                : theme.palette.backgroundVariant,
            datalabels: {
              anchor: "center",
              backgroundColor: null,
              borderWidth: 0,
            },
          },
        ],
      },
      options,
    };
    return chartData;
  }, [dataSource, theme, t]);

  return (
    <Box
      sx={{
        width: "100%",
        flex: 1,
        pb: 1,
        pr: 1,
      }}
    >
      <DoughnutChart data={chartData.data} options={chartData.options} />
    </Box>
  );
};
