import _ from "lodash";
interface MFilterOperator {
  value: string;
  applyFilterFunction: (filterItem: MFilterItem, value: any) => boolean | null;
}

const filterOperators: MFilterOperator[] = [
  {
    value: "between",
    applyFilterFunction: (filterItem: MFilterItem, value: any) => {
      const filterValue = filterItem.value.value;
      if (!Array.isArray(filterValue) || filterValue.length !== 2) {
        return null;
      }

      if (filterValue[0] == null || filterValue[1] == null) {
        return null;
      }

      return (
        value != null && filterValue[0] <= value && value <= filterValue[1]
      );
    },
  },
  {
    value: "isAnyOf",
    applyFilterFunction: (filterItem: MFilterItem, value: any) => {
      let filterValues = filterItem.value;
      if (!Array.isArray(filterValues)) {
        filterValues = [filterValues];
      }

      if (filterValues[0] == null) {
        return null;
      }
      return (
        value != null &&
        filterValues.find((filterValue) => filterValue.value === value)
      );
    },
  },
  {
    value: "is",
    applyFilterFunction: (filterItem: MFilterItem, value: any) => {
      const filterValue = filterItem.value.value;
      return value === filterValue;
    },
  },
];

export interface MFilterControl {
  field: string;
  label?: string;
  paths: string[];
  operator: string;
  // TEMP
  options?: {
    value: any;
    label?: string;
  }[];
}

export interface MFilterItem {
  field: string;
  label?: string;
  operator: string;
  paths: string[];
  value: any;
}

const getEntityPathValue = (entityItem: any, paths: string[]) => {
  const pathResults = paths.map((path) => _.get(entityItem, path, null));

  if (
    pathResults.every((pathResult) => pathResult === null) ||
    pathResults.length === 0
  ) {
    return null;
  }

  return pathResults.find((pathResult) => pathResult !== null);
};

export const mPassFilterLogic = (
  filterItems: MFilterItem[],
  entityItem: any,
) => {
  for (const filterItem of filterItems) {
    const value = getEntityPathValue(entityItem, filterItem.paths);
    if (!value || value == null) {
      return false;
    }

    const filterOperator = filterOperators.find(
      (operator) => operator.value === filterItem.operator,
    );
    if (!filterOperator) {
      return false;
    }

    const passedFilterFunction = filterOperator.applyFilterFunction(
      filterItem,
      value,
    );
    if (!passedFilterFunction) {
      return false;
    }
  }

  return true;
};
