const UI_COLORS = {
  coolgray50: "#f9fafb",
  coolgray100: "#f3f4f6",
  coolgray500: "#6b7280",
  coolgray600: "#4b5563",
  coolgray600rgb: "75, 85, 99",
  coolgray700: "#374151",
  coolgray900: "#111827",
  coolgray500rgb: "107, 114, 128",
  coolgray700rgb: "55, 65, 81",
  coolgray900rgb: "17, 24, 39",
  coolgraysikri900: "#131638",
  coolgraysikri800: "#1e2344",
  coolgraysikri700: "#4B5563",
  coolgraysikri700rgb: "75, 85, 99",
  coolgraysikri600: "#3f4565",
  gray50: "#fafafa",
  gray50rgb: "250, 250, 250",
  gray100: "#f4f4f5",
  gray200: "#e4e4e7",
  gray300: "#d4d4d8",
  gray300rgb: "212, 212, 216",
  gray400: "#a1a1aa",
  gray400rgb: "161, 161, 170",
  gray500: "#71717a",
  gray500rgb: "113, 113, 122",
  gray600: "#52525b",
  gray600rgb: "82, 82, 91",
  gray700: "#3f3f46",
  gray700rgb: "63, 63, 70",
  gray800: "#27272a",
  gray900: "#18181b",
  gray900rgb: "24, 24, 27",
  green600: "#16a34a",
  red600: "#dc2626",
  blue700: "#1d4ed8",
  amber600: "#d97706",
};

export const SIKRI_COLORS = {
  black: "#353637",
  darkGray: "#999ba2",
  darkGrayRgb: "153, 155, 162",
  lightGray: "#f3f2f7",
  lightGrayRgb: "243, 242, 247",
  gray: "#d1d0d9",
  grayRgb: "209, 208, 217",
  galaxyGreen: "#6bedb5",
  galaxyGreenSelected: "#cff9e7",
  galaxyGreenSelectedDark: "#2d5050",
  indigo: "#1b116e",
  red: "#a90a0a",
  darkGreen: "#067d68",
  mediumGreen: "#1f8a78",
  green: "#169e86",
  lightGreen: "#b5d8d2",
};

export const APP_COLORS = {
  light1: "#fff",
  light2: "#f9f9fb",
  light2point5: "#f6f5f9",
  light3: "#F3F2F7",
  light4: "#ebeaf0",
  light5: "#e4e3ea",
  backgroundDefault: SIKRI_COLORS.lightGray,
  backgroundPaper: "#fff",
  primary: SIKRI_COLORS.indigo,
  secondary: SIKRI_COLORS.galaxyGreen,
  success: "#059669",
  successDarkText: "#10403a",
  error: "#e1372c",
  errorDarkText: "#682422",
  warning: "#ff8c02",
  warningDarkText: "#744611",
  info: "#0369a1",
  textPrimary: "#18181b",
  textPrimaryRgb: "8, 8, 8",
  textSecondary: UI_COLORS.gray700,
  textDisabled: UI_COLORS.gray400,
  textThird: UI_COLORS.gray500,
  divider: `rgba(${SIKRI_COLORS.darkGrayRgb}, 0.28)`,

  dividerVariant: SIKRI_COLORS.gray,
  inputBorder: `rgba(${SIKRI_COLORS.darkGrayRgb}, 0.48)`,
  inputBorderRgb: "218, 217, 232",
  //
  dark1: "#21283a",
  dark2: "#191e30",
  dark3: "#101627",
  darkPrimary: "#a5b4fc", // 300 indigp

  darkSecondary: "#a5b4fc",
  darkSuccess: "#86efac",
  darkError: "#fb7185",
  darkWarning: "#eab308",
  darkInfo: "#93c5fd",
  darkTextPrimary: "#fff",
  darkTextSecondary: UI_COLORS.gray300,
  darkTextDisabled: UI_COLORS.gray500,
  darkTextVariant: UI_COLORS.gray400,
  darkDivider: `rgba(117, 112, 159, 0.2)`,
  darkInputBorder: `rgba(${SIKRI_COLORS.lightGrayRgb}, 0.24)`,
};
