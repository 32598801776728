import {
  createSelector,
  createSlice,
  type PayloadAction,
  createEntityAdapter,
  type EntityState,
} from "@reduxjs/toolkit";
import type { RootState } from "../../store";
import { REPORT_TYPES } from "../injuries/constants";
import type { FetchedStatus } from "../../types";
import {
  createArea,
  createSite,
  fetchAllAreas,
  fetchAllSites,
  fetchAreaLogs,
  fetchSiteLogs,
  updateSiteDetails,
} from "./locationsThunks";
import type { AreaLog, SiteLog } from "./locationsTypes";

export interface NewCoordinates {
  lat: number;
  lng: number;
}

export interface NewSite {
  site_id: string;
  department_id?: string;
  account_id?: string;
  name: string;
  coordinates: NewCoordinates;
  address?: string;
  type: string;
  description?: string;
}

const sitesAdapter = createEntityAdapter<NewSite>({
  selectId: (site) => site.site_id,
});

export interface NewArea {
  area_id: string;
  account_id?: string;
  site_id: string;
  name: string;
  description?: string;
  coordinates: NewCoordinates;
  outline_coordinates?: number[][];
  created_at?: any;
  created_by?: any;
}

const areasAdapter = createEntityAdapter<NewArea>({
  selectId: (area) => area.area_id,
});

const siteLogsAdapter = createEntityAdapter<SiteLog>({
  selectId: (log) => log._id,
});

const areaLogsAdapter = createEntityAdapter<AreaLog>({
  selectId: (log) => log._id,
});

export type Site = {
  domain: string;
  name: string;
  lat: number;
  lng: number;
  areaCoordinates?: number[][];
};

export type SitesCollection = {
  ids: string[];
  sites: {
    [id: string]: Site;
  };
};

export type LocationsState = {
  sites: EntityState<NewSite>;
  areas: EntityState<NewArea>;
  initialCoordinates: {
    lat: number;
    lng: number;
  };
  currentSiteId?: string;
  currentAreaId?: string;
  sitesFetched: FetchedStatus;
  areasFetched: FetchedStatus;
  siteLogs: EntityState<SiteLog>;
  areaLogs: EntityState<AreaLog>;
};

const testSites: NewSite[] = [
  {
    site_id: "1",
    name: "Grodem",
    coordinates: {
      lat: 59.01009,
      lng: 5.65333,
    },

    address: "Fjordsolveien 8",
    type: "school",
  },
  {
    site_id: "2",
    name: "Kongsvinger ungdomsskole",
    coordinates: {
      lat: 60.1914,
      lng: 12.01479,
    },

    address: "Markensvegen 20",
    type: "school",
  },
  {
    site_id: "3",
    name: "Marikollen",
    coordinates: {
      lat: 60.19127,
      lng: 11.97191,
    },

    address: "Trygve Stokkes veg 57",
    type: "school",
  },
];

const initialState: LocationsState = {
  sites: sitesAdapter.getInitialState(),
  areas: areasAdapter.getInitialState(),
  initialCoordinates: {
    lat: 59.01007,
    lng: 5.65323,
  },
  sitesFetched: "idle",
  areasFetched: "idle",
  siteLogs: siteLogsAdapter.getInitialState(),
  areaLogs: areaLogsAdapter.getInitialState(),
};

type CreateAreaPayload = {
  site_id: string;
  name: string;
  lat: number;
  lng: number;
  areaCoordinates?: number[][];
};

type CreateSitePayload = {
  name: string;
  coordinates: any;
  address?: string;
  type?: string;
};

export const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    initLocationTestData: (state) => {},
    siteClicked: (state, action: PayloadAction<string>) => {
      if (state.currentSiteId === action.payload) {
        state.currentSiteId = undefined;
      } else {
        state.currentSiteId = action.payload;
      }
    },
    siteSelected: (state, action: PayloadAction<string>) => {
      state.currentSiteId = action.payload;
    },
    siteDeselected: (state) => {
      state.currentSiteId = undefined;
    },
    areaClicked: (state, action: PayloadAction<string>) => {
      if (state.currentAreaId === action.payload) {
        state.currentAreaId = undefined;
      } else {
        state.currentAreaId = action.payload;
      }
    },
    areaDeselected: (state) => {
      state.currentAreaId = undefined;
    },
    siteCreated: (state, action: PayloadAction<CreateSitePayload>) => {
      const id = `${state.sites.ids.length + 1}`;
      const site: any = {
        id: id,
        ...action.payload,
      };
      sitesAdapter.addOne(state.sites, site);
    },
    areaCreated: (state, action: PayloadAction<CreateAreaPayload>) => {
      const id = `${action.payload.site_id}/${action.payload.name}`;
      const area: any = {
        id: id,
        ...action.payload,
      };
      areasAdapter.addOne(state.areas, area);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllSites.pending, (state, action) => {
      state.sitesFetched = "pending";
    });
    builder.addCase(fetchSiteLogs.fulfilled, (state, action) => {
      siteLogsAdapter.setMany(state.siteLogs, action.payload);
    });
    builder.addCase(fetchAllSites.fulfilled, (state, action) => {
      sitesAdapter.setAll(state.sites, action.payload);
      state.sitesFetched = "succeeded";
    });
    builder.addCase(fetchAllAreas.fulfilled, (state, action) => {
      areasAdapter.setAll(state.areas, action.payload);
      state.areasFetched = "succeeded";
    });
    builder.addCase(createSite.fulfilled, (state, action) => {
      const site = action?.payload?.site;
      const log = action?.payload?.log;
      sitesAdapter.addOne(state.sites, action.payload);
      siteLogsAdapter.addOne(state.siteLogs, log);
    });
    builder.addCase(createArea.fulfilled, (state, action) => {
      areasAdapter.addOne(state.areas, action.payload);
    });
    builder.addCase(updateSiteDetails.fulfilled, (state, action) => {
      const log = action?.payload?.log;
      if (log) {
        const changes: any = {};
        for (const update of log.updates) {
          changes[update.field_name] = update.new_value;
        }
        const update = {
          id: log.site_id,
          changes,
        };
        sitesAdapter.updateOne(state.sites, update);
      }
    });
    builder.addCase(fetchAreaLogs.fulfilled, (state, action) => {
      areaLogsAdapter.setMany(state.areaLogs, action.payload);
    });
  },
});

export const {
  initLocationTestData,
  siteSelected,
  siteDeselected,
  siteClicked,
  areaClicked,
  areaCreated,
  siteCreated,
  areaDeselected,
} = locationsSlice.actions;

export default locationsSlice.reducer;

export const {
  selectIds: selectSiteIds,
  selectEntities: selectSites,
  selectAll: selectAllSites,
  selectTotal: selectSitesTotal,
  selectById: selectSiteById,
} = sitesAdapter.getSelectors<RootState>((state) => state.locations.sites);

export const {
  selectIds: selectAreaIds,
  selectEntities: selectAreas,
  selectAll: selectAllAreas,
  selectTotal: selectAreasTotal,
  selectById: selectAreaById,
} = areasAdapter.getSelectors<RootState>((state) => state.locations.areas);

export const {
  selectIds: selectSiteLogIds,
  selectEntities: selectSiteLogs,
  selectAll: selectAllSiteLogs,
  selectTotal: selectSiteLogsTotal,
  selectById: selectSiteLogById,
} = siteLogsAdapter.getSelectors<RootState>(
  (state) => state.locations.siteLogs,
);

export const {
  selectIds: selectAreaLogIds,
  selectEntities: selectAreaLogs,
  selectAll: selectAllAreaLogs,
  selectTotal: selectAreaLogsTotal,
  selectById: selectAreaLogById,
} = areaLogsAdapter.getSelectors<RootState>(
  (state) => state.locations.areaLogs,
);

export const selectInitialCoordinates = (state: RootState) =>
  state.locations.initialCoordinates;
export const selectCurrentSiteId = (state: RootState) =>
  state.locations.currentSiteId;
export const selectCurrentAreaId = (state: RootState) =>
  state.locations.currentAreaId;
export const selectSitesFetched = (state: RootState) =>
  state.locations.sitesFetched;
export const selectAreasFetched = (state: RootState) =>
  state.locations.areasFetched;

export const makeSelectSiteLogsBySiteId = () => {
  return createSelector([selectAllSiteLogs, getId], (logs, siteId) =>
    logs.filter((log) => log.site_id === siteId),
  );
};

export const makeSelectAreaLogsByAreaId = () => {
  return createSelector([selectAllAreaLogs, getId], (logs, area_id) =>
    logs.filter((log) => log.area_id === area_id),
  );
};

export const selectCurrentSite = createSelector(
  [selectSites, selectCurrentSiteId],
  (sites, currentSiteId) => sites[currentSiteId],
);

export const selectCurrentArea = createSelector(
  [selectAreas, selectCurrentAreaId],
  (areas, currentAreaId) => areas[currentAreaId],
);

const getId = (_: any, id: string) => id;
const getStrKey = (_: any, key: string) => key;

export const selectSitesInBaseCount = createSelector(
  [selectSiteIds, selectAreaIds],
  (siteIds, areaIds: any) => {
    const areasInSiteCount: any = {};
    for (const siteId of siteIds) {
      areasInSiteCount[siteId] = areaIds.filter(
        (areaId) => areaId.split("/")[0] === siteId,
      ).length;
    }
    return areasInSiteCount;
  },
);

export const makeSelectLocationAreaById = () => {
  return createSelector([selectAreas, getId], (areas, id) => areas[id]);
};

export const makeSelectLocationSiteById = () => {
  return createSelector([selectSites, getId], (sites, id) => sites[id]);
};

export const makeSelectAreasBySiteId = () => {
  return createSelector([selectAllAreas, getId], (areas, siteId) =>
    areas.filter((area) => area.site_id === siteId),
  );
};

export const makeSelectAreasCountBySiteId = () => {
  return createSelector(
    [selectAllAreas, getId],
    (areas, siteId) => areas.filter((area) => area.site_id === siteId).length,
  );
};

const siteTypesToIconIds: any = {
  school: "10",
  generic: "0",
  workplace: "5",
  office: "5",
  warehouse: "4",
};

export const selectSiteFeatures = createSelector(
  [selectAllSites, selectCurrentSiteId],
  (sites, currentSiteId) =>
    sites.map(
      (site: NewSite) =>
        ({
          type: "Feature",
          properties: {
            id: site.site_id,
            site_id: site.site_id,
            iconImage:
              site.site_id === currentSiteId
                ? `site-${siteTypesToIconIds[site.type]}-4`
                : `site-${siteTypesToIconIds[site.type]}-2`,
            name: site.name,
            current: site.site_id === currentSiteId ? 1 : 0,
            type: "site",
          },
          geometry: {
            coordinates: [site.coordinates.lng, site.coordinates.lat],
            type: "Point",
          },
          id: site.site_id,
        }) as GeoJSON.Feature<GeoJSON.Point>,
    ),
);

export const selectAreaFeatures = createSelector(
  [selectAllAreas, selectCurrentAreaId],
  (areas, current) =>
    areas.map(
      (area: NewArea) =>
        ({
          type: "Feature",
          properties: {
            id: area.area_id,
            area_id: area.area_id,
            iconImage: area.outline_coordinates
              ? ""
              : current === area.area_id
                ? "pin-4"
                : "pin-0",
            showIcon: area.outline_coordinates ? 0 : 1,
            name: area.name,
            current: current === area.area_id ? 1 : 0,
            type: "area",
            reportsCount: {
              total: 0,
              [REPORT_TYPES.ACCIDENT]: 0,
              [REPORT_TYPES.ILLNESS]: 0,
              [REPORT_TYPES.MINOR_INJURY]: 0,
            },
            reportsCountStatus: {
              total: 1,
              [REPORT_TYPES.ACCIDENT]: 1,
              [REPORT_TYPES.ILLNESS]: 1,
              [REPORT_TYPES.MINOR_INJURY]: 1,
            },
          },
          geometry: {
            coordinates: [area.coordinates.lng, area.coordinates.lat],
            type: "Point",
          },
          id: area.area_id,
        }) as GeoJSON.Feature<GeoJSON.Point>,
    ),
);

export const selectAreaBoxFeatures = createSelector(
  [selectAllAreas, selectCurrentAreaId],
  (areas, current) =>
    areas
      .filter((area: NewArea) => area.outline_coordinates)
      .map(
        (area: NewArea) =>
          ({
            type: "Feature",
            properties: {
              id: `${area.area_id}-box`,
              area_id: area.area_id,
              site_id: area.site_id,
              current: current === area.area_id ? 1 : 0,
              reportsCount: {
                total: 0,
                [REPORT_TYPES.ACCIDENT]: 0,
                [REPORT_TYPES.ILLNESS]: 0,
                [REPORT_TYPES.MINOR_INJURY]: 0,
              },
              reportsCountStatus: {
                total: 1,
                [REPORT_TYPES.ACCIDENT]: 1,
                [REPORT_TYPES.ILLNESS]: 1,
                [REPORT_TYPES.MINOR_INJURY]: 1,
              },
            },
            geometry: {
              type: "Polygon",
              coordinates: [area.outline_coordinates],
            },
            id: `${area.area_id}-box`,
          }) as GeoJSON.Feature<GeoJSON.Polygon>,
      ),
);
