import { Box, CircularProgress } from "@mui/material";
import type { WithChildren } from "../../types";

export const LoadingButtonContainer = (
  props: WithChildren<{ loading: boolean; sx?: any }>,
) => {
  const { loading, children, sx } = props;
  return (
    <Box sx={{ position: "relative", ...sx }}>
      {children}
      {loading && (
        <CircularProgress
          size={24}
          sx={{
            color: "primary.main",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Box>
  );
};
