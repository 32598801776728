import { Stack } from "@mui/material";
import type { WithChildren } from "../../types";

type AppActionsProps = WithChildren<{}>;

export const AppActions = (props: AppActionsProps) => {
  const { children } = props;

  return (
    <Stack direction="row" alignItems="center" gap={1.5} justifyContent="end">
      {children}
    </Stack>
  );
};
