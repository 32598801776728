import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton } from "@mui/material";
import { AppIcon } from "../Elements";

export type AppMenuItem = {
  name: string;
  onClick?: () => void;
  iconName?: string;
};

type AppMoreMenuProps = {
  iconDirection?: "horiz" | "vert";
  menuItems: AppMenuItem[];
  sx?: any;
};

export const AppMoreMenu = (props: AppMoreMenuProps) => {
  const { iconDirection = "horiz", menuItems, sx } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="app-more-icon-button"
        onClick={handleClick}
        size="small"
        edge="end"
        sx={sx}
      >
        <AppIcon iconName={`more_${iconDirection}`} />
      </IconButton>
      <Menu
        id="app-more-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "app-more-button",
        }}
      >
        {menuItems.map((item: any) => (
          <MenuItem
            key={`app-more-menu-item-${item.name}`}
            onClick={() => {
              item.onClick();
              handleClose();
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
