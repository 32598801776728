import MapGL, { Marker } from "react-map-gl";
import mapMarkerImg from "../../../assets/mapmarker.svg";
import { Typography, useTheme } from "@mui/material";
import { Paper } from "@mui/material";
import { MAPBOX_TOKEN } from "../../../features/sites/components/Map";
import { Box } from "@mui/system";
import GeocoderControl from "../../../features/sites/components/CreateSiteDialog/GeocoderControl";
import React from "react";
import {
  DescriptionList,
  DescriptionListItem,
} from "../../DescriptionList/DescriptionsGrid";
import { useTranslation } from "react-i18next";

const mapStyles: any = {
  width: "100%",
  height: "100%",
  position: "relative",
  minHeight: "320px",
  minWidth: "480px",
  flex: 1,
};

type FormCoordinateMapPartProps = {
  coordinates?: any;
  onChangeCoordinates: any;
  error?: boolean;
  errorMessage?: string;
  onChangeAddress?: any;
};

export default function FormCoordinateMapPart(
  props: FormCoordinateMapPartProps,
) {
  const {
    coordinates,
    onChangeCoordinates,
    error = false,
    errorMessage,
    onChangeAddress,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  const handleClick = (event: any) => {
    if (event.lngLat) {
      const newCoordinates = { lat: event.lngLat.lat, lng: event.lngLat.lng };
      onChangeCoordinates(newCoordinates);
    }
  };

  const handleMarkerDrag = (event: any) => {
    if (event.lngLat) {
      const newCoordinates = { lat: event.lngLat.lat, lng: event.lngLat.lng };
      onChangeCoordinates(newCoordinates);
    }
  };

  const cursor = React.useMemo(() => {
    if (coordinates) {
      return "auto";
    }
    return "pointer";
  }, [coordinates]);

  const initialViewState = React.useMemo(() => {
    if (coordinates) {
      return {
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        zoom: 16,
      };
    }
    return {
      latitude: 60.472,
      longitude: 8.4689,
      zoom: 4,
    };
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          position: "relative",
          flex: 1,
          borderRadius: 1,
          overflow: "hidden",
          ...(error
            ? {
                borderColor: "error.main",
              }
            : {
                border: 0,
              }),
        }}
      >
        <MapGL
          style={mapStyles}
          onClick={handleClick}
          mapStyle={"mapbox://styles/emkf/clhleppw801np01pg76wi2jkx"}
          mapboxAccessToken={MAPBOX_TOKEN}
          initialViewState={initialViewState}
          cursor={cursor}
        >
          {coordinates && (
            <Marker
              longitude={coordinates.lng}
              latitude={coordinates.lat}
              draggable
              onDrag={handleMarkerDrag}
            >
              <img src={mapMarkerImg} />
            </Marker>
          )}
          {onChangeAddress && (
            <GeocoderControl
              mapboxAccessToken={MAPBOX_TOKEN}
              position="top-left"
              onResult={(result: any) => {
                onChangeAddress(result.result.place_name);
                onChangeCoordinates({
                  lng: result.result.center[0],
                  lat: result.result.center[1],
                });
              }}
            />
          )}
        </MapGL>
      </Paper>

      {coordinates && (
        <Paper
          elevation={1}
          sx={{
            opacity: 0.8,
            position: "absolute",
            top: 10,
            right: 10,
            p: 1.5,
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
            maxWidth: "320px",
          }}
        >
          <DescriptionList>
            <DescriptionListItem
              size="small"
              field={t("wif.injury.latitude")}
              value={`${coordinates.lat.toFixed(5)}`}
            />
            <DescriptionListItem
              size="small"
              field={t("wif.injury.longitude")}
              value={`${coordinates.lng.toFixed(5)}`}
            />
          </DescriptionList>
        </Paper>
      )}

      {errorMessage && (
        <Typography
          variant="body2"
          color="error"
          sx={{ fontSize: "10.5px", ml: 1.75, mt: "3px" }}
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
}
