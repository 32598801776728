import devConfig from "./config.dev";
import localConfig from "./config.local";
import prodConfig from "./config.prod";
import demoConfig from "./config.demo";

let config: {
  baseUrl: string;
  baseDomain: string;
  homeUrl: string;
  useSubdomain: boolean;
  samsvarUploadsUrl: string;
};

if (process.env.APP_ENV === "prod") {
  config = prodConfig;
} else if (process.env.APP_ENV === "dev") {
  config = devConfig;
} else if (process.env.APP_ENV === "demo") {
  config = demoConfig;
} else {
  config = localConfig;
}

export default config;
