import type React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { APP_COLORS } from "../../config";
import { AppIcon } from "../Elements";
import { useTheme } from "@mui/material/styles";

export interface DialogTitleProps {
  id?: string;
  children?: React.ReactNode;
  onClose?: () => void;
  bgcolor?: string;
}

export function AppDialogTitle(props: DialogTitleProps) {
  const { children, onClose, bgcolor, ...other } = props;
  const theme = useTheme();
  return (
    <>
      <DialogTitle
        sx={{
          m: 0,
          pt: 2.5,
          pb: 0.25,
          px: 3,
          typography: "h4",
          fontWeight: 600,
          ...(bgcolor && {
            bgcolor: `${bgcolor}`,
            pb: 2.5,
            color: (theme) =>
              theme.palette.mode === "light"
                ? APP_COLORS.darkTextPrimary
                : APP_COLORS.textPrimary,
          }),
        }}
        {...other}
      >
        {children}
      </DialogTitle>
      {onClose ? (
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 12,
            top: 12,
            color: "text.secondary",
          }}
        >
          <AppIcon iconName="close" />
        </IconButton>
      ) : null}
    </>
  );
}
