import { Box, type TextFieldProps, CircularProgress } from "@mui/material";
import { TextField } from "@mui/material";
import React from "react";
import type { Control, FieldPath } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import type { FieldValues } from "react-hook-form/dist/types";
import { useTranslation } from "react-i18next";
import { AppIcon } from "../Elements";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const filterOptions = createFilterOptions({
  matchFrom: "any",
  limit: 100,
});

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextFieldProps & {
  control?: Control<TFieldValues, any>;
  name: TName;
  controllerProps?: any;
  options: string[];
  optionDepths?: {
    [key: string]: number;
  };
  optionIconNames?: {
    [key: string]: string;
  };
  optionLabels?: {
    [key: string]: string;
  };
  label?: string;
  loading?: boolean;
};

export function FormSingleAutocomplete<TFieldValues extends FieldValues>(
  props: Props<TFieldValues>,
) {
  const {
    control = useFormContext<TFieldValues>().control,
    name,
    controllerProps,
    options,
    optionDepths,
    optionIconNames,
    optionLabels,
    label,
    loading = false,
    ...rest
  } = props;

  const [orderDialogOpen, setOrderDialogOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const { t } = useTranslation();
  const getTranslation = React.useCallback(
    (key: string) => {
      const keyWithValues = key.split(" ");
      const translationKey = keyWithValues[0];
      if (
        translationKey === "max" ||
        translationKey === "min" ||
        translationKey === "minmax"
      ) {
        return t(`wif.injury.errors.${translationKey}`, {
          i: keyWithValues[1],
        });
      }
      if (translationKey === "between") {
        return t(`wif.injury.errors.${translationKey}`, {
          min: keyWithValues[1],
          max: keyWithValues[2],
        });
      }
      return t(`wif.injury.errors.${key}`);
    },
    [t],
  );

  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field: { value, ...field }, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          filterOptions={filterOptions}
          id={`${name}-autocomplete`}
          value={!loading ? value : null}
          onChange={(event, newValue) => {
            field.onChange(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          loading={loading}
          getOptionLabel={(option) =>
            optionLabels ? optionLabels[option] : option
          }
          options={options}
          renderOption={(props, option) => (
            <li {...props}>
              <Box
                sx={{
                  ...(optionDepths && {
                    pl: optionDepths[option],
                  }),
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {optionIconNames && (
                  <AppIcon iconName={optionIconNames[option]} sx={{ mr: 1 }} />
                )}
                {optionLabels ? optionLabels[option] : option}
              </Box>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              required={!!error}
              error={!!error}
              helperText={error?.message ? getTranslation(error.message) : null}
              variant="outlined"
              {...rest}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
}
