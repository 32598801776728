import React, { useEffect } from "react";
import { InjuryDocument } from "./InjuryDocument";
import ReactPDF, { PDFViewer } from "@react-pdf/renderer";
import { Box } from "@mui/system";
import { useCurrentInjuryReport } from "../../routes/CurrentInjuryReportContext";
import { useAppSelector } from "../../../../store/hooks";
import {
  selectAllEmployees,
  selectEmployees,
} from "../../../employees/employeesSlice";
import { selectDepartmentEntities } from "../../../departments/departmentsSlice";
import {
  selectEmployeeInjuryTagEntities,
  selectInjuryUnitEntities,
  selectStudentInjuryTagEntities,
} from "../../store/injuryReportsSlice";
import {
  getInjuryReportDepartmentId,
  getInjuryReportSectionNames,
  getInjuryReportUnitId,
} from "../../helpers";
import { useTranslation } from "react-i18next";
import { selectNonconformityReportEntities } from "../../../nonconformity/nonconformitySlice";
import { TKB_INJURY } from "../../constants";
import { EMPLOYEE_SECTION_NAMES } from "../../constants/employee";
import { STUDENT_SECTION_NAMES } from "../../constants/student";
import { nokCurrencyFormatter } from "../../../../utils/format";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { getTranslationKeyForSectionFieldLabel } from "../../helpers/dataMapping";

type InjuryDocumentDialogProps = {
  open?: boolean;
  handleClose: () => void;
};

type FormattedSectionVal = {
  field: string;
  value: any;
};

export let GeneralReportblobUrl = "";

export const InjuryDocumentDialog = (props: InjuryDocumentDialogProps) => {
  const { open = true, handleClose } = props;
  const { t } = useTranslation();

  useEffect(() => {
    let isMounted = true;
    const renderUrl = async () => {
      const blob = await ReactPDF.pdf(
        <InjuryDocument
          injuryReport={injuryReport}
          formattedValues={{
            unitName,
            departmentName,
            assigneeName,
            reporterName,
          }}
          formattedSections={formattedSections}
          formattedActions={formattedActions}
        />,
      ).toBlob();

      const url = URL.createObjectURL(blob);
      if (url && url.length > 0) {
        GeneralReportblobUrl = url;
      }
    };

    renderUrl();

    return () => {
      isMounted = false;
    };
  }, []);

  const injuryReport = useCurrentInjuryReport();

  const users = useAppSelector(selectAllEmployees);
  const usersDict = useAppSelector(selectEmployees);
  const departments = useAppSelector(selectDepartmentEntities);
  const units = useAppSelector(selectInjuryUnitEntities);
  const employeeTags = useAppSelector(selectEmployeeInjuryTagEntities);
  const studentTags = useAppSelector(selectStudentInjuryTagEntities);
  const ncReports = useAppSelector(selectNonconformityReportEntities);
  const studentAccidentMapped = {
    "67604946-cf85-11ed-afa1-0242ac120002": t(
      `${TKB_INJURY}.accidentTypesOccurredTags.teachingLocation`,
    ),
    "751d94a8-cf85-11ed-afa1-0242ac120002": t(
      `${TKB_INJURY}.accidentTypesOccurredTags.duringTeaching`,
    ),
    "7c6476d2-cf85-11ed-afa1-0242ac120002": t(
      `${TKB_INJURY}.accidentTypesOccurredTags.sfo`,
    ),
    "8369f466-cf85-11ed-afa1-0242ac120002": t(
      `${TKB_INJURY}.accidentTypesOccurredTags.onTheWay`,
    ),
    "1d4b309e-cf87-11ed-afa1-0242ac120002": t(
      `${TKB_INJURY}.accidentTypesOccurredTags.other`,
    ),
  };

  const getMappedSectionValueFuncs = (sectionName: string) => {
    return {
      damageNature: (val: any[]) =>
        val
          .map(
            (v) =>
              `${v.id ? v.id : v} ` +
              t(
                `${TKB_INJURY}.employee.typeTab.natureOfDamage.${
                  v.id ? v.id : v
                }`,
              ),
          )
          .join("; "),
      damagedBodyPart: (val: any[]) =>
        val
          .map(
            (v) =>
              `${v.id ? v.id : v} ` +
              t(
                `${TKB_INJURY}.employee.typeTab.damagedBodyPart.${
                  v.id ? v.id : v
                }`,
              ),
          )
          .join("; "),
      accidentType: (val: any[]) =>
        val
          .map(
            (v) =>
              `${v.id ? v.id : v} ` +
              t(
                `${TKB_INJURY}.employee.typeTab.typeOfAccident.${
                  v.id ? v.id : v
                }`,
              ),
          )
          .join("; "),
      background: (val: any[]) =>
        val
          .map(
            (v) =>
              `${v.id ? v.id : v} ` +
              t(`${TKB_INJURY}.employee.typeTab.background.${v.id ? v.id : v}`),
          )
          .join("; "),
      damageMode: (val: any[]) =>
        val
          .map(
            (v) =>
              `${v.id ? v.id : v} ` +
              t(`${TKB_INJURY}.employee.typeTab.damageMode.${v.id ? v.id : v}`),
          )
          .join("; "),
      typeOfWorkplace: (val: any[]) =>
        val
          .map(
            (v) =>
              `${v.id ? v.id : v} ` +
              t(
                `${TKB_INJURY}.employee.typeTab.typeOfWorkplace.${
                  v.id ? v.id : v
                }`,
              ),
          )
          .join("; "),
      deviation: (val: any[]) =>
        val
          .map(
            (v) =>
              `${v.id ? v.id : v} ` +
              t(`${TKB_INJURY}.employee.typeTab.deviation.${v.id ? v.id : v}`),
          )
          .join("; "),
      assumedAbsence: (val: any[]) =>
        val
          .map(
            (v) =>
              `${v.id ? v.id : v} ` +
              t(
                `${TKB_INJURY}.employee.typeTab.assumedAbsence.${
                  v.id ? v.id : v
                }`,
              ),
          )
          .join("; "),
      ncReportId: (val: string) => ncReports[val]?.name || val,
      durationOfImpact: (val: string[]) => val.join(", "),
      assumedAnnualIncomeForClaimYear: (val: number) =>
        `kr ${nokCurrencyFormatter.format(val)}`,
      employmentPercentage: (val: number) => `${val} %`,
      competenceLevel: (val: string) =>
        t(`${TKB_INJURY}.employee.employeePersonTab.competenceLevel.${val}`),
      natureOfEmployment: (val: string) =>
        t(`${TKB_INJURY}.employee.employeePersonTab.natureOfWorking.${val}`),
      voluntaryOccupationalInjuryInsurance: (val: string) => yesNoOptions[val],
      workTimeArrangements: (val: string) =>
        t(`${TKB_INJURY}.employee.accidentTab.arrangements.${val}`),
      accidentOccured: (val: string) =>
        sectionName === EMPLOYEE_SECTION_NAMES.ACCIDENT
          ? t(`${TKB_INJURY}.employee.accidentTab.accidentOccurred.${val}`)
          : sectionName === STUDENT_SECTION_NAMES.ACCIDENT
            ? studentAccidentMapped[val]
            : "",
      formOfSalary: (val: any) =>
        t(`${TKB_INJURY}.employee.accidentTab.formOfSalary.${val}`),
      atNormalWorkplace: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      insideOutside: (val: any) =>
        t(`${TKB_INJURY}.employee.accidentTab.whereAccidentOccurred.${val}`),
      onTheWayToWork: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      onTheWayBetweenWork: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      deathFromAccident: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      necessaryTraining: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      reportedToLIA: (val: any) => t(`${TKB_INJURY}.yesNoOptions.${val}`),
      departmentId: (val: string) => departments[val]?.name || val,
      unitId: (val: string) => units[val]?.unit_name || val,
      typesOfInjury: (val: any[]) =>
        sectionName === EMPLOYEE_SECTION_NAMES.MINOR_INJURY
          ? (val &&
              val
                .map(
                  (v: any) =>
                    employeeTags && employeeTags[v] && employeeTags[v]?.name,
                )
                .map((v: any) =>
                  t(`${TKB_INJURY}.typesOfInjury.${v}`).includes(TKB_INJURY)
                    ? v
                    : t(`${TKB_INJURY}.typesOfInjury.${v}`),
                )
                .join(", ")) ||
            ""
          : sectionName === STUDENT_SECTION_NAMES.MINOR_INJURY
            ? (val &&
                val
                  .map(
                    (v: any) =>
                      studentTags && studentTags[v] && studentTags[v]?.name,
                  )
                  .map((v: any) =>
                    t(`${TKB_INJURY}.typesOfInjury.${v}`).includes(TKB_INJURY)
                      ? v
                      : t(`${TKB_INJURY}.typesOfInjury.${v}`),
                  )
                  .join(", ")) ||
              ""
            : (val && val.join(", ")) || "",
    };
  };

  const yesNoOptions = {
    option1: t(`${TKB_INJURY}.yesNoOptions.yes`),
    option2: t(`${TKB_INJURY}.yesNoOptions.no`),
  };

  const unitName = React.useMemo(() => {
    const unitId = getInjuryReportUnitId(injuryReport);
    if (unitId) {
      const unit = units[unitId];
      if (unit && unit?.unit_name) {
        return unit.unit_name;
      }
    }
    return "";
  }, [departments, injuryReport]);

  const departmentName = React.useMemo(() => {
    const departmentId = getInjuryReportDepartmentId(injuryReport);
    if (departmentId) {
      const department = departments[departmentId];
      if (department && department?.name) {
        return department.name;
      }
    }
    return "";
  }, [departments, injuryReport]);

  const reporterName = React.useMemo(() => {
    const reporterId = injuryReport.reporter_id;
    if (reporterId) {
      const reporter = usersDict[reporterId];
      if (reporter && reporter?.name) {
        return reporter.name;
      }
    }
    return "";
  }, [users, injuryReport]);

  const assigneeName = React.useMemo(() => {
    const assigneeId = injuryReport.assignee_id;
    const backupAssigneeName = injuryReport.assignee_details?.name;
    if (assigneeId) {
      const assignee = usersDict[assigneeId];
      if (backupAssigneeName && assignee?.name === "unresolved")
        return backupAssigneeName;
      return assignee?.name;
    }

    return "";
  }, [users, injuryReport]);

  const formattedSections = React.useMemo(() => {
    const sectionNames = getInjuryReportSectionNames(injuryReport);

    const newSections: {
      [sectionName: string]: { field: string; value: any }[];
    } = {};

    for (const section of sectionNames) {
      const formatted: FormattedSectionVal[] = [];

      const mappedSectionValueFuncs = getMappedSectionValueFuncs(section);
      for (const [key, val] of Object.entries(injuryReport.sections[section])) {
        const fieldName = t(
          getTranslationKeyForSectionFieldLabel(section, key),
        );
        formatted.push({
          field: fieldName,
          value: mappedSectionValueFuncs[key]
            ? mappedSectionValueFuncs[key](val)
            : val
              ? val
              : "",
        });
      }

      newSections[section] = formatted;
    }

    return newSections;
  }, [injuryReport, t]);

  const formattedActions = React.useMemo(() => {
    const actions: any = [];

    if (injuryReport?.actions && injuryReport?.actions.length > 0) {
      for (const action of injuryReport?.actions) {
        const account = users?.[action?.account_id]?.name || action?.account_id;
        let assignee = undefined;
        let newStatus = action?.status;
        if (action?.assignee_id) {
          assignee = users?.[action?.assignee_id]?.name || action?.assignee_id;
        }
        const members = [];
        if (action?.members) {
          for (const member of action?.members) {
            members.push(users?.[member]?.name || member);
          }
        }
        if (action?.deadline) {
          const deadlineDate = new Date(action?.deadline);
          deadlineDate.setHours(0, 0, 0, 0);
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          if (today > deadlineDate) {
            if (action?.status !== "completed") {
              newStatus = "overdue";
            }
          }
        }
        actions.push({
          ...action,
          status: newStatus,
          account,
          assignee,
          members,
        });
      }
    }
    return actions;
  }, [injuryReport, users]);

  if (
    Object.keys(usersDict).length !== 0 &&
    Object.keys(departments).length !== 0 &&
    Object.keys(units).length !== 0
  ) {
    return (
      <Backdrop
        open={open}
        onClick={handleClose}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Box
          sx={{
            height: {
              xs: "100vh",
              md: "90vh",
            },
            width: {
              xs: "100vw",
              md: "80vw",
              lg: "60vw",
              xl: "55vw",
            },

            overflow: "hidden",
            "& iframe": {
              height: "100%",
              width: "100%",
              "#toolbar": {
                backgroundColor: "red!important",
              },
            },
          }}
        >
          <PDFViewer
            style={{
              borderRadius: "6px",
            }}
          >
            <InjuryDocument
              injuryReport={injuryReport}
              formattedValues={{
                unitName,
                departmentName,
                assigneeName,
                reporterName,
              }}
              formattedSections={formattedSections}
              formattedActions={formattedActions}
            />
          </PDFViewer>
        </Box>
      </Backdrop>
    );
  } else {
    return (
      <Backdrop
        open={open}
        onClick={handleClose}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      </Backdrop>
    );
  }
};
