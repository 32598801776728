import Typography, { type TypographyTypeMap } from "@mui/material/Typography";
import { Stack } from "@mui/system";
import type React from "react";
import type { WithChildren } from "../../types";

type HeaderProps = WithChildren<{
  variant?: TypographyTypeMap["props"]["variant"]; // h1, h2, h3, h4, h5, h6
  description?: string; // Supplementary text below the heading.
  actions?: React.ReactNode; // Actions for the container
  px?: number;
  py?: number;
}>;

export function Header(props: HeaderProps) {
  const { variant = "h1", actions, children, px = 3, py } = props;

  return (
    <Stack direction="row" alignItems="center" px={px}>
      <Typography variant={variant} sx={{ flexGrow: 1 }}>
        {children}
      </Typography>
      {actions}
    </Stack>
  );
}
