import {
  Box,
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { InjuryReportsDataGrid } from "../components/InjuryReportsDataGrid/InjuryReportsDataGrid";
import { ContentLayout } from "../../../components/Layout";
import { NamedTabPanel } from "../../../components/Elements/TabPanel";
import { Statistics } from "../components/Statistics/Statistics";
import { type RootState, useAppDispatch, useAppSelector } from "../../../store";
import {
  injuryReportCleared,
  makeSelectTotalInjuryActionsByModule,
  makeSelectTotalInjuryMessagesByModule,
} from "../store/injuryReportsSlice";
import { AppIcon } from "../../../components/Elements";
import { Insights } from "../components/Statistics/Insights";
import FullScreenDialog from "../../../components/Elements/FullScreenDialog";
import CreateSiteDialog from "../../sites/components/CreateSiteDialog/CreateSiteDialog";
import { InjuryFormWrapper } from "../components/InjuryForm/InjuryFormWrapper";
import { Header } from "../../../components/Layout/Header";
import { TabsContainer } from "../../../components/Layout/AppTabs";
import ContentWrapper from "../../../components/Layout/ContentWrapper";
import { RightContainer } from "../../../components/Layout/RightContainer";
import { useTranslation } from "react-i18next";
import {
  REPORT_CATEGORY,
  REPORT_TYPES,
  TKB_INJURY,
  TKB_INJURY_REPORT,
} from "../constants";
import { navigateToUrl } from "single-spa";
import { InjuryActions } from "../components/InjuryActions/InjuryActions";
import { InjuryReportActionsDataGrid } from "../../actions/components/ActionsDataGrid";
import { InjuryInbox } from "../components/InjuryInbox";
import { selectCurrentAreaId } from "../../locations/locationsSlice";
import { AreaInjuryDetails } from "../components/AreaInjuryDetails/AreaInjuryDetails";
import {
  StatisticsAppliedFilterChips,
  StatisticsFiltersDialog,
} from "../components/Statistics/StatisticsFilters";
import { useFlags } from "launchdarkly-react-client-sdk";

type InjuryReportsHandlerPageProps = {
  category?: string;
};

export const InjuryReportsHandlerPage = (
  props: InjuryReportsHandlerPageProps,
) => {
  const { category } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [formOpen, setFormOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { sitesAndLocations } = useFlags();

  React.useEffect(() => {
    if (category && category === "no_access") {
      window.location.href.replace("/fm/injuries", "");
      navigateToUrl("#/mainDashboard");
      window.location.reload();
    }
  }, [category]);

  const handleCloseForm = () => {
    setFormOpen(false);
  };

  const [tab, setTab] = React.useState("reports");
  const [view, setView] = React.useState<string>("list");

  const handleChangeView = (
    event: React.MouseEvent<HTMLElement>,
    newView: string | null,
  ) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handleChangeTab = (event: React.SyntheticEvent, newTab: string) => {
    dispatch(injuryReportCleared());
    setTab(newTab);
  };

  const handleReportInjury = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleInjuryReportDestination = (type: string) => {
    setAnchorEl(null);
    window.location.href.replace("/fm/injuries", "");
    const injuryReportRoute = "#/injury";

    switch (type) {
      case `${REPORT_CATEGORY.STUDENT}`:
        navigateToUrl(`${injuryReportRoute}`);
        window.location.reload();
        return;
      case `${REPORT_CATEGORY.EMPLOYEE}`:
        navigateToUrl(`${injuryReportRoute}/employee`);
        window.location.reload();
        return;
      case `${REPORT_CATEGORY.STUDENT}/${REPORT_TYPES.MINOR_INJURY}`:
        navigateToUrl(`${injuryReportRoute}/minorInjury`);
        window.location.reload();
        return;
      case `${REPORT_CATEGORY.EMPLOYEE}/${REPORT_TYPES.MINOR_INJURY}`:
        navigateToUrl(`${injuryReportRoute}/employee/minorInjury`);
        window.location.reload();
        return;
      default:
        return;
    }
  };

  const selectTotalInjuryMessagesByModule = React.useMemo(
    makeSelectTotalInjuryMessagesByModule,
    [category],
  );
  const selectTotalInjuryActionsByModule = React.useMemo(
    makeSelectTotalInjuryActionsByModule,
    [category],
  );

  const totalMessages = useAppSelector((state: RootState) =>
    selectTotalInjuryMessagesByModule(state, category || ("all" as any)),
  );
  const totalActions = useAppSelector((state: RootState) =>
    selectTotalInjuryActionsByModule(state, category || ("all" as any)),
  );

  const currentAreaId = useAppSelector(selectCurrentAreaId);

  return (
    <ContentWrapper>
      <ContentLayout
        breadcrumbs={[{ to: "/", name: t(`${TKB_INJURY}.home`) }]}
        title={
          category === REPORT_CATEGORY.EMPLOYEE
            ? t(`${TKB_INJURY}.employeeInjuries`)
            : category === REPORT_CATEGORY.STUDENT
              ? t(`${TKB_INJURY}.studentInjuries`)
              : category === undefined
                ? t(`${TKB_INJURY}.sidebar.allInjuries`)
                : ""
        }
        altHeader={
          <Stack direction="column" gap={2}>
            <Header
              variant="h1"
              actions={<StatisticsFiltersDialog module={category} />}
            >
              <>
                {category === REPORT_CATEGORY.EMPLOYEE &&
                  `${t(`${TKB_INJURY}.employeeInjuries`)}: `}
                {category === REPORT_CATEGORY.STUDENT &&
                  `${t(`${TKB_INJURY}.studentInjuries`)}: `}
                {category === undefined &&
                  `${t(`${TKB_INJURY}.allInjuries`)}: `}
                <Typography
                  variant="h1"
                  component="span"
                  sx={{
                    display: "inline",
                    fontWeight: 500,
                    color: "text.secondary",
                  }}
                >
                  {t(`${TKB_INJURY}.reportHandler`)}
                </Typography>
              </>
            </Header>
            <StatisticsAppliedFilterChips module={category} />
          </Stack>
        }
      >
        <Stack
          direction="column"
          spacing={1}
          sx={{
            pt: 1.5,
          }}
        >
          <Header
            actions={
              <Stack direction="row" gap={1.25}>
                {tab === "actions" && (
                  <ToggleButtonGroup
                    value={view}
                    exclusive
                    onChange={handleChangeView}
                    aria-label="View mode"
                  >
                    <ToggleButton value="list" aria-label="List">
                      <AppIcon iconName="view_list" opticalSize={20} />
                    </ToggleButton>
                    <ToggleButton value="grid" aria-label="Grid">
                      <AppIcon iconName="grid_view" opticalSize={20} />
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
                {tab === "statistics" && (
                  <StatisticsFiltersDialog module={category} />
                )}
                {tab === "reports" && (
                  <>
                    <Button
                      color="primary"
                      id="fade-button"
                      aria-controls={open ? "fade-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleReportInjury}
                    >
                      {t(`${TKB_INJURY}.reportInjury`)}
                    </Button>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={() => handleInjuryReportDestination("overview")}
                      TransitionComponent={Fade}
                    >
                      {(category === REPORT_CATEGORY.STUDENT ||
                        category === undefined) && [
                        <MenuItem
                          key="studentInjury"
                          onClick={() => setFormOpen(true)}
                        >
                          {t(`${TKB_INJURY_REPORT}.studentInjury`)}
                        </MenuItem>,
                        <MenuItem
                          key="studentMinorInjury"
                          onClick={() =>
                            handleInjuryReportDestination(
                              `${REPORT_CATEGORY.STUDENT}/${REPORT_TYPES.MINOR_INJURY}`,
                            )
                          }
                        >
                          {t(`${TKB_INJURY_REPORT}.studentMinorInjury`)}
                        </MenuItem>,
                      ]}
                      {(category === REPORT_CATEGORY.EMPLOYEE ||
                        category === undefined) && [
                        <MenuItem
                          key="employeeInjury"
                          onClick={() =>
                            handleInjuryReportDestination(
                              `${REPORT_CATEGORY.EMPLOYEE}`,
                            )
                          }
                        >
                          {t(`${TKB_INJURY_REPORT}.employeeInjury`)}
                        </MenuItem>,
                        <MenuItem
                          key="employeeMinorInjury"
                          onClick={() =>
                            handleInjuryReportDestination(
                              `${REPORT_CATEGORY.EMPLOYEE}/${REPORT_TYPES.MINOR_INJURY}`,
                            )
                          }
                        >
                          {t(`${TKB_INJURY_REPORT}.employeeMinorInjury`)}
                        </MenuItem>,
                      ]}
                    </Menu>
                  </>
                )}
              </Stack>
            }
          >
            <>
              {category === REPORT_CATEGORY.EMPLOYEE &&
                `${t(`${TKB_INJURY}.employeeInjuries`)}: `}
              {category === REPORT_CATEGORY.STUDENT &&
                `${t(`${TKB_INJURY}.studentInjuries`)}: `}
              {category === undefined && `${t(`${TKB_INJURY}.allInjuries`)}: `}
              <Typography
                variant="h1"
                component="span"
                sx={{
                  display: "inline",
                  fontWeight: 500,
                  color: "text.secondary",
                }}
              >
                {t(`${TKB_INJURY}.reportHandler`)}
              </Typography>
            </>
          </Header>
          <TabsContainer px={3}>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
            >
              <Tab label={t(`${TKB_INJURY}.reports`)} value="reports" />
              {totalActions > 0 && (
                <Tab label={`${t("wif.injury.actions")}`} value="actions" />
              )}
              {totalMessages > 0 && (
                <Tab label={`${t("wif.injury.messages")}`} value="messages" />
              )}
              <Tab label={t(`${TKB_INJURY}.statistics`)} value="statistics" />
              {category === undefined && sitesAndLocations && (
                <Tab label={t(`${TKB_INJURY}.map`)} value="map" />
              )}
            </Tabs>
          </TabsContainer>
        </Stack>

        <NamedTabPanel value={tab} name="reports">
          <InjuryReportsDataGrid
            isHandler={true}
            categoryFilter={category}
            view="handler"
            module={category || ("all" as any)}
          />
        </NamedTabPanel>
        <NamedTabPanel value={tab} name="actions">
          {view === "list" && (
            <InjuryReportActionsDataGrid module={category || ("all" as any)} />
          )}
          {view === "grid" && <InjuryActions category={category} />}
        </NamedTabPanel>
        <NamedTabPanel value={tab} name="messages">
          <InjuryInbox module={category || ("all" as any)} />
        </NamedTabPanel>
        <NamedTabPanel value={tab} name="statistics">
          <Insights module={category || ("all" as any)} />
        </NamedTabPanel>
        {sitesAndLocations && (
          <NamedTabPanel value={tab} name="map">
            <Statistics />
          </NamedTabPanel>
        )}

        <FullScreenDialog
          open={formOpen}
          handleClose={handleCloseForm}
          title="Report new injury"
        >
          <Box sx={{}}>
            <InjuryFormWrapper />
          </Box>
        </FullScreenDialog>
        <CreateSiteDialog handleClose={handleCloseForm} open={false} />
      </ContentLayout>
      {currentAreaId && tab === "map" && (
        <RightContainer>
          <AreaInjuryDetails area_id={currentAreaId} />
        </RightContainer>
      )}
    </ContentWrapper>
  );
};
