import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { AppIcon } from "../../../../../components/Elements";

export const RenderCopyCell = (params: any) => {
  const handleClick = () => {
    navigator.clipboard.writeText(params.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        gap: 0.75,
      }}
    >
      <Typography
        variant="body2"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Typography>
      <Tooltip title="Copy ID" placement="bottom">
        <IconButton onClick={handleClick} size="small" sx={{ p: 0 }}>
          <AppIcon iconName="content_copy" opticalSize={20} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
