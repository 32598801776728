import React from "react";

import { Box, Stack, Typography } from "@mui/material";
import type { WithChildren } from "../../types";

interface DescriptionField {
  field?: string;
}

type DescriptionValue =
  | {
      value?: string | number;
      valueComponent?: never;
    }
  | {
      value?: never;
      valueComponent?: any;
    };

type DescriptionListItemProps = DescriptionField &
  DescriptionValue & {
    size?: "small" | "normal";
    disabledOrUndefined?: boolean;
    secondaryAlign?: string;
    primaryIcon?: any;
  };

export const DescriptionListItem = (props: DescriptionListItemProps) => {
  const {
    field,
    value,
    valueComponent,
    size = "medium",
    disabledOrUndefined = false,
    secondaryAlign,
    primaryIcon,
  } = props;
  return (
    <React.Fragment>
      {primaryIcon ? (
        <Stack direction="row" alignItems="center" gap={1.25}>
          {primaryIcon}
          <Typography
            variant={size === "small" ? "body2" : "body1"}
            color="text.secondary"
            sx={{ pr: 0.75 }}
          >
            {field}
          </Typography>
        </Stack>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Typography
            variant={size === "small" ? "body2" : "body1"}
            color="textVariant"
            sx={{ pr: 0.75 }}
          >
            {field}
          </Typography>
        </div>
      )}
      {value && (
        <Typography
          variant={size === "small" ? "body2" : "body1"}
          sx={{
            color: disabledOrUndefined ? "text.disabled" : "text.primary",
            textAlign: secondaryAlign || "left",
          }}
        >
          {value}
        </Typography>
      )}
      {valueComponent && valueComponent}
    </React.Fragment>
  );
};

type DescriptionsGridProp = WithChildren<{
  gap?: number;
  sx?: any;
}>;

export const DescriptionList = (props: DescriptionsGridProp) => {
  const { children, gap = 1.75, sx } = props;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        gap: gap,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
