import { createContext, useContext } from "react";
import type { NewArea, NewSite } from "./locationsSlice";

export const CurrentSiteContext = createContext<NewSite | null>(null);

export const useCurrentSite = () => {
  const currentSiteContext = useContext(CurrentSiteContext);

  if (!currentSiteContext) {
    throw new Error(
      "useCurrentSite has to be used within <CurrentSiteContext.Provider>",
    );
  }

  return currentSiteContext;
};

export const CurrentAreaContext = createContext<NewArea | null>(null);

export const useCurrentArea = () => {
  const currentAreaContext = useContext(CurrentAreaContext);

  if (!currentAreaContext) {
    throw new Error(
      "useCurrentSite has to be used within <CurrentAreaContext.Provider>",
    );
  }

  return currentAreaContext;
};
