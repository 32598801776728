import { Box } from "@mui/material";
import type { WithChildren } from "../../types";

type TabsContainerProps = WithChildren<{
  px?: number;
  sx?: any;
}>;

export function TabsContainer(props: TabsContainerProps) {
  const { children, px = 0, sx } = props;

  return (
    <Box
      sx={{
        width: "100%",
        borderBottom: (theme) => (theme.palette.mode === "light" ? 1 : 1),
        borderColor: (theme) =>
          theme.palette.mode === "light" ? "divider" : "divider",
        px: px,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
