import { useEffect, useState } from "react";
import type { Control, FieldPath } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import type { FieldValues } from "react-hook-form/dist/types";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import type { AppEmployee } from "../../features/employees/employeesSlice";

type FormSelectOption = {
  value: any;
  label?: string;
  depth?: number;
};

type CombinedSelectProps = {
  label?: string;
  options: FormSelectOption[];
};

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = CombinedSelectProps & {
  control?: Control<TFieldValues, any>;
  name: TName;
  required?: never;
  controllerProps?: any;
  minorInjury?: boolean;
  loading: boolean;
  employees: AppEmployee[];
};

export function FormSelectWithSearch<TFieldValues extends FieldValues>(
  props: Props<TFieldValues>,
) {
  const {
    control = useFormContext<TFieldValues>().control,
    name,
    controllerProps,
    label,
    options,
    minorInjury = false,
    loading,
  } = props;

  const [selectedValue, setSelectedValue] = useState<FormSelectOption | null>(
    null,
  );

  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      defaultValue={"" as any}
      render={({ field, fieldState: { error } }) => {
        useEffect(() => {
          !minorInjury
            ? setSelectedValue(
                options.filter((option) =>
                  field.value.id
                    ? option.value === field.value.id
                    : option.value === field.value,
                )[0],
              )
            : setSelectedValue(
                options.filter((option) => option.label === field.value)[0],
              );
        }, [options, loading]);
        return (
          <FormControl fullWidth error={!!error}>
            <Autocomplete
              {...field}
              options={options}
              loading={loading}
              getOptionLabel={(option) => option.label || option.value}
              onChange={(_, newValue) => {
                if (minorInjury) field.onChange(newValue?.label);
                else field.onChange(newValue?.value);
                setSelectedValue(newValue);
              }}
              value={selectedValue}
              renderOption={(props, item) => (
                <li {...props} key={item.value}>
                  {item.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </FormControl>
        );
      }}
    />
  );
}
