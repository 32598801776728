import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { Avatar, CircularProgress, Link, Typography } from "@mui/material";
import { AppIcon } from "../../../../../components/Elements";
import { CASE_STATUS, TKB_INJURY } from "../../../constants";
import { useAppSelector } from "../../../../../store";
import { selectDepartmentEntities } from "../../../../departments/departmentsSlice";
import { selectInjuryUnitEntities } from "../../../store/injuryReportsSlice";
import { selectEmployees } from "../../../../employees/employeesSlice";
import { Box } from "@mui/system";
import React from "react";
import { formatDateFromTimestamp } from "../../../../../utils/format";
import { generateAvatarColor } from "../../../../../utils/color";
import _ from "lodash";
import { AppUserAvatar } from "../../../../../components/Elements/AppAvatar";

export const RenderInjuryMessagesCell = (params: any) => {
  if (!params.value) {
    return <></>;
  }
  return (
    <Stack direction="row" alignItems="center" gap={0.75} sx={{}}>
      <AppIcon iconName="chat" color="textVariant" opticalSize={20} />
      <Typography
        variant="body2"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Typography>
    </Stack>
  );
};

export const RenderInjuryCommentsCell = (params: any) => {
  if (!params.value) {
    return <></>;
  }
  return (
    <Stack direction="row" alignItems="center" gap={0.75} sx={{}}>
      <AppIcon iconName="forum" color="textVariant" opticalSize={20} />
      <Typography
        variant="body2"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {params.value}
      </Typography>
    </Stack>
  );
};

export const RenderCategoryCell = (params: any) => {
  const { t } = useTranslation();

  return <>{t(`${TKB_INJURY}.reportCategory.${params.value}`)}</>;
};

export const RenderTypeCell = (params: any) => {
  const { t } = useTranslation();

  return <>{t(`${TKB_INJURY}.reportType.${params.value}`)}</>;
};

export const RenderSiteCell = (params: any) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={0.75}
      sx={{
        color: "text.primary",
        ml: -0.5,
      }}
    >
      <AppIcon
        iconName="location_on"
        opticalSize={20}
        sx={{ ml: 0.5, mr: 0 }}
        color="textVariant"
      />
      {params.value.split("/")[1]}
    </Stack>
  );
};

export const RenderUnitCell = (params: any) => {
  const units = useAppSelector(selectInjuryUnitEntities);

  const unit = units[params.value];
  return <>{unit ? unit?.unit_name : params.value}</>;
};

export const RenderDepartmentCell = (params: any) => {
  const departments = useAppSelector(selectDepartmentEntities);

  const department = departments[params.value];
  return <>{department ? department?.name : params.value}</>;
};

export const RenderNcReportCell = (params: any) => {
  return (
    <Link
      variant="body2"
      underline={"none"}
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        "&:hover": {
          textDecoration: "underline",
          color: "text.primary",
        },
        fontWeight: 400,
        minWidth: 0,
        color: (theme) =>
          theme.palette.mode === "light"
            ? "primary.main"
            : "#c7d2fe" /* indigo 300 */,
      }}
    >
      {params.value || params?.row?.ncReportId || ""}
    </Link>
  );
};

export const RenderHandlerCell = (params: any) => {
  const { t } = useTranslation();
  const users = useAppSelector(selectEmployees);
  const hasAssignee = params.value && params.value !== "";

  const assigneeName = React.useMemo(() => {
    if (hasAssignee) {
      return users[params.value]?.name || params.value;
    }
    return t("wif.injury.unassigned");
  }, [users, t]);
  if (!users[params.value] && params.value && params.value !== "") {
    return (
      <>
        <CircularProgress color="warning" size={20} />
      </>
    );
  }

  return (
    <Stack direction="row" alignItems="center" gap={1} sx={{}}>
      <AppUserAvatar
        username={hasAssignee ? assigneeName : undefined}
        size="small"
      />
      <Typography
        variant="body2"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          ...(!hasAssignee && {
            color: "textVariant",
          }),
        }}
      >
        {_.capitalize(assigneeName)}
      </Typography>
    </Stack>
  );
};

export const RenderUserCell = (params: any) => {
  const { t } = useTranslation();

  const users = useAppSelector(selectEmployees);

  const hasAssignee = params.value && params.value !== "";

  const assigneeName = React.useMemo(() => {
    if (params.value && params.value !== "") {
      return users[params.value]?.name || params.value;
    }
    return t("wif.injury.anonymous");
  }, [users]);

  return (
    <Stack direction="row" alignItems="center" gap={0.75} sx={{}}>
      <Avatar
        sx={{
          height: 24,
          width: 24,
          ...(hasAssignee
            ? {
                bgcolor: (theme) =>
                  generateAvatarColor(
                    assigneeName,
                    theme.palette.mode === "dark",
                  ),
              }
            : {
                bgcolor: "text.disabled",
              }),
          fontSize: "body2.fontSize",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {hasAssignee ? (
          `${assigneeName.charAt(0).toUpperCase()}`
        ) : (
          <AppIcon iconName="person" />
        )}
      </Avatar>
      <Typography
        variant="body2"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          ...(!hasAssignee && {
            color: "textVariant",
          }),
        }}
      >
        {assigneeName}
      </Typography>
    </Stack>
  );
};

export const RenderDueDateCell = (params: any) => {
  const getDeadlineStatus = () => {
    const deadline = params.value;

    if (deadline && deadline !== null) {
      const deadlineDate = new Date(deadline);
      if (deadlineDate) {
        deadlineDate.setHours(0, 0, 0, 0);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        console.log(
          `deadline: ${deadlineDate.getTime()}, today: ${today.getTime()}, > ${
            today.getTime() > deadlineDate.getTime()
          }}`,
        );
        if (today > deadlineDate) {
          return "overdue";
        }
        if (today.getTime() === deadlineDate.getTime()) {
          return "today";
        }
      }
    }
    return "normal";
  };
  return (
    <Box
      sx={{
        ...(!params.value && {
          color: "text.disabled",
        }),
        ...(getDeadlineStatus() === "overdue" && {
          color: "error.main",
        }),
        ...(getDeadlineStatus() === "today" && {
          color: "warning.main",
        }),
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={0.75}
        sx={{
          ...(!params.value && {
            color: "text.disabled",
          }),
          ...(getDeadlineStatus() === "overdue" && {
            color: (theme) =>
              theme.palette.mode === "light" ? "error.dark" : "error.light",
          }),
          ...(getDeadlineStatus() === "today" && {
            color: (theme) =>
              theme.palette.mode === "light" ? "warning.dark" : "warning.light",
          }),
        }}
      >
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {params.value ? formatDateFromTimestamp(params.value) : ""}
        </Typography>
      </Stack>
    </Box>
  );
};

export const RenderStatusCell = (params: any) => {
  const { t } = useTranslation();

  const statusColors: any = {
    [CASE_STATUS.ONGOING]: "info",
    [CASE_STATUS.CLOSED]: "success",
    [CASE_STATUS.REJECTED]: "error",
  };

  return (
    <Stack direction="row" alignItems="center" gap={0.75} sx={{}}>
      <Box
        sx={{
          height: 8,
          width: 8,
          borderRadius: "50%",
          bgcolor: `${statusColors[params.value]}.main`,
        }}
      />
      <Typography
        variant="body2"
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {t(`${TKB_INJURY}.${params.value}`)}
      </Typography>
    </Stack>
  );
};
