import {
  ACCIDENT_TYPE_NAMES,
  type BaseFurtherInformationSection,
  type BaseIllnessSection,
  DAMAGED_BODY_PART_NAMES,
  DAMAGE_NATURE_NAMES,
} from "../components/FormSections";
import type {
  EmployeeAccidentSection,
  EmployerSection,
  InjuredEmployeeSection,
} from "../components/FormSections/employee";
import type { EmployeeMinorInjurySection } from "../components/FormSections/employee/EmployeeMinorInjurySection";
import {
  B_TYPE_NAMES,
  E_TYPE_NAMES,
  type EmployeeTypesSection,
  F_TYPE_NAMES,
  G_TYPE_NAMES,
  H_TYPE_NAMES,
} from "../components/FormSections/employee/EmployeeTypesSection";
import type {
  InjuredStudentSection,
  SchoolSection,
  StudentAccidentSection,
} from "../components/FormSections/student";
import { EMPLOYEE_SECTION_NAMES } from "../constants/employee";
import { STUDENT_SECTION_NAMES } from "../constants/student";

export const MSectionNameMapping: any = {
  [EMPLOYEE_SECTION_NAMES.EMPLOYEE]: "person_information",
  [EMPLOYEE_SECTION_NAMES.EMPLOYER]: "employer_information",
  [EMPLOYEE_SECTION_NAMES.MINOR_INJURY]: "minor_injury_information",
  [EMPLOYEE_SECTION_NAMES.ACCIDENT]: "accident_information",
  [EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES]: "type_information",
  [EMPLOYEE_SECTION_NAMES.ILLNESS]: "illness_information",
  [EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION]: "further_information",
  [STUDENT_SECTION_NAMES.STUDENT]: "student_information",
  [STUDENT_SECTION_NAMES.SCHOOL]: "school_place_information",
  [STUDENT_SECTION_NAMES.ACCIDENT]: "accident_information",
  [STUDENT_SECTION_NAMES.ILLNESS]: "illness_information",
  [STUDENT_SECTION_NAMES.MINOR_INJURY]: "minor_injury_information",
  [STUDENT_SECTION_NAMES.FURTHER_INFORMATION]: "further_information",
};

export const MSectionNameMappingWithCategory: any = {
  [EMPLOYEE_SECTION_NAMES.EMPLOYEE]: {
    [EMPLOYEE_SECTION_NAMES.EMPLOYEE]: "person_information",
    [EMPLOYEE_SECTION_NAMES.EMPLOYER]: "employer_information",
    [EMPLOYEE_SECTION_NAMES.MINOR_INJURY]: "minor_injury_information",
    [EMPLOYEE_SECTION_NAMES.ACCIDENT]: "accident_information",
    [EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES]: "type_information",
    [EMPLOYEE_SECTION_NAMES.ILLNESS]: "illness_information",
    [EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION]: "further_information",
  },
  [STUDENT_SECTION_NAMES.STUDENT]: {
    [STUDENT_SECTION_NAMES.STUDENT]: "student_information",
    [STUDENT_SECTION_NAMES.SCHOOL]: "school_place_information",
    [STUDENT_SECTION_NAMES.ACCIDENT]: "accident_information",
    [STUDENT_SECTION_NAMES.ILLNESS]: "illness_information",
    [STUDENT_SECTION_NAMES.MINOR_INJURY]: "minor_injury_information",
    [STUDENT_SECTION_NAMES.FURTHER_INFORMATION]: "further_information",
  },
};

export const MSectionTranslationKeyMapping: any = {
  [EMPLOYEE_SECTION_NAMES.EMPLOYEE]: "employee.employeePersonTab",
  [EMPLOYEE_SECTION_NAMES.EMPLOYER]: "employee.employerTab",
  [EMPLOYEE_SECTION_NAMES.MINOR_INJURY]: "minorInjuryTab",
  [EMPLOYEE_SECTION_NAMES.ACCIDENT]: "employee.accidentTab",
  [EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES]: "employee.typeTab",
  [EMPLOYEE_SECTION_NAMES.ILLNESS]: "illnessTab",
  [EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION]: "furtherInfoTab",
  [STUDENT_SECTION_NAMES.STUDENT]: "pupilStudentTab",
  [STUDENT_SECTION_NAMES.SCHOOL]: "schoolPlaceTab",
  [STUDENT_SECTION_NAMES.ACCIDENT]: "accidentInfoTab",
  [STUDENT_SECTION_NAMES.ILLNESS]: "illnessTab",
  [STUDENT_SECTION_NAMES.MINOR_INJURY]: "minorInjuryTab",
  [STUDENT_SECTION_NAMES.FURTHER_INFORMATION]: "furtherInfoTab",
};

export const getTranslationKeyForSectionFieldLabel = (
  sectionName: string,
  fieldName: string,
) => {
  const newFieldName =
    MSectionFieldNameMapping[sectionName]?.[fieldName]?.newName || fieldName;
  return `wif.injury.${MSectionTranslationKeyMapping[sectionName]}.${newFieldName}.title`;
};

export const getTranslationKeyForSectionFieldPlaceholder = (
  sectionName: string,
  fieldName: string,
) => {
  const newFieldName =
    MSectionFieldNameMapping[sectionName]?.[fieldName]?.newName || fieldName;
  return `wif.injury.${MSectionTranslationKeyMapping[sectionName]}.${newFieldName}.placeholder`;
};

type MFieldName = keyof InjuredStudentSection | keyof StudentAccidentSection;
type ISectionFieldNameMapping<T> = {
  [key in keyof T]: {
    newName?: string;
    setValue?: (val: any) => any;
    setReverseValue?: (val: any) => any;
  };
};

const MStudentSectionFieldNameMapping: ISectionFieldNameMapping<InjuredStudentSection> =
  {
    name: {
      newName: "fullName",
    },
    nationalIdentityNumber: {
      newName: "ssn",
    },
    address: {
      newName: "residence",
    },
    municipality: {
      newName: "municipality",
    },
  };

const MIllnessSectionFieldNameMapping: ISectionFieldNameMapping<BaseIllnessSection> =
  {
    diseaseName: {
      newName: "diseaseNature",
    },
    provokingInfluence: {
      newName: "influence",
    },
    durationOfImpact: {
      newName: "durationImpact",
    },
  };

const MSchoolSectionFieldNameMapping: ISectionFieldNameMapping<SchoolSection> =
  {
    departmentId: {
      newName: "department",
    },
    unitId: {
      newName: "unit",
    },
  };

const MMInorInjurySectionFieldNameMapping: ISectionFieldNameMapping<EmployeeMinorInjurySection> =
  {
    departmentId: {
      newName: "department",
    },
    unitId: {
      newName: "unit",
    },
    nameOfInjuredParty: {
      newName: "nameOfInjurtParty",
    },
  };

const MFurtherInformationSectionFieldNameMapping: ISectionFieldNameMapping<BaseFurtherInformationSection> =
  {
    ncReportId: {
      newName: "nonconformityReport",
    },
  };

const MEmployeeSectionFieldNameMapping: ISectionFieldNameMapping<InjuredEmployeeSection> =
  {
    employeeId: {
      newName: "registeredEmployees",
    },
    phoneNumber: {
      newName: "phone",
    },
    bankAccountNumber: {
      newName: "bankAccount",
    },
    profession: {
      newName: "position",
    },
    employmentStartDate: {
      newName: "employmentFrom",
    },
    employmentEndDate: {
      newName: "employmentTo",
    },
    assumedAnnualIncomeForClaimYear: {
      newName: "annualIncome",
    },
    natureOfEmployment: {
      newName: "natureOfWorking",
    },
    voluntaryOccupationalInjuryInsurance: {
      newName: "voluntaryInsurance",
    },
  };

const MEmployerSectionFieldNameMapping: ISectionFieldNameMapping<EmployerSection> =
  {
    name: {
      newName: "employer",
    },
    departmentId: {
      newName: "department",
    },
    unitId: {
      newName: "unit",
    },
    accidentHappenedAtMainAddress: {
      newName: "isAccLocationCorrect",
    },
    addressOfAccident: {
      newName: "AccLocation",
    },
    insuranceCompanyName: {
      newName: "insuranceName",
    },
    insuranceCompanyAddress: {
      newName: "insuranceAddress",
    },
  };

const MStudentAccidentSectionFieldNameMapping: ISectionFieldNameMapping<StudentAccidentSection> =
  {
    accidentType: {
      newName: "typeOfAccident",
      setValue: (val: string[]) =>
        val.map((v) => ({ id: v, name: ACCIDENT_TYPE_NAMES[v] })),
    },
    damagedBodyPart: {
      setValue: (val: string[]) =>
        val.map((v) => ({ id: v, name: DAMAGED_BODY_PART_NAMES[v] })),
    },
    damageNature: {
      newName: "natureOfDamage",
      setValue: (val: string[]) =>
        val.map((v) => ({ id: v, name: DAMAGE_NATURE_NAMES[v] })),
    },
  };

const MEmployeeAccidentSectionFieldNameMapping: ISectionFieldNameMapping<EmployeeAccidentSection> =
  {
    workTimeArrangements: {
      newName: "arrangements",
    },
    atNormalWorkplace: {
      newName: "workplace",
    },
    insideOutside: {
      newName: "whereAccidentOccurred",
    },
    onTheWayToWork: {
      newName: "onTheWay",
    },
    onTheWayBetweenWork: {
      newName: "betweenWorkplaces",
    },
    deathFromAccident: {
      newName: "deathFromInjury",
    },
  };

const MEmployeeTypesSectionFieldNameMapping: ISectionFieldNameMapping<EmployeeTypesSection> =
  {
    accidentType: {
      newName: "typeOfAccident",
      setValue: (val: string[]) =>
        val.map((v) => ({ id: v, name: ACCIDENT_TYPE_NAMES[v] })),
    },
    damagedBodyPart: {
      setValue: (val: string[]) =>
        val.map((v) => ({ id: v, name: DAMAGED_BODY_PART_NAMES[v] })),
    },
    damageNature: {
      newName: "natureOfDamage",
      setValue: (val: string[]) =>
        val.map((v) => ({ id: v, name: DAMAGE_NATURE_NAMES[v] })),
    },
    background: {
      setValue: (val: string[]) =>
        val.map((v) => ({ id: v, name: B_TYPE_NAMES[v] })),
    },
    damageMode: {
      setValue: (val: string[]) =>
        val.map((v) => ({ id: v, name: E_TYPE_NAMES[v] })),
    },
    typeOfWorkplace: {
      setValue: (val: string[]) =>
        val.map((v) => ({ id: v, name: F_TYPE_NAMES[v] })),
    },
    deviation: {
      setValue: (val: string[]) =>
        val.map((v) => ({ id: v, name: G_TYPE_NAMES[v] })),
    },
    assumedAbsence: {
      setValue: (val: string[]) =>
        val.map((v) => ({ id: v, name: H_TYPE_NAMES[v] })),
    },
  };

export const MSectionFieldNameMapping: any = {
  [STUDENT_SECTION_NAMES.MINOR_INJURY]: MMInorInjurySectionFieldNameMapping,
  [STUDENT_SECTION_NAMES.STUDENT]: MStudentSectionFieldNameMapping,
  [STUDENT_SECTION_NAMES.SCHOOL]: MSchoolSectionFieldNameMapping,
  [STUDENT_SECTION_NAMES.ACCIDENT]: MStudentAccidentSectionFieldNameMapping,
  [STUDENT_SECTION_NAMES.ILLNESS]: MIllnessSectionFieldNameMapping,
  [STUDENT_SECTION_NAMES.FURTHER_INFORMATION]:
    MFurtherInformationSectionFieldNameMapping,
  [EMPLOYEE_SECTION_NAMES.MINOR_INJURY]: MMInorInjurySectionFieldNameMapping,
  [EMPLOYEE_SECTION_NAMES.EMPLOYEE]: MEmployeeSectionFieldNameMapping,
  [EMPLOYEE_SECTION_NAMES.EMPLOYER]: MEmployerSectionFieldNameMapping,
  [EMPLOYEE_SECTION_NAMES.ACCIDENT]: MEmployeeAccidentSectionFieldNameMapping,
  [EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES]:
    MEmployeeTypesSectionFieldNameMapping,
  [EMPLOYEE_SECTION_NAMES.ILLNESS]: MIllnessSectionFieldNameMapping,
  [EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION]:
    MFurtherInformationSectionFieldNameMapping,
};

export const getTableValidSection = (sectionName: string, updates: any) => {
  const newSectionName = MSectionNameMapping[sectionName];
  const sectionFieldNameMapping = MSectionFieldNameMapping[sectionName];
  const toReturn: any = {};
  for (const key in updates) {
    let newFieldName = key;
    let newFieldValue = updates[key];
    if (sectionFieldNameMapping[key]) {
      if (sectionFieldNameMapping[key]?.newName) {
        newFieldName = sectionFieldNameMapping[key].newName;
      }
      if (sectionFieldNameMapping[key]?.setValue) {
        newFieldValue = sectionFieldNameMapping[key].setValue(updates[key]);
      }
    }

    const newKey = `${newSectionName}.${newFieldName}`;
    toReturn[newKey] = sectionFieldNameMapping[key]?.setValue
      ? sectionFieldNameMapping[key]?.setValue(updates[key])
      : updates[key];
  }

  return toReturn;
};

export const getReverseMappedSectionName = (sectionName: string) => {
  for (const key in MSectionNameMapping) {
    if (MSectionNameMapping[key] === sectionName) {
      return key;
    }
  }
  return sectionName;
};

export const getReverseMappedSectionNameByCategory = (
  sectionName: string,
  categoryName: string,
) => {
  for (const key in MSectionNameMappingWithCategory[categoryName]) {
    if (MSectionNameMappingWithCategory[categoryName][key] === sectionName) {
      return key;
    }
  }
  return sectionName;
};

export const getReverseMappedSectionFieldName = (
  sectionName: string,
  fieldName: string,
) => {
  const sectionFieldNameMapping =
    MSectionFieldNameMapping[getReverseMappedSectionName(sectionName)];
  for (const key in sectionFieldNameMapping) {
    if (sectionFieldNameMapping[key].newName === fieldName) {
      return key;
    }
  }
  return fieldName;
};
