import type {
  GridColumnVisibilityModel,
  GridSortModel,
} from "@mui/x-data-grid";
import {
  type PayloadAction,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import type { RootState } from "../../../store";
import type { MFilterControl, MFilterItem } from "../../filter/filterUtils";
import { getInitialInjuryControlsState } from "./controlsHelpers";

export interface ModuleViewControls {
  addedControls: MFilterControl[];
  activeFilters: MFilterItem[];
  sortModel: GridSortModel;
  columnVisibilityModel: GridColumnVisibilityModel;
}

export type MInjuryModuleName = "student" | "employee" | "all";
export type MInjuryViewName = "reporter" | "handler";

export const InjuryModuleViews = [
  "student_reporter",
  "student_handler",
  "employee_reporter",
  "employee_handler",
  "all_reporter",
  "all_handler",
] as const;

export interface InjuryControlsState {
  student_reporter: ModuleViewControls;
  student_handler: ModuleViewControls;
  employee_reporter: ModuleViewControls;
  employee_handler: ModuleViewControls;
  all_reporter: ModuleViewControls;
  all_handler: ModuleViewControls;
}

const initialState = getInitialInjuryControlsState();

type BaseInjuryControlPayload = {
  moduleView: string;
};

type UpdateQueriedControlPayload = BaseInjuryControlPayload & {
  controlName: string;
  controlValue: any;
};

type SortModelChangedPayload = BaseInjuryControlPayload & {
  newModel: GridSortModel;
};

type ColumnVisibilityModelChangedPayload = BaseInjuryControlPayload & {
  newModel: GridColumnVisibilityModel;
};

const injuryControlsSlice = createSlice({
  name: "injuryControls",
  initialState,
  reducers: {
    injuryControlChanged: (
      state,
      action: PayloadAction<UpdateQueriedControlPayload>,
    ) => {
      const { moduleView, controlName, controlValue } = action.payload;
      state[moduleView][controlName] = controlValue;
    },
    sortModelChanged: (
      state,
      action: PayloadAction<SortModelChangedPayload>,
    ) => {
      const { moduleView, newModel } = action.payload;
      state[moduleView].sortModel = newModel;
    },
    columnVisibilityModelChanged: (
      state,
      action: PayloadAction<ColumnVisibilityModelChangedPayload>,
    ) => {
      const { moduleView, newModel } = action.payload;
      state[moduleView].columnVisibilityModel = newModel;
    },
    activeFilterRemoved: (
      state,
      action: PayloadAction<BaseInjuryControlPayload & { field: string }>,
    ) => {
      const { moduleView, field } = action.payload;
      const newActiveFilters = state[moduleView].activeFilters.filter(
        (filterItem: MFilterItem) => filterItem.field !== action.payload.field,
      );
      state[moduleView].activeFilters = newActiveFilters;
    },
    activeFilterSet: (
      state,
      action: PayloadAction<
        BaseInjuryControlPayload & { newFilterItem: MFilterItem }
      >,
    ) => {
      const { moduleView, newFilterItem } = action.payload;
      const activeFilters = state[moduleView].activeFilters;
      const indexOfNew = activeFilters.findIndex(
        (filterItem) => filterItem.field === newFilterItem.field,
      );
      if (indexOfNew > -1) {
        activeFilters[indexOfNew] = newFilterItem;
      } else {
        activeFilters.push(newFilterItem);
      }
    },
    filterControlAdded: (
      state,
      action: PayloadAction<
        BaseInjuryControlPayload & { control: MFilterControl }
      >,
    ) => {
      const newAddedControls = state[action.payload.moduleView].addedControls;
      newAddedControls.push(action.payload.control);
    },
    filterControlsReset: (
      state,
      action: PayloadAction<BaseInjuryControlPayload>,
    ) => {
      state[action.payload.moduleView].activeFilters = [];
      state[action.payload.moduleView].addedControls = [];
    },
  },
  extraReducers: (builder) => {},
});

export const {
  sortModelChanged,
  columnVisibilityModelChanged,
  injuryControlChanged,
  activeFilterRemoved,
  activeFilterSet,
  filterControlAdded,
  filterControlsReset,
} = injuryControlsSlice.actions;

export const selectInjuryControlsState = (state: RootState) =>
  state.injuryControls;

const getModuleView = (_: any, moduleView: string) => moduleView;

export const makeSelectInjuryControlsByModuleView = () =>
  createSelector(
    [selectInjuryControlsState, getModuleView],
    (controls, moduleView) => controls[moduleView],
  );

export default injuryControlsSlice.reducer;
