import type * as React from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Drawer from "@mui/material/Drawer";
import Paper from "@mui/material/Paper";
import { IconButton, Stack, Typography } from "@mui/material";
import { AppIcon } from "../Elements";
import { BOX_SHADOWS } from "../../config/theme/boxShadows";

interface FRightPanelProps {
  open?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
  drawerWidth?: string;
  side?: "left" | "right";
  bgcolor?: string;
  paperVariant?: "elevation" | "outlined";
  light?: boolean;
  title?: string;
  action?: React.ReactNode;
  titleDivider?: boolean;
}

export default function FSidePanel(props: FRightPanelProps) {
  const {
    open,
    onClose,
    children,
    drawerWidth = "400px",
    side = "right",
    bgcolor = "background.paper",
    paperVariant = "elevation",
    light = false,
    title = "Title",
    titleDivider = false,
  } = props;

  const theme = useTheme();
  const lgScreen = useMediaQuery(theme.breakpoints.up("lg"));

  if (!lgScreen) {
    return (
      <Drawer
        anchor={side}
        open={open}
        onClose={onClose}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          "& .MuiDrawer-paper": {
            bgcolor: "background.paper",
            boxShadow: BOX_SHADOWS.elevation1,
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            width: "100%",
            px: 2.5,
            pt: 2.25,
            pb: 0.5,
            ...(titleDivider && {
              borderBottom: 1,
              borderColor: "divider",
              pb: 2.25,
            }),
          }}
        >
          <Typography variant="h4" sx={{ flex: 1 }}>
            {title}
          </Typography>
          {props.action && props.action}
          <IconButton
            onClick={onClose}
            edge="end"
            size="small"
            sx={{
              minHeight: "0px!important",
              height: "24px",
              mr: -0.75,
              width: "24px",
            }}
          >
            <AppIcon iconName="close" opticalSize={20} />
          </IconButton>
        </Stack>
        {children}
      </Drawer>
    );
  }

  return (
    <Drawer
      className="BackgroundDefault"
      transitionDuration={0}
      onClose={onClose}
      sx={{
        width: open ? drawerWidth : "0px!important",
        height: "100vh",
        flexShrink: 0,
        bgcolor: "background.default",
        boxShadow: "none!important",
        "& .MuiDrawer-paper": {
          bgcolor: "transparent",
          boxShadow: "none!important",
          width: open ? drawerWidth : "0px",
          pt: "56px",
          overflow: "visible",
          borderRadius: 0,
          border: "none",
          borderLeftWidth: `0px!important`,
          borderLeftColor: `transparent!important`,
          ...(side === "right" && {
            borderLeftWidth: 0,
            /* boxShadow: BOX_SHADOWS.elevation1, */
          }),
          ...(paperVariant === "outlined" && {
            /* boxShadow: 'none', */
            ...(side === "left" && {
              borderRight: 1,
              borderColor: "divider",
            }),
            ...(side === "right" && {
              borderWidth: 0,
              /* borderLeft: `1px solid ${Colors.ltheme.divider}!important`, */
              boxShadow: "none",
            }),
          }),
        },
        overflow: "visible",
      }}
      PaperProps={{
        variant: "outlined",
        sx: {
          borderWidth: `0px!important`,
          borderColor: `transparent!important`,
          bgcolor: "transparent",
          boxShadow: "none!important",
          overflow: "visible",
        },
      }}
      variant="persistent"
      anchor={side}
      open={open}
    >
      <Paper
        elevation={0}
        className="BackgroundDefault"
        sx={{
          bgcolor: "transparent",
          p: 1.5,
          ml: -1.5,
          flex: 1,
          overflow: "hidden",
        }}
      >
        <Paper
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            flex: 1,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              width: "100%",
              px: 2.5,
              pt: 2.25,
              pb: 0.5,
              ...(titleDivider && {
                borderBottom: 1,
                borderColor: "divider",
                pb: 2.25,
              }),
            }}
          >
            <Typography variant="h4" sx={{ flex: 1 }}>
              {title}
            </Typography>
            {props.action && props.action}
            <IconButton
              onClick={onClose}
              edge="end"
              size="small"
              sx={{
                minHeight: "0px!important",
                height: "24px",
                mr: -0.75,
                width: "24px",
              }}
            >
              <AppIcon iconName="close" opticalSize={20} />
            </IconButton>
          </Stack>

          {children}
        </Paper>
      </Paper>
    </Drawer>
  );
}
