import Button from "@mui/material/Button";
import { useAppDispatch } from "../../../../store";
import { Container, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { snackAlertOpened } from "../../../global/controlsSlice";
import { useTranslation } from "react-i18next";
import FullScreenDialog from "../../../../components/Elements/FullScreenDialog";
import { AppForm } from "../../../../components/Forms/AppForm";
import {
  CreateAreaControls,
  type ICreateAreaPayload,
  createAreaPayloadSchema,
} from "../../../locations/components/Forms/CreateAreaForm";
import { AppActions } from "../../../../components/AppActions/AppActions";
import { useLoading } from "../../../../common/useLoading";
import { createArea } from "../../../locations/locationsThunks";

const fixNum = (number) => {
  return Number.parseFloat(number.toFixed(5));
};

const steps = [
  {
    label: "Mark center of location",
    description: "Click on the map to mark the center location of your site.",
  },
  {
    label: "Draw outline of site (optional)",
    description:
      "Use the draw controls in the upper right corner to draw a polygon for the outline of the site.",
  },
];

interface CreateAreaDialogProps {
  open?: boolean;
  handleClose: () => void;
}

export default function CreateAreaDialog(props: CreateAreaDialogProps) {
  const { open = true, handleClose } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [{ isLoading, loadingMessage }, { startLoading, stopLoading }] =
    useLoading(false);

  const handleSubmit = (data) => {
    const outline_coordinates = data?.outline_coordinates?.slice();
    if (outline_coordinates) {
      for (let i = 0; i < outline_coordinates.length; i++) {
        const coordinatesArr = outline_coordinates[i];
        outline_coordinates[i] = [
          fixNum(coordinatesArr[0]),
          fixNum(coordinatesArr[1]),
        ];
      }
    }
    const payload = Object.assign({}, data, {
      coordinates: {
        lat: +data.coordinates.lat.toFixed(5),
        lng: +data.coordinates.lng.toFixed(5),
      },
      outline_coordinates,
    });
    startLoading();
    dispatch(createArea(payload)).then((resAction) => {
      stopLoading();
      if (createArea.fulfilled.match(resAction)) {
        dispatch(
          snackAlertOpened({
            message: t("wif.injury.snackMessages.reportReopened.success"),
            severity: "success",
          }),
        );
        handleClose();
      } else {
        if (resAction.payload) {
          dispatch(
            snackAlertOpened({
              message: t("wif.injury.snackMessages.reportReopened.", {
                message: JSON.stringify(resAction.payload),
              }),
              severity: "error",
            }),
          );
        } else {
          dispatch(
            snackAlertOpened({
              message: t("wif.injury.snackMessages.errorWithMessage", {
                message: JSON.stringify(resAction.error),
              }),
              severity: "error",
            }),
          );
        }
      }
    });
    console.log(
      `CreateAreaDialog: handleSubmit: payload: \n${JSON.stringify(
        payload,
        null,
        2,
      )}`,
    );
  };

  return (
    <FullScreenDialog open={open} handleClose={handleClose} title="New area">
      <Container maxWidth="md">
        <Stack direction="column" gap={3} pt={5} pb={3}>
          <Stack direction="column" gap={0.5}>
            <Typography variant="h1">Create a new area</Typography>
            <Typography variant="body1" color="text.secondary">
              An area is a location inside a site, such as a classroom or an
              office.
            </Typography>
          </Stack>
          <AppForm<ICreateAreaPayload, typeof createAreaPayloadSchema>
            schema={createAreaPayloadSchema}
            onSubmit={handleSubmit}
            id="create-area-form"
          >
            {(methods) => <CreateAreaControls methods={methods} />}
          </AppForm>
          <AppActions>
            <Button color="plain" variant="contained" onClick={handleClose}>
              {t("wif.injury.cancel")}
            </Button>
            <Button color="primary" type="submit" form="create-area-form">
              Create area
            </Button>
          </AppActions>
        </Stack>
      </Container>
    </FullScreenDialog>
  );
}
