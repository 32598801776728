import * as React from "react";
import * as yup from "yup";
import { FormTextField } from "../../../../components/FormInputs";
import type { WithFormMethods } from "../../../../components/Forms";
import { AppForm } from "../../../../components/Forms/AppForm";
import { FormCoordinateMap } from "../../../../components/FormInputs/FormCoordinateMap";
import { Stack } from "@mui/material";
import { FormSectionSubHeader } from "../../../../components/FormInputs/FormSectionSubheader";
import { FormSingleAutocomplete } from "../../../../components/FormInputs/FormSingleAutocomplete";
import { SiteTypeIconNames, SiteTypeOptions } from "../SiteIcon";
import { useLoading } from "../../../../common/useLoading";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { createSite } from "../../locationsThunks";
import { snackAlertOpened } from "../../../global/controlsSlice";
import { useTranslation } from "react-i18next";
import {
  makeSelectDepartmentIdsLeadByUser,
  selectDepartmentEntities,
} from "../../../departments/departmentsSlice";
import { getActiveUser } from "../../../../utils/user";

export const createSiteFormSchema = yup.object({
  name: yup.string().required(),
  type: yup.string().required("Type is required"),
  department_id: yup.string().required("Type is required"),
  address: yup.string().required(),
  description: yup.string().optional().notRequired(),
  coordinates: yup
    .object()
    .shape({
      lat: yup.number().required("Latitude is required"),
      lng: yup.number().required("Longitude is required"),
    })
    .required("Coordinates are required"),
});

export type ICreateSitePayload = yup.InferType<typeof createSiteFormSchema>;

export const CreateSiteControls = (
  props: WithFormMethods<ICreateSitePayload>,
) => {
  const { methods } = props;
  const { control, watch, setValue } = methods;

  const user = getActiveUser();
  const selectDepartmentIds = React.useMemo(
    makeSelectDepartmentIdsLeadByUser,
    [],
  );

  const departmentIds = useAppSelector((state) =>
    selectDepartmentIds(state, user?.id),
  );
  const departments = useAppSelector(selectDepartmentEntities);

  const departmentLabels = React.useMemo(() => {
    const labels = {};
    for (const id of departmentIds) {
      labels[id] = departments[id].name;
    }
    return labels;
  }, [departmentIds]);

  return (
    <>
      <FormTextField
        control={control}
        name="name"
        label="Site name"
        placeholder="Site name"
      />
      <FormSingleAutocomplete
        name="type"
        label="Site type"
        control={control}
        options={SiteTypeOptions}
        optionIconNames={SiteTypeIconNames}
      />
      <FormSingleAutocomplete
        name="department_id"
        label="Department"
        control={control}
        options={departmentIds}
        optionLabels={departmentLabels}
      />
      <FormTextField
        control={control}
        name="description"
        label="Description (optional)"
        placeholder="Description"
        multiline
        maxRows={4}
      />
      <FormSectionSubHeader>Location</FormSectionSubHeader>
      <FormCoordinateMap
        control={control}
        name="coordinates"
        addressFieldName="address"
        setValue={setValue}
      />
      <FormTextField
        control={control}
        name="address"
        label="Address"
        placeholder="Address"
      />
    </>
  );
};

export const CreateSiteForm = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [{ isLoading, loadingMessage }, { startLoading, stopLoading }] =
    useLoading(false);

  const handleSubmit = (data) => {
    const payload = Object.assign({}, data, {
      coordinates: {
        lat: +data.coordinates.lat.toFixed(5),
        lng: +data.coordinates.lng.toFixed(5),
      },
    });
    startLoading();
    dispatch(createSite(payload)).then((resAction) => {
      stopLoading();
      if (createSite.fulfilled.match(resAction)) {
        dispatch(
          snackAlertOpened({
            message: t("wif.injury.snackMessages.reportReopened.success"),
            severity: "success",
          }),
        );
      } else {
        if (resAction.payload) {
          dispatch(
            snackAlertOpened({
              message: t("wif.injury.snackMessages.reportReopened.", {
                message: JSON.stringify(resAction.payload),
              }),
              severity: "error",
            }),
          );
        } else {
          dispatch(
            snackAlertOpened({
              message: t("wif.injury.snackMessages.errorWithMessage", {
                message: JSON.stringify(resAction.error),
              }),
              severity: "error",
            }),
          );
        }
      }
    });
  };

  return (
    <Stack direction="column" gap={3}>
      <AppForm<ICreateSitePayload, typeof createSiteFormSchema>
        schema={createSiteFormSchema}
        onSubmit={handleSubmit}
        id="create-site-form"
      >
        {(methods) => <CreateSiteControls methods={methods} />}
      </AppForm>
    </Stack>
  );
};
