import { Box, Stack, Typography } from "@mui/material";
import { useCurrentArea } from "../../locationsHooks";
import { StyledDetailsCardContent } from "../../../../components/DetailsCard/DetailsCard";
import React from "react";
import { useTranslation } from "react-i18next";
import { makeSelectAreaLogsByAreaId } from "../../locationsSlice";
import {
  type RootState,
  useAppDispatch,
  useAppSelector,
} from "../../../../store";
import { fetchAreaLogs } from "../../locationsThunks";
import { useLoading } from "../../../../common/useLoading";
import { AppLogItemSkeleton } from "../../../../components/AppLogItem/AppLogItemSkeleton";
import { AreaLogItem } from "../History/AreaLogItem";

export const AreaDetailsHistoryPanel = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const area = useCurrentArea();

  const [{ isLoading, loadingMessage }, { startLoading, stopLoading }] =
    useLoading(false);

  const selectAreaLogsByAreaId = React.useMemo(makeSelectAreaLogsByAreaId, [
    area?.area_id,
  ]);

  const areaLogs = useAppSelector((state: RootState) =>
    selectAreaLogsByAreaId(state, area?.area_id),
  );

  React.useEffect(() => {
    if (areaLogs.length === 0) {
      startLoading();
      dispatch(fetchAreaLogs(area?.area_id)).then((resAction) => {
        stopLoading();
      });
    }
  }, [areaLogs]);

  return (
    <StyledDetailsCardContent
      sx={{
        px: "0px!important",
        py: 1,
        overflowY: "auto",
      }}
    >
      <Stack direction="column" sx={{ overflowY: "auto", py: 1 }}>
        {isLoading ? (
          <>
            <AppLogItemSkeleton />
            <AppLogItemSkeleton />
            <AppLogItemSkeleton />
          </>
        ) : (
          <>
            {areaLogs.length > 0 ? (
              <>
                {areaLogs.map((log, index) => (
                  <AreaLogItem key={index} log={log} />
                ))}
              </>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body1">
                  {t("wif.injury.noActivity")}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Stack>
    </StyledDetailsCardContent>
  );
};
