import { useRef } from "react";
import MapGL, { Marker } from "react-map-gl";
import mapMarkerImg from "../../assets/mapmarker.svg";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material";
import { MAPBOX_TOKEN } from "../../features/sites/components/Map";
import type { NewCoordinates } from "../../features/locations/locationsSlice";

const mapStyles: any = {
  width: "100%",
  height: "100%",
  flex: 1,
  overflow: "hidden",
};

type SingleMarkerMapProps = {
  coordinates: NewCoordinates;
};

export default function SingleMarkerMap(props: SingleMarkerMapProps) {
  const { coordinates } = props;

  const theme = useTheme();
  const mapRef = useRef<any>(null);
  return (
    <Box
      sx={{
        width: "100%",
        height: "160px",
        borderRadius: 1,
        overflow: "hidden",
      }}
    >
      <MapGL
        latitude={coordinates.lat}
        longitude={coordinates.lng}
        zoom={10}
        style={mapStyles}
        maxPitch={85}
        mapStyle={"mapbox://styles/emkf/clhleppw801np01pg76wi2jkx"}
        mapboxAccessToken={MAPBOX_TOKEN}
        ref={mapRef}
      >
        <Marker longitude={coordinates.lng} latitude={coordinates.lat}>
          <img src={mapMarkerImg} style={{ transform: "scale(0.75)" }} />
        </Marker>
      </MapGL>
    </Box>
  );
}
