import React from "react";
import {
  DataGrid,
  type GridColumnVisibilityModel,
  type GridColDef,
  type GridSortModel,
  type GridEventListener,
} from "@mui/x-data-grid";
import type { IFilterControl, IFilters } from "./types";
import { DataGridContainer } from "./DataGridContainer";
import { RDataGridToolbar } from "./RDataGridToolbar";
import { RDataGridPagination } from "./RDataGridPagination";
import { useTranslation } from "react-i18next";
import { TKB_INJURY } from "../../features/injuries/constants";
import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import type { RSelectedItemToolbarProps } from "./RDataGridSelectedToolbar";
import { rPassFilterLogic } from "./rDataGridFilterUtils";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));

const CustomTextField = (props: any) => {
  return <TextField {...props} variant="outlined" size="small" />;
};

type RDataGridProps = {
  items: readonly any[];
  cols: GridColDef[];
  filters?: IFilters;
  addableControls?: {
    controls: IFilterControl[];
    handleAddFilterControl: (control: IFilterControl) => void;
  };
  initialColumnVisibilityModel?: GridColumnVisibilityModel;
  initialSortModel?: any;
  loading?: boolean;
  sortModel?: any;
  handleChangeSortModel?: (model: GridSortModel) => void;
  columnVisibilityModel?: GridColumnVisibilityModel;
  handleChangeColumnVisibilityModel?: (
    model: GridColumnVisibilityModel,
  ) => void;
  onRowClick?: GridEventListener<"rowClick">;
  onRowDoubleClick?: GridEventListener<"rowDoubleClick">;
  selected?: RSelectedItemToolbarProps;
};

export const RDataGrid = (props: RDataGridProps) => {
  const {
    items,
    cols,
    filters,
    addableControls,
    initialColumnVisibilityModel,
    initialSortModel = {
      field: "reportedTimestamp",
      sort: "desc",
    },
    loading,
    sortModel,
    handleChangeSortModel,
    columnVisibilityModel,
    handleChangeColumnVisibilityModel,
    onRowDoubleClick,
    onRowClick,
    selected,
  } = props;

  const { t } = useTranslation();

  const activeFilters = filters?.active || [];

  const rows = React.useMemo(() => {
    let filteredItems: any = items.slice();
    filteredItems = filteredItems.filter((item: any) => {
      return rPassFilterLogic(activeFilters as any, item);
    });
    return filteredItems;
  }, [items, activeFilters]);

  return (
    <DataGridContainer>
      <DataGrid
        onRowDoubleClick={onRowDoubleClick}
        onRowClick={onRowClick}
        loading={loading}
        rows={rows}
        columns={cols}
        hideFooterSelectedRowCount
        columnVisibilityModel={columnVisibilityModel}
        sortModel={sortModel}
        onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
        onSortModelChange={handleChangeSortModel}
        localeText={{
          toolbarColumns: "",
          toolbarExport: "",
          noResultsOverlayLabel: t(
            `${TKB_INJURY}.datagrid.noResultsOverlayLabel`,
          ),
          noRowsLabel: t(`${TKB_INJURY}.datagrid.noRowsLabel`),
          toolbarQuickFilterPlaceholder: t(`${TKB_INJURY}.datagrid.searchText`),
          columnsPanelShowAllButton: t(`${TKB_INJURY}.datagrid.showAll`),
          columnsPanelHideAllButton: t(`${TKB_INJURY}.datagrid.hideAll`),
          toolbarExportCSV: t(`${TKB_INJURY}.datagrid.exportCSV`),
          toolbarExportPrint: t(`${TKB_INJURY}.datagrid.exportPrint`),
          columnMenuFilter: t(`${TKB_INJURY}.datagrid.filter`),
          columnMenuSortAsc: t(`${TKB_INJURY}.datagrid.sortAsc`),
          columnMenuSortDesc: t(`${TKB_INJURY}.datagrid.sortDesc`),
          columnMenuHideColumn: t(`${TKB_INJURY}.datagrid.hideColumn`),
          columnMenuManageColumns: t(`${TKB_INJURY}.datagrid.manageColumns`),
          columnsPanelTextFieldLabel: t(
            `${TKB_INJURY}.datagrid.columnsPanelTextFieldLabel`,
          ),
          columnsPanelTextFieldPlaceholder: t(
            `${TKB_INJURY}.datagrid.columnsPanelTextFieldPlaceholder`,
          ),
        }}
        slots={{
          toolbar: RDataGridToolbar,
          pagination: RDataGridPagination,
          baseTextField: CustomTextField,
        }}
        columnHeaderHeight={48}
        slotProps={{
          toolbar: {
            filters:
              {
                ...filters,
                controls: filters.controls,
              } || undefined,
            addableControls: addableControls || undefined,
            selected: selected || undefined,
          },
          columnsPanel: {},
        }}
        pageSizeOptions={[25]}
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
          columns: {
            columnVisibilityModel: initialColumnVisibilityModel,
          },
        }}
      />
    </DataGridContainer>
  );
};
