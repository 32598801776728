import type { TextFieldProps } from "@mui/material";
import type { Control, FieldPath } from "react-hook-form";
import { Controller, useFormContext } from "react-hook-form";
import type { FieldValues } from "react-hook-form/dist/types";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/nb";
import React from "react";
import { useTranslation } from "react-i18next";

type Props<
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = TextFieldProps & {
  control?: Control<TFieldValues, any>;
  name: TName;
  label?: string;
  required?: never;
  formatTemplate?: string;
  maxDate?: boolean | string;
  controllerProps?: any;
  datePickerProps?: any;
  textFieldProps?: any;
};

export function FormDatePicker<TFieldValues extends FieldValues>(
  props: Props<TFieldValues>,
) {
  const {
    control = useFormContext<TFieldValues>().control,
    name,
    label = "Label",
    maxDate,
    controllerProps,
    datePickerProps,
    textFieldProps,
  } = props;

  const { t } = useTranslation();
  const getTranslation = React.useCallback(
    (key: string) => {
      const keyWithValues = key.split(" ");
      const translationKey = keyWithValues[0];
      if (
        translationKey === "max" ||
        translationKey === "min" ||
        translationKey === "minmax"
      ) {
        return t(`wif.injury.errors.${translationKey}`, {
          i: keyWithValues[1],
        });
      } else if (translationKey === "between") {
        return t(`wif.injury.errors.${translationKey}`, {
          min: keyWithValues[1],
          max: keyWithValues[2],
        });
      }
      return t(`wif.injury.errors.generic`);
    },
    [t],
  );

  return (
    <Controller
      control={control}
      name={name}
      {...controllerProps}
      render={({ field, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb">
          <DatePicker
            label={label}
            maxDate={
              maxDate
                ? typeof maxDate === "string"
                  ? dayjs(maxDate)
                  : dayjs()
                : undefined
            }
            value={field.value && dayjs(field.value)}
            onChange={(newValue: any) => field.onChange(newValue?.format())}
            {...datePickerProps}
            slotProps={{
              textField: {
                ...textFieldProps,
                error: !!fieldState.error,
                helperText: fieldState?.error?.message
                  ? getTranslation(fieldState.error.message)
                  : null,
              },
            }}
            sx={{ flex: 1 }}
          />
        </LocalizationProvider>
      )}
    />
  );
}
