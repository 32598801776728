import { EMPLOYEE_SECTION_NAMES } from "../../constants/employee";
import { STUDENT_SECTION_NAMES } from "../../constants/student";
import {
  EmployeeAccidentControls,
  EmployerControls,
  InjuredEmployeeControls,
} from "../FormSections/employee";
import { BaseIllnessControls } from "../FormSections";
import {
  InjuredStudentControls,
  SchoolControls,
  StudentAccidentControls,
} from "../FormSections/student";
import { BaseFurtherInformationControls } from "../FormSections/BaseFurtherInformationSection";
import { EmployeeTypesControls } from "../FormSections/employee/EmployeeTypesSection";
import { StudentMinorInjuryControls } from "../FormSections/student/StudentMinorInjurySection";
import { EmployeeMinorInjuryControls } from "../FormSections/employee/EmployeeMinorInjurySection";

type SectionInputMapperProps = {
  methods: any;
  sectionName: string;
};

export const SectionControlsMapper = (props: SectionInputMapperProps) => {
  const { methods, sectionName } = props;

  switch (sectionName) {
    case STUDENT_SECTION_NAMES.FURTHER_INFORMATION:
    case EMPLOYEE_SECTION_NAMES.FURTHER_INFORMATION:
      return <BaseFurtherInformationControls methods={methods} />;
    case STUDENT_SECTION_NAMES.STUDENT:
      return <InjuredStudentControls methods={methods} />;
    case STUDENT_SECTION_NAMES.MINOR_INJURY:
      return <StudentMinorInjuryControls methods={methods} />;
    case STUDENT_SECTION_NAMES.SCHOOL:
      return <SchoolControls methods={methods} />;
    case STUDENT_SECTION_NAMES.ILLNESS:
      return <BaseIllnessControls methods={methods} />;
    case STUDENT_SECTION_NAMES.ACCIDENT:
      return <StudentAccidentControls methods={methods} />;
    case EMPLOYEE_SECTION_NAMES.MINOR_INJURY:
      return <EmployeeMinorInjuryControls methods={methods} />;
    case EMPLOYEE_SECTION_NAMES.EMPLOYER:
      return <EmployerControls methods={methods} />;
    case EMPLOYEE_SECTION_NAMES.ILLNESS:
      return <BaseIllnessControls methods={methods} />;
    case EMPLOYEE_SECTION_NAMES.ACCIDENT:
      return <EmployeeAccidentControls methods={methods} />;
    case EMPLOYEE_SECTION_NAMES.EMPLOYEE:
      return <InjuredEmployeeControls methods={methods} />;
    case EMPLOYEE_SECTION_NAMES.EMPLOYEE_ACCIDENT_TYPES:
      return <EmployeeTypesControls methods={methods} />;
    default:
      return <></>;
  }
};
