import { Box } from "@mui/material";
import "material-symbols";
import { useTheme } from "@mui/material/styles";
import "./AppIcon.css";

export interface AppIconProps {
  iconName?: string;
  fill?: number;
  weight?: number;
  grade?: number;
  opticalSize?: number;
  color?: string;
  sx?: any;
  wrapperClassName?: string;
  variant?: "outlined" | "rounded" | "sharp";
}

export function AppIcon(props: AppIconProps) {
  const {
    iconName = "filter_list",
    fill = 0,
    weight = 400,
    grade,
    opticalSize = 20,
    color,
    sx,
    wrapperClassName,
    variant = "outlined",
  } = props;

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "inline-block",
        ...sx,
        height: `${opticalSize}px`,
        width: `${opticalSize}px`,
        alignSelf: "center",
        verticalAlign: "center",
        justifySelf: "center",
        ...(color && {
          color: color,
        }),
      }}
      className={wrapperClassName && wrapperClassName}
    >
      <span
        className={`material-symbols-${variant}`}
        style={{
          display: "block",
          fontSize: `${opticalSize}px`,
          fontVariationSettings: `'FILL' ${fill}, 'wght' ${weight}, 'GRAD' ${
            grade ? grade : theme.palette.mode === "light" ? 0 : -25
          }, 'opsz' ${opticalSize}`,
          height: `${opticalSize}px`,
          width: `${opticalSize}px`,
        }}
      >
        {iconName}
      </span>
    </Box>
  );
}
